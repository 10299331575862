import React, { MutableRefObject } from 'react';
import { ReactComponent as Bench } from './images/bench.svg';
import { ReactComponent as Rail } from './images/rail.svg';
import { ReactComponent as Manipulator } from './images/manipulator_hand.svg';
import { LayoutItem } from '../../domain/layout';
import { toCanvasCoords } from './utils';
import { distance1d } from '../../domain/geometry/utils';
import { BluePrintService } from '../../services/BluePrintService';

interface IProps {
  bluePrintService: BluePrintService;
  canvasRef: React.MutableRefObject<HTMLDivElement | null>;
}

const renderModel = (item: LayoutItem) => {
  if (item.view === 'small_bench') {
    return (
      <g
        style={{
          transformBox: 'fill-box',
          transformOrigin: '50% 50%',
          transform: `translate(-50%, -50%) rotate(${-1 * item.angle}deg)`
        }}
      >
        <Rail />
      </g>
    );
  }

  if (item.view === 'manipulator_hand') {
    return (
      <g
        style={{
          transformBox: 'fill-box',
          transformOrigin: '50% 50%',
          transform: `translate(-50%, -50%) rotate(${-1 * item.angle}deg)  translate(60px, 16px)`
        }}
      >
        <Manipulator />
      </g>
    );
  }

  if (item.view === 'rail') {
    return null;
  }

  if (item.payload.type === 'section') {
    if (item.view === 'main_bench' || item.view === 'single_bench') {
      return (
        <g
          style={{
            transformBox: 'fill-box',
            transformOrigin: '50% 50%',
            transform: `translate(-50%, -50%) rotate(${-1 * item.angle}deg)`
          }}
        >
          <Bench />
        </g>
      );
    }
  }
};

const LINE_COLOUR = 'rgba(228, 228, 228, 1)';
const DEFAULT_FONT_SIZE = 10;

export const BlueprintViewer = ({ bluePrintService, canvasRef: externalRef }: IProps) => {
  const {
    dimensions,
    topLeftCorner,
    topRightCorner,
    bottomLeftCorner,
    bottomRightCorner,
    padding,
    verticalDimensionsSide
  } = bluePrintService;

  const bluePrintRef = React.useRef<HTMLDivElement>(null);

  const minX = toCanvasCoords(topLeftCorner.x) - padding.left;
  const minY = toCanvasCoords(topLeftCorner.y) - padding.top;
  const svgWidth = toCanvasCoords(distance1d(topLeftCorner.x, topRightCorner.x)) + padding.left + padding.right;
  const svgHeight = toCanvasCoords(distance1d(topLeftCorner.y, bottomLeftCorner.y)) + padding.top + padding.bottom;

  // const textSize = React.useMemo(() => {
  //   return width ? (svgWidth * DEFAULT_FONT_SIZE) / width : DEFAULT_FONT_SIZE;
  // }, [width, topLeftCorner, topRightCorner, padding]);
  const textSize = DEFAULT_FONT_SIZE;

  return (
    <div
      className="w-full"
      ref={element => {
        if (externalRef) {
          externalRef.current = element;
        }
        if (bluePrintRef) {
          (bluePrintRef as MutableRefObject<HTMLDivElement | null>).current = element;
        }
      }}
    >
      <svg width="100%" viewBox={`${minX} ${minY} ${svgWidth} ${svgHeight}`}>
        <g>
          {/* Dimensions */}
          <g>
            <g transform={`translate(0 -45)`}>
              {dimensions.horizontal.map((points, index) => {
                return (
                  <g key={`hd-${index}`} transform={`translate(0 -${index * 30})`}>
                    <line
                      x1={toCanvasCoords(topLeftCorner.x)}
                      y1={toCanvasCoords(topLeftCorner.y)}
                      x2={toCanvasCoords(topRightCorner.x)}
                      y2={toCanvasCoords(topRightCorner.y)}
                      stroke={LINE_COLOUR}
                    />
                    {points.map((point, index) => {
                      const dist = index !== 0 ? distance1d(points[index - 1], points[index]) : 0;

                      return (
                        <React.Fragment key={`hdt-${index}`}>
                          {dist !== 0 && (
                            <g style={{ transform: `translateY(${/*dist > 230 ? 3 : 15*/ 3}px)` }}>
                              <text
                                fontSize={textSize}
                                textAnchor="middle"
                                strokeWidth={10}
                                stroke="white"
                                strokeLinejoin="round"
                                paintOrder="stroke"
                                x={toCanvasCoords(points[index - 1] + dist / 2)}
                                y={toCanvasCoords(topLeftCorner.y)}
                              >
                                {dist}
                              </text>
                              <text
                                fontSize={textSize}
                                textAnchor="middle"
                                x={toCanvasCoords(points[index - 1] + dist / 2)}
                                y={toCanvasCoords(topLeftCorner.y)}
                              >
                                {dist}
                              </text>
                            </g>
                          )}
                          <line
                            x1={toCanvasCoords(point)}
                            y1={toCanvasCoords(topLeftCorner.y - 40)}
                            x2={toCanvasCoords(point)}
                            y2={toCanvasCoords(topLeftCorner.y + 40)}
                            stroke={LINE_COLOUR}
                          />
                        </React.Fragment>
                      );
                    })}
                    )
                  </g>
                );
              })}
            </g>

            {/* right side */}
            {verticalDimensionsSide === 'right' && (
              <g transform={`translate(50 0)`}>
                {dimensions.vertical.map((points, index) => {
                  return (
                    <g key={`vd-${index}`} transform={`translate(${index * 30} 0)`}>
                      <line
                        x1={toCanvasCoords(topRightCorner.x)}
                        y1={toCanvasCoords(topRightCorner.y)}
                        x2={toCanvasCoords(bottomRightCorner.x)}
                        y2={toCanvasCoords(bottomRightCorner.y)}
                        stroke={LINE_COLOUR}
                      />
                      {points.map((point, index) => {
                        const dist = index !== 0 ? distance1d(points[index - 1], points[index]) : 0;

                        return (
                          <React.Fragment key={`vdt-${index}`}>
                            {dist !== 0 && (
                              <g
                                style={{
                                  transformBox: 'fill-box',
                                  transformOrigin: '50% 50%',
                                  transform: `rotate(-90deg) translateX(-12%) translateY(${
                                    /*dist > 230 ? 0 : -15*/ 0
                                  }px)`
                                }}
                              >
                                <text
                                  fontSize={textSize}
                                  textAnchor="middle"
                                  strokeWidth={10}
                                  stroke="white"
                                  strokeLinejoin="round"
                                  paintOrder="stroke"
                                  x={toCanvasCoords(topRightCorner.x)}
                                  y={toCanvasCoords(points[index - 1] + dist / 2)}
                                >
                                  {dist}
                                </text>
                                <text
                                  fontSize={textSize}
                                  textAnchor="middle"
                                  x={toCanvasCoords(topRightCorner.x)}
                                  y={toCanvasCoords(points[index - 1] + dist / 2)}
                                >
                                  {dist}
                                </text>
                              </g>
                            )}
                            <line
                              x1={toCanvasCoords(topRightCorner.x - 40)}
                              y1={toCanvasCoords(point)}
                              x2={toCanvasCoords(topRightCorner.x + 40)}
                              y2={toCanvasCoords(point)}
                              stroke={LINE_COLOUR}
                            />
                          </React.Fragment>
                        );
                      })}
                    </g>
                  );
                })}
              </g>
            )}

            {/* left side */}
            {verticalDimensionsSide === 'left' && (
              <g transform={`translate(-50 0)`}>
                {dimensions.vertical.map((points, index) => {
                  return (
                    <g key={`vd-${index}`} transform={`translate(-${index * 30} 0)`}>
                      <line
                        x1={toCanvasCoords(topLeftCorner.x)}
                        y1={toCanvasCoords(topLeftCorner.y)}
                        x2={toCanvasCoords(bottomLeftCorner.x)}
                        y2={toCanvasCoords(bottomLeftCorner.y)}
                        stroke={LINE_COLOUR}
                      />
                      {points.map((point, index) => {
                        const dist = index !== 0 ? distance1d(points[index - 1], points[index]) : 0;

                        return (
                          <React.Fragment key={`vdt-${index}`}>
                            {dist !== 0 && (
                              <g
                                style={{
                                  transformBox: 'fill-box',
                                  transformOrigin: '50% 50%',
                                  transform: `rotate(-90deg) translateX(-12%) translateY(${
                                    /*dist > 230 ? 0 : -15*/ 0
                                  }px)`
                                }}
                              >
                                <text
                                  fontSize={textSize}
                                  textAnchor="middle"
                                  strokeWidth={10}
                                  stroke="white"
                                  strokeLinejoin="round"
                                  paintOrder="stroke"
                                  x={toCanvasCoords(topLeftCorner.x)}
                                  y={toCanvasCoords(points[index - 1] + dist / 2)}
                                >
                                  {dist}
                                </text>
                                <text
                                  fontSize={textSize}
                                  textAnchor="middle"
                                  x={toCanvasCoords(topLeftCorner.x)}
                                  y={toCanvasCoords(points[index - 1] + dist / 2)}
                                >
                                  {dist}
                                </text>
                              </g>
                            )}
                            <line
                              x1={toCanvasCoords(topLeftCorner.x - 40)}
                              y1={toCanvasCoords(point)}
                              x2={toCanvasCoords(topLeftCorner.x + 40)}
                              y2={toCanvasCoords(point)}
                              stroke={LINE_COLOUR}
                            />
                          </React.Fragment>
                        );
                      })}
                    </g>
                  );
                })}
              </g>
            )}
          </g>

          {/* Elements */}
          {bluePrintService.viewModel.benches.map((bench, index) => {
            return (
              <g
                key={`${bench.groupId}-${index}`}
                transform={`translate(${toCanvasCoords(bench.centerX)} ${toCanvasCoords(bench.centerY)})`}
              >
                {renderModel(bench)}
              </g>
            );
          })}
        </g>
      </svg>
    </div>
  );
};
