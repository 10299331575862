import { useLayoutEffect, useMemo, useState } from 'react';

export const useResizeObserver = (elementToObserve: HTMLElement | null) => {
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [top, setTop] = useState<number>();
  const [left, setLeft] = useState<number>();
  const [bottom, setBottom] = useState<number>();

  useLayoutEffect(() => {
    if (!elementToObserve) {
      return undefined;
    }

    const resizeObserver = new ResizeObserver(entries => {
      if (entries.length === 0) {
        return;
      }
      const entry = entries[0];
      const contentRect = entry.target.getBoundingClientRect();

      // `Math.round` is in line with how CSS resolves sub-pixel values
      setWidth(Math.round(contentRect.width));
      setHeight(Math.round(contentRect.height));
      setTop(contentRect.top);
      setLeft(contentRect.left);
      setBottom(contentRect.bottom);
    });

    resizeObserver.observe(elementToObserve);

    return () => {
      resizeObserver.unobserve(elementToObserve);
    };
  }, [elementToObserve]);

  return useMemo(() => ({ width, height, top, left, bottom }), [bottom, height, left, top, width]);
};
