import { useEffect, useState } from 'react';
import { RawIntlProvider } from 'react-intl';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { localization } from './stores/Localization';
import { NotificationProvider } from './components/Notification/NotificationProvider';
import { ScrollToTop } from './components/ScrollToTop';
import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const Root = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    localization.init().then(() => setLoaded(true));
  }, []);

  return (
    // <React.StrictMode>
    <HashRouter>
      {loaded && (
        <NotificationProvider>
          <RawIntlProvider value={localization.intl!}>
            <App />
            <ScrollToTop />
          </RawIntlProvider>
        </NotificationProvider>
      )}
    </HashRouter>
    // </React.StrictMode>
  );
};
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
