import React, { ReactNode } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { cn } from './utils';
import { rootStore } from 'stores/RootStore';
import { ViewHelpGuideEventGA } from 'services/GoogleAnalyticsService';

export const Floater = ({
  trigger,
  children,
  options,
  className
}: {
  trigger: ({ open }: { open: boolean }) => ReactNode;
  children: ReactNode;
  options: Popover.PopoverContentProps;
  className?: string;
}) => {
  const [isHelpShown, setIsHelpShown] = React.useState(false);

  const handleShowHelp = () => {
    if (!isHelpShown) {
      rootStore.gaEvent(new ViewHelpGuideEventGA(), 'Configurator');
    }
    setIsHelpShown(!isHelpShown);
  };

  return (
    <Popover.Root onOpenChange={handleShowHelp}>
      <Popover.Trigger>{trigger({ open: isHelpShown })}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side={'right'}
          sideOffset={30}
          className={cn(
            'z-50 rounded-md  bg-neutral-0 p-10 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className
          )}
          {...options}
        >
          <Popover.Arrow className="fill-neutral-0" width={16} height={8} />
          {children}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
