import { Button, Icon, IconButton } from '../../../components';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from '../../../components/Button';
import React from 'react';

interface RouletteButtonProps extends ButtonProps {
  onClick: () => void;
  active: boolean;
}

export const RouletteButton = ({ onClick, active, variant = 'clear' }: RouletteButtonProps) => {
  return (
    <>
      <Button
        variant={variant}
        size="lg"
        withIcon
        className={`max-md:hidden ${active ? 'fill-primary-900 text-primary-900' : ''}`}
        onClick={onClick}
      >
        <Button.ButtonIcon>
          <Icon icon="straighten" />
        </Button.ButtonIcon>
        <Button.ButtonText>
          <FormattedMessage id="scene.buttonDimensions" />
        </Button.ButtonText>
      </Button>
      <IconButton size="lg" variant="filled" className="md:hidden" onClick={onClick}>
        <Icon icon="straighten" />
      </IconButton>
    </>
  );
};
