import { distance, getAngleBetween, positionEquals } from './utils';
import { Position, Segment, SegmentLine } from './types';

export class SegmentLinesBuilder {
  private lines: SegmentLine[] = [];

  build(): SegmentLine[] {
    return this.lines;
  }

  line(config: (segment: SegmentLineBuilder) => void): SegmentLinesBuilder {
    return this.lineAt({ x: 0, y: 0 }, config);
  }

  lineAt(position: Position, config: (segment: SegmentLineBuilder) => void): SegmentLinesBuilder {
    const segments: Segment[] = [];
    this.lines.push({ segments });
    config(new SegmentLineBuilder(segments, position));
    return this;
  }
}

class SegmentLineBuilder {
  constructor(private readonly segments: Segment[], private readonly position: Position) {}

  empty(): void {}

  up(size: number): SegmentLineBuilder {
    const start = { ...this.position };
    this.position.y -= size;
    const segment: Segment = {
      length: size,
      angle: 90,
      start,
      end: { ...this.position }
    };
    this.segments.push(segment);
    return this;
  }

  down(size: number): SegmentLineBuilder {
    const start = { ...this.position };
    this.position.y += size;
    const segment: Segment = {
      length: size,
      angle: -90,
      start,
      end: { ...this.position }
    };
    this.segments.push(segment);
    return this;
  }

  right(size: number): SegmentLineBuilder {
    const start = { ...this.position };
    this.position.x += size;
    const segment: Segment = {
      length: size,
      angle: 0,
      start,
      end: { ...this.position }
    };
    this.segments.push(segment);
    return this;
  }

  left(size: number): SegmentLineBuilder {
    const start = { ...this.position };
    this.position.x -= size;
    const segment: Segment = {
      length: size,
      angle: 180,
      start,
      end: { ...this.position }
    };
    this.segments.push(segment);
    return this;
  }

  build(): SegmentLine {
    return { segments: this.segments };
  }

  merge(segment: Segment): SegmentLineBuilder {
    if (!this.segments.length) {
      this.segments.push(segment);
      return this;
      // this.segments.push({ angle: segment.angle, start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, length: 0 });
    }
    const lastSegment = this.segments.at(-1)!;
    const angle = lastSegment.angle;

    if (segment.angle === angle) {
      if ((angle === 0 || angle === 180) && lastSegment.end.y !== segment.start.y) {
        throw new Error(`Invalid position.y ${lastSegment.end.y} <> ${segment.start.y}`);
      }
      if ((angle === 90 || angle === -90) && lastSegment.end.x !== segment.start.x) {
        throw new Error(`Invalid position.x ${lastSegment.end.x} <> ${segment.start.x}`);
      }
      lastSegment.end = segment.end;
      lastSegment.length = distance(lastSegment.start, lastSegment.end);
    } else {
      if (positionEquals(lastSegment.end, segment.start)) {
        this.segments.push(segment);
        return this;
      }
      const segmentPart: Segment = {
        start: lastSegment.end,
        end: segment.start,
        angle: lastSegment.angle,
        length: 0
      };
      segmentPart.length = distance(segmentPart.end, segmentPart.start);
      segmentPart.angle = getAngleBetween(segmentPart.start, segmentPart.end);
      return this.merge(segmentPart).merge(segment);
    }
    return this;
  }
}

export function createSegments(): SegmentLinesBuilder {
  return new SegmentLinesBuilder();
}

export function createSegmentLine(position: Position = { x: 0, y: 0 }): SegmentLineBuilder {
  return new SegmentLineBuilder([], position);
}
