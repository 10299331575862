import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { Box3, Color, DoubleSide, Group, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import { ProgressEventHandler } from './types';

export class ImagesLoader {
  cache = new Map<string, Group>();
  loader = new SVGLoader();

  async loadSVG(path: string, scale: number, onProgress: ProgressEventHandler = noop) {
    if (this.cache.has(path)) {
      return Promise.resolve(this.cache.get(path)!.clone());
    }
    return new Promise<Group>((resolve, reject) => {
      this.loader.load(
        path,
        data => {
          const paths = data.paths;
          const group = new Group();

          for (let i = 0; i < paths.length; i++) {
            const path = paths[i];

            const strokeColor = path.userData!.style.stroke;
            if (strokeColor !== undefined && strokeColor !== 'none') {
              const material = new MeshBasicMaterial({
                color: new Color().setStyle(strokeColor).convertSRGBToLinear(),
                opacity: path.userData!.style.strokeOpacity,
                transparent: true,
                side: DoubleSide,
                depthWrite: false,
                wireframe: false
              });

              for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
                const subPath = path.subPaths[j];
                const geometry = SVGLoader.pointsToStroke(subPath.getPoints(), path.userData!.style);
                if (geometry) {
                  const mesh = new Mesh(geometry, material);
                  group.add(mesh);
                }
              }
            }
          }
          group.scale.multiplyScalar(scale);
          group.scale.y *= -1;

          const bbox = new Box3();
          const center = new Vector3();

          bbox.setFromObject(group);
          bbox.getCenter(center);

          group.position.x = -center.x;
          group.position.y = -center.y;
          this.cache.set(path, group);
          resolve(group.clone());
        },
        onProgress,
        reject
      );
    });
  }
}

function noop() {}
