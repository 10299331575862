import * as THREE from 'three';
import {
  Camera,
  Color,
  HalfFloatType,
  Mesh,
  PCFSoftShadowMap,
  PerspectiveCamera,
  SRGBColorSpace,
  Texture,
  Vector2,
  Vector3
} from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { ConfigurationScene } from './ConfigurationScene';
import { BinarySearchCameraOptimizer, HorizontalCameraAligner } from '../camera';
import { OutlineEffect } from './OutlineEffect';

export const BACKGROUND_COLOR = 0xececec; //0xd1d1d1;

export class ConfigurationRenderer {
  private renderer!: THREE.WebGLRenderer;
  private scene!: ConfigurationScene;
  private camera!: Camera;
  private effect!: OutlineEffect;
  private width = 0;
  private height = 0;

  get domElement() {
    return this.renderer.domElement;
  }

  async init(
    width: number,
    height: number,
    element: HTMLCanvasElement,
    scene: ConfigurationScene,
    camera: Camera,
    hdrURL: string
  ): Promise<Texture> {
    this.width = width;
    this.height = height;
    this.scene = scene;
    this.camera = camera;
    this.renderer = new THREE.WebGLRenderer({
      canvas: element,
      antialias: true
    });
    this.renderer.setClearColor(BACKGROUND_COLOR);
    this.renderer.debug.checkShaderErrors = window.location.hostname === 'localhost';
    this.renderer.outputColorSpace = SRGBColorSpace;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    // this.renderer.shadowMap.autoUpdate = false;
    // this.renderer.shadowMap.needsUpdate = true;
    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.toneMappingExposure = 1;
    this.renderer.autoClear = false;
    this.effect = new OutlineEffect(this.renderer, {
      defaultColor: new Color(ConfigurationScene.ELEMENT_REPLACEMENT_SILHOUETTE_COLOR).toArray()
    });

    const pMREMGenerator = new THREE.PMREMGenerator(this.renderer);
    pMREMGenerator.compileEquirectangularShader();

    return new Promise<Texture>((resolve, reject) => {
      new RGBELoader()
        .setDataType(HalfFloatType)
        .setPath('assets/models/environment/')
        .load(
          hdrURL,
          texture => {
            this.scene.getScene().environment = pMREMGenerator.fromEquirectangular(texture).texture;
            texture.dispose();
            pMREMGenerator.dispose();
            resolve(pMREMGenerator.fromEquirectangular(texture).texture);
            this.renderer.render(this.scene.getScene(), this.camera);
          },
          //eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
          reject
        );
    });
  }

  setClearColor(color: Color) {
    this.renderer.setClearColor(color);
  }
  render() {
    this.renderer.clear();
    this.effect.render(this.scene.getScene(), this.camera);
  }

  resetObject() {
    this.effect.outlines = [];
  }

  render2d(target: Vector3) {
    let renderCamera = new PerspectiveCamera(30, 800 / 600, 0.1, 180);
    const position = ConfigurationRenderer.getResetPosition(target);

    this.setSize(800, 600);
    this.updateCamera(renderCamera, position, target);
    this.renderer.clear();
    this.renderer.render(this.scene.getScene(), renderCamera);
  }

  static getResetPosition(target: Vector3) {
    let target0 = new Vector3(3, -1, -3);
    const position0 = new Vector3(-10, 6, 9);
    const shift = target0.sub(target);
    return position0.sub(shift);
  }

  setSize(width: number, height: number) {
    this.renderer.setSize(width, height);
  }

  dispose() {
    this.renderer.dispose();
  }

  public updateCamera(camera: Camera, position: Vector3, lookAt: Vector3) {
    const { size, rootNode } = this.getPositioningParams();
    const targetState = this.getFitConfigurationTargetState(position, lookAt, camera);
    camera.position.set(targetState[0], targetState[1], targetState[2]);
    camera.lookAt(new Vector3(targetState[3], targetState[4], targetState[5]));

    const aligner = new HorizontalCameraAligner(size, camera, targetState, rootNode);
    aligner.alignCenter();
  }

  public getFitConfigurationTargetState(position: Vector3, lookAt: Vector3, camera: Camera) {
    const { size, rootNode } = this.getPositioningParams();
    const width = size.x;
    const height = size.y;
    const optimizer = BinarySearchCameraOptimizer.create(size, 0.5, 100);
    const desiredState = [...position.toArray(), ...lookAt.toArray()];
    camera.position.copy(position);
    camera.lookAt(lookAt);
    const infinity = 1e100;
    return optimizer.optimizeCameraPosition(camera, desiredState, rootNode, [
      // top
      { x: -infinity, width: infinity * 2 + width, y: -infinity, height: infinity },
      // bottom
      { x: -infinity, width: infinity * 2 + width, y: height, height: height + infinity },
      // left
      { x: -infinity, width: infinity, y: 0, height: height },
      // right
      { x: width, width: width + infinity, y: 0, height: height }
    ]);
  }

  private getPositioningParams() {
    const size = new Vector2();
    const rootNode = this.scene.getConfigurationNode();
    this.renderer.getSize(size);
    return { size, rootNode };
  }

  outlineObjects(selectedObjects: Mesh[]): void {
    this.effect.outlines = selectedObjects;
  }
}
