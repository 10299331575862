import { MutatorContext, TypeReference, ValidationResult } from '@canvas-logic/engine';
import { ElementPositionById } from '../layout';
import { IConfiguration } from '../../schema';
import { findBenchLocationByUUID } from './utils';
import { notFoundInMutator } from '../errors';
import { BaseBenchMutator } from './BaseBenchMutator';

export class DeleteBenchMutator extends BaseBenchMutator {
  public constructor(private readonly position: ElementPositionById) {
    super();
  }

  public override mutateBase(
    context: MutatorContext,
    typeRef: TypeReference,
    model: IConfiguration
  ): [IConfiguration, ValidationResult] {
    const { benches, index } = findBenchLocationByUUID(model, this.position.uuid);
    if (index >= 0) {
      benches.splice(index, 1);
      return [model, ValidationResult.Valid()];
    }
    return [model, ValidationResult.Error(notFoundInMutator())];
  }
}
