import { createContext } from 'react';
import { NotificationType } from './NotificationProvider';

const noop = (text: string) => {};

const Notification = createContext<{
  type: NotificationType | undefined;
  text: string;
  success: (text: string) => void;
  error: (text: string) => void;
  info: (text: string) => void;
  clear: () => void;
}>({
  type: undefined,
  text: '',
  success: noop,
  error: noop,
  info: noop,
  clear: () => {}
});

export default Notification;
