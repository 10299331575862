import { ConfigurationViewer } from '../domain/viewer';
import { GeometryElementList, IConfiguration, InstrumentCategory, InstrumentList, ISection } from '../schema';
import {
  backToBackShapeTypeGuard,
  customInstrumentTypeGuard,
  customShapeTypeGuard,
  sectionTypeGuard
} from '../domain/typeGuards';
import { BENCH_HEIGHT } from '../domain/viewer/ConfigurationScene';
import { BluePrintService } from './BluePrintService';
import { Sizes } from '../domain/geometry/types';

export type SummaryEquipment = {
  id: string;
  name: string;
  image: string;
  dimensions?: Sizes;
  count?: number;
};

export type SummaryReport = {
  sceneImage: string;
  topViewDimensions: Sizes;
  benches: SummaryEquipment[];
  instruments: SummaryEquipment[];
  customInstruments: SummaryEquipment[];
};

export const MANIPULATOR_DIMENSIONS: Sizes = { length: 1900, width: 530, height: 1890 };

export class SummaryService {
  private items: {
    benches: SummaryEquipment[];
    instruments: SummaryEquipment[];
    customInstruments: SummaryEquipment[];
  };

  constructor(
    private readonly bluePrintService: BluePrintService,
    private readonly viewer: ConfigurationViewer,
    private readonly model: IConfiguration
  ) {
    this.viewer = viewer;
    this.items = this.getSummaryItems();
  }

  private getStandardInstruments(rawData: InstrumentList): SummaryEquipment[] {
    return rawData
      .filter(instrument => instrument.category !== InstrumentCategory.custom)
      .map(item => ({
        id: item.uuid,
        name: item.name,
        image: item.image
      }));
  }

  private getCustomInstruments(rawData: InstrumentList): SummaryEquipment[] {
    return rawData.filter(customInstrumentTypeGuard).map(item => ({
      id: item.uuid,
      name: item.name,
      image: item.image,
      dimensions: {
        width: item.width,
        height: item.height,
        length: item.length
      }
    }));
  }

  private createBenchSummaryProfile(item: ISection, isBackToBack: boolean): SummaryEquipment[] {
    const benches: SummaryEquipment[] = [];

    const dimensions = {
      length: this.model.benchLength,
      width: this.model.benchWidth,
      height: BENCH_HEIGHT
    };

    const manipulator = {
      id: item.uuid + '_manipulator',
      name: `summary.${item.name}`,
      image: `assets/images/benches/scara_robot.jpg`,
      dimensions: MANIPULATOR_DIMENSIONS
    };

    const singleCore = {
      id: item.uuid,
      name: `summary.${item.name}`,
      image: item.image,
      dimensions: dimensions
    };
    //constant name and image, since singleTransport is not present in the current model, but is an interpretation of twin bench with manipulator
    const singleTransport = {
      id: item.uuid,
      name: `summary.bench.singleTransport`,
      image: `assets/images/benches/single_transport.jpg`,
      dimensions: dimensions
    };

    if (item.hasManipulator) {
      benches.push(manipulator);
      isBackToBack && benches.push(manipulator);
    }

    for (let i = 0; i < item.size; i++) {
      benches.push(item.hasManipulator ? singleTransport : singleCore);
      isBackToBack && benches.push(item.hasManipulator ? singleTransport : singleCore);
    }
    return benches;
  }

  private getBenches(rawData: GeometryElementList, isBackToBack = false): SummaryEquipment[] {
    const sections: ISection[] = rawData.filter(element => {
      return sectionTypeGuard(element);
    }) as ISection[];

    return sections.flatMap(item => {
      return this.createBenchSummaryProfile(item, isBackToBack);
    });
  }

  private getSummaryItems(): Pick<SummaryReport, 'instruments' | 'customInstruments' | 'benches'> {
    const instruments: SummaryEquipment[] = [];
    const customInstruments: SummaryEquipment[] = [];
    const benches: SummaryEquipment[] = [];

    this.model.shapes.forEach(shape => {
      if (backToBackShapeTypeGuard(shape)) {
        instruments.push(
          ...this.getStandardInstruments(shape.back.instruments),
          ...this.getStandardInstruments(shape.front.instruments)
        );
        customInstruments.push(
          ...this.getCustomInstruments(shape.back.instruments),
          ...this.getCustomInstruments(shape.front.instruments)
        );
        benches.push(...this.getBenches(shape.geometry, true));
      } else if (customShapeTypeGuard(shape)) {
        shape.lines.forEach(line => {
          instruments.push(...this.getStandardInstruments(line.instruments));
          customInstruments.push(...this.getCustomInstruments(line.instruments));
          benches.push(...this.getBenches(line.geometry));
        });
      } else {
        instruments.push(...this.getStandardInstruments(shape.instruments));
        customInstruments.push(...this.getCustomInstruments(shape.instruments));
        benches.push(...this.getBenches(shape.geometry));
      }
    });

    return { instruments, benches, customInstruments };
  }

  private itemsReducer(items: SummaryEquipment[]): SummaryEquipment[] {
    return items.reduce((acc: SummaryEquipment[], item) => {
      const { id, name, image, dimensions } = item;
      const existingItem = acc.find(obj => obj.name === name);
      if (existingItem) {
        existingItem.count!++;
      } else {
        const newItem: SummaryEquipment = { id, name, image, count: 1 };

        if (dimensions) {
          newItem.dimensions = dimensions;
        }

        acc.push(newItem);
      }
      return acc;
    }, []);
  }

  prepareSummaryData(): SummaryReport {
    const sceneImage = this.viewer.renderSceneImage();
    const topViewDimensions: Sizes = {
      length: this.bluePrintService.length,
      width: this.bluePrintService.width,
      height: MANIPULATOR_DIMENSIONS.height
    };

    return {
      sceneImage,
      topViewDimensions,
      benches: this.itemsReducer(this.items.benches),
      instruments: this.itemsReducer(this.items.instruments),
      customInstruments: this.itemsReducer(this.items.customInstruments)
    };
  }
}
