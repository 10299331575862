import * as THREE from 'three';

export type ProgressEventHandler = (event: ProgressEvent) => void;
export type Name = string;
export type Path = string;

export enum ResourceType {
  MODEL,
  MATERIAL,
  IMAGE
}

type ModelResource = {
  type: ResourceType.MODEL;
  path: string;
};
export type MaterialResource = {
  type: ResourceType.MATERIAL;
  path: string;
};
export type ImageResource = {
  type: ResourceType.IMAGE;
  scale: number;
  path: string;
};
export type Resource = ModelResource | MaterialResource | ImageResource;
type ResourceReturnType<T extends Resource> = T['type'] extends ResourceType.MATERIAL ? THREE.Material : THREE.Object3D;
export type ResourceTransactionToReturn<T extends readonly Resource[]> = {
  [K in keyof T]: T[K] extends Resource ? ResourceReturnType<T[K]> : never;
};
export type LoadableResource = Resource & {
  total: number;
  loaded: number;
};
