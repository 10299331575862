import { Material, Mesh, Object3D } from 'three';
import { InsertionLayoutPayload, LayoutItem } from '../layout';

export const removeNodeChildren = (node: Object3D) => {
  node.children.forEach(obj => {
    obj.traverse(child => {
      if (child instanceof Mesh) {
        if (child.material instanceof Material) {
          child.material.dispose();
        }
        child.geometry.dispose();
      }
    });
  });
  node.remove(...node.children);
};

export const toThreeCoordinate = (realWorld: number): number => realWorld / 1000;

export const arePlaceholdersEqual = (
  a: LayoutItem<InsertionLayoutPayload>,
  b: LayoutItem<InsertionLayoutPayload>
): boolean => {
  if (a === b) {
    return true;
  }

  return a.centerX === b.centerX && a.centerY === b.centerY && a.angle === b.angle && a.view === b.view;
};
