import { FormattedMessage } from 'react-intl';
import React from 'react';
import { localization } from '../../../../../stores/Localization';
import { SummaryEquipment, SummaryReport } from '../../../../../services/SummaryService';
import { toUSLocaleString } from '../../../../../services/utils';

interface ISummaryItemCard {
  withDimensions?: boolean;
  item: SummaryEquipment;
}

const SummaryItemCard: React.FC<ISummaryItemCard> = ({ withDimensions, item }) => {
  return (
    <div className="flex">
      <div className="flex items-center justify-center">
        <img
          className="aspect-1/1 h-[96px] object-cover"
          src={item.image}
          alt={localization.formatMessage(item.name)}
        />
      </div>
      <div className="ml-4 flex flex-1 items-center justify-between border-b border-b-neutral-100">
        <div className="flex flex-col content-center gap-1">
          <p className="typography-subtitle2">
            <FormattedMessage id={item.name} />
          </p>
          {withDimensions && item.dimensions && (
            <p className="typography-body2 text-neutral-500">
              {`${toUSLocaleString(item.dimensions.length)} x ${toUSLocaleString(
                item.dimensions.width
              )} x ${toUSLocaleString(item.dimensions.height)} ${localization.formatMessage('common.units.mm')}`}
            </p>
          )}
        </div>
        <p className="typography-body2 text-neutral-500">{`x${item.count}`}</p>
      </div>
    </div>
  );
};

type ISummaryItems = Pick<SummaryReport, 'benches' | 'instruments' | 'customInstruments'>;

export const SummaryItems: React.FC<ISummaryItems> = ({ benches, instruments, customInstruments }) => {
  return (
    <div className="flex flex-col gap-10 ls:mb-0">
      <h1 className="typography-h5">
        <FormattedMessage id="summary.installedTitle" />
      </h1>
      {benches?.length > 0 && (
        <div className="flex flex-col gap-2">
          <p className="typography-subtitle1">
            <FormattedMessage id="summary.benches" />
          </p>
          {benches.map(bench => {
            return <SummaryItemCard key={bench.id} item={bench} withDimensions />;
          })}
        </div>
      )}
      {instruments?.length > 0 && (
        <div className="flex flex-col gap-2">
          <p className="typography-subtitle1">
            <FormattedMessage id="summary.instruments" />
          </p>
          {instruments.map(instrument => {
            return <SummaryItemCard key={instrument.id} item={instrument} />;
          })}
        </div>
      )}
      {customInstruments?.length > 0 && (
        <div className="flex flex-col gap-2">
          <p className="typography-subtitle1">
            <FormattedMessage id="summary.customInstruments" />
          </p>
          {customInstruments.map(instrument => {
            return <SummaryItemCard key={instrument.id} item={instrument} withDimensions />;
          })}
        </div>
      )}
    </div>
  );
};
