import { QRCode } from 'components/QRCode/QRCode';
import { cn } from 'components/utils';
import { FormattedMessage } from 'react-intl';

interface IProps {
  className?: string;
  arLink?: string;
  onClose: () => void;
}

export const ARCodePopup = (props: IProps) => {
  const { arLink, onClose, className } = props;
  return (
    <>
      <div className="z-19 fixed bottom-0 left-0 right-0 top-0" onClick={onClose}></div>
      <div className={className}>
        <div className="material-icons absolute  left-1/2 z-20 -translate-x-1/2  text-5xl text-neutral-0  max-ls:top-[68px]  max-ls:-translate-y-8 ls:bottom-[68px] ls:translate-y-8 ls:rotate-180">
          arrow_drop_up
        </div>
        <div
          className={cn(
            `fixed z-20 flex w-fit max-w-[343px] items-center justify-center rounded-sm border-l-transparent bg-neutral-0 p-6 max-sm2:ml-4 max-sm2:max-w-[208px] max-sm2:flex-col`,
            `max-lg:right-4 max-lg:shadow-h`,
            `max-ls:top-22 max-ls:shadow-v`,
            `max-md:right-4 md:w-max md:max-w-[656px] md:flex-row md:pl-8 md:shadow-h`,
            `ls:bottom-[88px] ls:left-1/2 ls:shadow-h`,
            `lg:absolute lg:bottom-16 lg:left-1/2 lg:w-max lg:-translate-x-1/2 lg:shadow-h`,
            `xs:shadow-v sm:shadow-v xl:shadow-h`,
            className
          )}
        >
          <div className="max-w-[160px] md:max-w-[250px]">
            <p className="text-base">
              <FormattedMessage
                id="configurator.ar.guide"
                values={{
                  link: (
                    <a
                      className="text-primary-500 underline hover:text-primary-600 active:text-primary-700"
                      href={arLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage id="common.clickHere" />
                    </a>
                  )
                }}
              />
            </p>
          </div>
          <div className="flex justify-center">
            <QRCode className="w-[128px]" data={arLink} />
          </div>
        </div>
      </div>
    </>
  );
};
