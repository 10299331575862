import { Object3D } from 'three';

export class ArService {
  async generateArLink(scene: Object3D): Promise<string> {
    const gltf = await exportScene(scene);
    const blob = new Blob([gltf as ArrayBuffer], { type: 'application/octet-stream' });
    const url = process.env.REACT_APP_CL__STORAGE_SERVICE!;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: blob
    });
    const data = await response.json();
    return url + `link/${data.id}`;
  }
}

async function exportScene(scene: Object3D) {
  const { GLTFExporter } = await import('three/examples/jsm/exporters/GLTFExporter');
  const exporter = new GLTFExporter();

  return new Promise<object>((resolve, reject) => {
    exporter.parse(
      scene,
      gltf => {
        resolve(gltf);
      },
      er => reject(er),
      { forceIndices: true, binary: true }
    );
  });
}
