import { ImageResource, LoadableResource, MaterialResource, Resource, ResourceType } from './types';

export class ResourceLoadTransaction<T extends readonly Resource[]> {
  public resources: LoadableResource[];

  constructor(resources: T) {
    this.resources = resources.map(resource => ({
      ...resource,
      total: 0,
      loaded: 0
    }));
  }

  static Model(path: string): Resource {
    return {
      type: ResourceType.MODEL,
      path
    };
  }

  static Material(path: string): MaterialResource {
    return {
      type: ResourceType.MATERIAL,
      path
    };
  }

  static Image(path: string, scale: number): ImageResource {
    return {
      type: ResourceType.IMAGE,
      path,
      scale
    };
  }

  get progress() {
    const [total, loaded] = this.resources.reduce(
      ([total, loaded], resource) => {
        return [total + resource.total, loaded + resource.loaded];
      },
      [0, 0]
    );

    const progress = Math.floor((loaded * 100) / total);

    return Number.isNaN(progress) ? 100 : progress;
  }

  updateProgress(path: string, event: ProgressEvent) {
    const resource = this.resources.find(resource => resource.path === path);

    if (resource !== undefined && event.lengthComputable) {
      resource.total = event.total;
      resource.loaded = event.loaded;
    }
  }
}
