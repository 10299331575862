import { SummaryEquipment } from './SummaryService';
import { PdfDownloaderService } from './PdfDownloader';
import { createUrl } from './utils';
import { ConfigurationMode } from '../domain/modes';
import { InstrumentCategory } from '../schema';

export type PdfEquipment = Omit<SummaryEquipment, 'id'>; // to avoid passing much unnecessary data to PDF service
export type PdfCustomInstrumentEquipment = PdfEquipment & { customInstrument: true };

export interface PdfDtoBase {
  sceneImage: string;
  benches: PdfEquipment[];
  instruments: PdfEquipment[];
  customInstruments: PdfCustomInstrumentEquipment[];
}

export interface PdfDto extends PdfDtoBase {
  mode: ConfigurationMode;
  topViewImage: string;

  configurationLink: string;
  configurationLinkQrImage: string;

  linqBrochureLinkQrImage: string;
  linqBrochureLink: string;
  linkToOwnConfigurator: string;
}

export interface EmailDto {
  recipients: string[];
  link: string;
}

export class ContactService {
  public async generatePdf(pdfDto: PdfDto): Promise<any> {
    const url = `${process.env.REACT_APP_CL__CONTACT_US_SERVICE_URL}api/pdf/`;

    const templateUrl = createUrl('assets/templates/pdf');

    const result = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        templateUrl,
        clearCache: process.env.NODE_ENV === 'development',
        model: pdfDto
      })
    });

    if (![200, 201].includes(result.status)) {
      throw new Error(result.statusText);
    }

    return await result.blob();
  }

  async sendEmail(emailDto: EmailDto, pdfDto: PdfDto): Promise<void> {
    const url = `${process.env.REACT_APP_CL__CONTACT_US_SERVICE_URL}/api/email/`;
    const templateUrl = createUrl('assets/templates/email');

    const pdfTemplateUrl = createUrl('assets/templates/pdf');

    const { recipients, ...rest } = emailDto;

    const body = {
      gateway: 'automata',
      recipients,
      templateUrl: templateUrl,
      clearCache: process.env.NODE_ENV === 'development',
      model: rest,
      attachments: [
        {
          templateUrl: pdfTemplateUrl,
          fileName: PdfDownloaderService.generateFileName(),
          model: pdfDto
        }
      ]
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response.status >= 300) {
      throw new Error(response.statusText);
    }
  }
}

export const contactService = new ContactService();
