export function queryString(search: string) {
  search = search.slice(search.indexOf('?') + 1);

  return search
    .split('&')
    .map(pair => pair.split('='))
    .reduce((acc, next) => {
      acc[next[0]] = next[1];
      return acc;
    }, {} as Record<string, string>);
}
