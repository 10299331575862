import { Box3, Object3D, Vector3 } from 'three';
import { isBoxIntersectsBoundary } from './utils';
import {
  Boundary,
  IBoundingBoxService,
  IIntersectionService,
  IntersectionServiceSettings,
  IPaddingSettings,
  IPositionService,
  ScreenBox2D,
  Settings,
  Size
} from './types';

const emptyPadding: Required<IPaddingSettings> = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0
};

export class IntersectionService implements IIntersectionService {
  protected screenBoxes!: ScreenBox2D[];
  protected root!: Object3D;
  protected bbox!: Box3;
  protected settings: Settings;

  constructor(
    private screenSize: Size,
    private positionService: IPositionService,
    private boundingService: IBoundingBoxService,
    settings: IntersectionServiceSettings = {}
  ) {
    const padding2D: Required<IPaddingSettings> = Object.assign({}, emptyPadding, settings.padding2D ?? {});
    const padding3D: Required<IPaddingSettings> = Object.assign({}, emptyPadding, settings.padding3D ?? {});
    this.settings = {
      padding3D,
      padding2D
    };
  }
  init(screenBoxes: ScreenBox2D[], root: Object3D) {
    this.screenBoxes = screenBoxes;
    this.root = root;
    this.bbox = this.getBoundingBox();
  }

  getBoundingBox(): Box3 {
    const { top, left, right, bottom } = this.settings.padding3D;
    const bbox = this.boundingService.getBoundingBox(this.root).clone();
    bbox.max.add(new Vector3(right, top, 0));
    bbox.min.sub(new Vector3(left, bottom, 0));
    return bbox;
  }

  getBoundary(distance: number): Boundary {
    const { top, left, right, bottom } = this.settings.padding2D;
    let boundary = this.getBoundingBoxProjectionBox(distance, this.bbox);
    boundary.maxX += right;
    boundary.maxY += top;
    boundary.minX -= left;
    boundary.minY -= bottom;
    return boundary;
  }

  intersects(distance: number): boolean {
    const boundary = this.getBoundary(distance);
    const result = this.screenBoxes.find(isBoxIntersectsBoundary(boundary));
    return !!result;
  }

  private getBoundingBoxProjectionBox(distance: number, bbox: Box3) {
    const camera = this.positionService.getCameraAtDistance(distance);
    return this.boundingService.getBoundingBoxProjection(bbox, camera, this.screenSize);
  }
}
