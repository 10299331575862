import { useEffect, useMemo } from 'react';
import { ConfiguratorStore } from '../../../stores/ConfiguratorStore';
import { ConfigurationViewer } from '../../../domain/viewer';
import { Onboarding } from '../../../components/Onboarding';

import { ConfigurationOnboardingStore } from './ConfigurationOnboardingStore';
import { observer } from 'mobx-react-lite';

import { useMobileDetector } from '../../../components/hooks/useMobileDetector';

export type ConfigurationOnboardingProps = {
  store: ConfiguratorStore;
  viewer: ConfigurationViewer;
  onboardingStore: ConfigurationOnboardingStore;
};

export const ConfigurationOnboarding = observer(({ store, viewer, onboardingStore }: ConfigurationOnboardingProps) => {
  const stepCallbackData = useMemo(() => ({ store, viewer }), [store, viewer]);
  const mobileDetector = useMobileDetector();

  useEffect(() => {
    onboardingStore.setIsMobile(mobileDetector);
  }, [onboardingStore, mobileDetector]);

  if (!onboardingStore.hasActiveOnboarding) {
    return null;
  }

  return (
    <Onboarding
      key={onboardingStore.activeOnboardingType}
      stepCallbackData={stepCallbackData}
      steps={onboardingStore.activeOnboardingSteps}
      initialStepIndex={onboardingStore.initialStepIndex}
      onCompleted={() => onboardingStore.completeActiveOnboarding()}
      onSwitchedToStep={stepIndex => onboardingStore.updateMaxShownStep(stepIndex)}
      onSkipped={() => onboardingStore.skipOnboarding()}
    />
  );
});
