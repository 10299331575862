import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { getOptionId } from '@canvas-logic/engine';
import { FormattedMessage } from 'react-intl';
import { rootStore } from '../../stores/RootStore';
import { ConfigurationMode } from '../../domain/modes';
import { ConfigurationViewer } from '../../domain/viewer';
import { ConfigurationHeader } from './components/ConfigurationHeader';
import { ARCode, Button, Configuration, ElementCard, Icon, IconButton, useIsMobileScreenSize } from '../../components';
import { ConfigurationMenu } from './components/ConfigurationMenu';
import { HorizontalElementCard, SelectedItemCard } from '../../components/ElementCard';
import { ConfiguratorStore } from '../../stores/ConfiguratorStore';
import { localization } from '../../stores/Localization';
import { ShareButton } from './components/ShareButton';
import { RouletteButton } from './components/RouletteButton';
import { ShareService } from '../../domain/share';
import Notification from '../../components/Notification/Notification';
import { Loader } from '../../components/Loader/Loader';
import NotificationBar from '../../components/Notification/NotificationBar';
import { NoPossibilityToPutInstrumentError } from '../../domain/errors';
import { cn, copyTextToClipboard } from '../../components/utils';
import { insertingModeTypeGuard, selectedModeTypeGuard } from 'domain/typeGuards';
import { SummaryModal } from './components/SummaryModal/SummaryModal';
import { ConfirmChangesDialog } from './components/ConfirmChangesDialog';
import { ConfigurationOnboarding } from './Onboarding/ConfigurationOnboarding';
import { ConfigurationOnboardingStore } from './Onboarding/ConfigurationOnboardingStore';
import { HelpContent } from './components/Help';
import { Floater } from '../../components/Floater';
import { ConfigurationOnboardingContext } from './Onboarding/ConfigurationOnboardingContext';
import { CreateCustomInstrumentEventGA, DimensionEventGA, ShareLinkEventGA } from 'services/GoogleAnalyticsService';
import { ICustomInstrument, IInstrument, InstrumentCategory, ISection } from '../../schema';
import { ShareLinkHubspotEvent } from '../../services/HubspotService';
import { useHeightReporter } from '../../components/hooks/useHeightReporter';
import { useQuery } from '../../components/hooks/useQuery';
import {
  cardsListStyle,
  customInstrumentsCardsListStyle,
  InstrumentsInsertionPanel
} from './components/elementsList/InstrumentsInsertionPanel';
import { InstrumentsReplacementPanel } from './components/elementsList/instrumentReplacementPanel/InstrumentsReplacementPanel';
import { BenchesList } from './components/elementsList/BenchesList';
import { CustomInstrumentDimensions } from 'domain/instruments';

const mobileHeightCalcIncludingNavBar = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', mobileHeightCalcIncludingNavBar);
mobileHeightCalcIncludingNavBar();

const modifyingInstrumentsButtonsPanelContainerStyle =
  'absolute bottom-0 left-1/2 z-10 -translate-x-1/2 p-6 max-ls:hidden';

const selectedElementInfoContainerStyle =
  'absolute bottom-6 left-0 right-0 z-10 flex justify-center gap-2 w-full pl-6 pr-6 max-sm:pl-4 max-sm:pr-4';
const selectedElementButtonsBarPanelStyle =
  'selected-element-buttons-panel-shadow flex w-full max-w-[800px] min-h-[56px] flex-row items-center justify-between rounded-lg bg-white py-2 pr-2 pl-4 max-md:pl-2 max-md:pr-2 max-md:pt-4 max-md:flex-col max-md:justify-center max-md:gap-y-6';

type DialogCallback = () => void;

const KEY_DO_NOT_SHOW_CHANGES_CONFIRMATION = 'DO_NOT_SHOW_CHANGES_CONFIRMATION';

export const ConfiguratorPage = observer(function () {
  const [store] = useState<ConfiguratorStore>(() => new ConfiguratorStore());
  const [onboardingStore] = useState<ConfigurationOnboardingStore>(() => new ConfigurationOnboardingStore());
  const [viewer, setViewer] = useState<ConfigurationViewer | undefined>();

  const [confirmationCallback, setConfirmationCallback] = useState<DialogCallback>();

  // todo: derive from store.activeMode
  const [activeMenu, setActiveMenu] = useState<null | 'benches' | 'instruments'>(null);
  const [arLink, setArLink] = useState('');
  const [shareLinkLoading, setShareLinkLoading] = useState(false);
  const [isSummaryShown, setIsSummaryShown] = useState(false);

  const isMobile = useIsMobileScreenSize();

  const navigateTo = useNavigate();
  const params = useParams();
  const id = (params.id ?? 'default').toLowerCase();
  const mode =
    params.mode?.toLowerCase() === ConfigurationMode.Predefined ? ConfigurationMode.Predefined : ConfigurationMode.Own;

  const activeMode = store.activeMode;
  const configurationLink = useQuery()['link'] ?? '';

  useHeightReporter(true);

  useEffect(() => {
    store.fetch(rootStore.datasetService, id, configurationLink ?? undefined).catch(error => {
      if (error instanceof NoPossibilityToPutInstrumentError) {
        window.alert('Invalid configuration. ' + error.message);
        navigateTo('/');
      } else {
        throw error;
      }
    });
    store.onCloseMenu(() => setActiveMenu(null));
  }, [configurationLink]);

  const standardInstrumentsByCategory = store.getAvailableStandardInstrumentsByCategory();
  const customInstruments = store.getAvailableCustomInstruments();
  const benches = store.getAvailableBenches();

  const cancelInstallation = (closeMenu = false): void => {
    store.enterOverviewMode();
    if (closeMenu) {
      setActiveMenu(null);
    }
  };

  const notification = useContext(Notification);

  const createSharableLink = (): string => {
    const shareService = new ShareService(store);
    return shareService.makeSharableLink(id, mode);
  };

  const shareLinkFromSummary = async () => {
    const gaEvent = new ShareLinkEventGA({ from: 'Summary' });
    rootStore.gaEvent(gaEvent, 'Configurator');
    rootStore.hubspotTrackEvent(new ShareLinkHubspotEvent(createSharableLink()));
    await onShare();
  };

  const shareLinkFromConfigurator = async () => {
    const gaEvent = new ShareLinkEventGA({ from: 'Configurator' });
    rootStore.gaEvent(gaEvent, 'Configurator');
    rootStore.hubspotTrackEvent(new ShareLinkHubspotEvent(createSharableLink()));
    await onShare();
  };

  const onShare = async () => {
    try {
      setShareLinkLoading(true);
      const directLink = createSharableLink();
      await copyTextToClipboard(directLink);
      notification.success(localization.formatMessage('notification.link'));
    } catch (e) {
      let eMessage = '';
      if (e instanceof Error) {
        eMessage = e.message;
      } else {
        eMessage = String(e);
      }
      notification.error(eMessage);
    } finally {
      setShareLinkLoading(false);
    }
  };

  const toggleSummary = (open: boolean = !isSummaryShown) => {
    if (open) {
      viewer?.disableInteraction();
    } else {
      viewer?.enableInteraction();
    }

    setIsSummaryShown(open);
  };

  const isMenuShown =
    !!activeMenu &&
    (!isMobile ||
      (activeMode.type !== 'insertingInstrument' &&
        activeMode.type !== 'insertingBench' &&
        activeMode.type !== 'selectedBench' &&
        activeMode.type !== 'selectedInstrument'));

  const onBenchesIconClick = store.isUShapeConfiguration
    ? undefined
    : () => {
        if (isSummaryShown) {
          toggleSummary(false);
        }
        cancelInstallation();
        if (activeMenu === 'benches') {
          setActiveMenu(null);
        } else {
          setActiveMenu('benches');
          if (arLink) {
            setArLink('');
          }
        }
      };

  const onInstrumentIconClick = () => {
    if (isSummaryShown) {
      toggleSummary(false);
    }
    cancelInstallation();
    if (activeMenu === 'instruments') {
      setActiveMenu(null);
    } else {
      setActiveMenu('instruments');
      if (arLink) {
        setArLink('');
      }
    }
  };

  const onSummaryIconClick = () => {
    if (!isSummaryShown && store.showRuler) {
      store.toggleRuler();
    }
    cancelInstallation(true);
    toggleSummary();
  };

  /**
   * @todo Replace it with a proper message management store.
   *       This is simply ugly. I'm sorry.
   */
  const handleBackClick = (event: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    if (store.hasChanges && window.sessionStorage.getItem(KEY_DO_NOT_SHOW_CHANGES_CONFIRMATION) !== 'true') {
      event.preventDefault();
      setConfirmationCallback(() => () => navigateTo(path));
    }
  };

  const handleOnLeaveClick = useCallback(
    (doNotShowAgain: boolean) => {
      window.sessionStorage.setItem(KEY_DO_NOT_SHOW_CHANGES_CONFIRMATION, String(doNotShowAgain));
      confirmationCallback?.();
    },
    [confirmationCallback]
  );

  const handleRouletteButtonClick = () => {
    const gaEvent = new DimensionEventGA({ status: store.showRuler ? 'off' : 'on' });
    rootStore.gaEvent(gaEvent, 'Configurator');
    store.toggleRuler();
  };

  const handleInsertedInstrumentsSelection = (instrument: IInstrument, selected: boolean): void => {
    if (selected) {
      store.enterInstrumentInstallation(instrument);
      onboardingStore.instrumentInstallationModeWasActivated();
    } else {
      cancelInstallation();
    }
  };

  const handleReplacedInstrumentsSelection = (instrument: IInstrument, selected: boolean): void => {
    if (selected) {
      store.replaceInstrument(instrument);
    } else {
      cancelInstallation();
    }
  };

  const handleBenchSelection = (bench: ISection, selected: boolean): void => {
    if (selected) {
      store.enterBenchInstallation(bench);
      onboardingStore.installationModeWasActivated();
    } else {
      cancelInstallation();
    }
  };

  const onCloseMenu = (): void => {
    setActiveMenu(null);
    store.enterOverviewMode();
  };

  const handleCreateCustomInstrument = (dimensions: CustomInstrumentDimensions, name: string) => {
    const gaEvent = new CreateCustomInstrumentEventGA({ instrument_name: name });
    rootStore.gaEvent(gaEvent, 'Configurator');

    store.customInstrumentsService.createCustomInstrument(dimensions, name);
  };

  useEffect(() => {
    if ((selectedModeTypeGuard(activeMode) || insertingModeTypeGuard(activeMode)) && isMobile) {
      setActiveMenu(null);
    }
  }, [activeMode]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent('resize-configuration-viewer', { detail: isMenuShown && !isMobile ? 'open' : 'close' })
    );
  }, [isMenuShown, isMobile]);

  useEffect(() => {
    onboardingStore.configurationWasOpened(mode === ConfigurationMode.Predefined, store.isUShapeConfiguration);
  }, [onboardingStore, onboardingStore.onboardingStatus]);

  return (
    <ConfigurationOnboardingContext.Provider value={{ store: onboardingStore }}>
      {viewer && <ConfigurationOnboarding store={store} viewer={viewer} onboardingStore={onboardingStore} />}

      <div className="relative flex flex-nowrap overflow-hidden transition-all">
        <ConfigurationHeader
          isInstrumentActive={activeMenu === 'instruments'}
          isBenchesActive={activeMenu === 'benches'}
          isHiddenWhenEditMode={insertingModeTypeGuard(activeMode) || selectedModeTypeGuard(activeMode)}
          onInstrumentClick={onInstrumentIconClick}
          onBenchesClick={onBenchesIconClick}
          onBackClick={handleBackClick}
          isSummaryShown={isSummaryShown}
          onSummaryClick={onSummaryIconClick}
          mode={mode}
        />
        <div className={`${mode === ConfigurationMode.Predefined ? 'ls:pl-20' : 'ls:pl-24'}`} />
        <ConfigurationMenu
          title={activeMenu === 'instruments' ? 'Instruments' : activeMenu === 'benches' ? 'Benches' : ''}
          onClose={onCloseMenu}
          isOpen={isMenuShown}
        >
          {activeMode.type === 'replacingInstrument' && (
            <InstrumentsReplacementPanel
              mode={activeMode}
              customInstruments={customInstruments}
              standardInstruments={standardInstrumentsByCategory}
              onToggle={handleReplacedInstrumentsSelection}
              isAvailable={store.canInstrumentBeUsedForReplacement.bind(store)}
              createCustomInstrument={handleCreateCustomInstrument}
            />
          )}

          {mode === ConfigurationMode.Own &&
            activeMenu === 'instruments' &&
            activeMode.type !== 'replacingInstrument' && (
              <InstrumentsInsertionPanel
                mode={activeMode}
                standardInstruments={standardInstrumentsByCategory}
                customInstruments={customInstruments}
                onToggle={handleInsertedInstrumentsSelection}
                isAvailable={store.canInstrumentBeAppended.bind(store)}
                createCustomInstrument={handleCreateCustomInstrument}
              />
            )}

          {mode === ConfigurationMode.Own && activeMenu === 'benches' && (
            <BenchesList
              mode={activeMode}
              benches={benches}
              onToggle={handleBenchSelection}
              isAvailable={store.canBenchBeUsedForInstallation.bind(store)}
            />
          )}
        </ConfigurationMenu>

        {isSummaryShown && (
          <SummaryModal
            viewer={viewer!}
            mode={mode}
            store={store}
            onClose={toggleSummary}
            createSharableLink={createSharableLink}
            onShare={shareLinkFromSummary}
            shareLoading={shareLinkLoading}
          />
        )}

        <NotificationBar />

        <ConfirmChangesDialog
          open={confirmationCallback !== undefined}
          onLeave={handleOnLeaveClick}
          onClose={() => setConfirmationCallback(undefined)}
        />

        {!isSummaryShown && (
          <div className={`relative w-full overflow-x-hidden`} style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
            {insertingModeTypeGuard(activeMode) && (
              <div className="typography-label absolute left-1/2 top-0 z-10 -translate-x-1/2 bg-secondary-400 px-3 py-2 text-neutral-0">
                <FormattedMessage id="installation.mode" />
              </div>
            )}
            {activeMode.type === 'replacingInstrument' && (
              <div className="typography-label absolute left-1/2 top-0 z-10 -translate-x-1/2 bg-primary-400 px-3 py-2 text-neutral-0">
                <FormattedMessage id="replacement.mode" />
              </div>
            )}
            {activeMode.type === 'insertingInstrument' && (
              <div className="ms:p-6 absolute bottom-0 left-1/2 z-10 flex w-full -translate-x-1/2 justify-center p-4 ls:hidden">
                <SelectedItemCard
                  title={localization.formatMessage(activeMode.instrumentToAdd.name)}
                  image={activeMode.instrumentToAdd.image}
                  onClick={() => {
                    store.enterOverviewMode();
                    setActiveMenu('instruments');
                    if (arLink) {
                      setArLink('');
                    }
                  }}
                  onCancelSelect={() => cancelInstallation(true)}
                />
              </div>
            )}
            {activeMode.type === 'insertingBench' && (
              <div className="ms:p-6 absolute bottom-0 left-1/2 z-10 flex w-full -translate-x-1/2 justify-center p-4 ls:hidden">
                <SelectedItemCard
                  title={localization.formatMessage(activeMode.benchToAdd.name)}
                  image={activeMode.benchToAdd.image}
                  onClick={() => {
                    store.enterOverviewMode();
                    setActiveMenu('benches');
                    if (arLink) {
                      setArLink('');
                    }
                  }}
                  onCancelSelect={() => cancelInstallation(true)}
                />
              </div>
            )}
            {insertingModeTypeGuard(activeMode) && (
              <div className={modifyingInstrumentsButtonsPanelContainerStyle}>
                <Button variant="clear" size="lg" withIcon onClick={() => cancelInstallation()}>
                  {activeMode.installed ? (
                    <>
                      <Button.ButtonIcon>
                        <Icon icon="check" />
                      </Button.ButtonIcon>
                      <Button.ButtonText className="w-max">
                        <FormattedMessage id="installation.finish" />
                      </Button.ButtonText>
                    </>
                  ) : (
                    <>
                      <Button.ButtonIcon>
                        <Icon icon="close" />
                      </Button.ButtonIcon>
                      <Button.ButtonText className="w-max">
                        <FormattedMessage id="installation.cancel" />
                      </Button.ButtonText>
                    </>
                  )}
                </Button>
              </div>
            )}
            {activeMode.type === 'replacingInstrument' && (
              <div className={modifyingInstrumentsButtonsPanelContainerStyle}>
                <Button variant="clear" size="lg" withIcon onClick={() => store.enterOverviewMode(true)}>
                  <Button.ButtonIcon>
                    <Icon icon="close" />
                  </Button.ButtonIcon>
                  <Button.ButtonText className="w-max">
                    <FormattedMessage id="replacement.cancel" />
                  </Button.ButtonText>
                </Button>
              </div>
            )}
            <div>
              {viewer && activeMode.type === 'overview' && (
                <div className="absolute right-6 z-10 flex gap-2 max-ls:top-6 md:right-0 md:w-full md:justify-center ls:bottom-6">
                  <RouletteButton onClick={handleRouletteButtonClick} active={store.showRuler} />
                  <ShareButton onShare={shareLinkFromConfigurator} loading={shareLinkLoading} />
                  <ARCode
                    viewer={viewer}
                    arLink={arLink}
                    setArLink={setArLink}
                    active={!!arLink}
                    createSharableLink={createSharableLink}
                  />
                </div>
              )}

              <div className="absolute left-6 top-6 z-10 ls:hidden">
                <Floater
                  trigger={({ open }) => (
                    <IconButton
                      variant="filled"
                      color="secondary"
                      size="lg"
                      active={open}
                      data-onboarding="help-button-mobile"
                    >
                      <Icon icon="help_outline" />
                    </IconButton>
                  )}
                  options={{
                    side: 'bottom',
                    sideOffset: 5,
                    alignOffset: -5,
                    align: 'start'
                  }}
                  className="w-[calc(var(--radix-popover-content-available-width)-10px)] max-w-[388px]"
                >
                  <HelpContent />
                </Floater>
              </div>

              {activeMode.type === 'selectedInstrument' && (
                <div className={selectedElementInfoContainerStyle}>
                  <div className={selectedElementButtonsBarPanelStyle} data-onboarding="instrument-action-panel">
                    <p className="typography-subtitle1">
                      <FormattedMessage id={activeMode.selectedInstrument.name} />
                    </p>
                    <div className="flex flex-row max-md:w-full max-md:gap-x-4 md:gap-x-2">
                      <Button
                        className="max-md:w-full"
                        withIcon
                        onClick={() => {
                          store.enterReplacingMode();
                          setActiveMenu('instruments');
                          if (arLink) {
                            setArLink('');
                          }
                        }}
                      >
                        <Button.ButtonIcon>
                          <Icon icon="loop" />
                        </Button.ButtonIcon>
                        <Button.ButtonText>
                          <FormattedMessage id="replacement.replace" />
                        </Button.ButtonText>
                      </Button>
                      {mode === ConfigurationMode.Own && (
                        <Button
                          className="max-md:w-full"
                          withIcon
                          onClick={() =>
                            activeMode.selectedInstrument &&
                            store.deleteInstrument({ uuid: activeMode.selectedInstrument.uuid })
                          }
                        >
                          <Button.ButtonIcon>
                            <Icon icon="delete_outline" />
                          </Button.ButtonIcon>
                          <Button.ButtonText>
                            <FormattedMessage id="common.delete" />
                          </Button.ButtonText>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeMode.type === 'selectedBench' && (
                <div className={selectedElementInfoContainerStyle}>
                  <div className={selectedElementButtonsBarPanelStyle} data-onboarding="bench-action-panel">
                    <p className="typography-subtitle1">
                      <FormattedMessage id={activeMode.selectedBench.name} />
                    </p>
                    <Button
                      withIcon
                      onClick={() =>
                        activeMode.selectedBench && store.deleteBench({ uuid: activeMode.selectedBench.uuid })
                      }
                      disabled={
                        mode === ConfigurationMode.Predefined ||
                        !store.canBenchBeDeleted({ uuid: activeMode.selectedBench.uuid })
                      }
                      className="max-md:w-full"
                    >
                      <Button.ButtonIcon>
                        <Icon icon="delete_outline" />
                      </Button.ButtonIcon>
                      <Button.ButtonText>
                        <FormattedMessage id="common.delete" />
                      </Button.ButtonText>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {store.loaded && (
          <div
            className={cn('absolute bottom-0 left-0 right-0 top-0 transition-filters duration-300 ease-in-out')}
            style={{ left: isMobile ? 0 : 80 }}
          >
            <Configuration
              store={store}
              // Hack to destroy previous component to work properly in React.StrictMode
              key={store.uuid}
              mode={mode}
              onViewerCreated={setViewer}
            />
          </div>
        )}
      </div>
      {!store.loaded && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-neutral-0">
          <div className="flex flex-col items-center">
            <Loader />
            <p className="typography-button-m mt-20 text-center uppercase">
              <FormattedMessage id="indexPage.loading" />
            </p>
          </div>
        </div>
      )}
    </ConfigurationOnboardingContext.Provider>
  );
});
