import { Object3D, Vector3 } from 'three';
import { Angle, IPosition } from '../../schema';
import { getOrigin, shiftBasis, sumAngle, toRadians } from './utils';
import { Coordinates } from './types';

export class BasisBuilder {
  _angle: Angle = 0;
  x = 0;
  y = 0;

  angle(value: Angle): BasisBuilder {
    this._angle = value;
    return this;
  }

  position(x: number, y: number): BasisBuilder {
    this.x = x;
    this.y = y;
    return this;
  }

  local(config: (basis: LocalBasisBuilder) => void): { position: IPosition; angle: Angle } {
    let basis = new Object3D();
    basis.rotation.y = toRadians(this._angle);
    basis.position.x = this.x;
    basis.position.z = this.y;
    // basis = shiftBasis(basis, 0, new Vector3(this.x, 0, this.y));
    // basis = shiftBasis(basis, toRadians(this._angle), new Vector3());

    let coordinates: Coordinates = {
      angle: 0,
      x: 0,
      y: 0
    };
    config(new LocalBasisBuilder(coordinates));
    basis = shiftBasis(basis, 0, new Vector3(coordinates.x, 0, coordinates.y));
    const position = getOrigin(basis);
    return { position: { x: position.x, y: position.z }, angle: sumAngle(this._angle, coordinates.angle) };
  }
}

class LocalBasisBuilder {
  constructor(private coordinates: Coordinates) {}

  angle(value: Angle): LocalBasisBuilder {
    this.coordinates.angle = value;
    return this;
  }

  position(x: number, y: number): LocalBasisBuilder {
    this.coordinates.x = x;
    this.coordinates.y = y;
    return this;
  }
}

export function createBasis(): BasisBuilder {
  return new BasisBuilder();
}
