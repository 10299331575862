import { ModelMutator, MutatorContext, TypeReference, ValidationResult } from '@canvas-logic/engine';
import { IConfiguration } from '../../schema';
import { ElementPositionById } from '../layout';
import { notFoundInMutator } from '../errors/notFound';
import { findInstrumentLocationByUUID } from './utils';

export class DeleteInstrumentMutator implements ModelMutator {
  constructor(private readonly position: ElementPositionById) {}

  mutate(context: MutatorContext, typeRef: TypeReference, model: IConfiguration): [IConfiguration, ValidationResult] {
    const { instruments, index } = findInstrumentLocationByUUID(model, this.position.uuid);
    if (index >= 0) {
      instruments.splice(index, 1);
      return [model, ValidationResult.Valid()];
    }
    return [model, ValidationResult.Error(notFoundInMutator())];
  }
}
