import { v4 } from 'uuid';
import { getTypeRef } from '@canvas-logic/engine';
import { OneLineShape, ShapeService, ShapeServiceModel } from './types';
import {
  BenchLayoutService,
  InstrumentLayoutPayload,
  InstrumentLayoutService,
  LayoutItem,
  AvailableAreasService
} from '../layout';
import { CameraPositionService } from '../camera';

export class OneLineShapeService implements ShapeService {
  public type: string;
  public benchItems: LayoutItem[] = [];
  public instrumentItems: LayoutItem<InstrumentLayoutPayload>[] = [];

  constructor(
    private model: ShapeServiceModel,
    shape: OneLineShape,
    availableAreasService: AvailableAreasService,
    private cameraPositionService: CameraPositionService
  ) {
    this.type = getTypeRef(shape);

    const benchLayoutService = new BenchLayoutService(this.model, shape);

    this.cameraPositionService.updateSegmentLine(benchLayoutService.segmentLine);
    this.benchItems = benchLayoutService.items;
    const instrumentService = new InstrumentLayoutService(
      this.model,
      shape.instruments,
      benchLayoutService.segmentLine,
      shape.uuid
    );

    availableAreasService.setInstrumentLayoutService(instrumentService);
    availableAreasService.setBenchLayoutService({ type: 'notBackToBack', service: benchLayoutService });

    this.instrumentItems = instrumentService.items.map(item => ({
      groupId: v4(),
      centerX: item.centerX,
      centerY: item.centerY,
      angle: item.angle,
      view: item.view,
      payload: {
        type: 'instrument',
        instrument: (item.payload as InstrumentLayoutPayload).instrument
      }
    }));
  }
}
