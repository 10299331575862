import { Material, Mesh, Object3D, PerspectiveCamera, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  BENCH_HEIGHT,
  BENCH_INSERTION,
  BENCH_INSERTION_PLACEHOLDER,
  ConfigurationScene,
  INSTRUMENT_INSERTION,
  INSTRUMENT_INSERTION_PLACEHOLDER,
  INSTRUMENT_NAME,
  SECTION_NAME
} from './ConfigurationScene';
import { ResourceLoader } from '../media';
import { ConfigurationRenderer } from './ConfigurationRenderer';
import { LayoutObject3D, Size, ViewModel } from './types';
import { INodeClickEvent, INodePointerEvent, ObjectSelector } from '../behaviour';
import { LayoutItem } from '../layout';
import { benchLayoutItemTypeGuard, instrumentLayoutItemTypeGuard } from '../typeGuards';
import { DimensionsRenderer } from './DimensionsRenderer';
import { CameraView, Transition } from '../camera';
import { threeUtils } from '../camera/ThreeUtils';

type SelectionListener = (selection: LayoutItem | undefined) => void;
type PointerListener = (payload: LayoutItem | undefined, name?: string) => void;

export class ConfigurationViewer {
  private readonly configurationScene!: ConfigurationScene;
  public width: number;
  public height: number;
  public camera!: PerspectiveCamera;
  private _renderer: ConfigurationRenderer | null = null;
  private dimensionsRenderer!: DimensionsRenderer;
  private _controls: OrbitControls | null = null;
  private objectSelector!: ObjectSelector;
  private disposed = false;
  private showRuler = false;
  private _cameraTransition: Transition | null = null;

  public size: Size;
  private offsetHeight = 0;
  private lookAt = new Vector3(0, 0, 0);

  private selectionListener: SelectionListener | undefined;
  private pointerListener: PointerListener | undefined;
  private originalMaterials = new Map<number, Material | Material[]>();
  private readonly previouslyActiveUUIDs: string[] = [];
  private lastRendering = 0;
  private viewModel!: ViewModel;

  private get renderer(): ConfigurationRenderer {
    if (!this._renderer) {
      throw new Error('Renderer is not initialized');
    }
    return this._renderer;
  }
  private get controls(): OrbitControls {
    if (!this._controls) {
      throw new Error('Controls is not initialized');
    }
    return this._controls;
  }
  private get cameraTransition(): Transition {
    if (!this._cameraTransition) {
      throw new Error('CameraTransition is not initialized');
    }
    return this._cameraTransition;
  }
  constructor(private readonly canvasElement: HTMLCanvasElement, private readonly resourceLoader: ResourceLoader) {
    this.width = canvasElement.parentElement!.offsetWidth;
    this.height = canvasElement.offsetHeight;
    this.size = {
      width: this.width,
      height: this.height
    };

    this.configurationScene = new ConfigurationScene(resourceLoader);
    this.configurationScene.addSceneChangedListener(() => this.rerender());
    this.configurationScene.addRedrawListener(() => this.redraw());
    this._cameraTransition = this.initTransition();
  }

  async init(viewModel: ViewModel, cameraView: CameraView, isBackgroundRequired = false) {
    this.offsetHeight = viewModel.offsetHeight;
    this.initScene(this.offsetHeight);
    this.initCamera(cameraView);
    await this.initRenderer(viewModel, isBackgroundRequired);
    this.initControls();
    this.initHandlers();
    this.initBehaviours();
    this.startRendering();
  }

  get scene(): ConfigurationScene {
    return this.configurationScene;
  }

  dispose(): void {
    this.renderer.dispose();
    this.disposed = true;

    this.selectionListener = undefined;
    this.pointerListener = undefined;
  }

  setSelectionListener(listener: SelectionListener) {
    this.selectionListener = listener;
  }

  setPointerListener(listener: PointerListener) {
    this.pointerListener = listener;
  }

  highlightNodesWithOutlineLight(groupId: string) {
    this.resetOutlineHighlightLight();
    const nodes = this.findInstrumentNodesByPayloadUUID(groupId);
    // this.renderer.selectedObjects = groupId;

    const selectedObjects: Mesh[] = [];
    ConfigurationViewer.traverseNodes(nodes, mesh => {
      if (mesh instanceof Mesh) {
        selectedObjects.push(mesh as Mesh);
      }
    });
    this.renderer.outlineObjects(selectedObjects);
  }

  private static traverseNodes(nodes: Object3D[], callback: (node: Object3D) => void): void {
    for (const node of nodes) {
      node.traverse(callback);
    }
  }

  highlightNodesWithSilhouettes(groupId: string) {
    this.resetSilhouetteHighlight();
    const nodes = this.findInstrumentNodesByPayloadUUID(groupId);

    ConfigurationViewer.traverseNodes(nodes, mesh => {
      if (mesh instanceof Mesh) {
        this.originalMaterials.set(mesh.id, mesh.material);
        mesh.material = this.scene.replaceElementHighlightMaterial;
      }
    });

    this.previouslyActiveUUIDs.push(groupId);
  }

  resetOutlineHighlightLight(): void {
    if (this.renderer) {
      //this.renderer.outlineHoverPass.selectedObjects.length = 0;
      this.renderer.resetObject();
    }
  }

  resetSilhouetteHighlight(): void {
    for (const groupId of this.previouslyActiveUUIDs) {
      const nodes = this.findInstrumentNodesByPayloadUUID(groupId);

      ConfigurationViewer.traverseNodes(nodes, mesh => {
        if (mesh instanceof Mesh) {
          const material = this.originalMaterials.get(mesh.id);
          if (material) {
            mesh.material = material;
          }
        }
      });
    }

    this.previouslyActiveUUIDs.length = 0;
  }

  redraw(duration = 200): void {
    this.lastRendering = Math.max(this.lastRendering, Date.now() + duration);
  }

  transitionCamera(cameraView: CameraView, position: Vector3 | null = null): void {
    this.lookAt = new Vector3(cameraView.lookAt.x / 1000, BENCH_HEIGHT / 1000, cameraView.lookAt.y / 1000);
    const newPosition = position ?? this.camera.position.clone();
    const targetState = this.renderer.getFitConfigurationTargetState(newPosition, this.lookAt, this.camera);
    const [x, y, z, lx, ly, lz] = targetState;
    const cameraPosition = new Vector3(x, y, z);
    const target = new Vector3(lx, ly, lz);
    this.transitionTo(cameraPosition, target);
  }

  async initialRender(viewModel: ViewModel) {
    this.viewModel = viewModel;
    await this.configurationScene.buildScene(viewModel);
    if (this.showRuler) {
      this.dimensionsRenderer.buildRuler(this.configurationScene);
    }
    this.redraw();
  }
  render(viewModel: ViewModel) {
    this.viewModel = viewModel;
    this.configurationScene.rebuildScene(viewModel);
    if (this.showRuler) {
      this.dimensionsRenderer.buildRuler(this.configurationScene);
    }
    this.redraw();
  }
  rerender() {
    this.configurationScene.rebuildScene(this.viewModel);
    if (this.showRuler) {
      this.dimensionsRenderer.buildRuler(this.configurationScene);
    }
    this.redraw();
  }

  showDimensions() {
    this.showRuler = true;
    this.dimensionsRenderer && this.dimensionsRenderer.buildRuler(this.configurationScene);
  }

  hideDimensions() {
    this.showRuler = false;
    this.dimensionsRenderer && this.dimensionsRenderer.clearScene(this.configurationScene);
  }

  startRendering(): void {
    window.requestAnimationFrame(() => {
      if (this.disposed) {
        return;
      }
      if (Date.now() < this.lastRendering) {
        // console.time('rendering');
        if (this.controls.enabled) {
          /// need to check?
          this.controls.update();
        }
        this.renderer.render();
        if (this.controls) {
          this.dimensionsRenderer.render(
            this.configurationScene,
            this.camera,
            this.controls.getAzimuthalAngle(),
            this.showRuler
          );
        }
        // console.timeEnd('rendering');
      }

      this.startRendering();
    });
  }

  private notifySelectionListener(data: LayoutItem | undefined) {
    if (this.selectionListener) {
      this.selectionListener(data);
    }
  }

  private notifyPointerListener(data: LayoutItem | undefined, name?: string) {
    if (this.pointerListener) {
      this.pointerListener(data, name);
    }
  }

  private initScene(offsetHeight: number) {
    this.configurationScene.init(offsetHeight);
  }

  private initCamera(cameraView: CameraView) {
    const { width, height } = this;
    this.camera = new PerspectiveCamera(30, width / height, 0.1, 180);
    this.lookAt = new Vector3(cameraView.lookAt.x / 1000, BENCH_HEIGHT / 1000, cameraView.lookAt.y / 1000);
    this.camera.position.copy(ConfigurationRenderer.getResetPosition(this.lookAt));
    this.camera.lookAt(this.lookAt);
    this.cameraTransition.syncValue(
      threeUtils.vectorToArray(this.camera.position).concat(threeUtils.vectorToArray(this.lookAt))
    );
  }

  private async initRenderer(viewModel: ViewModel, isBackgroundRequired: boolean) {
    const { width, height, canvasElement } = this;
    this._renderer = new ConfigurationRenderer();
    const envTexture = await this.renderer.init(
      width,
      height,
      canvasElement,
      this.configurationScene,
      this.camera,
      viewModel.hdr
    );
    this.configurationScene.getScene().environment = envTexture;
    this.configurationScene.getOutlineScene().environment = envTexture;
    if (isBackgroundRequired) {
      this.configurationScene.getScene().background = envTexture;
    }
    this.dimensionsRenderer = new DimensionsRenderer(width, height);
  }

  private initControls() {
    this._controls = new OrbitControls(this.camera, this.canvasElement);
    this.controls.enabled = true;
    this.controls.enableZoom = true;
    //this.controls.enablePan = true;
    this.controls.minPolarAngle = Math.PI / 1000;
    this.controls.maxPolarAngle = Math.PI / 2;

    this.controls.target = this.lookAt.clone();
    this.controls.zoomSpeed = 1;
    this.controls.addEventListener('change', event => {
      if (!this.controls) {
        return;
      }
      if (event.type === 'change') {
        this.cameraTransition.syncValue(
          threeUtils.vectorToArray(this.camera.position).concat(threeUtils.vectorToArray(this.controls.target))
        );
      }
    });
    this.controls.update();
  }

  private initHandlers() {
    window.addEventListener('resize', this.resizeHandler);
    window.addEventListener('resize-configuration-viewer', event => {
      if (event instanceof CustomEvent) {
        if (event.detail === 'close') {
          this.canvasElement.style.transform = 'translateX(0)';
          this.dimensionsRenderer.dimensionsRenderer2D.domElement.style.transform = 'translateX(0)';
        } else {
          this.canvasElement.style.transform = 'translateX(12.5%)';
          this.dimensionsRenderer.dimensionsRenderer2D.domElement.style.transform = 'translateX(12.5%)';
        }
      }
    });
    this.controls.addEventListener('change', () => this.redraw());
  }

  private resizeHandler = () => {
    const parent = this.canvasElement.parentElement;
    if (parent) {
      if (this.width === parent.clientWidth && this.height === parent.clientHeight) {
        return;
      }
      this.height = parent.clientHeight;
      this.width = parent.clientWidth;
      this.size.width = this.width;
      this.size.height = this.height;

      this.renderer.setSize(this.width, this.height);
      this.dimensionsRenderer.setSize(this.width, this.height);
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
      this.redraw();
    }
  };

  enableInteraction = () => {
    if (this.controls) {
      this.controls.enabled = true;
    }
    this.objectSelector.activate();
  };

  disableInteraction = () => {
    if (this.controls) {
      this.controls.enabled = false;
    }
    this.objectSelector.deactivate();
  };

  private initBehaviours() {
    this.objectSelector = new ObjectSelector(
      this.canvasElement,
      this.camera,
      this.configurationScene.getSceneRoot(),
      true
    );

    this.objectSelector.addNodeClickEventHandler<LayoutObject3D>(INSTRUMENT_INSERTION, (node, event) => {
      this.onObjectClick(node, event);
    });

    this.objectSelector.addNodeClickEventHandler<LayoutObject3D>(INSTRUMENT_INSERTION_PLACEHOLDER, (node, event) => {
      this.onObjectClick(node, event);
    });

    this.objectSelector.addNodeClickEventHandler<LayoutObject3D>(BENCH_INSERTION_PLACEHOLDER, (node, event) => {
      this.onObjectClick(node, event);
    });

    this.objectSelector.addNodeClickEventHandler<LayoutObject3D>(BENCH_INSERTION, (node, event) => {
      this.onObjectClick(node, event);
    });
    this.objectSelector.addNodeClickEventHandler<LayoutObject3D>(INSTRUMENT_NAME, (node, event) =>
      this.onObjectClick(node, event)
    );
    this.objectSelector.addNodeClickEventHandler<LayoutObject3D>(SECTION_NAME, (node, event) =>
      this.onObjectClick(node, event)
    );
    this.objectSelector.addNodeClickEventHandler('', async (node, event) => {
      event.stopPropagation();
      this.notifySelectionListener(undefined);
    });

    this.objectSelector.addNodePointerEventHandler<LayoutObject3D>(INSTRUMENT_NAME, (node, event) =>
      this.onObjectHover(node, event)
    );

    this.objectSelector.addNodePointerEventHandler<LayoutObject3D>(INSTRUMENT_INSERTION_PLACEHOLDER, (node, event) =>
      this.onObjectHover(node, event)
    );

    this.objectSelector.addNodePointerEventHandler<LayoutObject3D>(INSTRUMENT_INSERTION, (node, event) => {
      this.onObjectHover(node, event);
    });

    this.objectSelector.addNodePointerEventHandler<LayoutObject3D>(BENCH_INSERTION, (node, event) => {
      this.onObjectHover(node, event);
    });

    this.objectSelector.addNodePointerEventHandler<LayoutObject3D>(BENCH_INSERTION_PLACEHOLDER, (node, event) =>
      this.onObjectHover(node, event)
    );

    this.objectSelector.addNodePointerEventHandler<LayoutObject3D>(SECTION_NAME, (node, event) =>
      this.onObjectHover(node, event)
    );

    this.objectSelector.addNodePointerEventHandler('', async (node, event) => {
      event.stopPropagation();
      this.notifyPointerListener(undefined);
    });
  }

  private onObjectClick(node: LayoutObject3D | null, event: INodeClickEvent) {
    if (!node) {
      return;
    }
    event.stopPropagation();

    this.notifySelectionListener(node.userData);
  }

  private onObjectHover(node: LayoutObject3D | null, event: INodePointerEvent) {
    if (!node) {
      return;
    }
    event.stopPropagation();

    this.notifyPointerListener(node.userData, node.name);
  }

  private findInstrumentNodesByPayloadUUID(key: string) {
    const nodes: Object3D[] = [];
    this.scene.getScene().traverse(node => {
      if (node.userData && (node.userData.name === INSTRUMENT_NAME || node.userData.name === SECTION_NAME)) {
        const item = node.userData as LayoutItem;
        if (instrumentLayoutItemTypeGuard(item) && item.payload.instrument.uuid === key) {
          nodes.push(node);
        } else if (benchLayoutItemTypeGuard(item) && item.payload.section.uuid === key) {
          nodes.push(node);
        }
      }
    });
    return nodes;
  }

  private get2dSceneImage(): string {
    this.scene.clearHighlights();
    this.resetSilhouetteHighlight();
    this.hideDimensions();
    this.renderer.render2d(this.lookAt.clone());
    const image = this.renderer.domElement.toDataURL('image/png');
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.render();
    return image;
  }

  renderSceneImage(): string {
    return this.get2dSceneImage();
  }

  private initTransition() {
    const transition = new Transition([0, 0, 0, 0, 0, 0], 700);
    transition.onStart(() => {
      if (this._controls) {
        this.controls.enabled = false;
      }
    });
    transition.onFinish(() => {
      if (this._controls) {
        this.controls.enabled = true;
        this.controls.target = this.lookAt;
        this.controls.update();
      }
    });
    transition.onChange(([x, y, z, lookX, lookY, lookZ]) => {
      this.camera.position.x = x;
      this.camera.position.y = y;
      this.camera.position.z = z;
      this.camera.lookAt(lookX, lookY, lookZ);
      this.redraw();
    });
    return transition;
  }

  public resetCamera(cameraView: CameraView): void {
    const target = new Vector3(cameraView.lookAt.x / 1000, BENCH_HEIGHT / 1000, cameraView.lookAt.y / 1000);
    const position = ConfigurationRenderer.getResetPosition(target);
    this.transitionCamera(cameraView, position);
  }
  private transitionTo(position: Vector3, target: Vector3) {
    const targetState = [position.x, position.y, position.z, target.x, target.y, target.z];
    this.cameraTransition.transitionTo(targetState, 1500);
  }
}
