import { useEffect, useState } from 'react';
import { ARButton } from './ARButton';
import { ARCodePopup } from './ARCodePopup';
import { ConfigurationViewer } from 'domain/viewer';
import { ArService } from 'domain/ar/services/ArService';
import { ViewInAREventGA } from 'services/GoogleAnalyticsService';
import { rootStore } from 'stores/RootStore';
import { ViewInARHubspotEvent } from '../../services/HubspotService';

interface IProps {
  className?: string;
  viewer: ConfigurationViewer;
  arLink: string;
  setArLink: (link: string) => void;
  createSharableLink: () => string;
  active: boolean;
}

const checkIfSupportsAr = async () => {
  await import('@google/model-viewer');

  const modelViewer = document.createElement('model-viewer');
  modelViewer.setAttribute('ar', 'true');
  modelViewer.setAttribute('ar-modes', 'webxr scene-viewer quick-look');
  modelViewer.setAttribute('src', '');
  modelViewer.style.position = 'absolute';
  modelViewer.style.height = '0';
  modelViewer.style.width = '0';

  document.body.appendChild(modelViewer);

  return new Promise<boolean>(resolve => {
    requestAnimationFrame(() => {
      const canActivateAr: boolean = (modelViewer as any).canActivateAR;
      resolve(canActivateAr);
    });
  }).finally(() => document.body.removeChild(modelViewer));
};

export const ARCode = ({ viewer, arLink, setArLink, active, createSharableLink }: IProps) => {
  const [supportsAr, setSupportsAr] = useState<boolean>();
  const [generatingArLink, setGeneratingArLink] = useState(false);

  const handleArLinkRequested = async () => {
    const gaEvent = new ViewInAREventGA();
    rootStore.gaEvent(gaEvent, 'Configurator');
    rootStore.hubspotTrackEvent(new ViewInARHubspotEvent(createSharableLink()));

    setGeneratingArLink(true);
    if (!viewer) {
      console.error('Attempt to get AR link before viewer is ready');
      return;
    }
    if (supportsAr === undefined) {
      const result = await checkIfSupportsAr();
      setSupportsAr(result);
    }

    const link = await new ArService().generateArLink(viewer.scene.getRootNode());
    setArLink(link);
    setGeneratingArLink(false);
  };

  useEffect(() => {
    if (arLink && supportsAr) {
      const currentLocation = createSharableLink();
      rootStore.replaceHostLocation(currentLocation);
      window.location.assign(arLink);
    }
  }, [arLink, createSharableLink, supportsAr]);

  return (
    <div className="relative">
      <ARButton loading={generatingArLink} onClick={handleArLinkRequested} active={active} />
      {arLink && supportsAr === false && <ARCodePopup onClose={() => setArLink('')} arLink={arLink} />}
    </div>
  );
};
