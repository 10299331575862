import { Object3D, Vector3 } from 'three';
import { InstrumentInsertionLayoutPayload, InstrumentLayoutModel, InstrumentLayoutPayload, LayoutItem } from '../types';
import { IInstrument } from '../../../schema';
import { Segment, SegmentLine, toRadians } from '../../geometry';
import { round } from '../../math';
import {
  InsertedItemMetaData,
  metadataToTuple,
  putResultToInsertedItemMetadata,
  PutInstrumentReturnValue,
  InstrumentCoordinatesReturnValue,
  MetaDataForInsertion
} from './types';
import { NoPossibilityToPutInstrumentError } from '../../errors';

// WARNING: in THREE.js z-axis maps to y-axis in real-world-2d coords

export class InstrumentLayoutService {
  public readonly items: LayoutItem<InstrumentLayoutPayload>[] = [];
  private itemsMetaData: InsertedItemMetaData[] = [];

  public static readonly BETWEEN_PADDING = 250;
  public static readonly HORIZONTAL_PADDING = 100;
  public static readonly VERTICAL_PADDING = 100;
  public static readonly MANIPULATOR_MARGIN = 250;

  public constructor(
    private readonly model: InstrumentLayoutModel,
    private readonly instruments: IInstrument[],
    private readonly segmentLine: SegmentLine,
    private readonly lineId: string
  ) {
    this.buildLine();
  }

  private buildLine(): void {
    this.items.length = 0;
    this.itemsMetaData.length = 0;

    if (!this.segmentLine) {
      throw new Error('No SegmentLine provided');
    }

    const { segments } = this.segmentLine;
    if (!segments || segments.length < 1) {
      throw new Error('No segments provided');
    }

    let { segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation } =
      this.initializeParamsForBuilding();

    for (const instrument of this.instruments) {
      const putResult = this.putInstrument(
        instrument,
        segments,
        segmentIndex,
        freeSpace,
        basis,
        indentationAfterPrev,
        previousDepthIndentation
      );

      if (putResult) {
        [segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation] = metadataToTuple(
          putResult.dataForNextInsertion
        );

        this.items.push(putResult.insertedItem);
        this.itemsMetaData.push(putResultToInsertedItemMetadata(putResult));
      } else {
        console.error('Unable to put instrument ', instrument, ' Next instruments will be skipped');
        throw new NoPossibilityToPutInstrumentError(instrument.name);
      }
    }
  }

  private putInstrument(
    instrument: IInstrument,
    segments: Segment[],
    currentSegmentIndex: number,
    currentSegmentFreeSpace: number,
    currentBasis: Object3D,
    indentationAfterPrev: number,
    previousVerticalIndent: number
  ): PutInstrumentReturnValue | null {
    if (instrument.length + indentationAfterPrev < currentSegmentFreeSpace) {
      // CASE 1: instrument fits along its length
      const {
        x: centerX,
        y: centerY,
        depthIndentation: previousVerticalPadding
      } = this.getInstrumentCenterCoordinates(currentBasis, instrument, indentationAfterPrev);
      const insertedItem: LayoutItem<InstrumentLayoutPayload> = {
        centerX,
        centerY,
        angle: segments[currentSegmentIndex].angle,
        payload: { type: 'instrument', instrument },
        groupId: this.lineId,
        view: instrument.view
      };
      const newBasis = InstrumentLayoutService.shiftBasisAlongMainAxis(
        currentBasis,
        instrument.length + indentationAfterPrev
      );
      const newFreeSpace = currentSegmentFreeSpace - instrument.length - indentationAfterPrev;

      return {
        insertedItem,
        dataForNextInsertion: {
          freeSpace: newFreeSpace,
          basis: newBasis,
          segmentIndex: currentSegmentIndex,
          indentationAfterPrev: InstrumentLayoutService.BETWEEN_PADDING,
          previousDepthIndentation: previousVerticalPadding
        }
      };
    }

    // CASES 2-3: instrument does not fit along its length:
    const segmentIndex = currentSegmentIndex + 1;
    if (segmentIndex >= segments.length) {
      console.warn('No segments left');
      return null;
    }

    let newBasis: Object3D = this.getSegmentOriginBasis(segments[segmentIndex]);
    let instrumentPosition: InstrumentCoordinatesReturnValue;
    let newFreeSpace: number;

    if (instrument.width + InstrumentLayoutService.MANIPULATOR_MARGIN <= currentSegmentFreeSpace) {
      // CASE 2: can put into corner, but to next segment
      // need to calculate depth indention
      const placeFromPreviousBench = currentSegmentFreeSpace - this.model.benchWidth;
      let depthIndentation: number;
      if (placeFromPreviousBench > 0) {
        depthIndentation = Math.max(
          InstrumentLayoutService.MANIPULATOR_MARGIN - placeFromPreviousBench,
          InstrumentLayoutService.VERTICAL_PADDING
        );
      } else {
        depthIndentation = Math.abs(placeFromPreviousBench) + InstrumentLayoutService.MANIPULATOR_MARGIN;
      }

      instrumentPosition = this.getInstrumentCenterCoordinates(
        newBasis,
        instrument,
        InstrumentLayoutService.HORIZONTAL_PADDING,
        depthIndentation
      );
      newBasis = InstrumentLayoutService.shiftBasisAlongMainAxis(
        newBasis,
        InstrumentLayoutService.HORIZONTAL_PADDING + instrument.length
      );
      newFreeSpace =
        this.initializeFreeSpace(segments[segmentIndex]) -
        instrument.length -
        InstrumentLayoutService.HORIZONTAL_PADDING;
    } else {
      // CASE 3: can NOT put into corner => shift along new segment away from corner
      const indentFromEdgeAlongMainAxis = this.model.benchWidth + InstrumentLayoutService.MANIPULATOR_MARGIN;
      instrumentPosition = this.getInstrumentCenterCoordinates(newBasis, instrument, indentFromEdgeAlongMainAxis);
      newFreeSpace = this.initializeFreeSpace(segments[segmentIndex]) - instrument.length - indentFromEdgeAlongMainAxis;
      newBasis = InstrumentLayoutService.shiftBasisAlongMainAxis(
        newBasis,
        indentFromEdgeAlongMainAxis + instrument.length
      );
    }

    const insertedItem: LayoutItem<InstrumentLayoutPayload> = {
      payload: { type: 'instrument', instrument: instrument },
      view: instrument.view,
      groupId: this.lineId,
      centerX: instrumentPosition.x,
      centerY: instrumentPosition.y,
      angle: segments[segmentIndex].angle
    };

    return {
      insertedItem,
      dataForNextInsertion: {
        freeSpace: newFreeSpace,
        basis: newBasis,
        indentationAfterPrev: InstrumentLayoutService.BETWEEN_PADDING,
        segmentIndex,
        previousDepthIndentation: instrumentPosition.depthIndentation
      }
    };
  }

  private getInstrumentCenterCoordinates(
    currentBasis: Object3D,
    instrument: IInstrument,
    indentationAfterPrevAlongPrimaryAxis: number,
    depthIndentation: number = InstrumentLayoutService.VERTICAL_PADDING
  ): InstrumentCoordinatesReturnValue {
    // TODO: it decides about depth indentation or PutInstrument ? -- when checking if it suits to manipulator zones
    // reduce depthIndentation (VERTICAL_PADDING) if too wide instrument
    if (this.model.benchWidth - depthIndentation < instrument.width) {
      depthIndentation = Math.max(0, this.model.benchWidth - instrument.width);
    }

    const globalCoordinates = currentBasis.localToWorld(
      new Vector3(
        indentationAfterPrevAlongPrimaryAxis + instrument.length / 2,
        0,
        this.model.benchWidth / 2 - depthIndentation - instrument.width / 2
      )
    );

    return { x: round(globalCoordinates.x), y: round(globalCoordinates.z), depthIndentation };
  }

  private getSegmentOriginBasis(segment: Segment): Object3D {
    const segmentPseudoStart = InstrumentLayoutService.defineBasis(
      segment.start.x,
      segment.start.y,
      toRadians(segment.angle)
    );

    const segmentStartRealWorldCoordinates = segmentPseudoStart.localToWorld(
      new Vector3(-0.5 * this.model.benchWidth, 0, 0)
    );

    return InstrumentLayoutService.defineBasis(
      round(segmentStartRealWorldCoordinates.x),
      round(segmentStartRealWorldCoordinates.z),
      segmentPseudoStart.rotation.y
    );
  }

  private static shiftBasisAlongMainAxis(basis: Object3D, shift: number): Object3D {
    const newBasisGlobalCoordinates = basis.localToWorld(new Vector3(shift, 0, 0));
    return InstrumentLayoutService.defineBasis(
      newBasisGlobalCoordinates.x,
      newBasisGlobalCoordinates.z,
      basis.rotation.y
    );
  }

  private initializeFreeSpace(segment: Segment): number {
    return segment.length + this.model.benchWidth;
  }

  public findFreePositionsToInsert(instrument: IInstrument): LayoutItem<InstrumentInsertionLayoutPayload>[] {
    const response: LayoutItem<InstrumentInsertionLayoutPayload>[] = [];

    if (this.items.length === 0) {
      // if current config is empty, we can put selected item only to very beginning
      const putResult = this.putInstrument(
        instrument,
        this.segmentLine.segments,
        ...metadataToTuple(this.initializeParamsForBuilding())
      );

      if (putResult) {
        return [
          {
            groupId: this.lineId,
            view: instrument.view,
            centerX: putResult.insertedItem.centerX,
            centerY: putResult.insertedItem.centerY,
            angle: putResult.insertedItem.angle,
            payload: {
              type: 'instrumentInsertion',
              instrument,
              position: { type: 'firstInstrument', line_uuid: this.lineId }
            }
          }
        ];
      }
    }

    for (let index = 1; index <= this.items.length; ++index) {
      if (
        (index < this.itemsMetaData.length &&
          this.itemsMetaData[index].segmentIndex !== this.itemsMetaData[index - 1].segmentIndex) ||
        index === this.itemsMetaData.length
      ) {
        const insertResult = this.tryInsertItem(index, instrument);

        if (insertResult) {
          insertResult.payload.type = 'instrumentInsertion';
          insertResult.payload = {
            type: 'instrumentInsertion',
            position: { type: 'afterInstrument', after: { uuid: this.items[index - 1].payload.instrument.uuid } },
            instrument: instrument
          };
          response.push(insertResult as LayoutItem<InstrumentInsertionLayoutPayload>);
        }
      }
    }

    return response;
  }

  public isInstrumentInsertableAnywhere(instrument: IInstrument): boolean {
    if (this.items.length === 0) {
      const putResult = this.putInstrument(
        instrument,
        this.segmentLine.segments,
        ...metadataToTuple(this.initializeParamsForBuilding())
      );
      return !!putResult;
    }

    for (let index = 1; index <= this.items.length; ++index) {
      if (
        (index < this.itemsMetaData.length &&
          this.itemsMetaData[index].segmentIndex !== this.itemsMetaData[index - 1].segmentIndex) ||
        index === this.itemsMetaData.length
      ) {
        if (this.tryInsertItem(index, instrument)) {
          return true;
        }
      }
    }

    return false;
  }

  private tryInsertItem(rawIndex: number, instrument: IInstrument): LayoutItem | null {
    const index = rawIndex - 1;
    let { segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation } =
      this.initializeParamsForBuildingFromMeta(this.itemsMetaData[index]);

    try {
      // try to locate inserted item
      const putResult1 = this.putInstrument(
        instrument,
        this.segmentLine.segments,
        segmentIndex,
        freeSpace,
        basis,
        indentationAfterPrev,
        previousDepthIndentation
      );

      if (putResult1) {
        [segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation] = metadataToTuple(
          putResult1.dataForNextInsertion
        );

        // now try to locate next item and check if it changed its position
        const nextItem = rawIndex < this.items.length ? this.items[rawIndex].payload.instrument : null;

        if (!nextItem) {
          return putResult1.insertedItem;
        }

        const putResult2 = this.putInstrument(
          nextItem,
          this.segmentLine.segments,
          segmentIndex,
          freeSpace,
          basis,
          indentationAfterPrev,
          previousDepthIndentation
        );

        if (!putResult2) {
          return null;
        }

        // compare next item's position from initial layout to currently received
        if (InstrumentLayoutService.doItemsHaveSamePosition(putResult2.insertedItem, this.items[rawIndex])) {
          return putResult1.insertedItem;
        }
      }
    } catch (error) {
      return null;
    }

    return null;
  }

  private initializeParamsForBuilding(): MetaDataForInsertion {
    const { segments } = this.segmentLine;

    const segmentIndex = 0;
    const freeSpace = this.initializeFreeSpace(segments[0]);
    const basis = this.getSegmentOriginBasis(segments[0]);
    const indentationAfterPrev = InstrumentLayoutService.HORIZONTAL_PADDING;
    const previousDepthIndentation = this.model.benchWidth;

    return { segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation };
  }

  private initializeParamsForBuildingFromMeta(metadata: InsertedItemMetaData): MetaDataForInsertion {
    if (!metadata) {
      console.warn('Metadata is not a valid object. Creating params by default');
      return this.initializeParamsForBuilding();
    }

    const segmentIndex = metadata.segmentIndex;
    const freeSpace = metadata.freeSpace;
    const basis = InstrumentLayoutService.defineBasis(metadata.basisX, metadata.basisZ, metadata.basisRotation);
    const indentationAfterPrev = metadata.indentationAfterPrev;
    const previousDepthIndentation = metadata.previousDepthIndentation;

    return { segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation };
  }

  private static defineBasis(x: number, z: number, rotation: number): Object3D {
    const basis = new Object3D();
    basis.position.x = x;
    basis.position.z = z;
    basis.rotation.y = rotation;

    return basis;
  }

  private static doItemsHaveSamePosition(a: LayoutItem, b: LayoutItem): boolean {
    return round(a.centerX) === round(b.centerX) && round(a.centerY) === round(b.centerY) && a.angle === b.angle;
  }
}
