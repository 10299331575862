import { useIsMobileScreenSize } from './useIsMobileScreenSize';
import { useIsHoverable } from './useMediaQuery';
import { useMemo } from 'react';

// TODO: bad file for this type as it can be used in the store
export type MobileDetector = {
  isMobile: boolean;
  isHoveringSupported: boolean;
  isMobileScreenSize: boolean;
};
export const useMobileDetector = () => {
  const isMobileScreenSize = useIsMobileScreenSize();
  const isHoveringSupported = useIsHoverable();

  return useMemo(
    () => ({
      isMobile: isMobileScreenSize || !isHoveringSupported,
      isHoveringSupported,
      isMobileScreenSize
    }),
    [isMobileScreenSize, isHoveringSupported]
  );
};
