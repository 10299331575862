import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { IMenuItem, PageTemplate } from './PageTemplate';
import { routes } from '../../routes';
import { ConfigurationMode } from '../../domain/modes';
import { localization } from '../../stores/Localization';
import { ExitIntentPageName, PredefinedConfigEventGA } from 'services/GoogleAnalyticsService';
import { rootStore } from 'stores/RootStore';
import { ExitIntentProvider, useExitIntent } from 'components/hooks/useExitIntent';
import { useEffect } from 'react';

export const OWN_CONFIGURATIONS: IMenuItem[] = [
  {
    value: 'line',
    text: 'shapePage.shape.linear',
    description: '',
    link: routes.configure.link(ConfigurationMode.Own, 'line'),
    preview: './assets/images/templates/line.png'
  },
  {
    value: 'l-left',
    text: 'shapePage.shape.l-left',
    description: '',
    link: routes.configure.link(ConfigurationMode.Own, 'l-left'),
    preview: './assets/images/templates/l-left.png'
  },
  {
    value: 'l-right',
    text: 'shapePage.shape.l-right',
    description: '',
    link: routes.configure.link(ConfigurationMode.Own, 'l-right'),
    preview: './assets/images/templates/l-right.png'
  },
  {
    value: 'u-shape',
    text: 'shapePage.shape.u-shape',
    description: '',
    link: routes.configure.link(ConfigurationMode.Own, 'u-shape'),
    preview: './assets/images/templates/u-shape.png'
  },
  {
    value: 'back-to-back',
    text: 'shapePage.shape.back-to-back',
    description: '',
    link: routes.configure.link(ConfigurationMode.Own, 'back-to-back'),
    preview: './assets/images/templates/back-to-back.png'
  }
];
export const ShapeSelectionPage = () => {
  const menuItems = OWN_CONFIGURATIONS;

  useEffect(() => {
    rootStore.gaPageView('Own layout selection');
  }, []);

  useExitIntent();

  return (
    <ExitIntentProvider page={ExitIntentPageName.OwnLayoutSelection} rootStore={rootStore}>
      <PageTemplate
        menuItems={menuItems}
        title={localization.formatMessage('shapePage.title')}
        configurationMode={ConfigurationMode.Own}
        description={
          <>
            <p className="mb-4 mt-6 w-full md:mt-0">
              <FormattedMessage id="shapePage.description.paragraphOne" />
            </p>
            <p className="mb-4 w-full">
              <FormattedMessage id="shapePage.description.paragraphTwo" />
            </p>
            <p className="w-full">
              <FormattedMessage id="shapePage.description.paragraphThree" />
            </p>
          </>
        }
        footerContent={
          <>
            <FormattedMessage id="shapePage.quick" />
            <br />
            <FormattedMessage
              id="shapePage.switchTo"
              values={{
                comp: (
                  <Link
                    className="text-neutral-900 underline"
                    to={routes.predefined.link()}
                    onClick={() => rootStore.gaEvent(new PredefinedConfigEventGA(), 'Own layout selection')}
                  >
                    <FormattedMessage id="shapePage.predefinedOption" />
                  </Link>
                )
              }}
            />
          </>
        }
      />
    </ExitIntentProvider>
  );
};
