import { PREDEFINED_CONFIGURATIONS } from 'pages/PreConfiguratorTemplate/PredefinedSolutionPage';
import { OWN_CONFIGURATIONS } from 'pages/PreConfiguratorTemplate/ShapeSelectionPage';
import { localization } from 'stores/Localization';

declare global {
  interface Window {
    gtag: any;
  }
}

export type EventCategory =
  | 'select_solution'
  | 'user_interactions'
  | 'select_layout'
  | 'exit_intent'
  | 'manage_instruments';

export type PageViewTitle =
  | `Configurator's home page`
  | 'Pre-defined layout selection'
  | 'Own layout selection'
  | 'Configurator';

interface IBaseEventGA {
  readonly action: string;
  readonly payload: object;
  readonly event_category: EventCategory;
}

export class PredefinedConfigEventGA implements IBaseEventGA {
  readonly action = 'Pre-defined solution';
  readonly event_category = 'select_solution';
  readonly payload = {};
}

export class OwnConfigEventGA implements IBaseEventGA {
  readonly action = 'Design your own solution';
  readonly event_category = 'select_solution';
  readonly payload = {};
}

export class DownloadPDFEventGA implements IBaseEventGA {
  readonly action = 'Download PDF';
  readonly event_category = 'user_interactions';
  readonly payload = {};
}

export class SendViaEmailEventGA implements IBaseEventGA {
  readonly action = 'Send via email';
  readonly event_category = 'user_interactions';
  readonly payload = {};
}

export class ShareLinkEventGA implements IBaseEventGA {
  readonly action = 'Share link';
  readonly event_category = 'user_interactions';
  constructor(readonly payload: { from: 'Summary' | 'Configurator' }) {}
}

export class ViewInAREventGA implements IBaseEventGA {
  readonly action = 'View in AR';
  readonly event_category = 'user_interactions';
  readonly payload = {};
}

export class DimensionEventGA implements IBaseEventGA {
  readonly action = 'Dimensions';
  readonly event_category = 'user_interactions';
  constructor(readonly payload: { status: 'on' | 'off' }) {}
}

export class SelectLayoutEventGA implements IBaseEventGA {
  readonly action = 'select_layout';
  readonly event_category = 'select_layout';
  readonly payload = {};
  constructor(readonly layoutName: string) {}
}

export enum ExitIntentPageName {
  HomePage = 'Home page',
  Configurator = 'Configurator',
  PreDefinedLayoutSelection = 'Pre-defined layout selection',
  OwnLayoutSelection = 'Own layout selection'
}

export class BeforeUnloadEventGA implements IBaseEventGA {
  readonly action = 'Tab close';
  readonly event_category = 'exit_intent';
  constructor(readonly payload: { page: ExitIntentPageName }) {}
}

export class HideTabEventGA implements IBaseEventGA {
  readonly action = 'Tab switch';
  readonly event_category = 'exit_intent';
  constructor(readonly payload: { page: ExitIntentPageName }) {}
}
export class AddInstrumentEventGA implements IBaseEventGA {
  readonly action = 'Add instrument';
  readonly event_category = 'manage_instruments';
  constructor(readonly payload: { instrument: string }) {}
}

export class ReplaceInstrumentEventGA implements IBaseEventGA {
  readonly action = 'Replace instrument';
  readonly event_category = 'manage_instruments';
  constructor(readonly payload: { instrument: string; from_instrument: string; to_instrument: string }) {}
}

export class CreateCustomInstrumentEventGA implements IBaseEventGA {
  readonly action = 'Create custom instrument';
  readonly event_category = 'manage_instruments';
  constructor(readonly payload: { instrument_name: string }) {}
}

export class RegistrationFormSubmissionEventGA implements IBaseEventGA {
  readonly action = 'Registration form submission';
  readonly event_category = 'user_interactions';
  readonly payload = {};
}

export class RestartGuideEventGA implements IBaseEventGA {
  readonly action = 'Restart help guide';
  readonly event_category = 'user_interactions';
  readonly payload = {};
}

export class ViewHelpGuideEventGA implements IBaseEventGA {
  readonly action = 'View help guide';
  readonly event_category = 'user_interactions';
  readonly payload = {};
}

export type EventGA =
  | PredefinedConfigEventGA
  | OwnConfigEventGA
  | SelectLayoutEventGA
  | DownloadPDFEventGA
  | SendViaEmailEventGA
  | ShareLinkEventGA
  | ViewInAREventGA
  | DimensionEventGA
  | AddInstrumentEventGA
  | ReplaceInstrumentEventGA
  | CreateCustomInstrumentEventGA
  | RegistrationFormSubmissionEventGA
  | BeforeUnloadEventGA
  | HideTabEventGA
  | RestartGuideEventGA
  | ViewHelpGuideEventGA;

export class GoogleAnalyticsService {
  private googleAnalyticsId = '';

  async init() {
    let shouldFilterGA = false;
    if (process.env.REACT_APP_CL__GA_NODE_ENV === 'dev') {
      const country = await getCountry();

      shouldFilterGA = !(country && !country.toLowerCase().includes('belarus'));
    }
    this.googleAnalyticsId = shouldFilterGA ? '' : process.env.REACT_APP_CL__GA_ID ?? '';

    if (this.googleAnalyticsId) {
      const firstScript = document.getElementsByTagName('script')[0];
      const gtagScript = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.googleAnalyticsId;
      firstScript.parentNode?.insertBefore(gtagScript, firstScript);
      window.gtag('js', new Date());
    }
  }

  private getPageTitle(page: PageViewTitle) {
    if (page === 'Configurator') {
      let configurationName;
      const route = window.location.hash.replace('#', '');

      for (const { link, text } of PREDEFINED_CONFIGURATIONS) {
        if (route === link) {
          configurationName = localization.formatMessage(text);
          break;
        }
      }
      if (!configurationName) {
        for (const { link, text } of OWN_CONFIGURATIONS) {
          if (route === link) {
            configurationName = localization.formatMessage(text);
            break;
          }
        }
      }
      if (configurationName) {
        return `Configurator ${configurationName}`;
      }
      return 'Automata configurator page';
    }
    return page;
  }

  pageView(page: PageViewTitle) {
    if (this.googleAnalyticsId) {
      const route = window.location.hash.replace('#', '');
      const pageTitle = this.getPageTitle(page);
      const payload = {
        page_title: pageTitle,
        page_path: route
      };

      window.gtag('config', this.googleAnalyticsId, {
        send_page_view: false
      });
      window.gtag('event', 'page_view', {
        page_title: payload.page_title,
        page_path: payload.page_path
      });
      if (process.env.REACT_APP_CL__GA_NODE_ENV === 'dev') {
        console.debug(`Page view: ${page}`);
      }
    }
  }

  event(event: EventGA, page: PageViewTitle) {
    if (this.googleAnalyticsId) {
      const pageTitle = this.getPageTitle(page);
      const payload = {
        event_category: event.event_category,
        page_title: pageTitle,
        ...event.payload
      };
      const eventAction = event.action === 'select_layout' ? event.layoutName : event.action;
      window.gtag('config', this.googleAnalyticsId, {
        send_page_view: false
      });
      window.gtag('event', eventAction, payload);

      if (process.env.REACT_APP_CL__GA_NODE_ENV === 'dev') {
        console.debug(`Sending GA event: ${eventAction}`, JSON.stringify(payload));
      }
    }
  }
}

async function getCountry(): Promise<string> {
  try {
    let response = await fetch('https://ipv4.illusdolphin.net');
    const data = await response.json();
    let country = '';
    if (data && data.length) {
      country = data[0].country;
    }
    return country;
  } catch (error) {
    console.error(error);
    return '';
  }
}
