import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { SummaryTopView } from './SummaryTopView';
import { SummaryItems } from './SummaryItems';
import { BlueprintViewer } from '../../../../../components/BlueprintViewer/BlueprintViewer';
import { BluePrintService } from '../../../../../services/BluePrintService';

import { SavePdfButton } from './SavePdfButton';
import { EmailButton } from './EmailButton';
import { isIosDevice, isMozillaMobile } from '../../../utils';
import { ShareButton } from '../../ShareButton';
import { SummaryReport } from '../../../../../services/SummaryService';
import { ConfigurationMode } from '../../../../../domain/modes';
import { DownloadPDFEventGA } from 'services/GoogleAnalyticsService';
import { rootStore } from 'stores/RootStore';

interface ISummaryBodyProps {
  reportData: SummaryReport;
  mode: ConfigurationMode;
  bluePrintService: BluePrintService;
  onSendEmailClicked?: () => void;
  onDownloadPdf: () => Promise<void>;
  onShare: () => void;
  shareLoading: boolean;
  bluePrintRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const SummaryBody = ({
  reportData,
  mode,
  bluePrintService,
  onSendEmailClicked,
  onDownloadPdf,
  onShare,
  shareLoading,
  bluePrintRef
}: ISummaryBodyProps) => {
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const onPdfDownloadButtonClick = async () => {
    const gaEvent = new DownloadPDFEventGA();
    rootStore.gaEvent(gaEvent, 'Configurator');
    setIsPdfLoading(true);
    try {
      await onDownloadPdf();
    } catch (error) {}
    setIsPdfLoading(false);
  };

  return (
    <div
      className={`flex min-h-0 w-full flex-col gap-20 overflow-y-scroll bg-neutral-0 py-10 pl-4 pr-0 sm:pl-6 ${
        isIosDevice || isMozillaMobile ? 'sm:pr-6' : 'sm:pr-2'
      } ls:h-[calc(100vh-90px)] ls:pl-10 ls:pr-6`}
    >
      <div className="w-full self-start">
        <h1 className="typography-h4 mb-2">
          <FormattedMessage id={mode === ConfigurationMode.Own ? 'summary.titleOwn' : 'summary.titlePredefined'} />
        </h1>
        <p className="typography-body2 mb-10">
          <FormattedMessage
            id={mode === ConfigurationMode.Own ? 'summary.descriptionOwn' : 'summary.descriptionPredefined'}
            values={{ br: <br /> }}
          />
        </p>
        <div className="mb-10 flex gap-4">
          <SavePdfButton variant="outlined" onClick={onPdfDownloadButtonClick} loading={isPdfLoading} />
          <EmailButton variant="outlined" onClick={onSendEmailClicked} />
          <ShareButton
            className="max-sm2:flex-1 max-sm2:px-3"
            variant="outlined"
            onShare={onShare}
            loading={shareLoading}
            hideTextOnScreen="sm2"
          />
        </div>
        <div className="flex flex-col gap-4">
          <img className="w-full" src={reportData.sceneImage} alt="scene" />
          <small className="typography-caption text-neutral-500">
            <FormattedMessage id="summary.remark" />
          </small>
        </div>
      </div>
      <SummaryTopView dimensions={reportData.topViewDimensions}>
        <BlueprintViewer bluePrintService={bluePrintService} canvasRef={bluePrintRef} />
      </SummaryTopView>
      <SummaryItems
        benches={reportData.benches}
        instruments={reportData.instruments}
        customInstruments={reportData.customInstruments}
      />
    </div>
  );
};
