import gzip from 'gzip-js';
import { toJS } from 'mobx';
import { ConfiguratorStore } from '../../../stores/ConfiguratorStore';
import { ConfigurationMode } from '../../modes';
import { rootStore } from 'stores/RootStore';

export class ShareService {
  constructor(private configuratorStore: ConfiguratorStore) {}

  makeSharableLink(configurationId: string, mode: ConfigurationMode): string {
    const gzipValue = this.getConfigurationHash();
    const link = `#/configuration/${mode}/${configurationId}?link=${gzipValue}`;
    const location = rootStore.location;
    const response = location.origin + (location.pathname !== '/' ? location.pathname : '') + link;

    return response;
  }

  private getConfigurationHash() {
    const json = this.configuratorStore.automataSerializer.serialize(toJS(this.configuratorStore.model));
    const str = JSON.stringify(json);
    const gzipped = gzip.zip(str);
    const gzippedStr = gzipped.map(u => String.fromCharCode(u)).join('');
    return btoa(gzippedStr);
  }
}
