import {
  AxesHelper,
  BoxGeometry,
  DirectionalLight,
  Group,
  Material,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  Scene
} from 'three';
import { ResourceLoader, ResourceLoadTransaction } from '../media';
import {
  InsertionLayoutPayload,
  InstrumentInsertionLayoutPayload,
  InstrumentLayoutPayload,
  LayoutItem,
  SectionInsertionLayoutPayload,
  View
} from '../layout';
import { toRadians } from '../geometry';
import { arePlaceholdersEqual, removeNodeChildren, toThreeCoordinate } from './utils';
import { InsertionApproachType, ViewModel, Listener } from './types';
import { IConfiguration } from '../../schema';
import { CUSTOM_INSTRUMENT_MODEL_SIZE, CUSTOM_INSTRUMENT_VIEW } from '../instruments';
import {
  customInstrumentTypeGuard,
  instrumentInsertionLayoutItemTypeGuard,
  instrumentLayoutItemTypeGuard
} from '../typeGuards';
import { BENCH_LENGTH, BENCH_WIDTH } from '../mocks/mocks';

export const BENCH_HEIGHT = 935;
export const DEBUG = false;
export const SECTION_NAME = 'section';
export const INSTRUMENT_INSERTION = 'instrumentInsertion';
export const BENCH_INSERTION = 'benchInsertion';
export const INSTRUMENT_INSERTION_PLACEHOLDER = 'instrumentInsertionPlaceholder';
export const BENCH_INSERTION_PLACEHOLDER = 'benchInsertionPlaceholder';
export const INSTRUMENT_NAME = 'instrument';
export const LIGHT_COLOR = 'white';

export class ConfigurationScene {
  private static readonly ELEMENT_INSERTION_SILHOUETTE_COLOR = '#2264C2';
  private static readonly ELEMENT_INSERTION_SILHOUETTE_EMISSIVE = '#6198E5';
  public static readonly ELEMENT_REPLACEMENT_SILHOUETTE_COLOR = '#EA3A6C';
  private static readonly ELEMENT_INSERTION_PLACEHOLDER_RECTANGLE_HEIGHT = 100;
  private static readonly ELEMENT_SILHOUETTE_OPACITY = 0.2;

  private scene!: Scene;
  private outlineScene!: Scene;
  private dimensionsScene!: Scene;
  private sceneNode!: Object3D;
  private rootNode!: Object3D;
  private instrumentsHighlightsNode!: Object3D;
  private hoveredSilhouetteNode!: Object3D;
  backgroundNode!: Object3D;
  private rulerNode!: Object3D;
  private configurationNode!: Group;
  private models = new Map<View, Object3D>();
  interiorNode: Object3D | null = null;

  public readonly insertElementTransparentMaterial: Material;
  public readonly replaceElementHighlightMaterial: Material;
  private manipulatorHandles: Object3D[] = [];
  private MANIPULATOR_HANDLE = 'manipulator_hand';
  private currentlyHoveredPlaceholder: LayoutItem<InsertionLayoutPayload> | undefined = undefined; // for optimizing

  // model: IConfiguration -- temp solution to know sizes of bench
  private sceneChangedListeners: Listener[] = [];
  private redrawChangedListeners: Listener[] = [];
  private instrumentHighlightedSpaces: LayoutItem<InstrumentInsertionLayoutPayload>[] = [];
  private benchHighlightedSpaces: LayoutItem<SectionInsertionLayoutPayload>[] = [];

  addSceneChangedListener(handler: () => void): () => void {
    this.sceneChangedListeners.push(handler);
    return () => {
      const idx = this.sceneChangedListeners.findIndex(handler);
      if (idx >= 0) {
        this.sceneChangedListeners.splice(idx, 1);
      }
    };
  }

  addRedrawListener(handler: () => void): () => void {
    this.redrawChangedListeners.push(handler);
    return () => {
      const idx = this.redrawChangedListeners.findIndex(handler);
      if (idx >= 0) {
        this.redrawChangedListeners.splice(idx, 1);
      }
    };
  }

  sceneChanged() {
    this.sceneChangedListeners.forEach(listener => listener());
  }

  private notifyRedraw() {
    this.redrawChangedListeners.forEach(listener => listener());
  }

  constructor(private readonly resourceLoader: ResourceLoader) {
    this.insertElementTransparentMaterial = ConfigurationScene.getTransparentMaterial(
      ConfigurationScene.ELEMENT_INSERTION_SILHOUETTE_COLOR,
      ConfigurationScene.ELEMENT_INSERTION_SILHOUETTE_EMISSIVE
    );
    this.replaceElementHighlightMaterial = ConfigurationScene.getTransparentMaterial(
      ConfigurationScene.ELEMENT_REPLACEMENT_SILHOUETTE_COLOR,
      ConfigurationScene.ELEMENT_REPLACEMENT_SILHOUETTE_COLOR
    );
  }

  init(offsetHeight: number) {
    this.initScene(offsetHeight);
    this.initLights();
  }

  getScene(): Scene {
    return this.scene;
  }

  getOutlineScene(): Scene {
    return this.outlineScene;
  }

  getDimensionsScene(): Scene {
    return this.dimensionsScene;
  }

  getSceneRoot(): Object3D {
    return this.sceneNode;
  }

  getConfigurationNode(): Object3D {
    return this.configurationNode;
  }

  getRulerNode(): Object3D {
    return this.rulerNode;
  }

  getRootNode(): Object3D {
    return this.rootNode;
  }

  addPermanentObject(node: Object3D) {
    this.sceneNode.add(node);
  }

  clearHighlights() {
    removeNodeChildren(this.instrumentsHighlightsNode);
  }

  async buildScene(viewModel: ViewModel) {
    await this.loadResources(viewModel);
    this.createScene(viewModel);
  }

  rebuildScene(viewModel: ViewModel) {
    this.loadResources(viewModel).then(needEvent => {
      if (needEvent) {
        this.sceneChanged();
      }
    });
    this.createScene(viewModel);
  }

  private createScene(viewModel: ViewModel) {
    removeNodeChildren(this.backgroundNode);
    removeNodeChildren(this.rootNode);

    this.interiorNode?.traverse(obj => {
      if (obj instanceof Mesh) {
        const { material } = obj;
        obj.receiveShadow = true;
        if (material instanceof MeshStandardMaterial) {
          if (material.name === 'floor') {
            material.envMapIntensity = 0.225;
            //material.envMapIntensity = 0.3;
          }
        }
      }
    });

    this.sceneNode.add(this.interiorNode!);
    this.clearManipulatorHandles();
    viewModel.benches.forEach(item => this.addItemToRootScene(SECTION_NAME, item, 0, false));
    viewModel.instruments.forEach(item => this.addItemToRootScene(INSTRUMENT_NAME, item, BENCH_HEIGHT, true));
  }

  async highlightAvailableInstrumentPositions(
    highlightedSpaces: LayoutItem<InstrumentInsertionLayoutPayload>[],
    highlightType: InsertionApproachType = 'rectanglePlaceholders'
  ) {
    this.benchHighlightedSpaces = [];
    this.instrumentHighlightedSpaces = highlightedSpaces;
    await this.buildAvailableInstrumentPositions(highlightedSpaces, highlightType);
  }

  async highlightAvailableBenchPositions(
    highlightedSpaces: LayoutItem<SectionInsertionLayoutPayload>[],
    highlightType: InsertionApproachType = 'rectanglePlaceholders'
  ) {
    this.benchHighlightedSpaces = highlightedSpaces;
    this.instrumentHighlightedSpaces = [];
    await this.buildAvailableBenchPositions(highlightedSpaces, highlightType);
  }

  private async buildAvailableInstrumentPositions(
    highlightedSpaces: LayoutItem<InsertionLayoutPayload>[],
    highlightType: InsertionApproachType = 'rectanglePlaceholders'
  ) {
    removeNodeChildren(this.instrumentsHighlightsNode);

    if (highlightedSpaces.length === 0) {
      return;
    }
    const view = highlightedSpaces[0].view;

    let nodeCreator;
    let nodeName: string;
    let heightFromFround: number;

    nodeName = highlightType === 'rectanglePlaceholders' ? INSTRUMENT_INSERTION_PLACEHOLDER : INSTRUMENT_INSERTION;
    heightFromFround = BENCH_HEIGHT;

    nodeCreator =
      highlightType === 'rectanglePlaceholders'
        ? ConfigurationScene.createRectangleForHighlightingPlaceToInsert
        : this.createItemNode.bind(this);
    if (!this.models.has(view)) {
      nodeCreator = ConfigurationScene.createRectangleForHighlightingPlaceToInsert;
      this.loadSingleModel(view).then(() => {
        this.buildAvailableInstrumentPositions(this.instrumentHighlightedSpaces, highlightType);
        this.notifyRedraw();
      });
    }
    for (const space of highlightedSpaces) {
      const node = nodeCreator(nodeName, space, heightFromFround, false);
      ConfigurationScene.setMaterial(node, this.insertElementTransparentMaterial);
      this.instrumentsHighlightsNode.add(node);
    }
  }

  private async buildAvailableBenchPositions(
    highlightedSpaces: LayoutItem<SectionInsertionLayoutPayload>[],
    initialHighlightType: InsertionApproachType = 'rectanglePlaceholders'
  ) {
    let highlightType = initialHighlightType;
    removeNodeChildren(this.instrumentsHighlightsNode);

    if (highlightedSpaces.length === 0) {
      return;
    }
    const view = highlightedSpaces[0].view;

    if (!this.models.has(view)) {
      highlightType = 'rectanglePlaceholders';
      this.loadSingleModel(view).then(() => {
        this.buildAvailableBenchPositions(this.benchHighlightedSpaces, initialHighlightType);
        this.notifyRedraw();
      });
    }

    if (highlightType === 'rectanglePlaceholders') {
      for (const space of highlightedSpaces) {
        const node = ConfigurationScene.createRectangleForHighlightingPlaceToInsert(
          BENCH_INSERTION_PLACEHOLDER,
          space,
          0
        );
        ConfigurationScene.setMaterial(node, this.insertElementTransparentMaterial);
        this.instrumentsHighlightsNode.add(node);
      }
    } else if (highlightType === 'silhouettes') {
      for (const space of highlightedSpaces) {
        this.renderBenchesSilhouettesFromPlaceholder(space, this.instrumentsHighlightsNode);
      }
    } else {
      throw new Error('Unknown highlightType');
    }
  }

  drawSilhouettesOnHover(
    model: IConfiguration,
    hoveredPlaceholder?: LayoutItem<InsertionLayoutPayload>,
    type?: 'bench' | 'instrument'
  ): void {
    if (!hoveredPlaceholder) {
      this.clearSilhouetteOnLeave();
      return;
    }

    if (this.currentlyHoveredPlaceholder) {
      if (arePlaceholdersEqual(this.currentlyHoveredPlaceholder, hoveredPlaceholder)) {
        return;
      }
      this.clearSilhouetteOnLeave();
    }

    if (type === 'instrument') {
      this.currentlyHoveredPlaceholder = hoveredPlaceholder;
      const silhouette = this.createItemNode(INSTRUMENT_INSERTION, hoveredPlaceholder, BENCH_HEIGHT, false);
      this.hoveredSilhouetteNode.add(silhouette);
      ConfigurationScene.setMaterial(silhouette, this.insertElementTransparentMaterial);
    } else if (type === 'bench') {
      this.currentlyHoveredPlaceholder = hoveredPlaceholder;
      this.renderBenchesSilhouettesFromPlaceholder(hoveredPlaceholder, this.hoveredSilhouetteNode);
    } else {
      return;
    }
  }

  hideManipulatorsHandles() {
    this.changeMaterials(this.manipulatorHandles, materials => {
      materials.forEach(material => {
        material.transparent = true;
        material.opacity = 0.2;
      });
    });
  }

  changeMaterials(nodes: Object3D[], callback: (materials: Material[]) => void): void {
    nodes.forEach(node =>
      node.traverse(inner => {
        if (inner instanceof Mesh) {
          const materials: Material[] = Array.isArray(inner.material) ? inner.material : [inner.material];
          callback(materials);
        }
      })
    );
  }

  showManipulatorsHandles() {
    this.changeMaterials(this.manipulatorHandles, materials =>
      materials.forEach(material => {
        material.transparent = false;
        material.opacity = 1;
      })
    );
  }

  // method adds to scene silhouettes on placeholders (if placeholder is for double-bench or back-to-back -- it will create as many silhouettes as needed)
  private renderBenchesSilhouettesFromPlaceholder(
    hoveredPlaceholder: LayoutItem<InsertionLayoutPayload>,
    containerToPush: Object3D
  ): void {
    const placeholdersSilhouettes = ConfigurationScene.computePlaceholders(
      hoveredPlaceholder as LayoutItem<SectionInsertionLayoutPayload>
    );

    placeholdersSilhouettes.forEach(placeholderSilhouette => {
      const silhouette = this.createItemNode(BENCH_INSERTION, placeholderSilhouette, 0, false);
      containerToPush.add(silhouette);
      ConfigurationScene.setMaterial(silhouette, this.insertElementTransparentMaterial);
    });
  }

  // split placeholder on 2 silhouettes for MVT transport (double bench) and also double it for back-to-back
  private static computePlaceholders(
    hoveredPlaceholder: LayoutItem<SectionInsertionLayoutPayload>
  ): LayoutItem<SectionInsertionLayoutPayload>[] {
    const placeholders: LayoutItem<SectionInsertionLayoutPayload>[] = [];
    if (hoveredPlaceholder.payload.backToBack) {
      if (hoveredPlaceholder.angle === 0 || hoveredPlaceholder.angle === 180) {
        placeholders.push({ ...hoveredPlaceholder, centerY: hoveredPlaceholder.centerY - 0.5 * BENCH_WIDTH });
        placeholders.push({ ...hoveredPlaceholder, centerY: hoveredPlaceholder.centerY + 0.5 * BENCH_WIDTH });
      } else if (hoveredPlaceholder.angle === -90 || hoveredPlaceholder.angle === 90) {
        placeholders.push({ ...hoveredPlaceholder, centerX: hoveredPlaceholder.centerX + 0.5 * BENCH_WIDTH });
        placeholders.push({ ...hoveredPlaceholder, centerX: hoveredPlaceholder.centerX - 0.5 * BENCH_WIDTH });
      }
    } else {
      placeholders.push(hoveredPlaceholder);
    }

    return placeholders.flatMap(placeholder => ConfigurationScene.splitPlaceHolderOnSilhouettes(placeholder));
  }

  // for twice-benches (MVT transport), when MVT consists of x2 views of 'single_bench'
  private static splitPlaceHolderOnSilhouettes(
    hoveredPlaceholder: LayoutItem<SectionInsertionLayoutPayload>
  ): LayoutItem<SectionInsertionLayoutPayload>[] {
    const angle = hoveredPlaceholder.angle;

    if (hoveredPlaceholder.payload.section.size !== 2) {
      return [hoveredPlaceholder];
    }

    if (angle === 0 || angle === 180) {
      const x = hoveredPlaceholder.centerX;
      return [
        { ...hoveredPlaceholder, centerX: x - BENCH_LENGTH * 0.5 },
        { ...hoveredPlaceholder, centerX: x + BENCH_LENGTH * 0.5 }
      ];
    } else if (angle === 90 || angle === -90) {
      const y = hoveredPlaceholder.centerY;
      return [
        { ...hoveredPlaceholder, centerY: y - BENCH_LENGTH * 0.5 },
        { ...hoveredPlaceholder, centerY: y + BENCH_LENGTH * 0.5 }
      ];
    } else {
      return [hoveredPlaceholder];
    }
  }

  private clearSilhouetteOnLeave(): void {
    if (this.hoveredSilhouetteNode) {
      removeNodeChildren(this.hoveredSilhouetteNode);
    }
    this.currentlyHoveredPlaceholder = undefined;
  }

  private static createRectangleForHighlightingPlaceToInsert(
    name: string,
    layoutItem: LayoutItem<InstrumentInsertionLayoutPayload | SectionInsertionLayoutPayload | InstrumentLayoutPayload>,
    yPos: number
  ): Object3D {
    let width: number, height: number, depth: number;

    if (layoutItem.payload.type === 'instrument' || layoutItem.payload.type === 'instrumentInsertion') {
      width = toThreeCoordinate(layoutItem.payload.instrument.length);
      height = toThreeCoordinate(ConfigurationScene.ELEMENT_INSERTION_PLACEHOLDER_RECTANGLE_HEIGHT);
      depth = toThreeCoordinate(layoutItem.payload.instrument.width);
    } else if (layoutItem.payload.type === 'sectionInsertion') {
      depth = toThreeCoordinate(BENCH_WIDTH * (layoutItem.payload.backToBack ? 2 : 1));
      width = toThreeCoordinate(BENCH_LENGTH) * layoutItem.payload.section.size;
      height = toThreeCoordinate(ConfigurationScene.ELEMENT_INSERTION_PLACEHOLDER_RECTANGLE_HEIGHT);
    } else {
      throw new Error('Unsupported type for creation insertion placeholder');
    }

    const geometry = new BoxGeometry(width, height, depth);
    const node = new Mesh(geometry);
    node.position.set(
      toThreeCoordinate(layoutItem.centerX),
      toThreeCoordinate(yPos),
      toThreeCoordinate(layoutItem.centerY)
    );
    node.rotation.y = toRadians(layoutItem.angle);
    node.name = name;
    node.userData = { ...layoutItem, name: name };
    return node;
  }

  private static setMaterial(node: any, material: Material): void {
    node.traverse((mesh: any) => {
      if (mesh instanceof Mesh) {
        mesh.material.dispose();
        mesh.material = material;
      }
    });
  }

  private initScene(offsetHeight: number) {
    this.outlineScene = new Scene();
    this.scene = new Scene();
    this.dimensionsScene = new Scene();
    this.sceneNode = new Object3D();
    this.sceneNode.name = 'Scene';
    this.scene.add(this.sceneNode);
    this.rulerNode = new Object3D();
    this.rulerNode.name = 'Ruler';
    this.sceneNode.add(this.rulerNode);

    this.rootNode = new Group();
    this.rootNode.name = 'Root';

    this.instrumentsHighlightsNode = new Group();
    this.hoveredSilhouetteNode = new Group();
    this.instrumentsHighlightsNode.name = 'instrumentsHighlights';
    this.configurationNode = new Group();
    this.configurationNode.name = 'configuration';

    this.rootNode.position.setY(offsetHeight);
    this.sceneNode.add(this.configurationNode);

    this.configurationNode.add(this.rootNode);
    this.configurationNode.add(this.instrumentsHighlightsNode);
    this.configurationNode.add(this.hoveredSilhouetteNode);

    this.backgroundNode = new Object3D();
    this.backgroundNode.name = 'Background';
    this.sceneNode.add(this.backgroundNode);

    if (DEBUG) {
      const axesHelper = new AxesHelper(1);
      this.scene.add(axesHelper);
    }
  }

  private initLights() {
    const light = new DirectionalLight(LIGHT_COLOR, 0.35);
    light.castShadow = true;
    light.shadow.mapSize.width = 128;
    light.shadow.mapSize.height = 128;
    light.shadow.bias = -0.00195;
    light.position.set(0, 15, 3);
    light.shadow.camera.near = 0.5;
    light.shadow.camera.far = 100;
    //const helper = new CameraHelper(light.shadow.camera)
    //this.scene.add(helper);
    //const light = new DirectionalLight(LIGHT_COLOR);
    //light.position.setZ(10);
    this.scene.add(light);
  }

  private async loadResources(viewModel: ViewModel) {
    const isInteriorRequired = true;
    const views = this.getUsedModels(viewModel);
    const resourcesList = views.map(view => ResourceLoadTransaction.Model(view));

    if (isInteriorRequired && viewModel.interior) {
      resourcesList.unshift(ResourceLoadTransaction.Model('interior/' + viewModel.interior));
    }
    const transaction = new ResourceLoadTransaction(resourcesList);

    const needEvent = views.some(view => !this.models.has(view));
    return this.resourceLoader.load(transaction).then(models => {
      if (isInteriorRequired && viewModel.interior) {
        this.interiorNode = models.shift()!;
      }
      for (let i = 0; i < views.length; i++) {
        const model = models[i];
        this.models.set(views[i], model);
      }
      return needEvent;
    });
  }

  private async loadSingleModel(view: string) {
    const resourcesList = [ResourceLoadTransaction.Model(view)];
    const transaction = new ResourceLoadTransaction(resourcesList);
    const models = await this.resourceLoader.load(transaction);
    this.models.set(view, models[0]);
  }

  private getUsedModels(viewModel: ViewModel): string[] {
    const usedModels = new Set<string>();

    for (const item of viewModel.benches) {
      usedModels.add(item.view);
    }

    for (const item of viewModel.instruments) {
      usedModels.add(item.view);
    }

    return Array.from(usedModels);
  }

  private addItemToRootScene(name: string, item: LayoutItem, height: number, loadingPlaceholder: boolean) {
    let node = this.createItemNode(name, item, height, loadingPlaceholder);
    if (item.view === this.MANIPULATOR_HANDLE) {
      this.manipulatorHandles.push(node);
    }
    this.rootNode.add(node);
  }

  private static scaleModelForCustomInstruments(item: LayoutItem, model: Object3D): void {
    if (item.view !== CUSTOM_INSTRUMENT_VIEW) {
      return;
    }

    if (instrumentLayoutItemTypeGuard(item) || instrumentInsertionLayoutItemTypeGuard(item)) {
      const instrument = item.payload.instrument;
      if (customInstrumentTypeGuard(instrument)) {
        const { length, width, height } = instrument;
        const xRatio = length / CUSTOM_INSTRUMENT_MODEL_SIZE;
        const zRatio = width / CUSTOM_INSTRUMENT_MODEL_SIZE;
        const yRatio = height / CUSTOM_INSTRUMENT_MODEL_SIZE;

        model.scale.set(xRatio, yRatio, zRatio);
      }
    }
  }

  private createItemNode(name: string, item: LayoutItem<any>, height: number, loadingPlaceholder: boolean): Object3D {
    const boxNode = new Object3D();
    boxNode.name = name;
    boxNode.position.set(toThreeCoordinate(item.centerX), toThreeCoordinate(height), toThreeCoordinate(item.centerY));
    boxNode.rotation.y = toRadians(item.angle);
    boxNode.userData = { ...item, name: name };

    const model3d = this.models.get(item.view);

    if (!model3d) {
      console.warn(`Cannot find model for ${item.view}`);
      if (loadingPlaceholder) {
        const width = toThreeCoordinate(item.payload.instrument.length);
        const height = toThreeCoordinate(ConfigurationScene.ELEMENT_INSERTION_PLACEHOLDER_RECTANGLE_HEIGHT);
        const depth = toThreeCoordinate(item.payload.instrument.width);

        const geometry = new BoxGeometry(width, height, depth);
        const node = new Mesh(geometry);
        node.material = new MeshBasicMaterial({ color: '#fff', opacity: 0.3, transparent: true });

        boxNode.add(node);
      }
      return boxNode;
      // throw new Error(`Cannot find model for ${item.view}`);
    }

    const model = model3d.clone();
    if (item.view === CUSTOM_INSTRUMENT_VIEW) {
      ConfigurationScene.scaleModelForCustomInstruments(item, model);
    }

    model.traverse(obj => {
      if (obj instanceof Mesh) {
        obj.castShadow = true;
        obj.receiveShadow = true;
      }
    });

    boxNode.add(model);
    return boxNode;
  }

  private static getTransparentMaterial(color: string, emissive: string): Material {
    return new MeshStandardMaterial({
      color,
      emissive,
      emissiveIntensity: 1,
      roughness: 1,
      metalness: 1,
      side: 2,
      depthWrite: false,
      transparent: true,
      opacity: ConfigurationScene.ELEMENT_SILHOUETTE_OPACITY
    });
  }

  private clearManipulatorHandles() {
    this.manipulatorHandles = [];
  }

  getFirstInstrumentNode(): Object3D | undefined {
    let result: Object3D | undefined = undefined;
    this.rootNode.traverse(node => {
      if (!result && node.name === INSTRUMENT_NAME) {
        result = node;
      }
    });
    return result;
  }

  getBenchPlaceholder(): Object3D | undefined {
    let result: Object3D | undefined = undefined;
    this.instrumentsHighlightsNode.traverse(node => {
      if (node.name === BENCH_INSERTION_PLACEHOLDER) result = node;
    });
    return result;
  }

  getInstrumentPlaceholder(): Object3D | undefined {
    let result: Object3D | undefined = undefined;
    this.instrumentsHighlightsNode.traverse(node => {
      if (node.name === INSTRUMENT_INSERTION_PLACEHOLDER) result = node;
    });
    return result;
  }

  getInstrumentInsertion(): Object3D | undefined {
    return this.hoveredSilhouetteNode;
  }

  getInstrumentInsertionOnMobile(): Object3D | undefined {
    return this.instrumentsHighlightsNode.children[0];
  }

  getBenchInsertion(): Object3D | undefined {
    return this.hoveredSilhouetteNode;
  }

  getBenchInsertionForMobile(): Array<Object3D> | undefined {
    const nodes = this.instrumentsHighlightsNode.children.filter(it => it.userData?.payload?.position.type === 'start');
    if (nodes.length > 0) {
      return nodes;
    }
  }

  getObjectsByGroupId(uuid: string): Array<Object3D> {
    const result: Array<Object3D> = [];
    this.rootNode.traverse(node => {
      if (node.userData.groupId === uuid) {
        result.push(node);
      }
    });
    return result;
  }
}
