import { Object3D } from 'three';
import { Position } from '../../geometry';
import { LayoutItem, InstrumentLayoutPayload } from '../types';

// which data we need to get and remember after previous insertion to be able to count next iteration
export interface MetaDataForInsertion {
  segmentIndex: number;
  freeSpace: number; // free space refers to current segment
  basis: Object3D;
  indentationAfterPrev: number; // along main axis
  previousDepthIndentation: number; // previous item's depth indentation (vertical padding)
}
type MetaDataForInsertionTuple = [number, number, Object3D, number, number];

export const metadataToTuple = (data: MetaDataForInsertion): MetaDataForInsertionTuple => {
  const { segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation } = data;
  return [segmentIndex, freeSpace, basis, indentationAfterPrev, previousDepthIndentation];
};

export interface PutInstrumentReturnValue {
  dataForNextInsertion: MetaDataForInsertion;
  insertedItem: LayoutItem<InstrumentLayoutPayload>;
}

export type InstrumentCoordinatesReturnValue = Position & { depthIndentation: number };

// In InsertedItemMetaData (which is used for further faster inserting of a new tool) we can store whole Object3D,
// but if we have for example 100 instruments, we will have to store 100 heavy Object3D instances
// so let's store necessary data to recreate Object3D (x, z, rotation)
export type InsertedItemMetaData = Omit<MetaDataForInsertion, 'basis'> & {
  basisX: number;
  basisZ: number;
  basisRotation: number;
};

export const putResultToInsertedItemMetadata = (putResult: PutInstrumentReturnValue): InsertedItemMetaData => {
  const { basis: _, ...metaData } = putResult.dataForNextInsertion;
  return {
    ...metaData,
    basisRotation: putResult.dataForNextInsertion.basis.rotation.y,
    basisX: putResult.dataForNextInsertion.basis.position.x,
    basisZ: putResult.dataForNextInsertion.basis.position.z
  };
};
