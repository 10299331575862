import { OnboardingProps, OnboardingRect } from './types';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import Joyride, { ACTIONS, CallBackProps, STATUS } from 'react-joyride';
import { OnboardingTooltip } from './OnboardingTooltip';
import { createPortal } from 'react-dom';

/**
 * Wait until element inserted into DOM
 */
const waitUntilElementInserted = (selector: string, node: Element) => {
  let mutationObserver: MutationObserver;
  const promise: Promise<void> = new Promise(resolve => {
    mutationObserver = new MutationObserver(() => {
      const el = document.querySelector(selector);
      if (el) {
        mutationObserver?.disconnect();
        resolve();
      }
    });
    mutationObserver.observe(node, { childList: true, subtree: true });
  });
  return {
    promise,
    disconnect: () => {
      mutationObserver?.disconnect();
    }
  };
};
export const Onboarding = <TStepCallbackData extends object>({
  stepCallbackData,
  steps,
  onCompleted,
  onSwitchedToStep,
  initialStepIndex,
  onSkipped
}: OnboardingProps<TStepCallbackData>): JSX.Element => {
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [stepTargetRect, setStepTargetRect] = React.useState<OnboardingRect | null>(null);
  const [stepTargetRectClass, setStepTargetRectClass] = React.useState<string | null>(null);
  const [isReady, setIsReady] = React.useState(false);
  const disconnectRef = React.useRef<() => void | null>();
  const [run, setRun] = React.useState(true);
  const rectPortalRef = React.useRef<HTMLDivElement>(null);

  const changeDisconnectHandler = (cb: () => void) => {
    disconnectRef.current?.();
    disconnectRef.current = cb;
  };

  const activateStep = useCallback(
    (stepIndex: number) => {
      setActiveStepIndex(stepIndex);
      onSwitchedToStep?.(stepIndex);
    },
    [setActiveStepIndex, onSwitchedToStep]
  );

  const prepareStep = useCallback(
    async (nextIndex: number) => {
      const nextStep = steps[nextIndex];
      if (nextStep && nextStep.doBeforeStep) {
        const { getCustomRectTarget, delay } = await nextStep.doBeforeStep(stepCallbackData);
        if (getCustomRectTarget) {
          if (typeof nextStep.target !== 'string') {
            throw new Error('Target should be string');
          }
          setStepTargetRect(getCustomRectTarget());
          setStepTargetRectClass(nextStep.target.slice(1)); // remove dot
          const { promise, disconnect } = waitUntilElementInserted(nextStep.target, rectPortalRef.current!);
          changeDisconnectHandler(disconnect);
          await promise;
        }
        if (delay) {
          setRun(false);
          await new Promise(resolve => {
            const timeoutId = setTimeout(resolve, delay);
            changeDisconnectHandler(() => clearTimeout(timeoutId));
          });
          setRun(true);
        }

        if (getCustomRectTarget) {
          const handleWindowResize = () => {
            setStepTargetRect(getCustomRectTarget());
          };
          window.addEventListener('resize', handleWindowResize);
          changeDisconnectHandler(() => window.removeEventListener('resize', handleWindowResize));
        }
      }

      activateStep(nextIndex);
    },
    [steps, activateStep, stepCallbackData]
  );

  // Calls on onboarding start
  // onboarding type change must be handled in the parent component
  useEffect(() => {
    prepareStep(initialStepIndex).then(() => setIsReady(true));

    return () => {
      disconnectRef.current?.();
    };
  }, []);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { status, type, action } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const stepsSwitchActions: string[] = [ACTIONS.NEXT, ACTIONS.PREV];

    if (action === ACTIONS.SKIP) {
      onSkipped();
    }

    if (finishedStatuses.includes(status)) {
      setRun(false);
      onCompleted?.();
    }

    if (stepsSwitchActions.includes(action) && type === 'step:after') {
      const isNext = action === ACTIONS.NEXT;
      disconnectRef.current?.();
      setStepTargetRect(null);
      const nextIndex = isNext ? activeStepIndex + 1 : activeStepIndex - 1;
      steps[activeStepIndex]?.doAfterStep?.(stepCallbackData);
      await prepareStep(nextIndex);
    }
  };

  return (
    <>
      {isReady && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          tooltipComponent={OnboardingTooltip}
          steps={steps}
          stepIndex={activeStepIndex}
          disableCloseOnEsc
          disableOverlayClose
        />
      )}
      {createPortal(
        <div ref={rectPortalRef}>
          {stepTargetRect && stepTargetRectClass && (
            <div
              key={stepTargetRectClass}
              className={stepTargetRectClass}
              style={{ position: 'absolute', ...stepTargetRect }}
            />
          )}
        </div>,
        document.body
      )}
    </>
  );
};
