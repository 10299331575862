import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { ActiveMode, SelectableElement } from '../domain/modes';
import { selectedModeTypeGuard } from '../domain/typeGuards';
import { toUSLocaleString } from '../services/utils';
import { localization } from '../stores/Localization';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isScrollable = (element: HTMLElement): boolean => {
  const hasScrollableContent = element.scrollHeight > element.clientHeight;

  const overflowYStyle = window.getComputedStyle(element).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

  return hasScrollableContent && !isOverflowHidden;
};

export const getScrollableParent = (element: HTMLElement | null): HTMLElement | undefined => {
  if (!element) {
    return undefined;
  }

  return element === document.body
    ? document.body
    : isScrollable(element)
    ? element
    : getScrollableParent(element.parentElement);
};

export const isScrolledIntoView = (element: HTMLElement | null): boolean => {
  const scrollableParent = getScrollableParent(element);
  if (scrollableParent && element) {
    const parentRect = scrollableParent.getBoundingClientRect();
    const childRect = element.getBoundingClientRect();

    return (
      childRect.x >= parentRect.x &&
      childRect.x + childRect.width <= parentRect.x + parentRect.width &&
      childRect.y >= parentRect.y &&
      childRect.y + childRect.height <= parentRect.y + parentRect.height
    );
  }

  return false;
};

export const scrollIntoNestedView = (element: HTMLElement) => {
  const parent = getScrollableParent(element);
  if (!parent) {
    return;
  }

  if (parent === document.body) {
    element.scrollIntoView({ behavior: 'auto', block: 'center' });
    return;
  }

  if (!isScrolledIntoView(element)) {
    parent.scrollTo({ top: element.offsetTop - parent.offsetTop, behavior: 'smooth' });
  }
};

/**
 * Clipboard API does not always work from the iframe context.
 * We're using a deprecated workaround instead.
 * @see https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 * @todo rewrite
 */
export const copyTextToClipboard = async (text: string) => {
  // if (navigator.clipboard) {
  //   await navigator.clipboard.writeText(link);
  // } else {

  /**
   * Clipboard doesn't work when executed after async link getter
   */
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      const textArea = document.createElement('textarea');

      // Place in the top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = '0';

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent';

      textArea.value = text;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          resolve();
        } else {
          reject('Unable to copy link');
        }
      } catch (err) {
        reject('Unable to copy link');
      }

      document.body.removeChild(textArea);
    }, 1);
  });
  // }
};

export function getSelectedElement(mode: ActiveMode): SelectableElement | undefined {
  if (selectedModeTypeGuard(mode)) {
    if (mode.type === 'selectedBench') {
      return mode.selectedBench;
    } else if (mode.type === 'selectedInstrument') {
      return mode.selectedInstrument;
    }
  }
  return undefined;
}

export const getDimensionsString = (length: number, width: number, height: number): string => {
  return `${toUSLocaleString(length)} x ${toUSLocaleString(width)} x ${toUSLocaleString(
    height
  )} ${localization.formatMessage('common.units.mm')}`;
};
