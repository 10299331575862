import { Camera, Intersection, Object3D, Raycaster, Vector2 } from 'three';
import {
  MouseRecord,
  NodeClickEventHandler,
  NodeClickListener,
  NodePointerEventHandler,
  NodePointerListener,
  TouchRecord
} from './types';
import NodeClickEvent from './NodeClickEvent';
import { UserObject3D } from '../viewer/types';
import NodePointerEvent from './NodePointerEvent';

const CLICK_EPS = 0.003;
const TOUCH_CLICK_EPS = 5;
const CLICK_TIME_EPS = 1000;
const HOVER_DELAY = 10;

export class ObjectSelector {
  private readonly mouse: MouseRecord;
  private raycaster!: Raycaster;
  private nodeClickListeners: NodeClickListener<Object3D>[] = [];
  private nodePointerListeners: NodePointerListener<Object3D>[] = [];
  private readonly intersections: Array<Intersection> = [];
  private hovered: Object3D | null = null;
  private touches: TouchRecord[] = [];
  private pendingHover = 0;

  constructor(
    private readonly element: HTMLElement,
    private readonly camera: Camera,
    private readonly sceneNode: Object3D,
    private canHover: boolean = false
  ) {
    this.raycaster = new Raycaster();
    this.mouse = {
      coordinates: new Vector2(),
      time: 0
    };
    this.activate();
  }

  addNodeClickEventHandler<T extends Object3D = UserObject3D>(nodeName: string, handler: NodeClickEventHandler<T>) {
    const relaxedHandler = handler as NodeClickEventHandler;
    this.nodeClickListeners.push({ nodeName, handler: relaxedHandler });
  }

  addNodePointerEventHandler<T extends Object3D = UserObject3D>(nodeName: string, handler: NodePointerEventHandler<T>) {
    const relaxedHandler = handler as NodePointerEventHandler;
    this.nodePointerListeners.push({ nodeName, handler: relaxedHandler });
  }

  deactivate() {
    this.element.removeEventListener('touchstart', this.onTouchStart);
    this.element.removeEventListener('touchend', this.onTouchEnd);
    this.element.removeEventListener('touchcancel', this.onTouchCancel);
    this.element.removeEventListener('mousedown', this.onMouseDown);
    this.element.removeEventListener('mouseup', this.onMouseUp);
    this.element.removeEventListener('pointermove', this.onPointerMove);

    this.element.style.cursor = '';
    this.touches = [];
  }

  activate() {
    this.element.addEventListener('touchstart', this.onTouchStart);
    this.element.addEventListener('touchend', this.onTouchEnd);
    this.element.addEventListener('touchcancel', this.onTouchCancel);
    this.element.addEventListener('mousedown', this.onMouseDown);
    this.element.addEventListener('mouseup', this.onMouseUp);
    this.element.addEventListener('pointermove', this.onPointerMove);
  }

  private onPointerMove = (event: PointerEvent) => {
    if (!this.canHover) {
      return;
    }
    this.updatePointer(event);
    if (this.pendingHover) {
      window.clearTimeout(this.pendingHover);
    }
    this.pendingHover = window.setTimeout(() => {
      this.updatePointer(event);

      if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
        this.raycaster.setFromCamera(this.mouse.coordinates, this.camera);
        this.intersections.length = 0;
        this.raycaster.intersectObjects(this.sceneNode.children, true, this.intersections);

        if (this.intersections.length > 0) {
          const object = this.getHoveredNode(this.intersections);
          if (this.hovered !== object) {
            this.hovered = object;
            this.hoverHandler();
          }
        } else {
          this.hovered = null;
        }
        if (this.hovered) {
          this.element.style.cursor = 'pointer';
        } else {
          this.element.style.cursor = 'auto';
        }
      }
    }, HOVER_DELAY);
  };

  private onTouchStart = (event: TouchEvent) => {
    this.touches = [...event.targetTouches].map(touch => ({
      ...touch,
      time: Date.now()
    }));
  };

  private onTouchEnd = (event: TouchEvent) => {
    if (!this.satisfiesTouchRequirements(event)) {
      return;
    }

    event.preventDefault();
    const changedTouch = event.changedTouches[0];

    const rect = this.element.getBoundingClientRect();
    const offsetX = changedTouch.pageX - rect.left;
    const x = (offsetX / this.element.clientWidth) * 2 - 1;
    const offsetY = changedTouch.pageY - rect.top;
    const y = -(offsetY / this.element.clientHeight) * 2 + 1;
    this.clickHandler({ x, y });
    this.touches = [];
  };

  private onTouchCancel = () => {
    this.touches = [];
  };

  private onMouseDown = (event: MouseEvent) => {
    this.canHover = false;
    const coordinates = this.eventToLocalCoordinates(event);
    this.updateMouseRecord(coordinates);
  };

  private onMouseUp = (event: MouseEvent) => {
    this.canHover = true;
    if (this.satisfiesClickRequirements(event)) {
      this.updateMouseRecord({ x: NaN, y: NaN });

      const coordinates = this.eventToLocalCoordinates(event);
      this.clickHandler(coordinates);
    }
  };

  private getSelectedNode = (intersects: Array<Intersection>, nodeName: string) => {
    let node: Object3D | null = null;
    for (let i = 0; i < intersects.length; i++) {
      let obj: Object3D | null = intersects[i].object;
      while (obj && obj.name !== nodeName) {
        obj = obj.parent;
      }
      if (obj && obj.name === nodeName && obj.visible) {
        node = obj;
        break;
      }
    }
    return node;
  };

  private clickHandler = (mouse: { x: number; y: number }) => {
    /**
     * Making sure we reset our focus state when user touches scene
     */
    const document = this.element.ownerDocument;
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    try {
      const mouseCoordinatesVector = new Vector2(mouse.x, mouse.y);

      this.raycaster.setFromCamera(mouseCoordinatesVector, this.camera);
      const intersects = this.raycaster.intersectObjects(this.sceneNode.children, true);

      const clickEvent = new NodeClickEvent();
      for (let { nodeName, handler } of this.nodeClickListeners) {
        if (nodeName === '') {
          handler(null, clickEvent);
        }
        const node = this.getSelectedNode(intersects, nodeName);
        if (node) {
          handler(node, clickEvent);
          if (clickEvent.stopped) {
            return;
          }
        }
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  private hoverHandler = () => {
    try {
      const event = new NodePointerEvent();
      for (let { nodeName, handler } of this.nodePointerListeners) {
        if (nodeName === '') {
          handler(null, event);
        }
        const node = this.getSelectedNode(this.intersections, nodeName);
        if (node) {
          // console.log('handler', node);
          handler(node, event);
          if (event.stopped) {
            return;
          }
        }
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  private updatePointer(event: PointerEvent) {
    const rect = this.element.getBoundingClientRect();

    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
    this.updateMouseRecord({ x, y });
  }

  private getHoveredNode(nodes: Intersection[]): Object3D | null {
    try {
      for (let { nodeName } of this.nodePointerListeners) {
        if (nodeName === '') {
          return null;
        }
        let node: Object3D | null = null;
        for (let i = 0; i < nodes.length; i++) {
          let obj: Object3D | null = nodes[i].object;
          while (obj && obj.name !== nodeName) {
            obj = obj.parent;
          }
          if (obj && obj.name === nodeName && obj.visible) {
            node = obj;
            break;
          }
        }

        if (node) {
          return node;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  private satisfiesClickRequirements(event: MouseEvent): boolean {
    const coordinates = this.eventToLocalCoordinates(event);

    const satisfiesCoordinates =
      Math.abs(this.mouse.coordinates.x - coordinates.x) < CLICK_EPS &&
      Math.abs(this.mouse.coordinates.y - coordinates.y) < CLICK_EPS;
    const satisfiesTime = Date.now() - this.mouse.time < CLICK_TIME_EPS;

    return satisfiesTime && satisfiesCoordinates;
  }

  private satisfiesTouchRequirements(event: TouchEvent): boolean {
    const initialTouch = this.touches[0];
    const changedTouch = event.changedTouches[0];
    if (!initialTouch || !changedTouch) {
      return false;
    }

    return (
      Math.sqrt((changedTouch.pageX - initialTouch.pageX) ** 2 + (changedTouch.pageY - initialTouch.pageY) ** 2) <
      TOUCH_CLICK_EPS
    );
  }

  private updateMouseRecord({ x, y }: { x: number; y: number }) {
    this.mouse.coordinates.x = x;
    this.mouse.coordinates.y = y;
    this.mouse.time = Date.now();
  }

  private eventToLocalCoordinates(event: MouseEvent): Vector2 {
    const x = (event.offsetX / this.element.clientWidth) * 2 - 1;
    const y = -(event.offsetY / this.element.clientHeight) * 2 + 1;

    return new Vector2(x, y);
  }
}
