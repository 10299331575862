import React from 'react';
import { observer } from 'mobx-react-lite';
import { ICustomInstrument, IInstrument, InstrumentCategory } from '../../../../../schema';
import { ReplacingInstrumentMode } from '../../../../../domain/modes';
import { CustomInstrumentDimensions } from '../../../../../domain/instruments';
import { InstrumentsByCategories } from '../../../../../stores/types';
import { StandardInstrumentReplacementPanel } from './StandardInstrumentReplacementPanel';
import { CustomInstrumentReplacementPanel } from './CustomInstrumentReplacementPanel';

interface InstrumentsReplacementListProps {
  standardInstruments: InstrumentsByCategories;
  customInstruments: ICustomInstrument[];
  mode: ReplacingInstrumentMode;

  onToggle: (instrument: IInstrument, selected: boolean) => void;
  isAvailable: (instrument: IInstrument) => boolean;
  createCustomInstrument: (dimensions: CustomInstrumentDimensions, name: string) => void;
}

export const InstrumentsReplacementPanel: React.FC<InstrumentsReplacementListProps> = observer(props => {
  if (props.mode.instrumentToReplace.category !== InstrumentCategory.custom) {
    return <StandardInstrumentReplacementPanel {...props} />;
  }

  return <CustomInstrumentReplacementPanel {...props} />;
});
