import { ConfigurationViewer, ViewModel } from '../domain/viewer';
import {
  InsertionLayoutPayload,
  InstrumentInsertionLayoutPayload,
  LayoutItem,
  SectionInsertionLayoutPayload
} from '../domain/layout';
import { InsertionApproachType } from '../domain/viewer/types';
import { IConfiguration } from '../schema';
import { CameraView } from '../domain/camera';
import { benchInsertionLayoutItemTypeGuard, instrumentInsertionLayoutItemTypeGuard } from '../domain/typeGuards';
import { AsyncQueue } from './AsyncQueue';

const duration = 300;

export class Orchestrator {
  private _viewer: ConfigurationViewer | undefined;
  private id = 1;
  private q = new AsyncQueue();
  public initializedViewer = false;

  injectViewer(viewer: ConfigurationViewer) {
    this._viewer = viewer;
  }

  public get viewer() {
    if (!this._viewer) {
      throw new Error('Viewer not initialized');
    }
    return this._viewer;
  }

  changeViewModel(viewModel: ViewModel): void {
    this.id++;
    if (this.initializedViewer) {
      console.log('changeViewModel');
      this.q.add(async () => this.viewer.render(viewModel));
    }
  }

  selectedChanged(selectedUUID: string | undefined) {
    console.log('selected', selectedUUID);
    if (selectedUUID) {
      this.viewer.highlightNodesWithSilhouettes(selectedUUID);
    } else {
      this.viewer.resetSilhouetteHighlight();
    }
    this.viewer.redraw(duration);
  }

  changeRuler(showRuler: boolean) {
    if (showRuler) {
      this.viewer.showDimensions();
    } else {
      this.viewer.hideDimensions();
    }
    this.viewer.redraw();
  }

  changeActive(activeUUID: string | undefined) {
    const id = this.id;
    this.q.add(async () => {
      if (id !== this.id) {
        return;
      }
      if (activeUUID) {
        this.viewer.scene.hideManipulatorsHandles();
        this.viewer.highlightNodesWithSilhouettes(activeUUID);
      } else {
        this.viewer.scene.showManipulatorsHandles();
        this.viewer.resetSilhouetteHighlight();
      }
      this.viewer.redraw();
    });
  }

  changeHovered(hoveredUUID: string | undefined) {
    this.q.add(async () => {
      if (hoveredUUID) {
        this.viewer.highlightNodesWithOutlineLight(hoveredUUID);
      } else {
        this.viewer.resetOutlineHighlightLight();
      }
      this.viewer.redraw();
    });
  }

  changeAvailableInstrumentAreas(
    availableInstrumentAreas: LayoutItem<InstrumentInsertionLayoutPayload>[],
    insertionApproach: InsertionApproachType
  ) {
    this.q.add(async () => {
      if (availableInstrumentAreas.length) {
        this.viewer.scene.hideManipulatorsHandles();
      } else {
        this.viewer.scene.showManipulatorsHandles();
      }
      await this.viewer.scene.highlightAvailableInstrumentPositions(availableInstrumentAreas, insertionApproach);
      this.viewer.redraw(duration);
      console.log('instruments');
    });
  }

  transitionCamera(cameraView: CameraView) {
    this.viewer.transitionCamera(cameraView);
  }

  changeAvailableBenchAreas(
    availableBenchAreas: LayoutItem<SectionInsertionLayoutPayload>[],
    cameraView: CameraView,
    insertionApproach: InsertionApproachType
  ) {
    this.q.add(async () => {
      await this.viewer.scene.highlightAvailableBenchPositions(availableBenchAreas, insertionApproach);
      this.viewer.transitionCamera(cameraView);
    });
  }

  hoverPlaceholders(hoveredPlaceholder: LayoutItem<InsertionLayoutPayload> | undefined, model: IConfiguration) {
    const type = benchInsertionLayoutItemTypeGuard(hoveredPlaceholder)
      ? 'bench'
      : instrumentInsertionLayoutItemTypeGuard(hoveredPlaceholder)
      ? 'instrument'
      : undefined;
    this.viewer.scene.drawSilhouettesOnHover(model, hoveredPlaceholder, type);
    this.viewer.redraw();
  }

  async initialRender(viewModel: ViewModel) {
    console.log('initialRender');
    await this.q.add(() => this.viewer.initialRender(viewModel));
    this.initializedViewer = true;
  }
}
