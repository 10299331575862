import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '../../../components/Checkbox';
import { Button, Header, useSwipable } from '../../../components';
import { AnimatePresence, motion } from 'framer-motion';
import { useScrollLock } from '../../../components/hooks/useScrollLock';

interface IConfirmDialogProps {
  open?: boolean;
  onLeave?: (doNotShowAgain: boolean) => void;
  onStay?: () => void;
  onClose?: () => void;
}

export const ConfirmChangesDialog = ({ open, onLeave, onStay, onClose }: IConfirmDialogProps) => {
  useScrollLock();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const handleOnStayClick = useCallback(() => {
    onStay?.();
    onClose?.();
  }, [onStay, onClose]);

  const {
    sheetRef,
    handleRef,
    sheetStyle,
    reset: resetEmailSwipe
  } = useSwipable({
    enabled: open,
    onCollapse: handleOnStayClick,
    swipeDownBoundaryCoefficient: 0.7
  });

  useEffect(() => {
    if (!open) {
      resetEmailSwipe();
    }
  }, [open, resetEmailSwipe]);

  const handleOnLeaveClick = useCallback(() => {
    onLeave?.(doNotShowAgain);
    onClose?.();
  }, [onLeave, doNotShowAgain, onClose]);

  return (
    <AnimatePresence>
      {open && (
        <div className="absolute inset-0 z-50 overflow-hidden">
          <motion.div
            className="invisible absolute inset-0 bg-neutral-50 md:visible"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className="absolute inset-0 flex flex-col"
            initial={{ opacity: 0.5, translateY: '100%' }}
            animate={{ opacity: 1, translateY: '0%' }}
            exit={{ opacity: 0.5, translateY: '100%' }}
            transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
          >
            <div
              ref={sheetRef}
              className={`
                  pointer-events-auto
                  relative
                  bottom-0
                  mt-auto
                  max-h-screen
                  w-full
                  shadow-modalMobile
                  md:m-auto md:w-[25rem] md:overflow-clip md:rounded-lg md:shadow-modal
                `}
              style={sheetStyle}
            >
              <Header className="md:hidden" withMobileGrabber ref={handleRef}></Header>

              <div className="isolate flex flex-col gap-10 bg-neutral-0 p-4 sm:p-6 sm2:p-10">
                <div className="flex flex-col gap-4">
                  <div>
                    <p className="typography-body1">
                      <FormattedMessage id="configurator.confirmChangesDialog.changes" />
                    </p>
                    <p className="typography-body1">
                      <FormattedMessage id="configurator.confirmChangesDialog.confirmation" />
                    </p>
                  </div>
                  <Checkbox
                    className="self-start"
                    checked={doNotShowAgain}
                    onChange={event => setDoNotShowAgain(event.target.checked)}
                    label={<FormattedMessage id="configurator.confirmChangesDialog.doNotShowAgain" />}
                  />
                </div>
                <div key={'idle'} className="flex gap-4">
                  <Button className="flex-1" type="button" variant="outlined" onClick={handleOnStayClick}>
                    <FormattedMessage id="configurator.confirmChangesDialog.stay" />
                  </Button>
                  <Button className="flex-1" type="button" onClick={handleOnLeaveClick}>
                    <FormattedMessage id="configurator.confirmChangesDialog.leave" />
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
