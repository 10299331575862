import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from '../../../../../components';
import { ButtonProps } from '../../../../../components/Button';
import { rootStore } from '../../../../../stores/RootStore';

interface IEmailButton extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const EmailButton = ({ onClick, variant = 'clear' }: IEmailButton) => {
  if (rootStore.isExhibition) {
    return null;
  }

  return (
    <Button className="max-sm2:flex-1 max-sm2:px-3" size="lg" variant={variant} withIcon onClick={onClick}>
      <Button.ButtonIcon className="max-sm2:mr-0">
        <Icon icon="mail_outline" />
      </Button.ButtonIcon>
      <Button.ButtonText className="m-auto h-full leading-normal max-sm2:hidden">
        <span className="align-middle">
          <FormattedMessage id="summary.sentToEmailButton" />
        </span>
      </Button.ButtonText>
    </Button>
  );
};
