import React, { ComponentProps, useCallback, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import isEmail from 'validator/lib/isEmail';
import { Form, FormControl, FormField, FormItem } from '../../../../../components/Form';
import { ChipsInput } from '../../../../../components/ChipsInput/ChipsInput';
import { Button } from '../../../../../components';
import { ReactComponent as Mail } from '../assets/mail-send.svg';
import { AnimatePresence, motion, Variant, Variants } from 'framer-motion';
import { cn } from '../../../../../components/utils';
import { Loader } from '../../../../../components/Loader/Loader';
import { FormattedMessage } from 'react-intl';
import { useScrollLock } from '../../../../../components/hooks/useScrollLock';
import { deepEqual } from '../../../../../domain/share/utils';

const formSchema = z.object({
  data: z.object({
    emails: z.string().email().array(),
    email: z.string().email().or(z.string().length(0))
  })
});

interface IEmailModalProps {
  onSubmit?: (recipients: string[]) => Promise<void>;
  onCancel?: () => void;
  onClose?: () => void;
}

type States = 'idle' | 'loading' | 'success' | 'error';

const containerVariants: Partial<Record<States, any>> = {
  idle: { '--idle-opacity': 1, '--error-opacity': 0, '--stroke-width': 4 },
  loading: { '--idle-opacity': 1, '--error-opacity': 0, transform: 'translateY(3rem)', '--stroke-width': 2 },
  success: { '--idle-opacity': 1, '--error-opacity': 0, transform: 'translateY(3rem)', '--stroke-width': 2 },
  error: { '--idle-opacity': 0, '--error-opacity': 1, transform: 'translateY(3rem)', '--stroke-width': 2 }
};

const imageVariants: Partial<Record<States, Variant>> = {
  idle: { transform: 'scale(1)' },
  loading: { transform: 'scale(2.3) translateY(-0.5rem)' },
  success: { transform: 'scale(2.3) translateY(-0.5rem)' },
  error: { transform: 'scale(2.3) translateY(-0.5rem)' }
};

const resolver = zodResolver(formSchema);

export const EmailModal = ({ onSubmit, onCancel, onClose }: IEmailModalProps) => {
  const [state, setState] = useState<States>('idle');
  useScrollLock();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      data: {
        emails: [],
        email: ''
      }
    }
  });

  const handleOnSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      setState('loading');
      try {
        await onSubmit?.([...values.data.emails, ...(values.data.email ? [values.data.email] : [])]);
        setState('success');
      } catch (e) {
        setState('error');
      }
    },
    [onSubmit]
  );

  const handleOnCancelClick = useCallback(() => {
    onCancel?.();
    onClose?.();
  }, [onCancel, onClose]);

  return (
    <div className="isolate bg-neutral-0">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="flex flex-col gap-10 p-4 sm:p-6 ls:p-10">
          <AnimatePresence mode="popLayout">
            <motion.div
              className={cn('flex flex-col items-center', { 'pointer-events-none min-h-[6.13rem]': state !== 'idle' })}
              animate={state}
              variants={containerVariants as Variants}
            >
              <motion.div className="flex h-14 w-14 items-center justify-center" variants={imageVariants}>
                <Mail />
              </motion.div>
              <div className="relative">
                {state === 'idle' && (
                  <motion.h2
                    key="idle"
                    className="typography-h6 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
                  >
                    <FormattedMessage id="summary.email.heading" values={{ br: <br /> }} />
                  </motion.h2>
                )}
                {state === 'loading' && (
                  <motion.h2
                    key="loading"
                    className="typography-h6 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transform: 'translateY(0)' }}
                    exit={{ opacity: 0, transform: 'translateY(-100%)' }}
                    transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
                  >
                    <FormattedMessage id="summary.email.sending" />
                  </motion.h2>
                )}
                {state === 'success' && (
                  <motion.h2
                    key="success"
                    className="typography-h6 text-center"
                    initial={{ opacity: 0, transform: 'translateY(100%)' }}
                    animate={{ opacity: 1, transform: 'translateY(0)' }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
                  >
                    <FormattedMessage id="common.success" />
                  </motion.h2>
                )}
                {state === 'error' && (
                  <motion.h2
                    key="error"
                    className="typography-h6 text-center"
                    initial={{ opacity: 0, transform: 'translateY(100%)' }}
                    animate={{ opacity: 1, transform: 'translateY(0)' }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
                  >
                    <FormattedMessage id="common.error" />
                  </motion.h2>
                )}
              </div>
            </motion.div>
            {state === 'idle' && (
              <fieldset key="idle" className="flex flex-col">
                <FormField
                  control={form.control}
                  name="data"
                  render={({ field }) => (
                    <FormItem className="group">
                      <FormControl>
                        <ChipsInput
                          type="email"
                          placeholder="receive@mail.com"
                          values={field.value.emails}
                          onChange={emails => {
                            field.onChange({ ...field.value, emails });
                          }}
                          inputValue={field.value.email}
                          onInputValueChange={(email, emails) => {
                            field.onChange({ ...field.value, email, ...(emails ? { emails } : {}) });
                          }}
                          validator={isEmail}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </fieldset>
            )}
            {state !== 'idle' && (
              <div key="status" className="flex min-h-[3.5rem]">
                {state === 'loading' && <Loader />}
                {state === 'success' && (
                  <p className="typography-paragraph1 w-full text-center">
                    <FormattedMessage id="summary.email.sent" />
                  </p>
                )}
                {state === 'error' && (
                  <p className="typography-paragraph1 w-full text-center">
                    <FormattedMessage id="summary.email.error" />
                    <br />
                    <FormattedMessage id="summary.email.retry" />
                  </p>
                )}
              </div>
            )}
          </AnimatePresence>
          <AnimatePresence mode="popLayout">
            {['idle', 'loading'].includes(state) && (
              <motion.div
                key="idle"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex gap-4"
              >
                <Button
                  className="flex-1"
                  type="button"
                  variant="outlined"
                  onClick={handleOnCancelClick}
                  disabled={state === 'loading'}
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                  className="flex-1"
                  type="submit"
                  disabled={
                    !form.formState.isValid ||
                    deepEqual(form.getValues(), form.formState.defaultValues) ||
                    state === 'loading'
                  }
                >
                  <FormattedMessage id="summary.email.send" />
                </Button>
              </motion.div>
            )}
            {state === 'success' && (
              <motion.div
                key="success"
                className="flex justify-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              >
                <Button type="button" onClick={() => onClose?.()}>
                  <FormattedMessage id="common.close" />
                </Button>
              </motion.div>
            )}
            {state === 'error' && (
              <motion.div
                key="error"
                className="flex gap-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              >
                <Button className="flex-1" type="button" variant="outlined" onClick={() => setState('idle')}>
                  <FormattedMessage id="common.back" />
                </Button>
                <Button className="flex-1" type="submit">
                  <FormattedMessage id="common.tryAgain" />
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </form>
      </Form>
    </div>
  );
};
