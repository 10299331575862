import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Header, Icon, IconButton } from '../../../../../components';

interface ISummaryHeaderProps {
  onClose: () => void;
}

const SummaryHeader = React.forwardRef<HTMLElement, ISummaryHeaderProps>(({ onClose }, ref) => {
  return (
    <Header
      ref={ref}
      variant="default"
      withMobileGrabber
      rightSlot={
        <IconButton onClick={onClose} variant="clear">
          <Icon icon="close" />
        </IconButton>
      }
    >
      <FormattedMessage id="summary.header" />
    </Header>
  );
});

SummaryHeader.displayName = 'Header';

export { SummaryHeader };
