import { Camera, PerspectiveCamera, Vector3 } from 'three';
import { CameraState, IPositionService } from './types';

export class PositionService implements IPositionService {
  private position!: Vector3;
  private dir!: Vector3;
  public camera!: Camera;
  private lookAt!: Vector3;

  init(camera: Camera, [x, y, z, lookX, lookY, lookZ]: CameraState): void {
    this.camera = camera;
    this.lookAt = new Vector3(lookX, lookY, lookZ);
    this.position = new Vector3(x, y, z);
    this.dir = this.lookAt.clone().sub(this.position).negate().normalize();
  }

  stateFromDistance(distance: number): CameraState {
    const dir = this.dir.clone().multiplyScalar(distance);
    const position = this.lookAt.clone().add(dir);
    return position.toArray().concat(this.lookAt.toArray());
  }

  getCameraAtDistance(distance: number): Camera {
    const state = this.stateFromDistance(distance);
    return this.cameraFromState(state);
  }

  cameraFromState([x, y, z, lookX, lookY, lookZ]: CameraState): Camera {
    const t = this.camera as PerspectiveCamera;
    const camera = new PerspectiveCamera(t.fov, t.aspect, t.near, t.far);
    camera.position.copy(new Vector3(x, y, z));
    camera.lookAt(new Vector3(lookX, lookY, lookZ));
    camera.updateMatrixWorld();
    return camera;
  }
}
