import { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { IndexPage } from './pages/IndexPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { PredefinedSolutionPage, ShapeSelectionPage } from './pages/PreConfiguratorTemplate';
import { ConfiguratorPage } from './pages/ConfiguratorPage';
import { routes } from './routes';
import { rootStore } from './stores/RootStore';
import { observer } from 'mobx-react-lite';
import { OpenConfiguratorHubspotEvent, OpenSavedLinkHubspotEvent } from './services/HubspotService';
import { UserFormPage } from './pages/IndexPage/IndexPage';

const App = observer(({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialLocation, setInitialLocation] = useState(location.pathname + location.search);

  useEffect(() => {
    rootStore.init().then(() => {
      setIsLoaded(true);
      if (rootStore.isEmbedded()) {
        const currentLocation = rootStore.location.hash.slice(2);
        setInitialLocation(currentLocation);
        if (rootStore.isLoginFormEnabled) {
          navigate(routes.homeLoginForm.path, { replace: true });
        } else {
          console.warn('Login form is disabled', initialLocation);
          navigate(currentLocation, { replace: true });
        }
      }
    });
  }, []);

  const redirectAfterUserFormSubmit = () => {
    navigate(initialLocation, { replace: true });
    if (rootStore.getLink()) {
      rootStore.hubspotTrackEvent(new OpenSavedLinkHubspotEvent(rootStore.location.href));
    } else {
      rootStore.hubspotTrackEvent(new OpenConfiguratorHubspotEvent());
    }
  };

  return (
    <div className="App h-full">
      {isLoaded && (
        <AnimatePresence mode="wait" initial={false}>
          <Routes key={location.pathname} location={location}>
            <Route path={routes.home.path} element={<IndexPage />} />
            <Route
              path={routes.homeLoginForm.path}
              element={<UserFormPage onSubmitted={redirectAfterUserFormSubmit} />}
            />
            <Route path={routes.predefined.path} element={<PredefinedSolutionPage />} />
            <Route path={routes.own.path} element={<ShapeSelectionPage />} />
            <Route path={routes.configure.path} element={<ConfiguratorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AnimatePresence>
      )}
    </div>
  );
});

export default App;
