import * as React from 'react';
import { Button } from '../Button';
import { TooltipRenderProps } from 'react-joyride';
import { cn } from '../utils';
import { FormattedMessage } from 'react-intl';

export const OnboardingTooltipProgress = ({ currentStep, steps }: { currentStep: number; steps: number }) => {
  return (
    <div className="flex justify-start">
      {Array.from({ length: steps }).map((_, i) => {
        const isCurrent = i === currentStep;
        return (
          <div
            key={i}
            className={cn(`mx-1 h-2 w-2 rounded-full ${isCurrent ? 'bg-primary-200' : 'bg-primary-50'}`)}
          ></div>
        );
      })}
    </div>
  );
};
export function OnboardingTooltip(props: TooltipRenderProps) {
  const { backProps, closeProps, continuous, index, isLastStep, primaryProps, step, tooltipProps, size, skipProps } =
    props;
  const { content, hideBackButton, hideCloseButton, hideFooter, locale, showProgress } = step;
  const { back, close, next } = locale;
  const output: Record<string, React.ReactNode> = {
    primary: close
  };

  if (continuous) {
    output.primary = isLastStep ? 'ok' : next;

    if (showProgress) {
      output.primary = <span>{output.primary}</span>;
    }
  }

  if (!hideBackButton && index > 0) {
    output.back = (
      <Button variant="clear" data-test-id="button-back" type="button" {...backProps}>
        {back}
      </Button>
    );
  }

  output.close = !hideCloseButton && <Button data-test-id="button-close" {...closeProps} />;

  return (
    <div key="JoyrideTooltip" className={cn('w-[300px] bg-neutral-0 p-6')} {...tooltipProps}>
      <div className="mb-10">
        {size >= 2 && (
          <div className="mb-4 flex items-center justify-between">
            <OnboardingTooltipProgress currentStep={index} steps={size} />
            <Button variant="link" {...skipProps}>
              <FormattedMessage id="configurator.onboarding.skip" />
            </Button>
          </div>
        )}
        <p className="typography-paragraph1">{content}</p>
      </div>
      {!hideFooter && (
        <div className={cn('flex', output.back ? 'justify-between' : 'justify-center')}>
          {output.back}
          <Button data-test-id="button-primary" type="button" {...primaryProps}>
            {output.primary}
          </Button>
        </div>
      )}
      {output.close}
    </div>
  );
}
