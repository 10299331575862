import { ConfigurationMode } from '../domain/modes';

export const routes = {
  home: {
    path: '/',
    link: () => '/'
  },
  homeLoginForm: {
    path: '/form',
    link: () => '/form'
  },
  own: {
    path: '/own',
    link: () => '/own'
  },
  predefined: {
    path: '/predefined',
    link: () => '/predefined'
  },
  configure: {
    path: '/configuration/:mode/:id',
    link: (mode: ConfigurationMode, id: string) => `/configuration/${mode}/${id}`
  },
  sandbox: {
    path: '/sandbox',
    link: () => '/sandbox'
  }
};
