import { CameraState, ScreenBox2D } from './types';
import { Box3, Camera, Object3D, Vector3 } from 'three';
import { BoundingBoxService } from './BoundingBoxService';
import { Size } from '../viewer/types';

export class HorizontalCameraAligner {
  constructor(private size: Size, private camera: Camera, private cameraState: CameraState, private root: Object3D) {}
  alignCenter(): void {
    try {
      const boundingBoxService = new BoundingBoxService();
      const bbox = boundingBoxService.getBoundingBox(this.root);

      const eps = 2;
      let iterations = 0;
      const maxIterations = 1000;
      let step = 5;
      let left = -step;
      let right = +step;
      let mError: any;
      do {
        let middle = 0.5 * (left + right);
        mError = this.calculateError(middle, boundingBoxService, bbox);
        if (mError.value < eps) {
          return;
        }
        if (mError.leftPadding < 0) {
          right = middle;
          continue;
        }
        if (mError.rightPadding < 0) {
          left = middle;
          continue;
        }
        if (mError.leftPadding < mError.rightPadding) {
          right = middle;
        } else {
          left = middle;
        }
      } while (iterations++ < maxIterations);
    } catch (e) {
      this.applyCameraState(this.cameraState);
    }
  }

  private calculateError(shift: number, boundingBoxService: BoundingBoxService, bbox: Box3) {
    const cameraState = this.getShiftedCameraState(shift);
    this.applyCameraState(cameraState);

    const projection = boundingBoxService.getBoundingBoxProjection(bbox, this.camera, this.size);
    const leftPadding = projection.minX;
    const rightPadding = this.size.width - projection.maxX;
    return { value: Math.abs(leftPadding - rightPadding), leftPadding, rightPadding };
  }

  private applyCameraState(cameraState: number[]) {
    this.camera.position.set(cameraState[0], cameraState[1], cameraState[2]);
    this.camera.lookAt(new Vector3(cameraState[3], cameraState[4], cameraState[5]));
  }

  private getShiftedCameraState(shift: number) {
    return [
      this.cameraState[0] + shift,
      this.cameraState[1],
      this.cameraState[2],
      this.cameraState[3] + shift,
      this.cameraState[4],
      this.cameraState[5]
    ];
  }
}
