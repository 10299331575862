import { ConfigurationMode } from 'domain/modes';
import { IMenuItem, PageTemplate } from 'pages/PreConfiguratorTemplate/PageTemplate';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { FormattedMessage } from 'react-intl';
import { rootStore } from 'stores/RootStore';
import { ExitIntentPageName, OwnConfigEventGA } from 'services/GoogleAnalyticsService';
import { ExitIntentProvider, useExitIntent } from '../../components/hooks/useExitIntent';
import { useEffect } from 'react';

export const PREDEFINED_CONFIGURATIONS: IMenuItem[] = [
  {
    value: 'cell-culture',
    text: 'shapePage.predefined.cell-culture',
    link: routes.configure.link(ConfigurationMode.Predefined, 'cell-culture'),
    preview: './assets/images/templates/cell-culture.png',
    description: ''
  },
  {
    value: 'ngs',
    text: 'shapePage.predefined.ngs',
    preview: './assets/images/templates/ngs.png',
    link: routes.configure.link(ConfigurationMode.Predefined, 'ngs'),
    description: ''
  },
  {
    value: 'elisa',
    text: 'shapePage.predefined.elisa',
    preview: './assets/images/templates/elisa.png',
    link: routes.configure.link(ConfigurationMode.Predefined, 'elisa'),
    description: 'Lorem ipsum'
  }
];
export const PredefinedSolutionPage = () => {
  const menuItems = PREDEFINED_CONFIGURATIONS;

  useEffect(() => {
    rootStore.gaPageView('Pre-defined layout selection');
  }, []);

  useExitIntent();

  return (
    <ExitIntentProvider page={ExitIntentPageName.PreDefinedLayoutSelection} rootStore={rootStore}>
      <PageTemplate
        menuItems={menuItems}
        configurationMode={ConfigurationMode.Predefined}
        title={<FormattedMessage id="predefinedPage.title" />}
        description={
          <>
            <p className="mb-8 mt-6 w-full md:mt-0">
              <FormattedMessage id="predefinedPage.description" />
            </p>
          </>
        }
        footerContent={
          <>
            <FormattedMessage id="shapePage.flexible" />
            <br />
            <FormattedMessage
              id="shapePage.switchTo"
              values={{
                comp: (
                  <Link
                    className="text-neutral-900 underline"
                    to={routes.own.link()}
                    onClick={() => rootStore.gaEvent(new OwnConfigEventGA(), 'Pre-defined layout selection')}
                  >
                    <FormattedMessage id="shapePage.buildYourOwnOption" />
                  </Link>
                )
              }}
            />
          </>
        }
      />
    </ExitIntentProvider>
  );
};
