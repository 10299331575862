import { useState } from 'react';
import { PropsWithChildren } from 'react';
import Notification from './Notification';

export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Error = 'error'
}

interface IProps {}

const NotificationProvider = ({ children }: PropsWithChildren<IProps>) => {
  const [type, setNotification] = useState<NotificationType>();
  const [text, setNotificationText] = useState<string>('');
  const timeToClear = 3000;
  const clear = () => {
    setNotificationText('');
    setNotification(undefined);
  };
  const success = (text: string) => {
    window.scroll(0, 0);
    setNotificationText(text);
    setNotification(NotificationType.Success);
    setTimeout(() => {
      clear();
    }, timeToClear);
  };
  const error = (text: string) => {
    window.scroll(0, 0);
    setNotificationText(text);
    setNotification(NotificationType.Error);
    setTimeout(() => {
      clear();
    }, timeToClear);
  };
  const info = (text: string) => {
    window.scroll(0, 0);
    setNotificationText(text);
    setNotification(NotificationType.Info);
    setTimeout(() => {
      clear();
    }, timeToClear);
  };
  return (
    <Notification.Provider
      value={{
        success,
        error,
        info,
        clear,
        type,
        text
      }}
    >
      {children}
    </Notification.Provider>
  );
};

export { NotificationProvider };
