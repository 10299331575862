import { ReactNode, useEffect } from 'react';
import { Header, Icon, IconButton, useSwipable, useIsMobileScreenSize } from '../../../components';
import { useScrollLock } from '../../../components/hooks/useScrollLock';
import { isIosDevice, isMozillaMobile } from '../utils';

export const ConfigurationMenu = ({
  children,
  isOpen,
  onClose,
  onClosed,
  title
}: {
  children: ReactNode;
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onClosed?: () => void;
}) => {
  const isMobile = useIsMobileScreenSize();
  useScrollLock(isOpen && isMobile);

  const { sheetRef, handleRef, sheetStyle, reset, isCollapsed } = useSwipable({
    onCollapse: onClose,
    swipeDownBoundaryCoefficient: 0.7
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <div
      ref={sheetRef}
      style={isOpen && !isCollapsed ? sheetStyle : isMobile ? { transform: 'translate(0, 150%)' } : { width: 0 }}
      className={`
        menu-shadow
        pointer-events-auto
        relative
        bottom-0
        z-20
        mt-7
        flex
        max-h-screen
        w-full
        translate-x-0
        translate-y-0
        flex-col
        overflow-hidden
        transition-all
        max-ls:fixed
        ls:z-10
        ls:mt-0
        ls:w-4/12
        ls:flex-shrink-0
        ${isOpen && !isCollapsed ? 'ls:flex-grow' : ''}
        lg:w-3/12
        xl:w-3/12
      `}
    >
      <div className="flex flex-col bg-neutral-50 ls:h-screen">
        <Header
          ref={isMobile ? handleRef : null}
          variant="dark"
          withMobileGrabber
          rightSlot={
            <IconButton onClick={onClose} variant="clear">
              <Icon icon="close" />
            </IconButton>
          }
        >
          {title}
        </Header>

        <div
          style={{ maxHeight: !isMobile ? '100%' : '75vh' }}
          className={`w-full flex-1 overflow-y-scroll py-4 pl-4 ${isIosDevice || isMozillaMobile ? 'pr-4' : 'pr-0'}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
