import { MutatorContext, TypeReference, ValidationResult } from '@canvas-logic/engine';
import { generateUUID } from 'three/src/math/MathUtils';
import { IConfiguration, ISection } from '../../schema';
import { notFoundInMutator } from '../errors';
import { traverseLines, BenchInsertPosition } from '../layout';
import { BaseBenchMutator } from './BaseBenchMutator';

export class AddBenchMutator extends BaseBenchMutator {
  constructor(private readonly bench: ISection, private readonly position: BenchInsertPosition) {
    super();
    this.bench = { ...bench, uuid: generateUUID() };
  }

  override mutateBase(
    context: MutatorContext,
    typeRef: TypeReference,
    model: IConfiguration
  ): [IConfiguration, ValidationResult] {
    let found = false;

    traverseLines(model, node => {
      if (!found && node.line.uuid === this.position.line_uuid) {
        found = true;
        if (this.position.type === 'start') {
          node.allGeometry.unshift(this.bench);
        } else if (this.position.type === 'end') {
          node.allGeometry.push(this.bench);
        }
      }
    });

    if (!found) {
      return [model, ValidationResult.Error(notFoundInMutator())];
    }

    return [model, ValidationResult.Valid()];
  }
}
