import { createContext, useContext } from 'react';
import { ConfigurationOnboardingStore } from './ConfigurationOnboardingStore';

type ConfigurationOnboardingContextType = {
  store: ConfigurationOnboardingStore;
};
export const ConfigurationOnboardingContext = createContext<ConfigurationOnboardingContextType | null>(null);

export const useConfigurationOnboardingStore = (): ConfigurationOnboardingStore => {
  const context = useContext(ConfigurationOnboardingContext);
  if (!context) {
    throw new Error('useConfigurationOnboardingStore must be used within a ConfigurationOnboardingContext');
  }
  return context.store;
};
