import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import { rootStore } from '../../stores/RootStore';
import { FormattedMessage } from 'react-intl';
import { RegistrationFormSubmissionEventGA } from 'services/GoogleAnalyticsService';

const HubspotContactForm = ({ onSubmitted }: { onSubmitted: () => void }) => {
  const formContainer = React.createRef<HTMLDivElement>();
  const navigate = useNavigate();
  useEffect(() => {
    const formId = process.env.REACT_APP_HUBSPOT_LOGIN_FORM_ID;
    const hubspotPortalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;

    if (!formId || !hubspotPortalId) {
      console.warn('Hubspot form ID or portal ID is not defined');
      navigate(routes.home.path);
      return;
    }

    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if ('hbspt' in window) {
        // @ts-ignore
        window.hbspt.forms.create({
          target: '#hubspotForm',
          region: 'na1',
          portalId: hubspotPortalId,
          formId: formId,
          onBeforeFormSubmit: (form: any, values: Array<{ name: string; value: string }>) => {
            rootStore.gaEvent(new RegistrationFormSubmissionEventGA(), "Configurator's home page");
            rootStore.hubspotService.userHasSubmittedForm({
              email: values.find(arg => arg.name === 'email')?.value || ''
            });
          },
          onFormSubmitted: (args: any) => {
            onSubmitted();
          }
        });
      }
    });
  }, []);

  return (
    <div className="mt-10 min-h-[600px] pb-6">
      <h2 className="typography-h5 mb-10">
        <FormattedMessage id="registration.hello" />
      </h2>
      <div id="hubspotForm" ref={formContainer}></div>
    </div>
  );
};

export default HubspotContactForm;
