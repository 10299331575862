import {
  DatasetService,
  Engine,
  ModelMutator,
  Option,
  PModel,
  ProductWithContext,
  Schema,
  ValidationResult
} from '@canvas-logic/engine';
import { computed, makeAutoObservable } from 'mobx';
import gzip from 'gzip-js';
import { generateUUID } from 'three/src/math/MathUtils';

import {
  GeometryElement,
  GeometryElementList,
  IConfiguration,
  ICustomInstrument,
  IInstrument,
  InstrumentCategory,
  ISection,
  SectionAlignment
} from '../schema';
import { CompositeShapeService, ShapeService } from '../domain/shapes';
import { ViewModel } from '../domain/viewer';
import { AddInstrumentMutator, DeleteInstrumentMutator, ReplaceInstrumentMutator } from '../domain/instruments';
import {
  BenchInsertPosition,
  ElementPositionById,
  InsertionLayoutPayload,
  InstrumentInsertPosition,
  LayoutItem,
  SectionInsertionLayoutPayload,
  traverseLines
} from '../domain/layout';
import { AutomataSerializer, IAutomataState, sortByTextField } from '../domain/share';
import { ActiveMode, SelectableElement } from '../domain/modes';

import { InstrumentsByCategories } from './types';
import { localization } from './Localization';
import {
  AddInstrumentEventGA,
  AvailableAreasService,
  CustomInstrumentsService,
  ReplaceInstrumentEventGA
} from '../services';
import { NoPossibilityToPutInstrumentError } from '../domain/errors';
import {
  AddBenchMutator,
  BenchMutationsValidator,
  DeleteBenchMutator,
  findBenchLocationByUUID
} from '../domain/benches';
import {
  benchInsertionLayoutItemTypeGuard,
  insertingModeTypeGuard,
  instrumentInsertionLayoutItemTypeGuard,
  lShapeTypeGuard,
  selectedModeTypeGuard,
  uShapeConfigurationTypeGuard,
  uShapeTypeGuard
} from '../domain/typeGuards';
import { getSelectedElement } from '../components/utils';

import { CameraPositionService, CameraView, defaultCameraView } from '../domain/camera';
import { rootStore } from './RootStore';

const emptyList: any[] = [];

export class ConfiguratorStore {
  public readonly uuid = generateUUID();
  public model!: IConfiguration;
  public viewModel!: ViewModel;
  public loaded = false;
  public activeMode: ActiveMode = { type: 'overview', hoveredElement: undefined };
  public showRuler = false;
  public hasChanges = false;
  public cameraState: CameraView = defaultCameraView;
  public readonly customInstrumentsService: CustomInstrumentsService;

  private availableAreasService!: AvailableAreasService;
  private engine!: Engine;
  private schema!: Schema;
  private datasetService!: DatasetService;
  private productWithContext!: ProductWithContext;
  private shapeService!: ShapeService;
  public automataSerializer!: AutomataSerializer;
  private closeMenuHandler: () => void = () => {};

  get hoveredUUID() {
    return !insertingModeTypeGuard(this.activeMode) ? this.activeMode.hoveredElement?.uuid : undefined;
  }

  get selectedUUID() {
    return getSelectedElement(this.activeMode)?.uuid;
  }
  get hoveredPlaceholders() {
    return insertingModeTypeGuard(this.activeMode) ? this.activeMode.hoveredPlaceholder : undefined;
  }
  get replacingInstrument() {
    return this.activeMode.type === 'replacingInstrument' ? this.activeMode.instrumentToReplace.uuid : undefined;
  }

  get highlightedInstrumentsPositions() {
    return this.activeMode.type === 'insertingInstrument' ? this.activeMode.highlightedInstrumentsPositions : emptyList;
  }
  get highlightedBenchPositions() {
    return this.activeMode.type === 'insertingBench' ? this.activeMode.highlightedBenchPositions : emptyList;
  }

  get cameraStateProp() {
    return this.cameraState;
  }
  get viewModelProp() {
    return this.viewModel;
  }

  constructor() {
    this.customInstrumentsService = new CustomInstrumentsService();

    makeAutoObservable(this, {
      cameraStateProp: computed.struct,
      replacingInstrument: computed.struct,
      highlightedInstrumentsPositions: computed.struct,
      highlightedBenchPositions: computed.struct,
      hoveredPlaceholders: computed.struct,
      viewModelProp: computed.struct,
      selectedUUID: computed.struct,
      hoveredUUID: computed.struct
    });
  }

  async fetch(datasetService: DatasetService, id: string, link?: string): Promise<void> {
    this.customInstrumentsService.init();

    this.datasetService = datasetService;
    this.schema = this.datasetService.getProductSchema('', {}, '') as Schema;
    this.engine = new Engine();
    this.productWithContext = this.datasetService.getProductById(id);
    this.loaded = true;

    this.automataSerializer = new AutomataSerializer(
      this.engine,
      this.getAvailableGeometries(),
      this.getAvailableStandardInstruments(),
      this.customInstrumentsService
    );

    if (link) {
      await this.loadFromLink(link);
    } else {
      this.model = this.engine.initByProductWithContext(this.schema, this.productWithContext) as PModel<IConfiguration>;
      this.initModel();
      this.update();
    }
  }

  getAvailableStandardInstruments(): IInstrument[] {
    if (!this.loaded) {
      return [];
    }
    return (this.datasetService.getOptions('Instrument') as Option<IInstrument>[]).map(option => option.model);
  }

  getAvailableCustomInstruments(): ICustomInstrument[] {
    if (!this.customInstrumentsService.loaded) {
      return [];
    }
    return this.customInstrumentsService.customInstruments;
  }

  getAvailableInstruments(): IInstrument[] {
    return [...this.getAvailableStandardInstruments(), ...this.getAvailableCustomInstruments()];
  }

  getAvailableBenches(): ISection[] {
    const allBenches = this.getAllBenches();
    // TODO: alignment !!! Now const
    // TODO: ATTENTION: change this filter later ?
    return allBenches.filter(bench => bench.alignment === SectionAlignment.R && bench.name !== 'bench.singleTransport');
  }

  getAvailableGeometries(): GeometryElementList {
    if (!this.loaded) {
      return [];
    }
    return ['Section', 'Connection'].flatMap(type => {
      return (this.datasetService.getOptions(type) as Option<GeometryElement>[]).map(option => option.model);
    });
  }

  getAvailableStandardInstrumentsByCategory(): InstrumentsByCategories {
    const result: InstrumentsByCategories = [];
    const instruments = this.getAvailableStandardInstruments();
    for (const instrument of instruments) {
      const title = localization.formatMessage(`instruments.category.${instrument.category}`);
      const group = result.find(it => it.title === title);
      if (!group) {
        result.push({
          title,
          instruments: [instrument]
        });
      } else {
        group.instruments.push(instrument);
      }
    }
    sortByTextField(result, 'title');
    for (const group of result) {
      group.instruments = sortByTextField(group.instruments, 'name');
    }

    return result;
  }

  addInstrument(position: InstrumentInsertPosition): void {
    if (this.activeMode.type === 'insertingInstrument') {
      const mutator = new AddInstrumentMutator(this.activeMode.instrumentToAdd, position);
      this.applyMutator(mutator);
      ++this.activeMode.installed;
      rootStore.gaEvent(
        new AddInstrumentEventGA({
          instrument: localization.formatMessage(this.activeMode.instrumentToAdd.name)
        }),
        'Configurator'
      );
    }
  }

  addBench(position: BenchInsertPosition): void {
    if (this.activeMode.type === 'insertingBench') {
      //const shape = this.model.shapes[0].lim
      const mutator = new AddBenchMutator(this.activeMode.benchToAdd, position);
      this.applyMutator(mutator);
      ++this.activeMode.installed;
      //this.enterOverviewMode();
      //this.closeMenu();
    }
  }

  deleteInstrument(position: ElementPositionById): void {
    const mutator = new DeleteInstrumentMutator(position);
    this.applyMutator(mutator);
    this.activeMode = { type: 'overview', hoveredElement: undefined };
  }

  replaceInstrument(instrument: IInstrument): void {
    if (this.activeMode.type === 'replacingInstrument') {
      const mutator = new ReplaceInstrumentMutator(instrument, this.activeMode.position);
      this.applyMutator(mutator);
      const fromInstrument = localization.formatMessage(this.activeMode.instrumentToReplace.name);
      const toInstrument = localization.formatMessage(instrument.name);
      rootStore.gaEvent(
        new ReplaceInstrumentEventGA({
          instrument: `from ${fromInstrument} to ${toInstrument}`,
          from_instrument: fromInstrument,
          to_instrument: toInstrument
        }),
        'Configurator'
      );
      this.enterOverviewMode(true);
    }
  }

  deleteBench(position: ElementPositionById): void {
    const mutator = new DeleteBenchMutator(position);
    this.applyMutator(mutator);
    this.activeMode = { type: 'overview', hoveredElement: undefined };
  }

  enterInstrumentInstallation(instrument: IInstrument | null): void {
    if (instrument) {
      const highlightedInstrumentsPositions =
        this.availableAreasService.getAvailablePositionsForInstruments(instrument);
      this.activeMode = {
        type: 'insertingInstrument',
        instrumentToAdd: instrument,
        installed: 0,
        highlightedInstrumentsPositions
      };
    } else {
      this.activeMode = {
        type: 'overview',
        hoveredElement: undefined
      };
    }
  }

  enterBenchInstallation(bench: ISection | null): void {
    if (bench) {
      const positions = this.availableBenchInsertPositions(bench);
      this.activeMode = {
        type: 'insertingBench',
        benchToAdd: bench,
        installed: 0,
        highlightedBenchPositions: positions // filter with canApply mutators
      };
    }
  }

  enterSelectedInstrument(instrument: IInstrument): void {
    if (this.activeMode.type === 'replacingInstrument') {
      this.setUpReplacementMode(instrument);
      return;
    }

    if (instrument) {
      this.activeMode = {
        type: 'selectedInstrument',
        selectedInstrument: instrument
      };
    }
  }

  enterSelectedBench(bench: ISection): void {
    if (this.activeMode.type === 'replacingInstrument' || this.activeMode.type === 'insertingInstrument') {
      return;
    }
    if (bench) {
      this.activeMode = {
        type: 'selectedBench',
        selectedBench: bench
      };
    }
  }

  canInstrumentBeAppended(instrument: IInstrument): boolean {
    return this.availableAreasService.canInstrumentBeAppendedAnywhere(instrument);
  }

  enterHoveredElement(element: SelectableElement | null): void {
    if (this.activeMode.type === 'overview' || selectedModeTypeGuard(this.activeMode)) {
      if (element) {
        const isOverviewMode = this.activeMode.type === 'overview';
        const selectedElement = getSelectedElement(this.activeMode);

        const notSelectedElementIsHovered = selectedElement?.uuid !== element?.uuid;
        const selectedElementIsHovered = selectedElement?.uuid === element?.uuid;

        if (isOverviewMode || notSelectedElementIsHovered) {
          this.activeMode.hoveredElement = element;
        } else if (selectedElementIsHovered) {
          this.activeMode.hoveredElement = undefined;
        }
      } else {
        this.activeMode.hoveredElement = undefined;
      }
    }
  }

  enterReplacingMode() {
    if (this.activeMode.type === 'selectedInstrument') {
      this.setUpReplacementMode(this.activeMode.selectedInstrument);
    }
  }

  highlightInsertionHover(layoutItem: LayoutItem<InsertionLayoutPayload> | undefined): void {
    if (
      this.activeMode.type === 'insertingInstrument' &&
      (instrumentInsertionLayoutItemTypeGuard(layoutItem) || layoutItem === undefined)
    ) {
      this.activeMode.hoveredPlaceholder = layoutItem;
    } else if (
      this.activeMode.type === 'insertingBench' &&
      (benchInsertionLayoutItemTypeGuard(layoutItem) || layoutItem === undefined)
    ) {
      this.activeMode.hoveredPlaceholder = layoutItem;
    }
  }

  resetHighlightInsertionHover() {
    if (this.activeMode.type === 'insertingInstrument' || this.activeMode.type === 'insertingBench') {
      this.activeMode.hoveredPlaceholder = undefined;
    }
  }

  enterOverviewMode(closeMenu = false) {
    this.activeMode = {
      type: 'overview',
      hoveredElement:
        this.activeMode.type !== 'insertingInstrument' && this.activeMode.type !== 'insertingBench'
          ? this.activeMode.hoveredElement
          : undefined
    };
    if (closeMenu) {
      this.closeMenu();
    }
  }

  toggleRuler() {
    this.showRuler = !this.showRuler;
  }

  onCloseMenu(handler: () => void): void {
    this.closeMenuHandler = handler;
  }

  closeMenu(): void {
    this.closeMenuHandler();
  }

  get isUShapeConfiguration(): boolean {
    if (!this.loaded) {
      return false;
    }
    return uShapeConfigurationTypeGuard(this.model.shapes);
  }

  public canBenchBeUsedForInstallation(bench: ISection): boolean {
    return this.availableBenchInsertPositions(bench).length !== 0;
  }

  private availableBenchInsertPositions(bench: ISection): LayoutItem<SectionInsertionLayoutPayload>[] {
    const positions = this.availableAreasService.getAvailablePositionsForBenches(bench);
    return positions.filter(position => {
      const addMutator = new AddBenchMutator(position.payload.section, position.payload.position);
      const [, validationResult] = this.canApplyMutator(addMutator);
      return validationResult.isValid;
    });
  }

  private canApplyMutator(mutator: ModelMutator): [IConfiguration, ValidationResult] {
    const [newModel, validationResult] = this.engine.mutate(this.model, mutator);

    if (validationResult.isInvalid) {
      return [newModel as IConfiguration, validationResult];
    }

    try {
      const tempModel = newModel as IConfiguration;
      const tempAvailableAreasService = new AvailableAreasService();
      const cameraPosition = new CameraPositionService();
      // runs for validation
      new CompositeShapeService(tempModel, tempModel.shapes, tempAvailableAreasService, cameraPosition);
      return [newModel as IConfiguration, ValidationResult.Valid()];
    } catch (error) {
      return [newModel as IConfiguration, ValidationResult.Error("Can' apply mutator")];
    }
  }

  private getAllBenches(): ISection[] {
    if (!this.loaded) {
      return [];
    }
    return (this.datasetService.getOptions('Section') as Option<ISection>[]).map(option => option.model);
  }

  private update(): void {
    this.availableAreasService = new AvailableAreasService();
    const cameraPosition = new CameraPositionService();
    this.shapeService = new CompositeShapeService(
      this.model,
      this.model.shapes,
      this.availableAreasService,
      cameraPosition
    );
    this.viewModel = {
      offsetHeight: 0,
      hdr: 'studio3.hdr',
      interior: 'floor',
      benches: this.shapeService.benchItems,
      instruments: this.shapeService.instrumentItems
    };
    this.cameraState = cameraPosition.state;

    if (this.activeMode.type === 'insertingInstrument') {
      this.enterInstrumentInstallation(this.activeMode.instrumentToAdd);
    } else if (this.activeMode.type === 'insertingBench') {
      this.enterBenchInstallation(this.activeMode.benchToAdd);
    }
  }

  public canInstrumentBeUsedForReplacement(instrument: IInstrument): boolean {
    if (this.activeMode.type !== 'replacingInstrument') {
      return false;
    }

    const replacementMutator = new ReplaceInstrumentMutator(instrument, this.activeMode.position);
    const [newModel, validationResult] = this.engine.mutate(this.model, replacementMutator);
    if (validationResult.isValid) {
      try {
        const tempModel = newModel as IConfiguration;
        const tempAvailableAreasService = new AvailableAreasService();
        const cameraPosition = new CameraPositionService();
        // runs for validation
        new CompositeShapeService(tempModel, tempModel.shapes, tempAvailableAreasService, cameraPosition);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  public canBenchBeDeleted(position: ElementPositionById): boolean {
    if (this.activeMode.type !== 'selectedBench') {
      return false;
    }

    const benchLocationResult = findBenchLocationByUUID(this.model, position.uuid);

    if (uShapeTypeGuard(benchLocationResult.shape)) {
      return false;
    }

    if (
      BenchMutationsValidator.isBenchCornerInCurrentConfiguration(this.activeMode.selectedBench, benchLocationResult)
    ) {
      return false;
    }

    const deleteMutator = new DeleteBenchMutator(position);
    const [newModel, validationResult] = this.engine.mutate(this.model, deleteMutator);

    if (!BenchMutationsValidator.doesCornerAmountSatisfy(newModel as IConfiguration, benchLocationResult)) {
      return false;
    }

    if (lShapeTypeGuard(benchLocationResult.shape)) {
      if (!BenchMutationsValidator.isMinimumBenchesAmountForLShapeFollowed(newModel as IConfiguration)) {
        return false;
      }
    }

    if (validationResult.isValid) {
      try {
        const tempModel = newModel as IConfiguration;
        const tempAvailableAreasService = new AvailableAreasService();
        const cameraPosition = new CameraPositionService();
        // runs for validation
        new CompositeShapeService(tempModel, tempModel.shapes, tempAvailableAreasService, cameraPosition);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  private applyMutator(mutator: ModelMutator): void {
    const [newModel, validationResult] = this.engine.mutate(this.model, mutator);
    if (validationResult.isValid) {
      this.hasChanges = true;
      this.model = newModel as IConfiguration;
    } else {
      alert(validationResult.errorMessage);
    }
    this.update();
  }

  private getJsonFromLink(link: string) {
    const data = Array.from(atob(link)).map(u => u.charCodeAt(0));
    const unzipped = gzip.unzip(data);
    const unzippedString = unzipped.map(d => String.fromCharCode(d)).join('');
    return JSON.parse(unzippedString);
  }

  private async loadFromLink(paramsLink: string) {
    const link = decodeURIComponent(paramsLink);
    const json = this.getJsonFromLink(link);
    try {
      await this.restoreConfiguration(json);
      return;
    } catch (e) {
      console.error(e);

      if (e instanceof NoPossibilityToPutInstrumentError) {
        throw e;
      }
    }
  }

  private async restoreConfiguration(json: Record<string, any>) {
    this.model = this.automataSerializer.deserialize(json as IAutomataState);
    this.initModel();
    this.update();
  }

  private initModel(): void {
    traverseLines(this.model, line => {
      line.line.uuid = generateUUID();
      line.instruments.forEach(instrument => {
        instrument.uuid = generateUUID();
      });
      line.benches.forEach(bench => (bench.uuid = generateUUID()));
    });
  }

  private setUpReplacementMode(selectedInstrument: IInstrument): void {
    let altInstruments: IInstrument[];
    if (selectedInstrument.category === InstrumentCategory.custom) {
      altInstruments = this.customInstrumentsService.customInstruments;
    } else {
      altInstruments = this.getAvailableInstruments().filter(
        instrument => instrument.category === selectedInstrument.category
      );
    }

    sortByTextField(altInstruments, 'name');

    this.activeMode = {
      type: 'replacingInstrument',
      category: localization.formatMessage(`instruments.category.${selectedInstrument.category}`),
      instrumentToReplace: selectedInstrument,
      position: { uuid: selectedInstrument.uuid },
      altInstruments
    };
  }

  getFirstInstrument(): IInstrument | undefined {
    let instrument: IInstrument | undefined;
    // TODO: Traverse lines do not allow to stop traversing when found
    traverseLines(this.model, line => {
      if (!instrument && line.instruments.length > 0) {
        instrument = line.instruments[0];
      }
    });
    return instrument;
  }

  getLastBench(): ISection | undefined {
    let bench: ISection | undefined;
    // TODO: Traverse lines do not allow to stop traversing when found
    traverseLines(this.model, line => {
      if (line.benches.length > 0) {
        bench = line.benches[line.benches.length - 1];
      }
    });

    return bench;
  }

  highlightBench() {
    if (this.activeMode.type === 'insertingBench' && this.activeMode.benchToAdd) {
      const positions = this.availableBenchInsertPositions(this.activeMode.benchToAdd);
      this.highlightInsertionHover(positions[positions.length - 1]);
    }
  }

  highlightInstrument() {
    if (this.activeMode.type === 'insertingInstrument' && this.activeMode.instrumentToAdd) {
      this.highlightInsertionHover(
        this.availableAreasService.getAvailablePositionsForInstruments(this.activeMode.instrumentToAdd)[0]
      );
    }
  }
}
