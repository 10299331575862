import { INodePointerEvent } from './types';

class NodePointerEvent implements INodePointerEvent {
  stopped = false;

  stopPropagation() {
    this.stopped = true;
  }
}

export default NodePointerEvent;
