import * as React from 'react';

function trackFinger(event: TouchEvent | MouseEvent, touchId: React.MutableRefObject<number | undefined>) {
  if (event instanceof TouchEvent && touchId.current !== undefined && event.changedTouches) {
    for (let i = 0; i < event.changedTouches.length; i += 1) {
      const touch = event.changedTouches[i];
      if (touch.identifier === touchId.current) {
        return {
          x: touch.clientX,
          y: touch.clientY
        };
      }
    }
  } else if (event instanceof MouseEvent) {
    return {
      x: event.clientX,
      y: event.clientY
    };
  }
  return false;
}

function getOwnerDocument(element: HTMLElement | null) {
  return element?.ownerDocument ?? document;
}

interface IArgs {
  enabled?: boolean;
  onCollapse: () => void;
  collapseBoundaryCoefficient?: number;
  swipeDownBoundaryCoefficient?: number;
}

export function useSwipable({
  enabled = true,
  onCollapse,
  collapseBoundaryCoefficient = 0.3,
  swipeDownBoundaryCoefficient = 0.2
}: IArgs) {
  const sheetRef = React.useRef<HTMLDivElement>(null);
  const handleRef = React.useRef<HTMLDivElement>(null);
  const touchId = React.useRef<number>();
  const dragStartPosition = React.useRef<number>(0);
  const [dragOffset, setDragOffset] = React.useState(0);
  const maxOffset = React.useRef<number>(0);

  const dragEasingFunction = React.useMemo(
    () => (x: number) => maxOffset.current * Math.tanh(x / maxOffset.current),
    []
  );

  const reset = React.useCallback(() => {
    setDragOffset(0);
  }, []);

  const closeSheet = React.useCallback(() => {
    setDragOffset(Infinity);

    const { current: sheet } = sheetRef;

    if (sheet) {
      sheet.addEventListener('transitionend', onCollapse, { once: true });
    }
  }, [onCollapse]);

  const handleTouchMove = React.useCallback(
    (event: TouchEvent) => {
      const finger = trackFinger(event, touchId);
      if (finger) {
        const calculatedDragOffset = finger.y - dragStartPosition?.current;
        setDragOffset(calculatedDragOffset < 0 ? 0 : dragEasingFunction(calculatedDragOffset));
      }
    },
    [dragEasingFunction]
  );

  const handleTouchEnd = React.useCallback(
    (event: TouchEvent) => {
      const finger = trackFinger(event, touchId);

      if (!finger) {
        return;
      }

      touchId.current = undefined;
      if (finger.y - dragStartPosition?.current > maxOffset.current * collapseBoundaryCoefficient) {
        closeSheet();
      } else {
        setDragOffset(0);
        dragStartPosition.current = 0;
      }

      const doc = getOwnerDocument(handleRef.current);
      doc.removeEventListener('touchmove', handleTouchMove);
      doc.removeEventListener('touchend', handleTouchEnd);
    },
    [closeSheet, collapseBoundaryCoefficient, handleTouchMove]
  );

  const handleTouchStart = React.useCallback(
    (event: TouchEvent) => {
      // todo: remove if nothing breaks
      // Workaround as Safari has partial support for touchAction: 'none'.
      // event.preventDefault();
      const touch = event.changedTouches[0];
      if (touch != null) {
        // A number that uniquely identifies the current finger in the touch session.
        touchId.current = touch.identifier;
      }

      const finger = trackFinger(event, touchId);
      if (finger) {
        dragStartPosition.current = finger.y;
      }

      const doc = getOwnerDocument(handleRef.current);
      doc.addEventListener('touchmove', handleTouchMove);
      doc.addEventListener('touchend', handleTouchEnd);
    },
    [handleTouchEnd, handleTouchMove]
  );

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const { current: handle } = handleRef;
    if (handle !== null) {
      handle.addEventListener('touchstart', handleTouchStart, { passive: true });
    }

    const { current: sheet } = sheetRef;

    if (sheet !== null) {
      maxOffset.current = sheet.clientHeight * swipeDownBoundaryCoefficient;
    }

    return () => {
      if (handle !== null) {
        handle.removeEventListener('touchstart', handleTouchStart);
      }
    };
  }, [enabled, handleTouchStart, swipeDownBoundaryCoefficient]);

  const dragProgress = dragOffset === Infinity ? 1 : dragOffset / maxOffset.current ?? 0;

  const sheetStyle = {
    transform:
      dragOffset === Infinity ? `translateY(150%)` : dragOffset !== 0 ? `translateY(${dragOffset}px)` : undefined,
    transitionDuration: dragOffset === Infinity ? '150ms' : dragOffset !== 0 ? `0ms` : undefined,
    '--progress': dragProgress
  };

  return {
    sheetRef,
    handleRef,
    sheetStyle,
    isCollapsed: dragOffset === Infinity,
    dragOffset,
    progress: dragProgress,
    reset
  };
}
