import { Link } from 'react-router-dom';
import React from 'react';
import { useHeightReporter } from '../../components/hooks/useHeightReporter';
import { FormattedMessage } from 'react-intl';

// TODO
export const NotFoundPage = () => {
  useHeightReporter();

  return (
    <div>
      <h2>404</h2>
      <p>
        <Link to="/">
          <FormattedMessage id="common.goHome" />
        </Link>
      </p>
    </div>
  );
};
