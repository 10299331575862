import { generateUUID } from 'three/src/math/MathUtils';
import { ModelMutator, MutatorContext, TypeReference, ValidationResult } from '@canvas-logic/engine';
import { IConfiguration, IInstrument } from '../../schema';
import { InstrumentInsertPosition, traverseLines, AfterInstrumentPosition, FirstInstrumentPosition } from '../layout';
import { notFoundInMutator } from '../errors';
import { findInstrumentLocationByUUID } from './utils';

export class AddInstrumentMutator implements ModelMutator {
  constructor(private readonly instrument: IInstrument, private readonly position: InstrumentInsertPosition) {
    this.instrument = { ...instrument, uuid: generateUUID() };
  }

  mutate(context: MutatorContext, typeRef: TypeReference, model: IConfiguration): [IConfiguration, ValidationResult] {
    switch (this.position.type) {
      case 'firstInstrument':
        return this.insertFirstInstrument(this.position, model);
      case 'afterInstrument':
        return this.insertAfterInstrument(this.position, model);
    }
  }

  private insertFirstInstrument(
    position: FirstInstrumentPosition,
    model: IConfiguration
  ): [IConfiguration, ValidationResult] {
    let found = false;
    traverseLines(model, node => {
      if (!found && node.line.uuid === position.line_uuid) {
        found = true;
        node.instruments.push(this.instrument);
      }
    });
    if (!found) {
      return [model, ValidationResult.Error(notFoundInMutator())];
    }
    return [model, ValidationResult.Valid()];
  }

  private insertAfterInstrument(
    position: AfterInstrumentPosition,
    model: IConfiguration
  ): [IConfiguration, ValidationResult] {
    const { instruments, index } = findInstrumentLocationByUUID(model, position.after.uuid);
    if (index >= 0) {
      instruments.splice(index + 1, 0, this.instrument);
      return [model, ValidationResult.Valid()];
    }

    return [model, ValidationResult.Error(notFoundInMutator())];
  }
}
