import { ShapeService, ShapeServiceModel } from './types';
import { Shape } from '../../schema';
import { OneLineShapeService } from './OneLineShapeService';
import { backToBackShapeTypeGuard, customShapeTypeGuard } from '../typeGuards';
import { BackToBackShapeService } from './BackToBackShapeService';
import { LayoutItem, InstrumentLayoutPayload } from '../layout';
import { AvailableAreasService } from '../../services';
import { CameraPositionService } from '../camera/CameraPositionService';

export class CompositeShapeService implements ShapeService {
  private shapesServices: ShapeService[] = [];
  public readonly benchItems: LayoutItem[] = [];
  public readonly instrumentItems: LayoutItem<InstrumentLayoutPayload>[] = [];

  constructor(
    private model: ShapeServiceModel,
    shapes: Shape[],
    availableAreasService: AvailableAreasService,
    private cameraPositionService: CameraPositionService
  ) {
    for (const shape of shapes) {
      if (backToBackShapeTypeGuard(shape)) {
        const service = new BackToBackShapeService(model, shape, availableAreasService, this.cameraPositionService);
        this.shapesServices.push(service);
      } else {
        if (customShapeTypeGuard(shape)) {
          shape.lines.forEach(line => {
            const innerShape = { ...line, minCorners: 0, maxCorners: 9999 };
            const service = new OneLineShapeService(
              model,
              innerShape,
              availableAreasService,
              this.cameraPositionService
            );
            this.shapesServices.push(service);
          });
        } else {
          const service = new OneLineShapeService(model, shape, availableAreasService, this.cameraPositionService);
          this.shapesServices.push(service);
        }
      }
    }

    for (const service of this.shapesServices) {
      this.benchItems.push(...service.benchItems);
      this.instrumentItems.push(...service.instrumentItems);
    }
  }
}
