import { deepCopy, Engine, getOptionId, PModel } from '@canvas-logic/engine';
import { toJS } from 'mobx';
import { IAutomataState, OptionIdList, ShapeState, ShapeType } from '../types';
import {
  GeometryElement,
  GeometryElementList,
  IConfiguration,
  ICustomInstrument,
  IInstrument,
  InstrumentCategory
} from '../../../schema';
import { rootStore } from '../../../stores/RootStore';
import {
  backToBackShapeTypeGuard,
  customShapeTypeGuard,
  linearShapeTypeGuard,
  lShapeTypeGuard,
  uShapeTypeGuard
} from '../../typeGuards';
import { CustomInstrumentsService } from '../../instruments';
import { traverseLines } from '../../layout';

export class AutomataSerializer {
  constructor(
    private readonly engine: Engine,
    private readonly geometries: GeometryElementList,
    private readonly instruments: IInstrument[],
    private readonly customInstrumentsService: CustomInstrumentsService
  ) {}

  private getOptionIds = (options: GeometryElement[] | IInstrument[] | ICustomInstrument[]): string[] => {
    return options.map(option => getOptionId(option));
  };

  serialize(model: IConfiguration): IAutomataState {
    const productId = 'empty';
    const customInstrumentsUsedIds = new Set<string>();

    const addCustomInstrumentsIdsToSet = (instruments: IInstrument[]): void => {
      const ids = instruments.filter(instrument => instrument.category === InstrumentCategory.custom).map(getOptionId);
      ids.forEach(id => customInstrumentsUsedIds.add(id));
    };

    const shapes = model.shapes.map<ShapeState>(shape => {
      if (!customShapeTypeGuard(shape)) {
        const commonShape: ShapeState = {
          type: ShapeType.Linear,
          angle: shape.angle,
          position: shape.position,
          geometry: this.getOptionIds(shape.geometry),
          instruments: []
        };

        if (linearShapeTypeGuard(shape)) {
          addCustomInstrumentsIdsToSet(shape.instruments);
          return {
            ...commonShape,
            instruments: this.getOptionIds(shape.instruments)
          };
        }
        if (lShapeTypeGuard(shape)) {
          addCustomInstrumentsIdsToSet(shape.instruments);
          return {
            ...commonShape,
            type: ShapeType.LLeft,
            instruments: this.getOptionIds(shape.instruments)
          };
        }
        if (uShapeTypeGuard(shape)) {
          addCustomInstrumentsIdsToSet(shape.instruments);
          return {
            ...commonShape,
            type: ShapeType.UShape,
            instruments: this.getOptionIds(shape.instruments)
          };
        }
        if (backToBackShapeTypeGuard(shape)) {
          addCustomInstrumentsIdsToSet(shape.front.instruments);
          addCustomInstrumentsIdsToSet(shape.back.instruments);

          return {
            ...commonShape,
            type: ShapeType.BackToBack,
            frontInstruments: this.getOptionIds(shape.front.instruments),
            backInstruments: this.getOptionIds(shape.back.instruments)
          };
        }
      }

      return {
        type: ShapeType.Custom,
        lines: shape.lines.map(line => {
          return {
            angle: line.angle,
            position: line.position,
            geometry: this.getOptionIds(line.geometry),
            instruments: this.getOptionIds(line.instruments)
          };
        })
      };
    });

    const customInstrumentsPresentInConfiguration = this.customInstrumentsService.customInstruments.filter(intrument =>
      customInstrumentsUsedIds.has(getOptionId(intrument))
    );

    return {
      productId,
      shapes,
      customInstruments: toJS(customInstrumentsPresentInConfiguration).map(CustomInstrumentsService.serialize)
    };
  }

  deserialize(state: IAutomataState): PModel<IConfiguration> {
    const schema = rootStore.datasetService.getProductSchema('', {}, '');
    const templateProduct = rootStore.datasetService.getProductById(state.productId);

    const model = templateProduct.product.model;

    this.customInstrumentsService.deserializeAndMergeWithExisting(state.customInstruments);

    model.shapes.value = [];

    state.shapes.forEach(shape => {
      this.addShapeToProduct(model.shapes.value, shape);
    });

    /* @TODO:
         Attention: it is a hack to save heights in custom instruments, because engine considers instruments as
         IInstrument only -- and cleans fields, not belonging to IInstrument (such as height)
    */
    const memoizedHeights = this.customInstrumentsHeights();
    const result = this.engine.initByProductWithContext(schema, templateProduct) as PModel<IConfiguration>;
    this.restoreHeightsForCustomInstruments(result, memoizedHeights);
    return result;
  }

  // To memorize heights before running engine, because engine will remove as they're not in product
  private customInstrumentsHeights(): Map<string, number> {
    const memory = new Map<string, number>();
    this.customInstrumentsService.customInstruments.forEach(i => memory.set(i.uuid, i.height));
    return memory;
  }

  private restoreHeightsForCustomInstruments(model: IConfiguration, memoizedHeights: Map<string, number>): void {
    if (memoizedHeights.size === 0) {
      return;
    }
    traverseLines(model, lineNode => {
      lineNode.instruments.forEach(instrument => {
        if (memoizedHeights.has(instrument.uuid)) {
          (instrument as ICustomInstrument).height = memoizedHeights.get(instrument.uuid)!;
        }
      });
    });
  }

  private findOptionsWithIds(options: (GeometryElement | IInstrument | ICustomInstrument)[], elementIds: OptionIdList) {
    return elementIds.map(id => {
      return options.find((it): it is GeometryElement | IInstrument => {
        return getOptionId(it) === id;
      });
    });
  }

  private addShapeToProduct(value: any, shape: ShapeState) {
    const allInstruments = [...this.instruments, ...this.customInstrumentsService.customInstruments];

    let shapeData: any;
    if ('angle' in shape) {
      shapeData = {
        angle: shape.angle,
        position: shape.position,
        geometry: this.findOptionsWithIds(this.geometries, shape.geometry)
      };
      if ('instruments' in shape) {
        shapeData = {
          ...shapeData,
          instruments: this.findOptionsWithIds(allInstruments, shape.instruments)
        };
      }
    }

    switch (shape.type) {
      case ShapeType.BackToBack:
        shapeData = {
          ...shapeData,
          _type: 'BackToBackShape',
          front: {
            instruments: this.findOptionsWithIds(allInstruments, shape.frontInstruments)
          },
          back: {
            instruments: this.findOptionsWithIds(allInstruments, shape.backInstruments)
          }
        };
        break;
      case ShapeType.Linear:
        shapeData._type = 'LinearShape';
        break;
      case ShapeType.LLeft:
      case ShapeType.LRight:
        shapeData._type = 'LShape';
        break;
      case ShapeType.UShape:
        shapeData._type = 'UShape';
        break;
      case ShapeType.Custom:
        shapeData = {
          _type: 'CustomShape',
          lines: shape.lines.map(line => {
            return {
              angle: line.angle,
              position: line.position,
              geometry: this.findOptionsWithIds(this.geometries, line.geometry),
              instruments: this.findOptionsWithIds(allInstruments, line.instruments)
            };
          })
        };
        break;
    }

    value.push(shapeData);
  }
}
