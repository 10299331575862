import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as MouseIcon } from './HelpIcons/MouseIcon.svg';
import { ReactComponent as MouseLeftIcon } from './HelpIcons/MouseLeft.svg';
import { ReactComponent as MouseRightIcon } from './HelpIcons/MouseRight.svg';
import { ReactComponent as CameraZoomMobile } from './HelpIcons/CameraZoomMobile.svg';
import { ReactComponent as CameraPanMobile } from './HelpIcons/CameraPanMobile.svg';
import { ReactComponent as CameraRotationMobile } from './HelpIcons/CameraRotationMobile.svg';
import { ReactComponent as CameraResetMobile } from './HelpIcons/CameraResetMobile.svg';
import { useIsHoverable } from '../../../components/hooks/useMediaQuery';
import { cn } from '../../../components/utils';
import { useConfigurationOnboardingStore } from '../Onboarding/ConfigurationOnboardingContext';
import { observer } from 'mobx-react-lite';
import { Button, Icon } from '../../../components';
import { rootStore } from 'stores/RootStore';
import { RestartGuideEventGA } from 'services/GoogleAnalyticsService';

const HelpItem = ({
  title,
  description,
  icon,
  className
}: {
  title: ReactNode;
  description: ReactNode;
  icon: ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('mb-4 flex flex-wrap items-center justify-between', className)}>
      <div className="mr-4 flex-1">
        <h6 className="typography-body2">{title}</h6>
        <div className="typography-caption text-neutral-600">{description}</div>
      </div>
      <div className="flex items-center justify-center">{icon}</div>
    </div>
  );
};

export const HelpContent = observer(() => {
  const isHoverable = useIsHoverable();
  const onboardingStore = useConfigurationOnboardingStore();
  const startGuide = () => {
    rootStore.gaEvent(new RestartGuideEventGA(), 'Configurator');
    onboardingStore.resetOnboarding();
  };
  return isHoverable ? <HelpContentDesktop startGuide={startGuide} /> : <HelpContentMobile startGuide={startGuide} />;
});

type ContentProps = {
  startGuide: () => void;
};

export const HelpContentDesktop = ({ startGuide }: ContentProps) => {
  return (
    <div className="">
      <div className="mb-6 flex flex-wrap items-center justify-between">
        <div>
          <div className="typography-subtitle1">
            <FormattedMessage id="configurator.help.desktop.guide.title" />
          </div>
          <div className="typography-caption mb-2 text-neutral-600">
            <FormattedMessage id="configurator.help.desktop.guide.description" />
          </div>
          <Button
            variant="link-secondary"
            onClick={startGuide}
            data-testid="toggle-onboarding-button"
            className="gap-1 p-0"
          >
            <Button.ButtonText>
              <FormattedMessage id="configurator.help.desktop.guide.start" />
            </Button.ButtonText>
            <Button.ButtonIcon>
              <Icon icon={'arrow_forward'} className="text-base" />
            </Button.ButtonIcon>
          </Button>
        </div>
      </div>
      <div className="">
        <div className="typography-subtitle1 mb-2">
          <FormattedMessage id="configurator.help.desktop.cameraManipulation" />
        </div>

        <HelpItem
          title={<FormattedMessage id="configurator.help.desktop.cameraZoom.title" />}
          description={<FormattedMessage id="configurator.help.desktop.cameraZoom.description" />}
          icon={<MouseIcon />}
        />
        <HelpItem
          title={<FormattedMessage id="configurator.help.desktop.cameraRotation.title" />}
          description={<FormattedMessage id="configurator.help.desktop.cameraRotation.description" />}
          icon={<MouseLeftIcon />}
        />
        <HelpItem
          title={<FormattedMessage id="configurator.help.desktop.cameraPan.title" />}
          description={<FormattedMessage id="configurator.help.desktop.cameraPan.description" />}
          icon={<MouseRightIcon />}
        />

        <HelpItem
          className="mb-0"
          title={<FormattedMessage id="configurator.help.desktop.cameraReset.title" />}
          description={<FormattedMessage id="configurator.help.desktop.cameraReset.description" />}
          icon={
            <>
              <div className="mr-2 flex h-[24px] w-[52px]  items-center justify-center  rounded-sm bg-blue-0 text-xs text-blue-600">
                Shift
              </div>
              <div className="flex h-[24px] w-[24px] items-center justify-center rounded-sm bg-blue-0 text-xs text-blue-600">
                R
              </div>
            </>
          }
        />
      </div>
    </div>
  );
};

export const HelpContentMobile = ({ startGuide }: ContentProps) => {
  return (
    <div className="">
      <div className="mb-6 flex flex-wrap items-center justify-between">
        <div>
          <div className="typography-subtitle1">
            <FormattedMessage id="configurator.help.mobile.guide.title" />
          </div>
          <div className="typography-caption text-neutral-600">
            <FormattedMessage id="configurator.help.mobile.guide.description" />
          </div>
        </div>
        <Button
          variant="link-secondary"
          onClick={startGuide}
          data-testid="toggle-onboarding-button"
          className="gap-1 p-0"
        >
          <Button.ButtonText>
            <FormattedMessage id="configurator.help.mobile.guide.start" />
          </Button.ButtonText>
          <Button.ButtonIcon>
            <Icon icon={'arrow_forward'} className="text-base" />
          </Button.ButtonIcon>
        </Button>
      </div>
      <div className="">
        <div className="typography-subtitle1 mb-2">
          <FormattedMessage id="configurator.help.mobile.cameraManipulation" />
        </div>

        <HelpItem
          title={<FormattedMessage id="configurator.help.mobile.cameraZoom.title" />}
          description={<FormattedMessage id="configurator.help.mobile.cameraZoom.description" />}
          icon={<CameraZoomMobile />}
        />
        <HelpItem
          title={<FormattedMessage id="configurator.help.mobile.cameraRotation.title" />}
          description={<FormattedMessage id="configurator.help.mobile.cameraRotation.description" />}
          icon={<CameraRotationMobile />}
        />
        <HelpItem
          title={<FormattedMessage id="configurator.help.mobile.cameraPan.title" />}
          description={<FormattedMessage id="configurator.help.mobile.cameraPan.description" />}
          icon={<CameraPanMobile />}
        />

        <HelpItem
          className="mb-0"
          title={<FormattedMessage id="configurator.help.mobile.cameraReset.title" />}
          description={<FormattedMessage id="configurator.help.mobile.cameraReset.description" />}
          icon={<CameraResetMobile />}
        />
      </div>
    </div>
  );
};
