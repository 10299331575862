import { GeometryElement, IConfiguration, ISection, Shape, GeometryElementList, ILShape } from '../../schema';
import { LineNode, traverseLines } from '../layout';
import {
  backToBackShapeTypeGuard,
  connectionTypeGuard,
  lShapeTypeGuard,
  linearShapeTypeGuard,
  sectionTypeGuard,
  uShapeTypeGuard,
  customShapeTypeGuard
} from '../typeGuards';
import { FindBenchLocationByUUIDResult } from './utils';

export class BenchMutationsValidator {
  private static readonly TWIN_BENCH_SIZE = 2;
  private static readonly SINGLE_BENCH_SIZE = 1;
  private static readonly MAX_BENCHES_AMOUNT = 10;
  private static readonly MAX_BENCHES_AMOUNT_BACK_TO_BACK = BenchMutationsValidator.MAX_BENCHES_AMOUNT / 2;

  public static validate(model: IConfiguration): boolean {
    try {
      traverseLines(model, (lineNode: LineNode) => {
        const { sourceShape: shape } = lineNode;
        const benches = BenchMutationsValidator.getBenches(shape);

        if (!BenchMutationsValidator.validateSingleShape(shape, benches)) {
          throw new Error('Validation failed');
        }
      });
    } catch (error) {
      console.error(error);
      return false;
    }

    return true;
  }

  public static validateSingleShape(shape: Shape, benchesList: ISection[]): boolean {
    let validationResult = BenchMutationsValidator.validateBenchesOnGeneralRules(benchesList, shape);
    if (!validationResult) {
      return false;
    }

    if (backToBackShapeTypeGuard(shape)) {
      validationResult &&= BenchMutationsValidator.validateBenchesBackToBack(benchesList);
    } else if (linearShapeTypeGuard(shape)) {
      validationResult &&= BenchMutationsValidator.validateLinearShape(benchesList);
    } else if (lShapeTypeGuard(shape)) {
      validationResult &&= BenchMutationsValidator.validateLShape(benchesList);
    }

    if (!validationResult) {
      return false;
    }

    return true;
  }

  private static validateBenchesOnGeneralRules(benches: ISection[], shape: Shape): boolean {
    // BR2

    const count = benches.reduce((result, bench) => result + bench.size, 0);
    if (count > BenchMutationsValidator.MAX_BENCHES_AMOUNT) {
      return false;
    }

    // BR4 : no twin bench after single bench
    if (benches.length > 2) {
      const [first, second] = [benches[0], benches[1]];
      if (
        first.size === BenchMutationsValidator.TWIN_BENCH_SIZE &&
        second.size === BenchMutationsValidator.SINGLE_BENCH_SIZE &&
        !second.hasTransport
      ) {
        return false;
      }

      const [preLast, last] = [benches[benches.length - 2], benches[benches.length - 1]];
      if (
        preLast.size === BenchMutationsValidator.SINGLE_BENCH_SIZE &&
        last.size === BenchMutationsValidator.TWIN_BENCH_SIZE &&
        !preLast.hasTransport
      ) {
        return false;
      }
    }

    // BR5
    if (benches.length > 2) {
      const [first, second] = [benches[0], benches[1]];
      if (
        first.size === BenchMutationsValidator.SINGLE_BENCH_SIZE &&
        second.size === BenchMutationsValidator.SINGLE_BENCH_SIZE &&
        !second.hasTransport &&
        !first.hasTransport
      ) {
        return false;
      }

      const [preLast, last] = [benches[benches.length - 2], benches[benches.length - 1]];
      if (
        preLast.size === BenchMutationsValidator.SINGLE_BENCH_SIZE &&
        last.size === BenchMutationsValidator.SINGLE_BENCH_SIZE &&
        !preLast.hasTransport &&
        !last.hasTransport
      ) {
        return false;
      }
    }

    return true;
  }

  private static getGeometryList(shape: Shape): GeometryElementList {
    if (backToBackShapeTypeGuard(shape)) {
      return shape.geometry;
    } else if (linearShapeTypeGuard(shape)) {
      return shape.geometry;
    } else if (uShapeTypeGuard(shape)) {
      return shape.geometry;
    } else if (lShapeTypeGuard(shape)) {
      return shape.geometry;
    } else if (customShapeTypeGuard(shape)) {
      return shape.lines.flatMap(line => line.geometry);
    } else {
      return [];
    }
  }

  private static getBenches(shape: Shape): ISection[] {
    return BenchMutationsValidator.getGeometryList(shape).filter(element => sectionTypeGuard(element)) as ISection[];
  }

  private static validateBenchesBackToBack(benches: ISection[]): boolean {
    // BR3
    if (
      !benches.some(element => sectionTypeGuard(element) && element.size === BenchMutationsValidator.TWIN_BENCH_SIZE)
    ) {
      return false;
    }

    return true;
  }

  private static validateLShape(benches: GeometryElement[]): boolean {
    // BR3
    for (let index = 0; index < benches.length; ++index) {
      const element = benches[index];
      if (connectionTypeGuard(element)) {
        let beforeCorner: GeometryElement | undefined;
        let afterCorner: GeometryElement | undefined;
        if (element.join === 'aside') {
          beforeCorner = benches[index - 2];
          afterCorner = benches[index + 1];
        } else if (element.join === 'along') {
          beforeCorner = benches[index - 1];
          afterCorner = benches[index + 2];
        }

        if (sectionTypeGuard(beforeCorner) && sectionTypeGuard(afterCorner)) {
          if (
            !(
              beforeCorner.size === BenchMutationsValidator.TWIN_BENCH_SIZE &&
              afterCorner.size === BenchMutationsValidator.TWIN_BENCH_SIZE
            )
          ) {
            return false;
          }
        } else {
          return false;
        }
      }
    }

    return true;
  }

  private static validateLinearShape(benches: ISection[]): boolean {
    // BR3 : minimum 1 twin bench for line

    if (benches.findIndex(element => element.size === 2) === -1) {
      return false;
    }

    return true;
  }

  public static doesCornerAmountSatisfy(
    model: IConfiguration,
    benchLocationResult: FindBenchLocationByUUIDResult
  ): boolean {
    let result: boolean = true;
    traverseLines(model, (lineNode: LineNode) => {
      if (!result) {
        // already found not satisfying case, no need to look for further
        return;
      }

      for (let index = 0; index < lineNode.allGeometry.length; ++index) {
        if (connectionTypeGuard(lineNode.allGeometry[index])) {
          if (
            !(sectionTypeGuard(lineNode.allGeometry[index - 1]) && sectionTypeGuard(lineNode.allGeometry[index + 1]))
          ) {
            result = false;
            return;
          }
        }
      }

      const [firstElement, lastElement] = [
        lineNode.allGeometry[0],
        lineNode.allGeometry[lineNode.allGeometry.length - 1]
      ];

      if (
        sectionTypeGuard(firstElement) &&
        this.isBenchCornerInCurrentConfiguration(firstElement, benchLocationResult)
      ) {
        result = false;
        return;
      }

      if (
        sectionTypeGuard(lastElement) &&
        BenchMutationsValidator.isBenchCornerInCurrentConfiguration(lastElement, benchLocationResult)
      ) {
        result = false;
        return;
      }
    });

    return result;
  }

  public static isBenchCornerInCurrentConfiguration(
    bench: ISection,
    benchLocationResult: FindBenchLocationByUUIDResult
  ): boolean {
    // corner bench only single
    // along R --> R single or R single --> R aside

    if (bench.size !== 1) {
      return false;
    }

    const previousElement = benchLocationResult.benches[benchLocationResult.index - 1];
    const nextElement = benchLocationResult.benches[benchLocationResult.index + 1];

    if (connectionTypeGuard(previousElement) && previousElement.join === 'along') {
      return true;
    }

    if (connectionTypeGuard(nextElement) && nextElement.join === 'aside') {
      return true;
    }

    return false;
  }

  public static isMinimumBenchesAmountForLShapeFollowed(newModel: IConfiguration): boolean {
    let result: boolean = true;
    const twinBenchSize = 2;

    traverseLines(newModel, (lineNode: LineNode) => {
      if (!result) {
        // already found not satisfying case, no need to look for further
        return;
      }

      if (!lShapeTypeGuard(lineNode.sourceShape)) {
        return;
      }

      for (let index = 0; index < lineNode.allGeometry.length; ++index) {
        const element = lineNode.allGeometry[index];
        if (connectionTypeGuard(element)) {
          let beforeCorner: GeometryElement | undefined;
          let afterCorner: GeometryElement | undefined;
          if (element.join === 'aside') {
            beforeCorner = lineNode.allGeometry[index - 2];
            afterCorner = lineNode.allGeometry[index + 1];
          } else if (element.join === 'along') {
            beforeCorner = lineNode.allGeometry[index - 1];
            afterCorner = lineNode.allGeometry[index + 2];
          }

          if (sectionTypeGuard(beforeCorner) && sectionTypeGuard(afterCorner)) {
            if (!(beforeCorner.size === twinBenchSize && afterCorner.size === twinBenchSize)) {
              result = false;
              break;
            }
          } else {
            result = false;
            break;
          }
        }
      }
    });

    return result;
  }
}
