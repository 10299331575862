import * as React from 'react';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { cn } from 'components/utils';
import { Chip } from './Chip';
import { useResizeObserver } from '../hooks/useResizeObserver';

const CONTAINER_HEIGHT = 42;

export interface ChipsInputProps {
  className?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  placeholder?: string;
  values?: string[];
  onChange?: (values: string[]) => void;
  inputValue?: string;
  onInputValueChange?: (value: string, values?: string[]) => void;
  validator?: (value: string) => boolean;
}

const ChipsInput = ({
  className,
  type = 'text',
  placeholder,
  values = [],
  onChange,
  inputValue = '',
  onInputValueChange,
  validator
}: ChipsInputProps) => {
  const [editable, setEditable] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const lastChipRef = useRef<HTMLButtonElement>(null);

  const clearOnNextRender = useRef<boolean>(false);

  const { height = 0 } = useResizeObserver(containerRef.current);

  const isOverflown = useMemo(() => height > CONTAINER_HEIGHT, [height]);

  const itemsToDisplay = useMemo(() => {
    if (editable) {
      return values;
    }

    return values.length > 1 ? [values[0], `+${values.length - 1} more`] : values;
  }, [editable, values]);

  const restItemsValidator = useCallback(() => {
    if (!validator || values === undefined || editable) {
      return true;
    }

    return values.slice(1).every(item => validator(item));
  }, [editable, validator, values]);

  const handleOnInputValueChange = useCallback(
    (value: string) => {
      onInputValueChange?.(value);
    },
    [onInputValueChange]
  );

  /**
   * This is a hack to make updates in react-hook-form state
   */
  useLayoutEffect(() => {
    if (clearOnNextRender.current) {
      clearOnNextRender.current = false;
      handleOnInputValueChange('');
    }
  }, [handleOnInputValueChange]);

  const handleAddItem = useCallback(
    (label: string) => {
      const updatedLabels = [...new Set([...values, label])];

      onChange?.(updatedLabels);
      clearOnNextRender.current = true;
    },
    [values, onChange]
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleOnInputValueChange(event.target.value);
    },
    [handleOnInputValueChange]
  );

  const handleRemoveItem = useCallback(
    (index: number) => {
      const updatedValues = [...values];
      updatedValues.splice(index, 1);

      onChange?.(updatedValues);
    },
    [onChange, values]
  );

  const handleOnKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Backspace':
          if (event.currentTarget.value === '' && values.length > 0) {
            handleRemoveItem(values.length - 1);
          }
          break;
        case ' ':
        case ',':
        case 'Enter': {
          event.preventDefault();
          const trimmedValue = event.currentTarget.value.trim();
          if (trimmedValue !== '') {
            handleAddItem(trimmedValue);
          }
          break;
        }
      }
    },
    [handleAddItem, handleRemoveItem, values.length]
  );

  const handleEditItem = useCallback(
    (index: number) => {
      const value = values[index];
      const updatedValues = [...values];
      updatedValues.splice(index, 1);

      // we need to update both values in one call to make react-hook-form happy
      onInputValueChange?.(value, updatedValues);
      inputRef?.current?.focus();
    },
    [onInputValueChange, values]
  );

  const handleChipClick = useCallback(
    (_: React.MouseEvent<HTMLButtonElement>, index: number) => {
      handleEditItem(index);
    },
    [handleEditItem]
  );

  const handleOnChipKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
      switch (event.key) {
        case 'Backspace':
          handleRemoveItem(index);
          inputRef?.current?.focus();
          break;
      }
    },
    [handleRemoveItem]
  );

  const handleOnFocus = useCallback(() => {
    if (!editable) {
      setEditable(true);
      inputRef?.current?.focus();
    }
  }, [editable]);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent) => {
      if (!containerRef?.current?.contains(event.relatedTarget)) {
        setEditable(false);

        const trimmedValue = inputValue.trim();
        if (trimmedValue !== '') {
          handleAddItem(trimmedValue);
        }
      }
    },
    [handleAddItem, inputValue]
  );

  return (
    <div className={cn(`relative h-[42px] w-full`, className)}>
      <div
        className={cn(
          'typography-body1 ring-offset-background peer absolute bottom-0 flex max-h-56 w-full  overflow-y-auto rounded-md border border-neutral-300 bg-neutral-0 px-3 py-2  md:bottom-auto md:top-0 md:max-h-32',
          'focus-within:border-neutral-800',
          { 'z-10 shadow-list': isOverflown },
          { 'absolute inset-x-0': !editable }
        )}
        onFocus={handleOnFocus}
        onBlurCapture={handleOnBlur}
        ref={containerRef}
      >
        <div className="flex w-full flex-wrap gap-2">
          {itemsToDisplay.map((value, index, values) => (
            <Chip
              key={`${value}-${index}`}
              ref={index === values.length - 1 ? lastChipRef : undefined}
              className={cn('overflow-hidden text-ellipsis whitespace-nowrap', {
                'max-w-[11rem]': !editable && index === 0 && values.length > 1
              })}
              value={value}
              validator={!editable && index > 0 ? restItemsValidator : validator}
              onClick={event => handleChipClick(event, index)}
              onKeyDown={event => handleOnChipKeyPress(event, index)}
            />
          ))}
          <input
            type={type}
            placeholder={!values?.length ? placeholder : undefined}
            className={cn(
              'min-w-0 flex-1 placeholder:text-neutral-300  focus-visible:outline-none   disabled:cursor-not-allowed disabled:opacity-50',
              'hover:border-neutral-500',
              'active:border-neutral-800, focus:outline-none',
              'disabled:border-neutral-100',
              { 'min-w-[6rem]': editable }
            )}
            ref={inputRef}
            value={inputValue}
            onKeyDown={handleOnKeyPress}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </div>
  );
};

ChipsInput.displayName = 'ChipsInput';

export { ChipsInput };
