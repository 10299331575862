import React, { forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormattedMessage } from 'react-intl';
import { Form, FormControl, FormField, FormItem, FormLabel } from '../../../components/Form';
import { Button, Input } from '../../../components';
import { localization } from '../../../stores/Localization';
import { cn } from '../../../components/utils';
import { CustomInstrumentDimensions } from '../../../domain/instruments';

const dimensionsFields = ['width', 'depth', 'height'] as const;
const allFields = [...dimensionsFields, 'name'] as const;

type DimensionFieldNameType = (typeof dimensionsFields)[number];
type FieldNameType = (typeof allFields)[number];

const errorMessages: Record<FieldNameType, string> = {
  width: 'configurator.menu.customInstruments.form.errors.width',
  height: 'configurator.menu.customInstruments.form.errors.height',
  depth: 'configurator.menu.customInstruments.form.errors.depth',
  name: 'configurator.menu.customInstruments.form.errors.name'
} as const;

const tipMessages: Record<DimensionFieldNameType | 'default' | 'invalid', string> = {
  width: 'configurator.menu.customInstruments.form.tips.width',
  height: 'configurator.menu.customInstruments.form.tips.height',
  depth: 'configurator.menu.customInstruments.form.tips.depth',
  default: 'configurator.menu.customInstruments.form.tips.default',
  invalid: 'configurator.menu.customInstruments.form.tips.invalid'
} as const;

const errorObjFromString = (message: string): { invalid_type_error: string | undefined } => ({
  invalid_type_error: message
});

const formSchema = z.object({
  data: z.object({
    name: z
      .string(errorObjFromString(errorMessages['name']))
      .min(1, errorMessages['name'])
      .max(50, errorMessages['name']),
    width: z.coerce
      .number(errorObjFromString(errorMessages['width']))
      .positive(errorMessages['width'])
      .min(150, errorMessages['width'])
      .max(2000, errorMessages['width']),
    depth: z.coerce
      .number(errorObjFromString(errorMessages['depth']))
      .positive(errorMessages['depth'])
      .min(150, errorMessages['depth'])
      .max(750, errorMessages['depth']),
    height: z.coerce
      .number(errorObjFromString(errorMessages['height']))
      .positive(errorMessages['height'])
      .min(100, errorMessages['height'])
      .max(1000, errorMessages['height'])
  })
});

const resolver = zodResolver(formSchema);

interface CustomInstrumentFormProps {
  onSubmit: (dimensions: CustomInstrumentDimensions, name: string) => void;
  title?: React.ReactNode;
  className?: string;
}

export const CustomInstrumentForm = forwardRef<HTMLFormElement, CustomInstrumentFormProps>(
  ({ onSubmit, className, title }, ref) => {
    const [currentActiveInput, setCurrentActiveInput] = useState<FieldNameType | null>(null);
    const [submitted, setSubmitted] = useState(false);

    const form = useForm<z.infer<typeof formSchema>>({
      resolver: resolver,
      defaultValues: {
        data: {
          name: '',
          width: 400,
          depth: 400,
          height: 400
        }
      },
      mode: 'onChange',
      shouldFocusError: false
    });

    const handleOnInputBlur = () => setCurrentActiveInput(null);

    const handleOnSubmit = ({ data }: z.infer<typeof formSchema>): void => {
      setCurrentActiveInput(null);
      const { name, width, depth, height } = data;
      const dimensions: CustomInstrumentDimensions = {
        width: Number(width),
        depth: Number(depth),
        height: Number(height)
      };
      onSubmit(dimensions, name);
      form.reset();
    };

    const dimensionsInputAdornment: React.ReactNode = (
      <p className="typography-body1 max-w-[41px] pr-3 text-neutral-500">
        {localization.formatMessage('common.units.mm')}
      </p>
    );

    const errors: string[] = [];

    if (form.formState.errors && form.formState.errors.data) {
      const data = form.formState.errors.data;
      const existingErrors = allFields
        .filter(dim => dim in data)
        .map(dim => data[dim]!.message)
        .filter((message): message is string => message !== undefined);

      errors.push(...existingErrors);
    }

    const currentActiveInputError: string[] = [];
    if (currentActiveInput && currentActiveInput !== 'name') {
      currentActiveInputError.push(tipMessages[currentActiveInput]);
    } else if (submitted && form.formState.errors && form.formState.errors.data) {
      currentActiveInputError.push(tipMessages.invalid);
    } else {
      currentActiveInputError.push(tipMessages.default);
    }

    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className={cn('rounded bg-white p-6', className)} ref={ref}>
          {title}

          <hr className="mb-6 h-px w-full bg-neutral-100" />

          <FormField
            control={form.control}
            name="data.name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    maxLength={50}
                    placeholder={localization.formatMessage(
                      'configurator.menu.customInstruments.form.inputNamePlaceholder'
                    )}
                    onFocus={() => setCurrentActiveInput('name')}
                    {...field}
                    onBlur={() => {
                      handleOnInputBlur();
                      field.onBlur();
                    }}
                    onChange={event => {
                      setSubmitted(false);
                      field.onChange(event);
                    }}
                    highlightError={submitted}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="mt-4 flex min-h-12 flex-col items-center justify-center gap-y-2">
            <ErrorMessages errors={currentActiveInputError} />
          </div>

          <div className="mt-4 flex w-full gap-6 max-md:flex-col md:flex-row md:items-end ls:flex-col">
            <div className="grid w-full grid-cols-3 gap-x-2 gap-y-4 max-sm2:grid-cols-1 sm2:grid-cols-3 ls:grid-cols-1 xl:grid-cols-3">
              {dimensionsFields.map(dimension => (
                <FormField
                  key={dimension}
                  control={form.control}
                  name={`data.${dimension}`}
                  render={({ field }) => (
                    <FormItem className="flex w-full gap-x-4 gap-y-2 space-y-0 max-sm2:flex-row max-sm2:items-center max-sm2:justify-between max-sm2:gap-x-4 sm2:flex-col sm2:items-start ls:flex-row ls:items-center ls:justify-between xl:flex-col xl:items-start">
                      <FormLabel className="w-16 text-left !font-medium">
                        <FormattedMessage id={`common.dimensions.${dimension}`} />
                      </FormLabel>
                      <FormControl>
                        <Input
                          maxLength={5}
                          highlightError={submitted}
                          endAdornment={dimensionsInputAdornment}
                          {...field}
                          onFocus={() => setCurrentActiveInput(dimension)}
                          onBlur={() => {
                            handleOnInputBlur();
                            field.onBlur();
                          }}
                          onChange={event => {
                            setSubmitted(false);
                            field.onChange(event);
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              ))}
            </div>

            <Button
              type="submit"
              variant="outlined"
              className="h-fit w-full whitespace-nowrap disabled:opacity-30 max-ls:w-fit max-md:w-full"
              onClick={() => setSubmitted(true)}
            >
              <FormattedMessage id="configurator.menu.customInstruments.form.createButton" />
            </Button>
          </div>

          {submitted && (
            <div className="mt-3 flex flex-col items-center gap-y-3">
              <ErrorMessages errors={errors} highlightWithRed />
            </div>
          )}
        </form>
      </Form>
    );
  }
);

interface ErrorMessagesProps {
  errors: string[];
  highlightWithRed?: boolean;
}

const ErrorMessages: React.FC<ErrorMessagesProps> = ({ errors, highlightWithRed }) => {
  return (
    <>
      {errors.map(error => (
        <p
          key={error}
          className={cn(
            'typography-body2 w-full text-center text-neutral-500',
            highlightWithRed && 'text-primary-200 '
          )}
        >
          {localization.formatMessage(error)}
        </p>
      ))}
    </>
  );
};
