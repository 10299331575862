export function sortByTextField<T>(array: T[], fieldName: keyof T, order: 'asc' | 'desc' = 'asc'): T[] {
  return array.sort((a, b) => {
    const valueA = a[fieldName]?.toString().toLowerCase() ?? '';
    const valueB = b[fieldName]?.toString().toLowerCase() ?? '';

    if (order === 'asc') {
      return valueA === valueB ? 0 : valueA < valueB ? -1 : 1;
    } else {
      return valueA === valueB ? 0 : valueA > valueB ? -1 : 1;
    }
  });
}

export function deepEqual(first: unknown, second: unknown): boolean {
  return JSON.stringify(first) === JSON.stringify(second);
}
