import { Position, Segment, SegmentLine } from '../geometry';
import { middlePosition } from '../geometry/utils';
import { CameraView } from './types';

const defaultSegmentLine = { segments: [] };

export class CameraPositionService {
  lookAt: Position = { x: 0, y: 0 };
  segmentLine: SegmentLine = defaultSegmentLine;

  get state(): CameraView {
    return {
      lookAt: this.lookAt
    };
  }

  updateSegmentLine(segmentLine: SegmentLine) {
    const segments = segmentLine.segments;
    this.segmentLine = segmentLine;
    switch (segments.length) {
      case 1:
        this.positionLineShape(segments);
        break;
      case 2:
      case 3:
        this.positionToGeometryCenter(segments);
        break;
      default:
        this.lookAt = { x: 0, y: 0 };
    }
  }

  private positionLineShape(segments: Segment[]): void {
    const { end, start } = segments[0];
    this.lookAt = middlePosition(start, end);
  }

  updateByBackToBackSegmentLines(frontSegmentLine: SegmentLine, backSegmentLine: SegmentLine) {
    const frontSegments = frontSegmentLine.segments;
    const backSegments = backSegmentLine.segments;
    if (frontSegments.length === 1 && backSegments.length === 1) {
      const front = frontSegments[0];
      const back = backSegments[0];
      const start = middlePosition(front.start, back.start);
      const end = middlePosition(front.end, back.end);
      this.segmentLine = {
        segments: [
          {
            start,
            end,
            length: front.length,
            angle: front.angle
          }
        ]
      };
    } else {
      this.segmentLine = defaultSegmentLine;
    }
    this.updateSegmentLine(this.segmentLine);
  }

  private positionToGeometryCenter(segments: Segment[]): void {
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];
    this.lookAt = middlePosition(firstSegment.start, lastSegment.end);
  }
}
