import { IInstrument, ISection } from '../../schema';
import { InstrumentInsertionLayoutPayload, ElementPositionById, LayoutItem } from '../layout';
import { SectionInsertionLayoutPayload } from '../layout/types';

export enum ConfigurationMode {
  Predefined = 'predefined',
  Own = 'own'
}

export type SelectableElement = IInstrument | ISection;

export interface OverviewMode {
  type: 'overview';
  hoveredElement?: SelectableElement;
}

export interface InsertingInstrumentMode {
  type: 'insertingInstrument';
  installed: number;
  instrumentToAdd: IInstrument;
  highlightedInstrumentsPositions: LayoutItem<InstrumentInsertionLayoutPayload>[];
  hoveredPlaceholder?: LayoutItem<InstrumentInsertionLayoutPayload>;
}

export interface ReplacingInstrumentMode {
  type: 'replacingInstrument';
  instrumentToReplace: IInstrument;
  position: ElementPositionById;
  category: string;
  altInstruments: IInstrument[];
  hoveredElement?: SelectableElement;
}

export interface SelectedInstrumentMode {
  type: 'selectedInstrument';
  selectedInstrument: IInstrument;
  hoveredElement?: SelectableElement;
}

export interface SelectedBenchMode {
  type: 'selectedBench';
  selectedBench: ISection;
  hoveredElement?: SelectableElement;
}

export interface InsertingBenchMode {
  type: 'insertingBench';
  benchToAdd: ISection;
  highlightedBenchPositions: LayoutItem<SectionInsertionLayoutPayload>[];
  hoveredPlaceholder?: LayoutItem<SectionInsertionLayoutPayload>;
  installed: number;
}

export type SelectedMode = SelectedInstrumentMode | SelectedBenchMode;
export type InsertingMode = InsertingInstrumentMode | InsertingBenchMode;

export type ActiveMode =
  | OverviewMode
  | InsertingInstrumentMode
  | ReplacingInstrumentMode
  | SelectedInstrumentMode
  | SelectedBenchMode
  | InsertingBenchMode;
