import { queryString } from '../domain/share/services/queryString';

export enum IFrameMessageType {
  EMBEDDED = 'embedded',
  BOOTSTRAPPED = 'bootstrapped',
  CONFIGURATOR_READY = 'configurator_ready',
  LOCATION_REPLACE = 'location_replace',
  HUBSPOT_TRACK_EVENT = 'hubspot_track_event',
  HUBSPOT_LOGIN = 'hubspot_login',
  ADJUST_HEIGHT = 'adjust_height'
}

type IFrameMessageEventData<T extends IFrameMessageType> = {
  readonly type: T;
  readonly data: IFrameMessageEventPayload<T>;
};

type IFrameMessageEventPayload<T extends IFrameMessageType> = T extends IFrameMessageType.LOCATION_REPLACE
  ? { location: string }
  : unknown;

type IFrameMessageEvent<T extends IFrameMessageType> = MessageEvent & {
  data: IFrameMessageEventData<T>;
};

type IFrameMessageHandler<T extends IFrameMessageType> = (event: IFrameMessageEvent<T>) => void;

type IFrameMessageEventListener<T extends IFrameMessageType = any> = {
  once?: boolean;
  handler: IFrameMessageHandler<T>;
};

/**
 * Communication wrapper between iframe host and the application.
 *
 * Message channel: window.postMessage
 * When application is used outside of iframe, no message is sent or handled.
 *
 * @see EmbeddingService.isEmbedded
 * @see window.postMessage
 */
export class EmbeddingService {
  private readonly listenerMap = new Map<IFrameMessageType, IFrameMessageEventListener>();
  public location: Location = window.location;
  /**
   * Decide if we are working from inside the iframe
   */
  get isEmbedded() {
    return window !== window.parent;
  }

  constructor() {
    if (this.isEmbedded) {
      window.addEventListener('message', this.handleMessage);
    }
  }

  /**
   * Awaiting initial message from the host page.
   * Presumably waiting for host to finish initialization.
   */
  async awaitBootstrapping(): Promise<void> {
    if (!this.isEmbedded) {
      return;
    }

    // todo: consider extraction to periodicEvent method
    const handler = window.setInterval(() => {
      this.sendMessage(IFrameMessageType.EMBEDDED);
    }, 100);

    this.location = JSON.parse((await this.awaitEvent(IFrameMessageType.BOOTSTRAPPED)).data.location);

    window.clearInterval(handler);
  }

  sendReady() {
    this.sendMessage(IFrameMessageType.CONFIGURATOR_READY);
  }

  replaceLocation(location: string) {
    this.sendMessageWithPayload(IFrameMessageType.LOCATION_REPLACE, { location });
  }

  /**
   * Obtain page location data.
   * Get current window data for standalone version and parent window data for embedded one.
   *
   * @see window.location
   */

  /**
   * Send message to parent and wait for specific message in response.
   *
   * @param requestType app -> host request message type
   * @param responseType host -> app response message type
   * @private
   */
  private async asyncCall<T extends IFrameMessageType>(
    requestType: IFrameMessageType,
    responseType: T
  ): Promise<IFrameMessageEvent<T>> {
    this.sendMessage(requestType);
    return this.awaitEvent(responseType);
  }

  private sendMessage(type: IFrameMessageType) {
    if (!this.isEmbedded) {
      return;
    }

    window.parent.postMessage({ type }, '*');
  }

  sendMessageWithPayload<T extends IFrameMessageType>(type: T, payload: IFrameMessageEventPayload<T>) {
    if (!this.isEmbedded) {
      return;
    }

    const message = Object.assign({}, payload, { type });
    window.parent.postMessage(message, '*');
  }

  /**
   * Wait for specific message to be received.
   *
   * @param type message type to wait for
   * @private
   */
  private async awaitEvent(type: IFrameMessageType): Promise<IFrameMessageEvent<typeof type>> {
    return new Promise(resolve => {
      this.setMessageListener(
        type,
        event => {
          resolve(event);
        },
        true
      );
    });
  }

  private setMessageListener(type: IFrameMessageType, handler: IFrameMessageHandler<typeof type>, once?: boolean) {
    if (this.listenerMap.has(type)) {
      console.warn(`Overriding iframe message listener (type: ${type})`);
    }

    this.listenerMap.set(type, {
      handler,
      once
    });
  }

  private removeMessageListener(type: IFrameMessageType) {
    this.listenerMap.delete(type);
  }

  private handleMessage = <T extends IFrameMessageType>(event: IFrameMessageEvent<T>) => {
    const {
      data: { type }
    } = event;
    const listener = this.listenerMap.get(type);

    if (listener === undefined) {
      console.warn(`Unhandled iframe message (type: ${type})`);
      return;
    }

    listener.handler(event);

    if (listener.once) {
      this.removeMessageListener(type);
    }
  };

  sendHubspotEvent(event: any) {
    this.sendMessageWithPayload(IFrameMessageType.HUBSPOT_TRACK_EVENT, { event });
  }

  /*
     Returns referrer from query string, not from document.referrer, because
     It's not possible to use document.referrer from parent window for hubspot as all its links with no-referrer policy
   */
  get referrer() {
    return queryString(this.location.hash).referrer ?? '';
  }

  dispose() {
    window.removeEventListener('message', this.handleMessage);
  }
}
