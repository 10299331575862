import { Variants } from 'framer-motion';
import { useMediaQuery } from '../index';

const mobileImageAnimation: Variants = {
  exit: { transform: 'translateY(-50%)', opacity: 0 },
  whileInView: { transform: 'translateY(0%)', opacity: 1 }
};
const screenImageAnimation: Variants = {
  exit: { transform: 'translateX(100%)', opacity: 0 },
  whileInView: { transform: 'translateX(0%)', opacity: 1 }
};
const hiScreenImageAnimation: Variants = {
  exit: { transform: 'translateX(100%)', opacity: 0 },
  whileInView: { transform: 'translateX(0)', opacity: 1 }
};
const screenContentAnimation: Variants = {
  initial: { transform: 'translateY(-400px)', opacity: 0 },
  animate: { transform: 'translateY(0)', opacity: 1 },
  exit: { transform: 'translateY(-400px)', opacity: 0 }
};
const mobileContentAnimation: Variants = {
  initial: { transform: 'translateX(-400px)', opacity: 0 },
  animate: { transform: 'translateX(0)', opacity: 1 },
  exit: { transform: 'translateX(-400px)', opacity: 0 }
};
const duration = 0.2;

export const useFrameAnimationParameters = () => {
  const isScreen = useMediaQuery('(min-width: 1024px)');
  const isHiResScreen = useMediaQuery('(min-width: 1920px)');
  const imageAnimation = isHiResScreen
    ? hiScreenImageAnimation
    : isScreen
    ? screenImageAnimation
    : mobileImageAnimation;
  const contentAnimation = isScreen ? screenContentAnimation : mobileContentAnimation;

  return { imageAnimation, contentAnimation, duration };
};
