import { v4 } from 'uuid';
import { action, makeObservable, observable, toJS } from 'mobx';
import { ICustomInstrument, InstrumentCategory } from '../../schema';

export interface CustomInstrumentDimensions {
  width: number;
  depth: number;
  height: number;
}

export type SerializedCustomInstrument = Pick<ICustomInstrument, 'name' | 'width' | 'length' | 'height' | 'uuid'>;

export const CUSTOM_INSTRUMENT_MODEL_SIZE = 750;
export const CUSTOM_INSTRUMENT_VIEW = 'custom_instrument';
const CUSTOM_INSTRUMENT_IMAGE = 'assets/images/instruments/custom.png';

const optionIdSymbol = Symbol.for('__optionId__'); // hack, because custom instruments are not processed with engine
export const createCustomInstrumentWithOptionId = (source: ICustomInstrument, optionId: string): ICustomInstrument => {
  return {
    ...source,
    [optionIdSymbol]: optionId
  } as ICustomInstrument;
};

export class CustomInstrumentsService {
  private readonly storage: Storage = sessionStorage;
  private static readonly storageKey = 'automata_created_custom_instruments';
  public customInstruments: ICustomInstrument[] = [];
  public loaded = false;

  public constructor() {
    makeObservable(
      this,
      {
        customInstruments: observable,
        createCustomInstrument: action.bound,
        deserializeAndMergeWithExisting: action.bound
      },
      {
        deep: true
      }
    );
  }

  public init(): void {
    this.customInstruments = this.loadSavedInstruments();
    this.loaded = true;
  }

  public createCustomInstrument(dimensions: CustomInstrumentDimensions, name: string): void {
    const id = v4();

    const item: ICustomInstrument = createCustomInstrumentWithOptionId(
      {
        category: InstrumentCategory.custom,
        name: name,
        image: CUSTOM_INSTRUMENT_IMAGE,
        length: dimensions.width,
        width: dimensions.depth,
        uuid: id,
        view: CUSTOM_INSTRUMENT_VIEW,
        height: dimensions.height
      },
      id
    );

    this.customInstruments.push(item);
    this.saveCreatedInstruments();
  }

  private loadSavedInstruments(): ICustomInstrument[] {
    const savedItem = this.storage.getItem(CustomInstrumentsService.storageKey);

    if (!savedItem) {
      return [];
    }

    try {
      const parsed = JSON.parse(savedItem);

      if (!Array.isArray(parsed)) {
        return [];
      }

      return parsed
        .map(CustomInstrumentsService.deserialize)
        .map(instrument => createCustomInstrumentWithOptionId(instrument, instrument.uuid));
    } catch (error) {
      console.error('Error in parsing saved custom instruments. ', error);
    }

    return [];
  }

  private saveCreatedInstruments(): void {
    const serialized = JSON.stringify(this.customInstruments.map(CustomInstrumentsService.serialize));
    this.storage.setItem(CustomInstrumentsService.storageKey, serialized);
  }

  public static serialize(instrument: ICustomInstrument): SerializedCustomInstrument {
    return {
      width: instrument.width,
      name: instrument.name,
      height: instrument.height,
      uuid: instrument.uuid,
      length: instrument.length
    };
  }

  private static deserialize(instrument: SerializedCustomInstrument): ICustomInstrument {
    return {
      ...instrument,
      view: CUSTOM_INSTRUMENT_VIEW,
      image: CUSTOM_INSTRUMENT_IMAGE,
      category: InstrumentCategory.custom
    };
  }

  public deserializeAndMergeWithExisting(serializedInstruments: SerializedCustomInstrument[]): void {
    serializedInstruments.forEach(instrument => {
      if (this.customInstruments.find(i => i.uuid === instrument.uuid)) {
        return;
      }
      const customInstrument = CustomInstrumentsService.deserialize(instrument);
      const withOption = createCustomInstrumentWithOptionId(customInstrument, customInstrument.uuid);
      this.customInstruments.push(withOption);
    });
  }
}
