import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from './utils';

const iconButtonVariants = cva(
  'ring-offset-background group  inline-flex items-center justify-center    text-sm  font-medium  uppercase  transition-colors   focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2   disabled:pointer-events-none disabled:opacity-50 ',
  {
    variants: {
      size: {
        md: 'h-10 w-10',
        lg: 'h-12 w-12'
      },

      variant: {
        filled: 'rounded bg-neutral-0 hover:text-primary-500 active:text-primary-900',
        menu: 'min-w-12 flex h-[70px] flex-col capitalize ',
        clear: ''
      },
      color: {
        default: 'text-neutral-900 hover:text-primary-500 active:text-primary-900 [&>svg]:fill-neutral-900',
        primary: 'text-neutral-900 hover:text-primary-500 active:text-primary-600  [&>svg]:fill-primary-600',
        secondary: 'text-neutral-900 hover:text-secondary-500 active:text-secondary-600 '
      },
      active: {
        true: '',
        false: ''
      }
    },

    compoundVariants: [
      { color: 'primary', active: true, class: 'text-primary-900 [&>svg]:fill-primary-500' },
      { color: 'secondary', active: true, class: 'text-secondary-600 hover:text-secondary-600' }
    ],
    defaultVariants: {
      size: 'md',
      color: 'default',
      active: false
    }
  }
);
export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof iconButtonVariants> & {
    className?: string;
    asChild?: boolean;
  };
const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, size, variant, color, active, asChild = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp className={cn(iconButtonVariants({ size, className, variant, color, active }))} ref={ref} {...props}>
        {children}
      </Comp>
    );
  }
);

IconButton.displayName = 'IconButton';
export { IconButton };
