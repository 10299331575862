import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components';
import { useFrameAnimationParameters } from '../../components/hooks/useFrameAnimationParameters';
import { routes } from '../../routes';
import BGFull from './bg-full.png';
import { rootStore } from 'stores/RootStore';
import { ExitIntentPageName, OwnConfigEventGA, PredefinedConfigEventGA } from 'services/GoogleAnalyticsService';
import { ExitIntentProvider, useExitIntent } from '../../components/hooks/useExitIntent';
import { ReactNode, useEffect } from 'react';
import HubspotContactForm from './HubspotForm';
import { useHeightReporter } from '../../components/hooks/useHeightReporter';
import './IndexPage.css';

export const IndexPageMenu = () => {
  return (
    <>
      <h1 className="mb-12 mt-10 text-[2.5rem]/[3rem] font-extrabold max-ls:mr-auto md:text-[3.125rem]/[3.75rem] lg:text-[3.75rem]/[4.375rem]">
        <FormattedMessage id="indexPage.title" />
      </h1>
      <p className="typography-paragraph1 mb-8 w-full">
        <FormattedMessage id="indexPage.description.paragraphOne" />
      </p>
      <p className="typography-paragraph1 mb-8 w-full">
        <FormattedMessage id="indexPage.description.paragraphTwo" />
      </p>
      <p className="typography-paragraph1 mb-22 w-full">
        <FormattedMessage
          id="indexPage.description.paragraphThree"
          values={{
            link: (
              <a className="automata-link" href="https://automata.tech/products/software/">
                <FormattedMessage id="indexPage.description.paragraphThreeLink" />
              </a>
            )
          }}
        />
      </p>
      <div className="mb-12 flex flex-col gap-6">
        <Button asChild size="lg" className="text-center">
          <Link
            to={routes.own.link()}
            onClick={() => {
              rootStore.gaEvent(new OwnConfigEventGA(), "Configurator's home page");
            }}
          >
            <FormattedMessage id="indexPage.buttonOwn" />
          </Link>
        </Button>

        <Button asChild size="lg" className="text-center" variant="outlined">
          <Link
            to={routes.predefined.link()}
            onClick={() => {
              rootStore.gaEvent(new PredefinedConfigEventGA(), "Configurator's home page");
            }}
          >
            <FormattedMessage id="indexPage.buttonPredefined" />
          </Link>
        </Button>
      </div>
    </>
  );
};
export const IndexPageLayout = ({ children }: { children: ReactNode }) => {
  const { imageAnimation, contentAnimation, duration } = useFrameAnimationParameters();

  useHeightReporter();

  useEffect(() => {
    rootStore.gaPageView("Configurator's home page");
  }, []);

  useExitIntent();

  return (
    <ExitIntentProvider page={ExitIntentPageName.HomePage} rootStore={rootStore}>
      <div className="flex flex-col flex-nowrap ls:flex-row ls:items-stretch">
        <motion.div
          className="w-full object-cover ls:order-1 ls:w-1/2 ls:shrink-0 ls:grow-0 xl:w-7/12"
          variants={imageAnimation}
          whileInView="whileInView"
          exit="exit"
          transition={{ duration: duration }}
        >
          <img src={BGFull} className="w-full object-cover object-left ls:h-full" alt="" />
        </motion.div>

        <motion.div
          className=" ls:order-0 flex  w-full flex-col items-center justify-center ls:min-h-screen ls:w-1/2 ls:flex-shrink-0 ls:flex-grow-0 xl:w-5/12"
          variants={contentAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: duration }}
        >
          <div className="relative mx-4 grid h-full grid-cols-2 gap-2 sm:mx-6 sm:grid md:grid-cols-6 ls:grid-cols-6">
            <div className="col-span-2 flex flex-col items-center justify-center md:col-start-2 md:col-end-6 ls:items-start ">
              {children}
            </div>
          </div>
        </motion.div>
      </div>
    </ExitIntentProvider>
  );
};

export const IndexPage = (): JSX.Element => {
  return (
    <IndexPageLayout>
      <IndexPageMenu />
    </IndexPageLayout>
  );
};

export const UserFormPage = ({ onSubmitted }: { onSubmitted: () => void }): JSX.Element => {
  return (
    <IndexPageLayout>
      <HubspotContactForm onSubmitted={onSubmitted} />
    </IndexPageLayout>
  );
};
