import * as React from 'react';
import QRCodeGenerator from 'qrcode';

interface IProps {
  className?: string;
  data?: string;
}

export const QRCode = (props: IProps) => {
  const { className, data } = props;
  const [codeURL, setCodeURL] = React.useState<string>('');

  React.useEffect(() => {
    if (data) {
      QRCodeGenerator.toDataURL(data).then(setCodeURL);
    } else {
      setCodeURL('');
    }
  }, [data, setCodeURL]);

  return <img className={className} alt="QR-code" src={codeURL} />;
};
