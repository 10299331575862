import * as React from 'react';
import { useMemo } from 'react';

import { cn } from 'components/utils';

export interface ChipProps extends React.HTMLAttributes<HTMLButtonElement> {
  value: string;
  validator?: (value: string) => boolean;
}

const Chip = React.forwardRef<HTMLButtonElement, ChipProps>(({ className, value, validator, ...props }, ref) => {
  const isValid = useMemo(() => (validator ? validator(value) : true), [validator, value]);

  return (
    <button
      className={cn(
        'typography-body2 rounded-full bg-blue-50 px-2 py-0.5  focus-visible:outline-none   disabled:cursor-not-allowed disabled:opacity-50',
        'hover:bg-blue-100',
        'focus-visible:outline-blue-200 active:bg-blue-200',
        { 'bg-pink-50 hover:bg-pink-100 focus-visible:outline-pink-200 active:bg-pink-200': !isValid },
        className
      )}
      type="button"
      ref={ref}
      {...props}
    >
      {value}
    </button>
  );
});
Chip.displayName = 'Chip';

export { Chip };
