import { Button, Icon } from '../../../../../components';
import { Loader } from '../../../../../components/Loader/Loader';
import { cn } from '../../../../../components/utils';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from '../../../../../components/Button';
import { rootStore } from '../../../../../stores/RootStore';

interface ISavePdfButtonProps extends ButtonProps {
  loading?: boolean;
  onClick: () => void;
}

export const SavePdfButton = ({ loading = false, variant = 'clear', onClick }: ISavePdfButtonProps) => {
  if (rootStore.isExhibition) {
    return null;
  }

  return (
    <Button
      disabled={loading}
      className="relative max-sm2:flex-1 max-sm2:px-3"
      size="lg"
      variant={variant}
      withIcon
      onClick={onClick}
    >
      <Loader className={cn('flex items-center justify-center', loading ? 'absolute' : 'hidden')} />
      <div className={cn('flex items-center justify-center gap-2', loading ? 'invisible' : '')}>
        <Button.ButtonIcon className="max-sm2:mr-0">
          <Icon icon="save_alt" />
        </Button.ButtonIcon>
        <Button.ButtonText className="m-auto h-full leading-normal max-sm2:hidden">
          <span className="align-middle">
            <FormattedMessage id="summary.savePdfButton" />
          </span>
        </Button.ButtonText>
      </div>
    </Button>
  );
};
