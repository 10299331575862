import { GetStepConfig } from '../../../components/Onboarding/types';
import { getRectangleFromCanvas } from '../../../components/Onboarding/helpers';
import { IInstrument } from '../../../schema';
import { CSSProperties } from 'react';
import { ConfigurationOnboardingStepData } from './types';
import { MobileDetector } from '../../../components/hooks/useMobileDetector';
import { localization } from '../../../stores/Localization';

const spotlightBaseStyles = {
  borderRadius: '50%',
  padding: '0 15px',
  boxSizing: 'content-box',
  transform: 'translate(-10px, 0)'
} satisfies CSSProperties;

const waitUntil = <T>(condition: () => T, maxWaitTimeout = 1000): Promise<T> => {
  return new Promise((resolve, reject) => {
    const currentValue = condition();
    if (currentValue) {
      resolve(currentValue);
      return;
    }
    const attemptNumber = 0;
    const startTime = Date.now();
    const intervalId = setInterval(() => {
      const result = condition();
      if (result) {
        clearInterval(intervalId);
        resolve(result);
      } else if (Date.now() - startTime > maxWaitTimeout) {
        clearInterval(intervalId);
        reject();
      }
    }, attemptNumber * 50);
  });
};
export const firstOpenUShapeSteps: GetStepConfig<ConfigurationOnboardingStepData> = (
  mobileDetector: MobileDetector
) => [
  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.1'),
    placement: mobileDetector.isMobile ? 'top' : 'right',
    spotlightPadding: mobileDetector.isMobile ? 15 : 23,
    offset: 35,
    styles: {
      spotlight: spotlightBaseStyles
    },
    target: '[data-onboarding="instrument-button"]',
    showSkipButton: false,
    disableBeacon: true
  },
  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.2'),
    placement: mobileDetector.isMobile ? 'top' : 'right',
    spotlightPadding: 25,
    styles: {
      spotlight: spotlightBaseStyles
    },
    target: '[data-onboarding="summary-button"]',
    offset: 35,
    showSkipButton: false,
    disableBeacon: true
  },

  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.3'),
    placement: mobileDetector.isMobile ? 'bottom' : 'right',
    spotlightPadding: mobileDetector.isMobileScreenSize ? 10 : 25,
    styles: {
      spotlight: mobileDetector.isMobileScreenSize ? { borderRadius: '50%' } : spotlightBaseStyles
    },
    floaterProps: {
      styles: {
        floater: {
          marginBottom: mobileDetector.isMobileScreenSize ? '10px' : '160px'
        }
      }
    },
    target: mobileDetector.isMobileScreenSize
      ? '[data-onboarding="help-button-mobile"]'
      : '[data-onboarding="help-button"]',
    offset: mobileDetector.isMobileScreenSize ? 10 : 40,
    showSkipButton: false,
    disableBeacon: true
  },
  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.4'),
    placement: 'left',
    target: '.onboarding-bench-highlight',
    spotlightPadding: 15,
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    doBeforeStep: async ({ store, viewer }) => {
      return {
        getCustomRectTarget: () => {
          const nodes = viewer.scene.getObjectsByGroupId(store.getLastBench()?.uuid as string);
          return getRectangleFromCanvas(nodes, viewer.camera, viewer.size);
        }
      };
    },

    showSkipButton: false,
    disableBeacon: true
  },

  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.5'),
    placement: 'top',
    target: '[data-onboarding="bench-action-panel"]',
    showSkipButton: false,
    disableBeacon: true,
    doBeforeStep: async ({ store }) => {
      store.enterSelectedBench(store.getLastBench() as any);
      return { delay: 1000 };
    },
    doAfterStep: ({ store }) => {
      store.enterOverviewMode();
    }
  }
];

export const firstOpenSteps: GetStepConfig<ConfigurationOnboardingStepData> = (mobileDetector: MobileDetector) => [
  {
    content: localization.formatMessage('configurator.onboarding.firstOpen.1'),
    spotlightPadding: 23,
    target: '[data-onboarding="bench-button"]',
    showSkipButton: false,
    disableBeacon: true,
    styles: {
      spotlight: spotlightBaseStyles
    },
    offset: 35,
    placement: mobileDetector.isMobile ? 'top' : 'right'
  },
  ...firstOpenUShapeSteps(mobileDetector)
];

export const whenInstrumentInstalledSteps: GetStepConfig<ConfigurationOnboardingStepData> = mobileDetector => [
  {
    content: localization.formatMessage('configurator.onboarding.instrumentsInstalled.1'),
    placement: 'top',
    target: '.onboarding-instrument-highlight',
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    spotlightPadding: 5,
    doBeforeStep: async ({ store, viewer }) => {
      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getFirstInstrumentNode();
          if (!node) {
            throw new Error('Node not found');
          }
          return getRectangleFromCanvas([node], viewer.camera, viewer.size);
        }
      };
    },

    showSkipButton: false,
    disableBeacon: true
  },
  {
    content: localization.formatMessage('configurator.onboarding.instrumentsInstalled.2'),
    placement: 'top',
    target: '[data-onboarding="instrument-action-panel"]',
    showSkipButton: false,
    disableBeacon: true,
    doBeforeStep: async ({ store }) => {
      store.enterSelectedInstrument(store.getFirstInstrument() as IInstrument);
      return { delay: 1000 };
    },
    doAfterStep: ({ store }) => {
      store.enterOverviewMode();
    }
  }
];

export const whenInstallationActivatedSteps: GetStepConfig<ConfigurationOnboardingStepData> = (
  mobileDetector: MobileDetector
) => [
  {
    content: localization.formatMessage('configurator.onboarding.installationActivated.1'),
    placement: 'top',
    target: '.onboarding-bench-placeholder',
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    spotlightPadding: 15,
    doBeforeStep: async ({ store, viewer }) => {
      await waitUntil(() => viewer.scene.getBenchPlaceholder());
      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getBenchPlaceholder();
          if (!node) {
            throw new Error('Bench placeholder not found');
          }
          return getRectangleFromCanvas([node], viewer.camera, viewer.size, viewer.scene);
        }
      };
    },

    showSkipButton: false,
    disableBeacon: true
  },
  {
    content: localization.formatMessage('configurator.onboarding.installationActivated.2'),
    placement: 'top',
    target: '.onboarding-bench-siluate',
    showSkipButton: false,
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    doBeforeStep: async ({ store, viewer }) => {
      await waitUntil(() => viewer.scene.getBenchInsertion());

      store.highlightBench();
      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getBenchInsertion();

          if (!node) {
            throw new Error('Bench placeholder not found');
          }
          return getRectangleFromCanvas([node], viewer.camera, viewer.size, viewer.scene);
        }
      };
    },
    doAfterStep: ({ store }) => {
      store.resetHighlightInsertionHover();
    }
  }
];

export const whenInstrumentInstallationActivatedSteps: GetStepConfig<
  ConfigurationOnboardingStepData
> = mobileDetector => [
  {
    content: localization.formatMessage('configurator.onboarding.installationActivated.1'),
    placement: 'top',
    target: '.onboarding-bench-placeholder',
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    spotlightPadding: 15,
    doBeforeStep: async ({ store, viewer }) => {
      await waitUntil(() => viewer.scene.getInstrumentPlaceholder());
      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getInstrumentPlaceholder();
          if (!node) {
            throw new Error('Instrument placeholder not found');
          }
          return getRectangleFromCanvas([node], viewer.camera, viewer.size, viewer.scene);
        }
      };
    },

    showSkipButton: false,
    disableBeacon: true
  },
  {
    content: localization.formatMessage('configurator.onboarding.installationActivated.2'),
    placement: 'top',
    target: '.onboarding-instrument-siluate',
    showSkipButton: false,
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    doBeforeStep: async ({ store, viewer }) => {
      await waitUntil(() => viewer.scene.getInstrumentInsertion());

      store.highlightInstrument();
      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getBenchInsertion();

          if (!node) {
            throw new Error('Instrument placeholder not found');
          }
          return getRectangleFromCanvas([node], viewer.camera, viewer.size, viewer.scene);
        }
      };
    },
    doAfterStep: ({ store }) => {
      store.resetHighlightInsertionHover();
    }
  }
];

export const whenInstrumentInstallationActivatedStepsOnMobile: GetStepConfig<
  ConfigurationOnboardingStepData
> = mobileDetector => [
  {
    content: localization.formatMessage('configurator.onboarding.installationActivatedMobile.1'),
    placement: 'top',
    target: '.onboarding-instrument-siluate',
    showSkipButton: false,
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    doBeforeStep: async ({ store, viewer }) => {
      await waitUntil(() => viewer.scene.getInstrumentInsertionOnMobile());

      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getInstrumentInsertionOnMobile();

          if (!node) {
            throw new Error('Instrument placeholder not found');
          }
          return getRectangleFromCanvas([node], viewer.camera, viewer.size, viewer.scene);
        }
      };
    }
  }
];
export const whenInstallationActivatedStepsOnMobile: GetStepConfig<
  ConfigurationOnboardingStepData
> = mobileDetector => [
  {
    content: localization.formatMessage('configurator.onboarding.installationActivatedMobile.1'),
    placement: 'top',
    target: '.onboarding-bench-siluate',
    showSkipButton: false,
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    doBeforeStep: async ({ store, viewer }) => {
      await waitUntil(() => viewer.scene.getBenchInsertionForMobile());

      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getBenchInsertionForMobile();

          if (!node) {
            throw new Error('Bench placeholder not found');
          }
          return getRectangleFromCanvas(node, viewer.camera, viewer.size, viewer.scene);
        }
      };
    }
  }
];

export const firstOpenPredefined: GetStepConfig<ConfigurationOnboardingStepData> = mobileDetector => [
  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.2'),
    placement: mobileDetector.isMobile ? 'top' : 'right',
    spotlightPadding: 25,
    styles: {
      spotlight: spotlightBaseStyles
    },
    target: '[data-onboarding="summary-button"]',
    offset: 35,
    showSkipButton: false,
    disableBeacon: true
  },

  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.3'),
    placement: mobileDetector.isMobile ? 'bottom' : 'right',
    spotlightPadding: mobileDetector.isMobileScreenSize ? 10 : 25,
    styles: {
      spotlight: mobileDetector.isMobileScreenSize ? { borderRadius: '50%' } : spotlightBaseStyles
    },
    floaterProps: {
      styles: {
        floater: {
          marginBottom: mobileDetector.isMobileScreenSize ? '10px' : '160px'
        }
      }
    },
    target: mobileDetector.isMobileScreenSize
      ? '[data-onboarding="help-button-mobile"]'
      : '[data-onboarding="help-button"]',
    offset: mobileDetector.isMobileScreenSize ? 10 : 40,
    showSkipButton: false,
    disableBeacon: true
  },
  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.4'),
    placement: mobileDetector.isMobile ? 'right' : 'top',
    target: '.onboarding-instrument-highlight',
    styles: {
      spotlight: {
        borderRadius: '50%'
      }
    },
    spotlightPadding: 5,
    doBeforeStep: async ({ store, viewer }) => {
      return {
        getCustomRectTarget: () => {
          const node = viewer.scene.getFirstInstrumentNode();
          if (!node) {
            throw new Error('Node not found');
          }
          return getRectangleFromCanvas([node], viewer.camera, viewer.size);
        }
      };
    },

    showSkipButton: false,
    disableBeacon: true
  },
  {
    content: localization.formatMessage('configurator.onboarding.firstOpenUShape.5'),
    placement: 'top',
    target: '[data-onboarding="instrument-action-panel"]',
    showSkipButton: false,
    disableBeacon: true,
    doBeforeStep: async ({ store }) => {
      store.enterSelectedInstrument(store.getFirstInstrument() as IInstrument);
      return { delay: 1000 };
    },
    doAfterStep: ({ store }) => {
      store.enterOverviewMode();
    }
  }
];
