import { Object3D, Vector3 } from 'three';

import { BenchLayoutModel, LayoutItem, SectionInsertionLayoutPayload } from './types';
import { sectionTypeGuard } from '../typeGuards';
import { Angle, ConnectionJoin, ConnectionSide, ILine, ISection, SectionAlignment } from '../../schema';
import { round } from '../math';
import {
  createSegmentLine,
  distance,
  getOrigin,
  roundPosition,
  Segment,
  SegmentLine,
  shiftBasis,
  sumAngle,
  toRadians
} from '../geometry';

/***
 * Default orientation for bench - R
 */
export class BenchLayoutService {
  public readonly items: LayoutItem[] = [];
  public readonly segmentLine: SegmentLine = { segments: [] };
  private readonly startBasis: Object3D; // metadata for future determining of possible free space
  private readonly startAngle: Angle;
  private readonly finalAngle: Angle;
  private readonly finalBasis: Object3D; // metadata for future determining of possible free space

  constructor(
    private model: BenchLayoutModel,
    private line: Omit<ILine, 'instruments'>,
    private readonly isBackToBack = false
  ) {
    const result: LayoutItem[] = [];
    const W = this.model.benchWidth;
    const L = this.model.benchLength;
    const RL = this.model.railLength;
    const MBW = this.model.manipulatorBenchWidth;

    let basis = new Object3D();
    basis.position.z = line.position.y; //W * lineNo;
    basis.position.x = line.position.x;
    basis.rotation.y = toRadians(line.angle);

    this.startBasis = basis.clone();
    basis = shiftBasis(basis, 0, new Vector3(0.5 * L, 0, 0));

    let angle: Angle = line.angle;
    this.startAngle = angle;
    let prevAngle = false;
    const segmentLine = createSegmentLine();

    for (let sectionNo = 0; sectionNo < line.geometry.length; sectionNo++) {
      const section = line.geometry[sectionNo];
      if (sectionTypeGuard(section)) {
        const sectionId = section.uuid;
        prevAngle = false;
        let itemAngle: Angle = angle;
        let equipmentZ = 0.5 * W + 0.5 * MBW;
        if (section.alignment === SectionAlignment.L) {
          itemAngle = sumAngle(itemAngle, 180);
          equipmentZ *= -1;
        }
        if (section.hasManipulator && section.size > 0) {
          const manipulatorHand = new Vector3(0.5 * L - 0.5 * RL + 0.5 * MBW, 0, equipmentZ);
          basis.localToWorld(manipulatorHand);
          result.push({
            groupId: sectionId,
            centerX: round(manipulatorHand.x),
            centerY: round(manipulatorHand.z),
            view: this.model.manipulatorView,
            angle: itemAngle,
            payload: {
              type: 'section',
              section
            }
          });
        }
        if (section.hasManipulator && section.size === 2) {
          const manipulatorRail = new Vector3(0.5 * L, 0, equipmentZ);
          basis.localToWorld(manipulatorRail);
          result.push({
            groupId: sectionId,
            centerX: round(manipulatorRail.x),
            centerY: round(manipulatorRail.z),
            view: this.model.manipulatorRailView,
            angle: itemAngle,
            payload: {
              type: 'section',
              section
            }
          });
        }
        for (let i = 0; i < section.size; i++) {
          const position = getOrigin(basis);
          const layoutItem: LayoutItem = {
            groupId: sectionId,
            centerX: round(position.x),
            centerY: round(position.z),
            view: section.hasTransport ? this.model.transportBenchView : this.model.benchView,
            angle: itemAngle,
            payload: {
              type: 'section',
              section
            }
          };
          result.push(layoutItem);
          const start = basis.localToWorld(new Vector3(-0.5 * L + 0.5 * W, 0, 0));
          const end = basis.localToWorld(new Vector3(0.5 * L - 0.5 * W, 0, 0));
          const segment: Segment = {
            start: roundPosition({ x: start.x, y: start.z }),
            end: roundPosition({ x: end.x, y: end.z }),
            angle: itemAngle,
            length: 0
          };
          segment.length = distance(segment.start, segment.end);
          segmentLine.merge(segment);
          if (section.hasManipulator) {
            const manipulatorBench = new Vector3(0, 0, equipmentZ);
            basis.localToWorld(manipulatorBench);
            result.push({
              groupId: sectionId,
              centerX: round(manipulatorBench.x),
              centerY: round(manipulatorBench.z),
              view: this.model.manipulatorBenchView,
              angle: itemAngle,
              payload: {
                type: 'section',
                section
              }
            });
          }
          basis = shiftBasis(basis, 0, new Vector3(L, 0, 0));
        }
      } else {
        if (prevAngle) {
          basis = shiftBasis(basis, 0, new Vector3(L, 0, 0));
        }
        prevAngle = true;
        if (section.join === ConnectionJoin.along) {
          const basisAngle = section.side === ConnectionSide.R ? -90 : 90;
          angle = sumAngle(angle, basisAngle);
          basis = shiftBasis(
            basis,
            toRadians(basisAngle),
            section.side === ConnectionSide.R
              ? new Vector3(-0.5 * W + 0.5 * L, 0, 0.5 * L - 0.5 * W)
              : new Vector3(-0.5 * W + 0.5 * L, 0, -0.5 * L + 0.5 * W)
          );
        }
        if (section.join === ConnectionJoin.aside) {
          const basisAngle = section.side === ConnectionSide.R ? -90 : 90;
          angle = sumAngle(angle, basisAngle);
          basis = shiftBasis(
            basis,
            toRadians(basisAngle),
            section.side === ConnectionSide.R
              ? new Vector3(0.5 * L + 0.5 * W, 0, 0.5 * L + 0.5 * W)
              : new Vector3(0.5 * L + 0.5 * W, 0, -0.5 * L - 0.5 * W)
          );
        }
      }
    }

    this.finalBasis = shiftBasis(basis, 0, new Vector3(-0.5 * L, 0, 0));

    this.finalAngle = angle;
    this.segmentLine = segmentLine.build();
    this.items = result;
  }

  public getPositionsToAppendBench(bench: ISection): LayoutItem<SectionInsertionLayoutPayload>[] {
    const bases: Array<[Object3D, Angle, number]> = [
      [this.startBasis, this.startAngle, -1],
      [this.finalBasis, this.finalAngle, 1]
    ];

    return bases.map(([basis, angle, dir]): LayoutItem<SectionInsertionLayoutPayload> => {
      const position = new Vector3(dir * 0.5 * bench.size * this.model.benchLength, 0, 0);
      basis.localToWorld(position);

      return {
        payload: {
          type: 'sectionInsertion',
          section: bench,
          position: {
            type: dir === -1 ? 'start' : 'end',
            line_uuid: this.line.uuid
          },
          backToBack: this.isBackToBack
        },
        view: this.model.benchView,
        groupId: '',
        centerX: position.x,
        centerY: position.z,
        angle: angle
      };
    });
  }
}
