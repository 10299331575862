import { AsyncYamlDatasetService, DatasetService } from '@canvas-logic/engine';
import { ImagesLoader, MediaPathResolver, ModelLoader, ResourceLoader } from '../domain/media';
import { queryString } from '../domain/share/services/queryString';
import { EmbeddingService } from '../services';
import { EventGA, GoogleAnalyticsService, PageViewTitle } from 'services/GoogleAnalyticsService';
import { HubspotService, IHubspotEvent } from '../services/HubspotService';

export class RootStore {
  public datasetService!: DatasetService;
  public resourceLoader!: ResourceLoader;
  public defaultProducts = '';
  private gaService?: GoogleAnalyticsService;
  private mediaPathResolver!: MediaPathResolver;
  private modelLoader!: ModelLoader;
  readonly embeddingService = new EmbeddingService();
  readonly hubspotService = new HubspotService(this.embeddingService);

  private schema = '';
  private options = '';

  async init() {
    this.mediaPathResolver = await MediaPathResolver.create();
    this.modelLoader = new ModelLoader(this.mediaPathResolver);
    this.resourceLoader = new ResourceLoader(this.modelLoader, new ImagesLoader());
    const [schema, options, products] = await loadYamls();
    this.schema = schema;
    this.options = options;
    this.defaultProducts = products;
    this.datasetService = await AsyncYamlDatasetService.create(schema, [options, products], []);

    await this.embeddingService.awaitBootstrapping();
    this.embeddingService.sendReady();

    this.gaService = new GoogleAnalyticsService();
    await this.gaService.init();
    try {
      this.hubspotService.validateConfiguration();
    } catch (error) {
      console.error(error);
    }
  }

  public isEmbedded(): boolean {
    return this.embeddingService.isEmbedded;
  }

  async updateProducts(value: any) {
    this.datasetService = await AsyncYamlDatasetService.create(this.schema, [this.options, value], []);
  }

  get location(): Readonly<Location> {
    return this.embeddingService.location;
  }

  replaceHostLocation(location: string) {
    if (rootStore.isEmbedded()) {
      this.embeddingService.replaceLocation(location);
    } else {
      window.location.replace(location);
    }
  }

  getLink(): string {
    const params = queryString(this.location.hash);
    return params.link ?? '';
  }

  hubspotTrackEvent(event: IHubspotEvent) {
    this.hubspotService.sendHubspotEvent(event);
  }

  gaEvent(event: EventGA, page: PageViewTitle) {
    if (this.gaService) {
      this.gaService.event(event, page);
    }
  }

  gaPageView(page: PageViewTitle) {
    if (this.gaService) {
      this.gaService.pageView(page);
    }
  }

  get isLoginFormEnabled(): boolean {
    return this.hubspotService.isLoginFormEnabled && !this.isExhibition;
  }

  get isExhibition(): boolean {
    return process.env.REACT_APP_CL__ENV_SLAS === 'true';
  }
}

async function loadYamls() {
  try {
    const [schema, all] = await Promise.all([
      fetch('assets/dataset/schema.yml').then(r => r.text()),
      fetch(`assets/dataset/automata.catalog.yml`).then(r => r.text())
    ]);
    // TODO: Remove after
    const options = [];
    const products = [];
    for (const doc of all.split('---')) {
      if (doc.includes('kind: Option')) {
        options.push(doc);
      } else {
        products.push(doc);
      }
    }
    return [schema, options.join('---'), products.join('---')];
  } catch (error) {
    throw new Error('Error downloading one or more files:' + error);
  }
}

export const rootStore = new RootStore();
