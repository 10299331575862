import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getOptionId } from '@canvas-logic/engine';
import { FormattedMessage } from 'react-intl';
import { InstrumentsByCategoriesAccordionList } from '../InstrumentsByCategoriesAccordionList';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../../../components';
import { ICustomInstrument, IInstrument, InstrumentCategory } from '../../../../../schema';
import { CollapsibleCustomInstrumentForm } from '../CollapsibleCustomInstrumentForm';
import { cn, getDimensionsString, scrollIntoNestedView } from '../../../../../components/utils';
import { customInstrumentsCardsListStyle } from '../InstrumentsInsertionPanel';
import { HorizontalElementCard } from '../../../../../components/ElementCard';
import { localization } from '../../../../../stores/Localization';
import { ReplacingInstrumentMode } from '../../../../../domain/modes';
import { InstrumentsByCategories } from '../../../../../stores/types';
import { CustomInstrumentDimensions } from '../../../../../domain/instruments';
import { useIsMobile } from '../../../../../components/hooks/useIsMobile';

interface CustomInstrumentReplacementPanelProps {
  standardInstruments: InstrumentsByCategories;
  customInstruments: ICustomInstrument[];
  mode: ReplacingInstrumentMode;

  onToggle: (instrument: IInstrument, selected: boolean) => void;
  isAvailable: (instrument: IInstrument) => boolean;
  createCustomInstrument: (dimensions: CustomInstrumentDimensions, name: string) => void;
}
export const CustomInstrumentReplacementPanel: React.FC<CustomInstrumentReplacementPanelProps> = observer(
  ({ standardInstruments, mode, onToggle, isAvailable, createCustomInstrument, customInstruments }) => {
    const [accordionValue, setAccordionValue] = useState<string>(InstrumentCategory.custom);
    const [justCreatedInstrument, setJustCreatedInstrument] = useState(false);
    const customInstrumentsBlockRef = useRef<HTMLDivElement>(null);

    const isMobile = useIsMobile();

    useLayoutEffect(() => {
      if (!isMobile && customInstrumentsBlockRef.current) {
        scrollIntoNestedView(customInstrumentsBlockRef.current);
      }
    }, []);

    const onAccordionValueChange = (value: string): void => {
      setAccordionValue(value);
      setJustCreatedInstrument(false);
    };

    const createCustomInstrumentHandler = (dimensions: CustomInstrumentDimensions, name: string): void => {
      createCustomInstrument(dimensions, name);
      setJustCreatedInstrument(true);
    };

    return (
      <Accordion
        type="single"
        collapsible
        className="w-full"
        value={accordionValue}
        onValueChange={onAccordionValueChange}
      >
        <InstrumentsByCategoriesAccordionList
          instruments={standardInstruments}
          isSelected={instrument => getOptionId(mode.instrumentToReplace) === getOptionId(instrument)}
          isAvailable={isAvailable}
          onToggle={onToggle}
          cardVariant="replacement"
        />

        <AccordionItem
          value={InstrumentCategory.custom}
          key={InstrumentCategory.custom}
          ref={customInstrumentsBlockRef}
        >
          <AccordionTrigger>
            <FormattedMessage id="instruments.category.custom" />
          </AccordionTrigger>
          <AccordionContent>
            <div>
              <CollapsibleCustomInstrumentForm create={createCustomInstrumentHandler} />

              <div className={cn(customInstrumentsCardsListStyle, 'mt-2')}>
                {[...customInstruments].reverse().map((instrument, index) => (
                  <HorizontalElementCard
                    key={instrument.name}
                    title={localization.formatMessage(instrument.name)}
                    image={instrument.image}
                    onToggle={selected => onToggle(instrument, selected)}
                    selected={getOptionId(mode.instrumentToReplace) === getOptionId(instrument)}
                    variant="replacement"
                    disabled={!isAvailable(instrument)}
                    disabledMessage={localization.formatMessage('installation.doesntFit')}
                    description={getDimensionsString(instrument.length, instrument.width, instrument.height)}
                    recent={justCreatedInstrument && index === 0}
                  />
                ))}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }
);
