import React, { useState } from 'react';
import { getOptionId } from '@canvas-logic/engine';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { cardsListStyle, customInstrumentsCardsListStyle } from '../InstrumentsInsertionPanel';
import { ElementCard } from '../../../../../components';
import { localization } from '../../../../../stores/Localization';
import { CollapsibleCustomInstrumentForm } from '../CollapsibleCustomInstrumentForm';
import { cn, getDimensionsString } from '../../../../../components/utils';
import { HorizontalElementCard } from '../../../../../components/ElementCard';
import { ICustomInstrument, IInstrument } from '../../../../../schema';
import { ReplacingInstrumentMode } from '../../../../../domain/modes';
import { CustomInstrumentDimensions } from '../../../../../domain/instruments';

interface StandardInstrumentReplacementPanelProps {
  customInstruments: ICustomInstrument[];
  mode: ReplacingInstrumentMode;
  onToggle: (instrument: IInstrument, selected: boolean) => void;
  isAvailable: (instrument: IInstrument) => boolean;
  createCustomInstrument: (dimensions: CustomInstrumentDimensions, name: string) => void;
}
export const StandardInstrumentReplacementPanel: React.FC<StandardInstrumentReplacementPanelProps> = observer(
  ({ mode, customInstruments, isAvailable, createCustomInstrument, onToggle }) => {
    const [justCreatedInstrument, setJustCreatedInstrument] = useState(false);

    const createCustomInstrumentHandler = (dimensions: CustomInstrumentDimensions, name: string): void => {
      createCustomInstrument(dimensions, name);
      setJustCreatedInstrument(true);
    };

    return (
      <div className="flex w-full flex-col gap-y-4">
        <div>
          <h3 className="typography-subtitle1 px-3 py-3.5">{mode.category}</h3>
          <div className={cardsListStyle}>
            {mode.altInstruments.map(instrument => (
              <ElementCard
                key={instrument.name}
                title={localization.formatMessage(instrument.name)}
                image={instrument.image}
                onToggle={selected => onToggle(instrument, selected)}
                selected={getOptionId(mode.instrumentToReplace) === getOptionId(instrument)}
                variant="replacement"
                disabled={!isAvailable(instrument)}
                disabledMessage={localization.formatMessage('installation.doesntFit')}
              />
            ))}
          </div>
        </div>

        <div>
          <h3 className="typography-subtitle1 px-3 py-3.5">
            <FormattedMessage id="instruments.category.custom" />
          </h3>

          <CollapsibleCustomInstrumentForm create={createCustomInstrumentHandler} />

          <div className={cn(customInstrumentsCardsListStyle, 'mt-2')}>
            {[...customInstruments].reverse().map((instrument, index) => (
              <HorizontalElementCard
                key={instrument.name}
                title={instrument.name}
                image={instrument.image}
                onToggle={selected => onToggle(instrument, selected)}
                selected={getOptionId(mode.instrumentToReplace) === getOptionId(instrument)}
                variant="replacement"
                disabled={!isAvailable(instrument)}
                disabledMessage={localization.formatMessage('installation.doesntFit')}
                description={getDimensionsString(instrument.length, instrument.width, instrument.height)}
                recent={justCreatedInstrument && index === 0}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);
