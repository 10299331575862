import * as THREE from 'three';
import { ModelLoader } from './ModelLoader';
import { ImagesLoader } from './ImagesLoader';
import { ImageResource, ProgressEventHandler, Resource, ResourceTransactionToReturn, ResourceType } from './types';
import { ResourceLoadTransaction } from './ResourceLoadTransaction';

export class ResourceLoader {
  private static MATERIALS_PATH = 'materials';
  private materialsCache = new Map<string, THREE.Material>();

  constructor(private readonly modelLoader: ModelLoader, private readonly imagesLoader?: ImagesLoader) {}

  private async loadMaterial(materialName: string, onProgress?: ProgressEventHandler) {
    try {
      const node = await this.modelLoader.load(`${ResourceLoader.MATERIALS_PATH}/${materialName}`, 'glb', onProgress);
      node.traverse(obj => {
        if (obj instanceof THREE.Mesh) {
          const material: THREE.Material = Array.isArray(obj.material) ? obj.material[0] : obj.material;
          this.materialsCache.set(materialName, material);
        }
      });
    } catch (e: any) {
      console.error(e.message);
    }
  }

  async getMaterial(name: string, onProgress?: ProgressEventHandler) {
    if (!this.materialsCache.get(name)) {
      await this.loadMaterial(name, onProgress);
    }
    return this.materialsCache.get(name)!;
  }

  async getModel(path: string, onProgress: ProgressEventHandler) {
    return this.modelLoader.load(path, 'glb', onProgress);
  }

  async getImage(path: string, scale: number, onProgress: ProgressEventHandler) {
    if (this.imagesLoader) {
      return this.imagesLoader.loadSVG(path, scale, onProgress);
    }
  }

  private async getResource(resource: Resource, onProgress: ProgressEventHandler, scale: number = 0) {
    const { type, path } = resource;

    switch (type) {
      case ResourceType.MATERIAL:
        return this.getMaterial(path, onProgress);
      case ResourceType.MODEL:
        return this.getModel(path, onProgress);
      case ResourceType.IMAGE:
        return this.getImage(path, scale, onProgress);
      default:
        throw new Error(`Unsupported resource type: ${type}`);
    }
  }

  load<T extends readonly Resource[]>(
    transaction: ResourceLoadTransaction<T>,
    progressListener?: (progress: number) => void
  ): Promise<ResourceTransactionToReturn<T>> {
    return Promise.all(
      transaction.resources.map(resource =>
        this.getResource(
          resource,
          event => {
            transaction.updateProgress(resource.path, event);

            if (progressListener) {
              progressListener(transaction.progress);
            }
          },
          (resource as ImageResource).scale
        )
      )
    ) as any;
  }
}
