import { getTypeRef } from '@canvas-logic/engine';
import {
  Angle,
  GeometryElement,
  IBackToBackShape,
  IConnection,
  ICustomInstrument,
  ICustomShape,
  IInstrument,
  ILinearShape,
  ILShape,
  InstrumentCategory,
  ISection,
  IUShape,
  Shape
} from '../schema';
import { ActiveMode, SelectedMode } from './modes';
import {
  InsertionLayoutPayload,
  InstrumentInsertionLayoutPayload,
  InstrumentLayoutPayload,
  LayoutItem,
  SectionInsertionLayoutPayload,
  SectionLayoutPayload
} from './layout';
import { InsertingMode } from './modes/type';

export function sectionTypeGuard(sectionElement: GeometryElement | undefined): sectionElement is ISection {
  return !!sectionElement && getTypeRef(sectionElement) === 'Section';
}

export function instrumentTypeGuard(element: any): element is IInstrument {
  return !!element && getTypeRef(element) === 'Instrument';
}

export function connectionTypeGuard(connectionElement: GeometryElement): connectionElement is IConnection {
  return connectionElement && getTypeRef(connectionElement) === 'Connection';
}

export function angleTypeGuard(sectionElement: GeometryElement): sectionElement is IConnection {
  return getTypeRef(sectionElement) === 'Angle';
}

export function linearShapeTypeGuard(shape: Shape): shape is ILinearShape {
  return getTypeRef(shape) === 'LinearShape';
}
export function lShapeTypeGuard(shape: Shape): shape is ILShape {
  return getTypeRef(shape) === 'LShape';
}
export function uShapeTypeGuard(shape: Shape): shape is IUShape {
  return getTypeRef(shape) === 'UShape';
}

export function uShapeConfigurationTypeGuard(shapes: Shape[]): shapes is IUShape[] {
  return shapes.every(shape => uShapeTypeGuard(shape));
}

export function backToBackConfigurationTypeGuard(shapes: Shape[]): shapes is IUShape[] {
  return shapes.every(shape => backToBackShapeTypeGuard(shape));
}
export function backToBackShapeTypeGuard(shape: Shape): shape is IBackToBackShape {
  return getTypeRef(shape) === 'BackToBackShape';
}

export function customShapeTypeGuard(shape: Shape): shape is ICustomShape {
  return getTypeRef(shape) === 'CustomShape';
}
export function angleNumberTypeGuard(value: number): value is Angle {
  return value === 0 || value === 90 || value === -90 || value === 180;
}

export function selectedModeTypeGuard(mode: ActiveMode): mode is SelectedMode {
  return mode.type === 'selectedBench' || mode.type === 'selectedInstrument';
}

export function insertingModeTypeGuard(mode: ActiveMode): mode is InsertingMode {
  return mode.type === 'insertingInstrument' || mode.type === 'insertingBench';
}

export const instrumentLayoutItemTypeGuard = (item: LayoutItem): item is LayoutItem<InstrumentLayoutPayload> =>
  item.payload.type === 'instrument';

export const benchLayoutItemTypeGuard = (item: LayoutItem): item is LayoutItem<SectionLayoutPayload> =>
  item.payload.type === 'section';

export const instrumentInsertionLayoutItemTypeGuard = (
  item: LayoutItem | undefined
): item is LayoutItem<InstrumentInsertionLayoutPayload> =>
  !!item && !!item.payload && item.payload.type === 'instrumentInsertion';

export const benchInsertionLayoutItemTypeGuard = (
  item: LayoutItem | undefined
): item is LayoutItem<SectionInsertionLayoutPayload> =>
  !!item && !!item.payload && item.payload.type === 'sectionInsertion';

export const insertLayoutItemTypeGuard = (item: LayoutItem): item is LayoutItem<InsertionLayoutPayload> => {
  return item.payload.type === 'instrumentInsertion' || item.payload.type === 'sectionInsertion';
};

export const customInstrumentTypeGuard = (item: any): item is ICustomInstrument => {
  return item?.category === InstrumentCategory.custom && 'height' in item;
};
