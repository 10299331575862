import { LayoutItem } from '../../domain/layout';
import { toRadians } from '../../domain/geometry';
import { Angle } from '../../schema';

/**
 * Converts 3D scene coordinate to canvas coordinate based on SVG sizing
 */
export const toCanvasCoords = (x: number) => (x * 300) / 1960;

export const unique = <T extends unknown>(arr: T[]): T[] => {
  return [...new Set(arr)];
};

/**
 * Numeric array sorter
 */
export const sortNumbers = (arr: number[]): number[] =>
  [...arr].sort(function (a, b) {
    return a - b;
  });

/**
 * Rotates LayoutItem coordinates
 * @param item
 * @param angle
 */
export const rotate = (item: LayoutItem, angle: number): LayoutItem => {
  const { centerX: x, centerY: y } = item;

  return {
    ...item,
    centerX: Math.round(x * Math.cos(toRadians(angle)) + y * Math.sin(toRadians(angle))),
    centerY: Math.round(-1 * x * Math.sin(toRadians(angle)) + y * Math.cos(toRadians(angle))),
    angle: (item.angle + angle) as Angle
  };
};
