import { IConfiguration, ICustomInstrument, IInstrument } from '../../schema';
import { traverseLines } from '../layout';

export function findInstrumentLocationByUUID(
  configuration: IConfiguration,
  uuid: string
): {
  instruments: IInstrument[];
  index: number;
} {
  let found = false;
  let index = -1;
  let instruments: IInstrument[] = [];
  traverseLines(configuration, node => {
    if (found) {
      return;
    }
    index = node.instruments.findIndex(instrument => instrument.uuid === uuid);
    if (index >= 0) {
      instruments = node.instruments;
      found = true;
    }
  });
  return { instruments, index };
}
