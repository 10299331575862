import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from './utils';

const buttonVariants = cva(
  'ring-offset-background inline-flex items-center justify-center gap-2 rounded text-sm  font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'border-2 border-primary-500 bg-primary-500  uppercase text-neutral-0 hover:border-primary-800 hover:bg-primary-800 active:border-primary-900 active:bg-primary-900 [&>svg]:fill-neutral-0 ',
        outlined:
          ' :fill-neutral-0 border-2 border-primary-500 bg-neutral-0 fill-primary-500 uppercase text-primary-500 hover:border-primary-600 hover:bg-primary-0 hover:fill-primary-500 hover:text-primary-600 active:border-primary-500 active:bg-primary-500 active:text-neutral-0',
        clear:
          'bg-neutral-0 fill-neutral-900 uppercase  text-neutral-900 hover:fill-primary-500 hover:text-primary-500 active:fill-primary-900 active:text-primary-900',
        link: 'text-base font-normal text-primary-500 underline hover:text-primary-600 active:text-primary-700',
        'link-secondary': 'text-base font-medium text-secondary-500  hover:text-secondary-600 active:text-secondary-700'
      },
      size: {
        default: 'px-6 py-3 text-sm',
        lg: ' px-6 py-3 text-[0.938rem] leading-6'
      }
    },
    compoundVariants: [
      { variant: 'outlined', size: 'default', class: 'leading-[calc(1.25rem-4px)]' },
      { variant: 'outlined', size: 'lg', class: 'leading-[calc(1.5rem-4px)]' },
      { variant: 'link', size: 'default', class: 'p-0' }
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  withIcon?: boolean;
}

const ButtonCmp = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, withIcon = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), withIcon ? 'pl-4 pr-6' : '')}
        ref={ref}
        {...props}
      />
    );
  }
);
ButtonCmp.displayName = 'Button';

const ButtonIcon = ({ children, className }: { className?: string; children: React.ReactNode }) => (
  <div className={cn('flex items-center justify-center text-2xl/6 ', className)}>{children}</div>
);
const ButtonText = ({ children, className }: { className?: string; children: React.ReactNode }) => (
  <div className={cn(className)}>{children}</div>
);

const Button = Object.assign(ButtonCmp, {
  ButtonIcon,
  ButtonText
});

export { Button };
