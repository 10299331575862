import { GeometryElementList, IConfiguration, Shape } from '../../schema';
import { traverseLines } from '../layout';

export interface FindBenchLocationByUUIDResult {
  benches: GeometryElementList;
  index: number;
  shape: Shape;
}
export const findBenchLocationByUUID = (configuration: IConfiguration, uuid: string): FindBenchLocationByUUIDResult => {
  let found = false;
  let index = -1;
  let benches: GeometryElementList = [];
  let shape: Shape = {} as Shape;

  traverseLines(configuration, (node): void => {
    if (found) {
      return;
    }
    index = node.allGeometry.findIndex(bench => 'uuid' in bench && bench.uuid === uuid);
    if (index >= 0) {
      benches = node.allGeometry;
      found = true;
      shape = node.sourceShape;
    }
  });

  return { benches, index, shape };
};
