import { EmbeddingService, IFrameMessageType } from './EmbeddingService';

export interface IHubspotEvent {
  name: string;
}

export abstract class HubspotEventWithConfiguration implements IHubspotEvent {
  properties: {
    configuration_link: string;
  };
  abstract name: string;

  constructor(configuration_link: string) {
    this.properties = {
      configuration_link: configuration_link + '&referrer=app.hubspot.com'
    };
  }
}

/*
Hubspot events are defined in the environment variables for each environment,
because it hard to change names of events in Hubspot account after event creation.
For example if you create event and then delete it, you can't create event with the same name again, hubspot will  add version to the name.
 */
const HubspotEvents = {
  OpenConfigurator: process.env.REACT_APP_HUBSPOT_OPEN_CONFIGURATOR_EVENT!,
  OpenSavedLink: process.env.REACT_APP_HUBSPOT_OPEN_SAVED_LINK_EVENT!,
  SaveAsPdf: process.env.REACT_APP_HUBSPOT_SAVE_AS_PDF_EVENT!,
  ShareLink: process.env.REACT_APP_HUBSPOT_SHARE_LINK_EVENT!,
  ShareViaEmail: process.env.REACT_APP_HUBSPOT_SHARE_VIA_EMAIL_EVENT!,
  CloseTab: process.env.REACT_APP_HUBSPOT_CLOSE_EVENT!,
  ViewInAR: process.env.REACT_APP_HUBSPOT_VIEW_IN_AR_EVENT!
};
const isLoginFormEnabled = process.env.REACT_APP_HUBSPOT_FORM_ENABLED === 'YES';
export class HubspotService {
  constructor(private embeddingService: EmbeddingService) {}

  private get isDisableForReferrer() {
    return this.embeddingService.referrer.includes(`app.hubspot.com`);
  }

  get isTrackingEnabled(): boolean {
    return process.env.REACT_APP_HUBSPOT_TRACKING_ENABLED === 'YES' && !this.isDisableForReferrer;
  }

  get isLoginFormEnabled(): boolean {
    return isLoginFormEnabled && !this.isDisableForReferrer;
  }

  /**
   * Ensure that all Hubspot events are defined in the environment variables.
   */
  validateConfiguration() {
    for (const [key, value] of Object.entries(HubspotEvents)) {
      if (!value) {
        throw new Error(
          `Hubspot event '${key}' is not defined in the environment variables. See .env.example for more details.`
        );
      }
    }
  }

  sendHubspotEvent(event: IHubspotEvent) {
    if (this.isTrackingEnabled) {
      this.embeddingService.sendHubspotEvent(event);
    }
  }

  userHasSubmittedForm(record: Record<string, string>) {
    this.embeddingService.sendMessageWithPayload(IFrameMessageType.HUBSPOT_LOGIN, {
      user: record,
      closeTabEventName: HubspotEvents.CloseTab
    });
  }
}

export class OpenConfiguratorHubspotEvent implements IHubspotEvent {
  name = HubspotEvents.OpenConfigurator;
  properties = {};
}

export class OpenSavedLinkHubspotEvent extends HubspotEventWithConfiguration {
  name = HubspotEvents.OpenSavedLink;
}

export class SaveAsPdfHubspotEvent extends HubspotEventWithConfiguration {
  name = HubspotEvents.SaveAsPdf;
}

export class ShareLinkHubspotEvent extends HubspotEventWithConfiguration {
  name = HubspotEvents.ShareLink;
}

export class ShareViaEmailHubspotEvent extends HubspotEventWithConfiguration {
  name = HubspotEvents.ShareViaEmail;
}
export class CloseTabHubspotEvent implements IHubspotEvent {
  name = HubspotEvents.CloseTab;
  properties = {};
}

export class ViewInARHubspotEvent extends HubspotEventWithConfiguration {
  name = HubspotEvents.ViewInAR;
}
