import React, { KeyboardEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import clsx from 'clsx';
import { cn, isScrolledIntoView, scrollIntoNestedView } from './utils';
import { Icon } from './Icon';
import { IconButton } from './IconButton';

export type ElementCardVariant = 'default' | 'replacement';

interface ElementCardProps {
  title: string;
  image: string;
  onToggle: (selected: boolean) => void;
  selected?: boolean;
  description?: string;
  disabled?: boolean;
  disabledMessage?: string;
  variant?: ElementCardVariant;
  autoFocus?: boolean;
}

export const ElementCard = ({
  title,
  description,
  image,
  selected,
  disabled,
  disabledMessage,
  onToggle,
  variant,
  autoFocus
}: ElementCardProps): JSX.Element => {
  const [element, setElement] = useState<HTMLElement | null>();

  useEffect(() => {
    if (autoFocus && !disabled && element) {
      setTimeout(() => {
        if (!isScrolledIntoView(element)) {
          scrollIntoNestedView(element);
        }
      }, 150);
    }
  }, [autoFocus, element, disabled]);

  const select = (): void => {
    if (!disabled && !selected && onToggle) {
      onToggle(true);
    }
  };

  const cancelSelect = (): void => {
    if (!disabled && selected && onToggle) {
      onToggle(false);
    }
  };

  const onCardKeyDown: KeyboardEventHandler = event => {
    if ((event.target === event.currentTarget && event.key === 'Enter') || event.key === ' ') {
      event.preventDefault();
      select();
    }
  };

  const id = `element-card-${title}`;

  return (
    <section
      className={cn(
        'relative flex w-full  grow flex-col overflow-hidden bg-neutral-0',
        'max-md:min-h-24 max-md:w-auto max-md:max-w-full max-md:flex-row max-md:items-center max-md:justify-start',
        'rounded-[1px] outline outline-2  -outline-offset-2 outline-transparent',
        'transition-colors',
        'group',
        'hover:outline-neutral-100 focus:outline-secondary-200 active:outline-secondary-500',
        'aria-disabled:pointer-events-none aria-disabled:outline-none',
        {
          'outline-secondary-200 hover:outline-secondary-200 md:hover:bg-blue-0': selected
        },
        variant === 'replacement' && 'focus:outline-primary-200 active:outline-primary-500',
        variant === 'replacement' &&
          selected &&
          'outline-primary-200 hover:bg-primary-0 hover:outline-primary-200 focus:outline-primary-500 active:outline-primary-500 max-md:bg-primary-0 md:hover:bg-primary-0'
      )}
      onClick={select}
      role="button"
      aria-disabled={!!disabled}
      tabIndex={!!disabled ? undefined : 0}
      onKeyDown={onCardKeyDown}
      aria-labelledby={id}
      ref={setElement}
    >
      <div className="relative flex w-full items-center justify-center overflow-hidden mix-blend-multiply max-md:h-full max-md:w-full max-md:max-w-24">
        <img
          className="aspect-4/3 select-none object-cover mix-blend-multiply contrast-100 transition-transform group-aria-disabled:opacity-25 group-aria-disabled:mix-blend-luminosity max-md:aspect-square max-md:h-full"
          src={image}
          alt={title}
          loading="lazy"
        />
        {!!disabled && disabledMessage && (
          <span className="typography-label absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 rounded-[1px] bg-neutral-500 px-3 py-2 text-center uppercase text-neutral-0 max-md:w-full max-md:max-w-20">
            {disabledMessage}
          </span>
        )}
      </div>
      <div className="flex grow flex-col items-center justify-center gap-y-1 px-2 py-4 transition-transform max-md:items-start max-md:px-4 max-md:py-6">
        <h1
          className="typography-subtitle1 max-md:typography-subtitle2 w-fit text-center text-neutral-900 group-aria-disabled:text-neutral-400"
          id={id}
        >
          {title}
        </h1>
        {description && (
          <p className="typography-body2 text-neutral-500 group-aria-disabled:text-neutral-300">{description}</p>
        )}
      </div>
      {!disabled && selected && variant !== 'replacement' && (
        <button
          className="flex h-12 w-12 items-center justify-center rounded bg-neutral-0 text-neutral-900 transition-transform group-hover:visible max-md:h-10 max-md:w-10 md:invisible md:absolute md:left-1/2 md:top-[calc(50%-4rem)] md:-translate-x-1/2"
          type="button"
          onClick={cancelSelect}
        >
          <Icon icon="close" />
        </button>
      )}
    </section>
  );
};

interface ISelectedItemCardProps {
  title: string;
  image: string;
  description?: string;
  onClick?: () => void;
  onCancelSelect: () => void;
}

export const SelectedItemCard = ({
  title,
  image,
  onClick,
  onCancelSelect,
  description
}: ISelectedItemCardProps): JSX.Element => {
  const cancelSelect: MouseEventHandler = (e): void => {
    e.stopPropagation();
    onCancelSelect();
  };

  return (
    <section
      className={cn(
        'relative flex h-full w-full  grow  overflow-hidden   rounded-[1px] bg-blue-0',
        'min-h-24 w-auto max-w-[480px] items-center  outline-secondary-200 hover:outline-secondary-800',
        'outline outline-2 -outline-offset-1',
        'transition-colors',
        'group',
        'hover:outline-neutral-100 focus:outline-secondary-200 active:outline-secondary-500',
        'aria-disabled:pointer-events-none aria-disabled:outline-none'
      )}
      role="button"
      onClick={onClick}
    >
      <div className="relative flex h-full w-full max-w-24 items-center justify-center overflow-hidden  mix-blend-multiply group-aria-disabled:opacity-50">
        <img
          className={clsx(
            'h-24 w-24 select-none object-contain mix-blend-multiply contrast-100 transition-transform group-aria-disabled:opacity-25 group-aria-disabled:mix-blend-luminosity'
          )}
          src={image}
          alt={title}
          loading="lazy"
        />
      </div>
      <div
        className={clsx(
          'flex grow flex-col items-center justify-center gap-y-1 px-2 py-4 transition-transform group-aria-disabled:opacity-50 max-md:items-start max-md:px-4 max-md:py-6'
        )}
      >
        <h1 className="typography-subtitle1 max-md:typography-subtitle2 text-neutral-900 group-aria-disabled:text-neutral-300">
          {title}
        </h1>
        {description && (
          <p className="typography-body2 text-neutral-500 group-aria-disabled:text-neutral-300">{description}</p>
        )}
      </div>

      <div className="z-10 flex p-2">
        <IconButton onClick={cancelSelect} className="bg-white">
          <Icon icon="close" />
        </IconButton>
      </div>
    </section>
  );
};

interface HorizontalElementCardProps extends ElementCardProps {
  recent?: boolean;
}

export const HorizontalElementCard: React.FC<HorizontalElementCardProps> = ({
  title,
  description,
  image,
  selected,
  disabled,
  disabledMessage,
  onToggle,
  variant,
  autoFocus,
  recent
}) => {
  const [element, setElement] = useState<HTMLElement | null>();

  useEffect(() => {
    if (autoFocus && !disabled && element) {
      setTimeout(() => {
        if (!isScrolledIntoView(element)) {
          scrollIntoNestedView(element);
        }
      }, 150);
    }
  }, [autoFocus, element, disabled]);

  const select = (): void => {
    if (!disabled && !selected && onToggle) {
      onToggle(true);
    }
  };

  const cancelSelect = (): void => {
    if (!disabled && selected && onToggle) {
      onToggle(false);
    }
  };

  const onCardKeyDown: KeyboardEventHandler = event => {
    if ((event.target === event.currentTarget && event.key === 'Enter') || event.key === ' ') {
      event.preventDefault();
      select();
    }
  };

  const id = `horizontal-element-card-${title}`;

  return (
    <section
      className={cn(
        'relative box-border flex min-h-24 w-full flex-row items-center justify-start overflow-hidden bg-neutral-0',
        'rounded-[1px] outline outline-2 outline-offset-0 outline-transparent',
        'transition-colors',
        'group',
        'hover:outline-neutral-100 focus:outline-secondary-200 active:outline-secondary-500',
        'aria-disabled:pointer-events-none aria-disabled:outline-none',
        variant !== 'replacement' && selected && 'outline-secondary-200 hover:bg-blue-0 hover:outline-secondary-200',
        variant === 'replacement' && 'focus:outline-primary-200 active:outline-primary-500',
        variant === 'replacement' &&
          selected &&
          'outline-primary-200 hover:bg-primary-0 hover:outline-primary-200 focus:outline-primary-500 active:outline-primary-500',
        recent && 'animate-flash-created-instrument',
        variant === 'replacement' && recent && 'animate-flash-created-instrument'
      )}
      onClick={select}
      role="button"
      aria-disabled={!!disabled}
      tabIndex={!!disabled ? undefined : 0}
      onKeyDown={onCardKeyDown}
      aria-labelledby={id}
      ref={setElement}
    >
      <div
        className={clsx(
          'relative flex w-full max-w-[96px] items-center justify-center overflow-hidden mix-blend-multiply transition-transform',
          selected && variant !== 'replacement' && 'group-hover:translate-x-[-56px]'
        )}
      >
        <img
          className="select-none object-contain mix-blend-multiply transition-transform group-aria-disabled:opacity-25 group-aria-disabled:mix-blend-luminosity"
          src={image}
          alt={title}
          loading="lazy"
        />
        {!!disabled && disabledMessage && (
          <span className="typography-label absolute left-1/2 top-1/2 flex w-full max-w-20 -translate-x-1/2 -translate-y-1/2 rounded-[1px] bg-neutral-500 px-3 py-2 text-center uppercase text-neutral-0">
            {disabledMessage}
          </span>
        )}
      </div>
      <div
        className={clsx(
          'flex grow flex-col items-start justify-center gap-y-1 px-4 py-6 transition-transform',
          selected && variant !== 'replacement' && 'group-hover:translate-x-[-56px]'
        )}
      >
        <h1
          className="typography-subtitle1 max-md:typography-subtitle2 text-neutral-900 group-aria-disabled:text-neutral-400"
          id={id}
        >
          {title}
        </h1>
        {description && (
          <p className="typography-body2 text-neutral-500 group-aria-disabled:text-neutral-300">{description}</p>
        )}
      </div>
      {!disabled && selected && variant !== 'replacement' && (
        <button
          className="flex h-10 w-10 translate-x-full items-center justify-center bg-white transition-transform [@media(hover:hover)]:group-hover:translate-x-[-8px] [@media(hover:none)]:group-focus:translate-x-0"
          type="button"
          onClick={cancelSelect}
        >
          <Icon icon="close" />
        </button>
      )}
    </section>
  );
};
