import React, { forwardRef, Fragment, PropsWithChildren } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from './utils';

const selectButtonVariants = cva(
  'typography-body1  typography-body1 relative flex h-10 w-full cursor-default items-center rounded-lg bg-neutral-0 px-6  text-left  focus:outline-none  sm:text-sm',
  {
    variants: {
      variant: {
        default:
          'border border-neutral-300 hover:border-neutral-500 active:border-neutral-800 disabled:border-neutral-100 disabled:text-neutral-200',
        clear: ''
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);
export const SelectButton = ({
  className,
  children,
  variant
}: PropsWithChildren<
  {
    className?: string;
  } & VariantProps<typeof selectButtonVariants>
>) => {
  return (
    <Listbox.Button className={cn(selectButtonVariants({ variant, className }))}>
      {({ open }) => (
        <>
          <span className="block truncate">{children}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <div className="material-icons h-5 w-5" aria-hidden="true">
              {open ? 'arrow_drop_up' : 'arrow_drop_down'}
            </div>
          </span>
        </>
      )}
    </Listbox.Button>
  );
};

export const SelectOptionsContainer = forwardRef(({ children }: PropsWithChildren<{}>, ref: any) => {
  return (
    <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
      <Listbox.Options
        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        ref={ref}
      >
        {children}
      </Listbox.Options>
    </Transition>
  );
});

export const SelectOption = ({
  children,
  value,
  drawTick,
  className
}: PropsWithChildren<{ value: any; drawTick: boolean; className?: string }>) => {
  return (
    <Listbox.Option
      className={({ active }) =>
        `typography-body1 relative cursor-default select-none py-2
                     pl-10 pr-4 ${
                       active && 'bg-neutral-50 text-neutral-900'
                     }  hover:bg-neutral-50 hover:text-neutral-900 ${className}`
      }
      value={value}
    >
      {({ selected }) => (
        <>
          <span className={`block truncate ${selected && 'text-secondary-500'}`}>{children}</span>
          {drawTick && selected ? (
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <div className="material-icons  text-base text-secondary-500" aria-hidden="true">
                check
              </div>
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};

export const Select = Listbox;
