import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { cn } from './utils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => <AccordionPrimitive.Item ref={ref} className={className} {...props} />);

AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    icon?: string;
    iconExpandedStyle?: string;
    containerClassName?: string;
  }
>(({ className, containerClassName, children, icon, iconExpandedStyle, ...props }, ref) => (
  <AccordionPrimitive.Header className={cn('flex  overflow-hidden pl-3 pr-2', containerClassName)}>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'typography-subtitle1 group flex  flex-1 items-center justify-between overflow-hidden whitespace-nowrap py-4 text-neutral-600 transition-all  duration-1000 hover:text-neutral-900',
        className
      )}
      {...props}
    >
      {children}
      <span
        className={cn(
          'material-icons transition-transform  duration-150 ease-out group-data-[state=open]:rotate-180 ',
          iconExpandedStyle && `group-data-[state=open]:${iconExpandedStyle}`
        )}
        aria-hidden
      >
        {icon || 'arrow_drop_down'}
      </span>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden pb-4 pt-0 text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className
    )}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
