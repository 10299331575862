import { Box3, Camera, MathUtils, Vector3 } from 'three';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { ConfigurationScene } from './ConfigurationScene';

const TEXT_SIZE = '18px';
const TEXT_COLOR = 'rgb(255,255,255)';
const BACKGROUND_COLOR = 'rgb(222,42,108)';
const BORDER_RADIUS = '0px';
const PADDING = '6px 10px 4px 10px';
const LABEL_OFFSET = 0.08;
const MAIN_LINE_COLOR = 0xfbb4cc;
const LINE_COLOR = 0xfdd3e1;
const LINE_OFFSET = 0.475;
const WIDTH_ANGLE_RANGE = { start: 95, end: 265 };
const DEPTH_ANGLE_RANGE = { start: 10, end: 190 };
const HEIGHT_ANGLE_RANGE = { start: 175, end: 285 };
const UNITS = ' MM';

export class DimensionsRenderer {
  public dimensionsRenderer2D;
  private width = true;
  private depth = true;
  private height = true;

  constructor(width: number, height: number) {
    this.dimensionsRenderer2D = new CSS2DRenderer();
    this.dimensionsRenderer2D.setSize(width, height);
    this.dimensionsRenderer2D.domElement.classList.add('transition-all');
    this.dimensionsRenderer2D.domElement.classList.add('pointer-events-none');
    if (document.getElementById('viewer')) {
      document.getElementById('viewer')!.appendChild(this.dimensionsRenderer2D.domElement);
    }
  }

  render(confScene: ConfigurationScene, camera: Camera, angle: number, showRuler: boolean) {
    if (showRuler) {
      const angleInDegrees =
        MathUtils.radToDeg(angle) < 0 ? 180 + (180 + MathUtils.radToDeg(angle)) : MathUtils.radToDeg(angle);
      this.width = this.rebuildRuler(
        angleInDegrees,
        WIDTH_ANGLE_RANGE.start,
        WIDTH_ANGLE_RANGE.end,
        confScene,
        this.width
      );
      this.depth = this.rebuildRuler(
        angleInDegrees,
        DEPTH_ANGLE_RANGE.start,
        DEPTH_ANGLE_RANGE.end,
        confScene,
        this.depth
      );
      this.height = this.rebuildRuler(
        angleInDegrees,
        HEIGHT_ANGLE_RANGE.start,
        HEIGHT_ANGLE_RANGE.end,
        confScene,
        this.height
      );
      this.dimensionsRenderer2D.render(confScene.getDimensionsScene(), camera);
    }
  }

  private rebuildRuler(
    angle: number,
    startAngle: number,
    endAngle: number,
    confScene: ConfigurationScene,
    rebuildFlag: boolean
  ) {
    if (rebuildFlag && angle > startAngle && angle < endAngle) {
      rebuildFlag = false;
      this.clearScene(confScene);
      this.buildRuler(confScene, angle);
    } else {
      if (!rebuildFlag && !(angle > startAngle && angle < endAngle)) {
        rebuildFlag = true;
        this.clearScene(confScene);
        this.buildRuler(confScene, angle);
      }
    }
    return rebuildFlag;
  }

  setSize(width: number, height: number) {
    this.dimensionsRenderer2D.setSize(width, height);
  }

  clearScene(scene: ConfigurationScene) {
    scene.getDimensionsScene().remove(...scene.getDimensionsScene().children);
    scene.getRulerNode().remove(...scene.getRulerNode().children);
  }

  buildRuler(scene: ConfigurationScene, angle = -1) {
    this.clearScene(scene);

    const sceneBox = new Box3();
    const sceneBoxSize = new Vector3();
    const sceneBoxCenter = new Vector3();
    sceneBox.setFromObject(scene.getRootNode());
    sceneBox.getSize(sceneBoxSize);
    sceneBox.getCenter(sceneBoxCenter);

    //width
    const widthFlip = angle > WIDTH_ANGLE_RANGE.start && angle < WIDTH_ANGLE_RANGE.end;
    const widthLabelPosition = new Vector3(
      sceneBoxCenter.x,
      sceneBox.min.y + LABEL_OFFSET,
      widthFlip ? sceneBox.min.z - LINE_OFFSET : sceneBox.max.z + LINE_OFFSET
    );
    const widthLineStart = [
      sceneBox.min.x,
      sceneBox.min.y,
      widthFlip ? sceneBox.min.z - LINE_OFFSET : sceneBox.max.z + LINE_OFFSET
    ];
    const widthLineEnd = [
      sceneBox.max.x,
      sceneBox.min.y,
      widthFlip ? sceneBox.min.z - LINE_OFFSET : sceneBox.max.z + LINE_OFFSET
    ];

    this.add2DLabel(widthLabelPosition, this.formatValue(sceneBoxSize.x), scene);
    this.add3DLine(widthLineStart, widthLineEnd, 0.0025, scene, MAIN_LINE_COLOR);
    this.add3DLine(
      widthLineStart,
      [sceneBox.min.x, sceneBox.min.y, widthFlip ? sceneBox.min.z : sceneBox.max.z],
      0.0025,
      scene,
      LINE_COLOR
    );
    this.add3DLine(
      widthLineEnd,
      [sceneBox.max.x, sceneBox.min.y, widthFlip ? sceneBox.min.z : sceneBox.max.z],
      0.0025,
      scene,
      LINE_COLOR
    );

    //depth
    const depthFlip = angle > DEPTH_ANGLE_RANGE.start && angle < DEPTH_ANGLE_RANGE.end;
    const depthLabelPosition = new Vector3(
      depthFlip ? sceneBox.max.x + LINE_OFFSET : sceneBox.min.x - LINE_OFFSET,
      sceneBox.min.y + LABEL_OFFSET,
      sceneBoxCenter.z
    );
    const depthLineStart = [
      depthFlip ? sceneBox.max.x + LINE_OFFSET : sceneBox.min.x - LINE_OFFSET,
      sceneBox.min.y,
      sceneBox.max.z
    ];
    const depthLineEnd = [
      depthFlip ? sceneBox.max.x + LINE_OFFSET : sceneBox.min.x - LINE_OFFSET,
      sceneBox.min.y,
      sceneBox.min.z
    ];

    this.add2DLabel(depthLabelPosition, this.formatValue(sceneBoxSize.z), scene);
    this.add3DLine(depthLineStart, depthLineEnd, 0.0025, scene, MAIN_LINE_COLOR);
    this.add3DLine(
      depthLineEnd,
      [depthFlip ? sceneBox.max.x : sceneBox.min.x, sceneBox.min.y, sceneBox.min.z],
      0.002,
      scene,
      LINE_COLOR
    );
    this.add3DLine(
      depthLineStart,
      [depthFlip ? sceneBox.max.x : sceneBox.min.x, sceneBox.min.y, sceneBox.max.z],
      0.002,
      scene,
      LINE_COLOR
    );

    //height
    const heightFlip = angle > HEIGHT_ANGLE_RANGE.start && angle < HEIGHT_ANGLE_RANGE.end;
    const heightLabelPosition = new Vector3(
      heightFlip ? sceneBox.min.x : sceneBox.max.x,
      sceneBoxCenter.y,
      heightFlip ? sceneBox.min.z - LINE_OFFSET : sceneBox.max.z + LINE_OFFSET
    );
    const heightLineStart = [
      heightFlip ? sceneBox.min.x : sceneBox.max.x,
      sceneBox.min.y,
      heightFlip ? sceneBox.min.z - LINE_OFFSET : sceneBox.max.z + LINE_OFFSET
    ];
    const heightLineEnd = [
      heightFlip ? sceneBox.min.x : sceneBox.max.x,
      sceneBox.max.y,
      heightFlip ? sceneBox.min.z - LINE_OFFSET : sceneBox.max.z + LINE_OFFSET
    ];

    this.add2DLabel(heightLabelPosition, this.formatValue(sceneBoxSize.y), scene, -90);
    this.add3DLine(heightLineStart, heightLineEnd, 0.001, scene, MAIN_LINE_COLOR);
    this.add3DLine(
      heightLineStart,
      [heightFlip ? sceneBox.min.x : sceneBox.max.x, sceneBox.min.y, heightFlip ? sceneBox.min.z : sceneBox.max.z],
      0.002,
      scene,
      LINE_COLOR
    );
    this.add3DLine(
      heightLineEnd,
      [heightFlip ? sceneBox.min.x : sceneBox.max.x, sceneBox.max.y, heightFlip ? sceneBox.min.z : sceneBox.max.z],
      0.002,
      scene,
      LINE_COLOR
    );
  }

  private add2DLabel(position: Vector3, content: string, scene: ConfigurationScene, rotation = 0) {
    const text = document.createElement('div');
    text.textContent = content;
    text.style.fontSize = TEXT_SIZE;
    text.style.color = TEXT_COLOR;
    text.style.transform = `rotate(${rotation}deg)`;
    text.style.backgroundColor = BACKGROUND_COLOR;
    text.style.borderRadius = BORDER_RADIUS;
    text.style.padding = PADDING;
    const wrap = document.createElement('div');
    wrap.appendChild(text);
    const label = new CSS2DObject(wrap);
    label.position.copy(position);
    scene.getDimensionsScene().add(label);
  }

  private add3DLine(
    from: number[],
    to: number[],
    width: number,
    scene: ConfigurationScene,
    color: number,
    opacity = 0.85
  ) {
    const geometry = new LineGeometry().setPositions([...from, ...to]);
    const material = new LineMaterial({ color: color, linewidth: width, transparent: true });
    material.uniforms.opacity.value = opacity;
    const line = new Line2(geometry, material);
    scene.getRulerNode().add(line);
  }

  private formatValue(size: number) {
    const mm = size * 1000;
    const roundedToCm = Math.round(mm / 10) * 10;
    return roundedToCm.toLocaleString('en-GB') + UNITS;
  }
}
