import * as React from 'react';
import { cn } from '../utils';
import './style.css';
import { useFormField } from '../Form';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  endAdornment?: React.ReactNode;
  containerClassName?: string;
  highlightError?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, containerClassName, type, endAdornment, highlightError, ...props }, ref) => {
    const formField = useFormField();

    if (!formField) {
      throw new Error('Attention: This <Input/> must be used inside Form Hook Provider');
    }

    return (
      <label
        className={cn(
          'ring-offset-background peer flex w-full overflow-hidden rounded-md  border border-neutral-300 bg-neutral-0   focus-visible:outline-none   disabled:cursor-not-allowed disabled:opacity-50',
          highlightError && formField.error && 'border-primary-500',
          containerClassName
        )}
      >
        <input
          type={type}
          className={cn(
            'typography-body1 prevent-autofill-change-bg w-full min-w-0 px-3 py-2 placeholder:text-neutral-300',
            'hover:border-neutral-500',
            'active:border-neutral-800, focus:outline-none',
            'disabled:border-neutral-100',
            className
          )}
          ref={ref}
          {...props}
        />
        {endAdornment && <div className="flex items-center">{endAdornment}</div>}
      </label>
    );
  }
);
Input.displayName = 'Input';

export { Input };
