import { Angle, IPosition } from '../../schema';
import { SerializedCustomInstrument } from '../instruments';

export enum ShapeType {
  Linear = 'line',
  BackToBack = 'back-to-back',
  LLeft = 'l-left',
  LRight = 'l-right',
  UShape = 'u-shape',
  Custom = 'custom'
}

export type OptionId = string;
export type OptionIdList = OptionId[];

export interface IAutomataState {
  productId: string;
  shapes: ShapeState[];
  customInstruments: SerializedCustomInstrument[];
}

export interface IOneLineShapeState {
  angle: Angle;
  position: IPosition;
  geometry: OptionIdList;
  instruments: OptionIdList;
}

export interface ILinearShapeState extends IOneLineShapeState {
  type: ShapeType.Linear;
}

export interface IBackToBackShapeState {
  type: ShapeType.BackToBack;
  angle: Angle;
  position: IPosition;
  geometry: OptionIdList;
  frontInstruments: OptionIdList;
  backInstruments: OptionIdList;
}

export interface ILShapeState extends IOneLineShapeState {
  type: ShapeType.LLeft;
}

export interface IRShapeState extends IOneLineShapeState {
  type: ShapeType.LRight;
}

export interface IUShapeState extends IOneLineShapeState {
  type: ShapeType.UShape;
}

export interface ICustomShapeState {
  type: ShapeType.Custom;
  lines: IOneLineShapeState[];
}

export type ShapeState =
  | ILinearShapeState
  | IBackToBackShapeState
  | ILShapeState
  | IRShapeState
  | IUShapeState
  | ICustomShapeState;
