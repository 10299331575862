export const duration = 0.2;

export const arrowBackAnimation = {
  initial: { transform: 'translateX(-100%)' },
  animate: { transform: 'translateX(0)' },
  exit: { transform: 'translateX(-100%)' }
};

export const rightPartAnimation = {
  initial: { transform: 'translateX(100%)', opacity: 0 },
  animate: { transform: 'translateX(0)', opacity: 1 },
  exit: { transform: 'translateX(100%)', opacity: 0 }
};

export const leftPartAnimation = {
  initial: { transform: 'translateY(400px)', opacity: 0 },
  animate: { transform: 'translateY(0)', opacity: 1 },
  exit: { transform: 'translateY(400px)', opacity: 0 }
};
