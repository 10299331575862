import React from 'react';
import { InstrumentsByCategories } from '../../../../stores/types';
import { IInstrument } from '../../../../schema';
import { ElementCard, ElementCardVariant } from '../../../../components/ElementCard';
import { AccordionContent, AccordionItem, AccordionTrigger } from '../../../../components';
import { localization } from '../../../../stores/Localization';
import { cardsListStyle } from './InstrumentsInsertionPanel';

interface InstrumentsByCategoriesAccordionListProps {
  instruments: InstrumentsByCategories;
  isSelected: (instrument: IInstrument) => boolean;
  isAvailable: (instrument: IInstrument) => boolean;
  onToggle: (instrument: IInstrument, selected: boolean) => void;
  cardVariant?: ElementCardVariant;
}
export const InstrumentsByCategoriesAccordionList: React.FC<InstrumentsByCategoriesAccordionListProps> = ({
  instruments,
  onToggle,
  isSelected,
  isAvailable,
  cardVariant = 'default'
}) => {
  return (
    <>
      {instruments.map(category => (
        <AccordionItem value={category.title} key={category.title}>
          <AccordionTrigger>{category.title}</AccordionTrigger>
          <AccordionContent>
            <div className={cardsListStyle}>
              {category.instruments.map((instrument, index) => (
                <ElementCard
                  key={instrument.name}
                  title={localization.formatMessage(instrument.name)}
                  image={instrument.image}
                  onToggle={selected => onToggle(instrument, selected)}
                  selected={isSelected(instrument)}
                  disabled={!isAvailable(instrument)}
                  disabledMessage={localization.formatMessage('installation.doesntFit')}
                  autoFocus={index === 0}
                  variant={cardVariant}
                />
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </>
  );
};
