import { makeAutoObservable } from 'mobx';
import {
  firstOpenPredefined,
  firstOpenSteps,
  firstOpenUShapeSteps,
  whenInstallationActivatedSteps,
  whenInstallationActivatedStepsOnMobile,
  whenInstrumentInstallationActivatedSteps,
  whenInstrumentInstallationActivatedStepsOnMobile
} from './onboardingStepConfig';
import { GetStepConfig, StepConfig } from '../../../components/Onboarding/types';
import { ConfigurationOnboardingStepData } from './types';
import { MobileDetector } from '../../../components/hooks/useMobileDetector';

export enum ConfigurationOnboardingType {
  FirstOpen = 'FirstOpen',
  FirstOpenUShape = 'FirstOpenUShape',
  FirstOpenPredefined = 'FirstOpenPredefined',
  FirstBenchInstallationMode = 'FirstBenchInstallationMode',
  FirstBenchInstallationModeMobile = 'FirstBenchInstallationModeMobile',
  FirstInstrumentInstallationMode = 'FirstInstrumentInstallationMode',
  FirstInstrumentInstallationModeMobile = 'FirstInstrumentInstallationModeMobile'
}

export type ConfigurationOnboardingState = {
  type: ConfigurationOnboardingType;
  isOnboardingCompleted: boolean;
  maxShownStep: number;
};

const defaultOnboardingState: Omit<ConfigurationOnboardingState, 'type'> = {
  isOnboardingCompleted: false,
  maxShownStep: 0
};

export type OnboardingStatus = 'completed' | 'uncompleted' | 'resetted';

// Have to be updated when states saved in the local storage are changed
const ONBOARDING_VERSION = 'v1.0';

const getDefaultOnboardingStates: () => Record<ConfigurationOnboardingType, ConfigurationOnboardingState> = () => {
  return {
    [ConfigurationOnboardingType.FirstOpen]: {
      type: ConfigurationOnboardingType.FirstOpen,
      ...defaultOnboardingState
    },
    [ConfigurationOnboardingType.FirstOpenUShape]: {
      type: ConfigurationOnboardingType.FirstOpenUShape,
      ...defaultOnboardingState
    },
    [ConfigurationOnboardingType.FirstOpenPredefined]: {
      type: ConfigurationOnboardingType.FirstOpenPredefined,
      ...defaultOnboardingState
    },
    [ConfigurationOnboardingType.FirstBenchInstallationMode]: {
      type: ConfigurationOnboardingType.FirstBenchInstallationMode,
      ...defaultOnboardingState
    },
    [ConfigurationOnboardingType.FirstBenchInstallationModeMobile]: {
      type: ConfigurationOnboardingType.FirstBenchInstallationModeMobile,
      ...defaultOnboardingState
    },
    [ConfigurationOnboardingType.FirstInstrumentInstallationMode]: {
      type: ConfigurationOnboardingType.FirstInstrumentInstallationMode,
      ...defaultOnboardingState
    },
    [ConfigurationOnboardingType.FirstInstrumentInstallationModeMobile]: {
      type: ConfigurationOnboardingType.FirstInstrumentInstallationModeMobile,
      ...defaultOnboardingState
    }
  };
};

const interchangeableOnboardingTypes = [
  [ConfigurationOnboardingType.FirstOpen, ConfigurationOnboardingType.FirstOpenUShape],
  [
    ConfigurationOnboardingType.FirstBenchInstallationModeMobile,
    ConfigurationOnboardingType.FirstBenchInstallationMode,
    ConfigurationOnboardingType.FirstInstrumentInstallationMode,
    ConfigurationOnboardingType.FirstInstrumentInstallationModeMobile
  ]
];

const LocalStorageKeys = {
  states: 'configurationOnboardingStates',
  version: 'configurationOnboardingVersion',
  status: 'configurationOnboardingStatus'
} as const;

export class ConfigurationOnboardingStore {
  activeOnboardingType: ConfigurationOnboardingType | null = null;
  onboardingStates: Record<ConfigurationOnboardingType, ConfigurationOnboardingState> = getDefaultOnboardingStates();

  private mobileDetector: MobileDetector = {
    isMobile: false,
    isHoveringSupported: false,
    isMobileScreenSize: false
  };
  private steps: Record<ConfigurationOnboardingType, GetStepConfig<ConfigurationOnboardingStepData>> = {
    [ConfigurationOnboardingType.FirstOpen]: firstOpenSteps,
    [ConfigurationOnboardingType.FirstOpenUShape]: firstOpenUShapeSteps,
    [ConfigurationOnboardingType.FirstOpenPredefined]: firstOpenPredefined,
    [ConfigurationOnboardingType.FirstBenchInstallationMode]: whenInstallationActivatedSteps,
    [ConfigurationOnboardingType.FirstBenchInstallationModeMobile]: whenInstallationActivatedStepsOnMobile,
    [ConfigurationOnboardingType.FirstInstrumentInstallationMode]: whenInstrumentInstallationActivatedSteps,
    [ConfigurationOnboardingType.FirstInstrumentInstallationModeMobile]:
      whenInstrumentInstallationActivatedStepsOnMobile
  };

  private status: OnboardingStatus = 'uncompleted';

  constructor() {
    makeAutoObservable(this);
    this.fetchFromStorage();
  }

  setIsMobile(mobileDetector: MobileDetector) {
    this.mobileDetector = mobileDetector;
  }
  private fetchFromStorage() {
    const onboardingStates = localStorage.getItem(LocalStorageKeys.states);
    const version = localStorage.getItem(LocalStorageKeys.version);

    // Onboarding was updated, reset state
    if (version !== ONBOARDING_VERSION) {
      this.saveToStorage();
      return;
    }

    if (onboardingStates) {
      try {
        this.onboardingStates = { ...this.onboardingStates, ...JSON.parse(onboardingStates) };
        this.status = (localStorage.getItem(LocalStorageKeys.status) as OnboardingStatus) || 'uncompleted';
      } catch (e) {
        console.error('Error while parsing onboarding states from storage', e);
      }
    }
  }

  private saveToStorage() {
    localStorage.setItem(LocalStorageKeys.states, JSON.stringify(this.onboardingStates));
    localStorage.setItem(LocalStorageKeys.status, this.status);
    localStorage.setItem(LocalStorageKeys.version, ONBOARDING_VERSION);
  }

  setActiveOnboardingType(type: ConfigurationOnboardingType) {
    this.activeOnboardingType = type;
  }

  get onboardingStatus(): OnboardingStatus {
    return this.status;
  }

  get hasActiveOnboarding(): boolean {
    return this.activeOnboardingType !== null;
  }

  get initialStepIndex(): number {
    if (this.activeOnboardingType) {
      return this.onboardingStates[this.activeOnboardingType].maxShownStep;
    }
    return 0;
  }

  canStartOnboarding(type: ConfigurationOnboardingType): boolean {
    if (this.status === 'completed') {
      return false;
    }

    if (this.onboardingStates[type].isOnboardingCompleted) {
      return false;
    }

    for (const dependency of interchangeableOnboardingTypes) {
      if (dependency.includes(type)) {
        return dependency.every(type => !this.onboardingStates[type].isOnboardingCompleted);
      }
    }

    return true;
  }

  startOnboarding(type: ConfigurationOnboardingType): boolean {
    if (this.canStartOnboarding(type)) {
      this.status = 'uncompleted';
      this.setActiveOnboardingType(type);
      return true;
    }
    return false;
  }

  completeActiveOnboarding() {
    if (this.activeOnboardingType) {
      this.onboardingStates[this.activeOnboardingType].isOnboardingCompleted = true;

      // Complete all interchangeable onboarding
      for (const interchangeableTypes of interchangeableOnboardingTypes) {
        if (interchangeableTypes.includes(this.activeOnboardingType)) {
          for (const type of interchangeableTypes) {
            this.onboardingStates[type].isOnboardingCompleted = true;
          }
        }
      }
      this.checkIfAllOnboardingAreCompleted();
      this.saveToStorage();
    }

    this.activeOnboardingType = null;
  }
  private checkIfAllOnboardingAreCompleted() {
    const areAllOnboardingCompleted = Object.values(this.onboardingStates).every(
      onboardingState => onboardingState.isOnboardingCompleted
    );
    if (areAllOnboardingCompleted) {
      this.status = 'completed';
      this.saveToStorage();
    }
  }
  updateMaxShownStep(stepIndex: number) {
    if (this.activeOnboardingType) {
      // TODO: Can't start from the second step, because waiting  canvas render doesn't work on onboarding initialization
      if (
        this.activeOnboardingType === ConfigurationOnboardingType.FirstBenchInstallationMode ||
        this.activeOnboardingType === ConfigurationOnboardingType.FirstBenchInstallationModeMobile ||
        this.activeOnboardingType === ConfigurationOnboardingType.FirstInstrumentInstallationMode
      ) {
        return;
      }
      if (stepIndex > this.onboardingStates[this.activeOnboardingType].maxShownStep) {
        this.onboardingStates[this.activeOnboardingType].maxShownStep = stepIndex;
      }
      this.saveToStorage();
    } else {
      console.error('No active onboarding type');
    }
  }

  get activeOnboardingSteps(): Array<StepConfig<ConfigurationOnboardingStepData>> {
    if (this.activeOnboardingType) {
      return this.steps[this.activeOnboardingType](this.mobileDetector);
    }
    return [];
  }
  configurationWasOpened(isPredefined: boolean, isUShape: boolean) {
    if (isPredefined) {
      this.startOnboarding(ConfigurationOnboardingType.FirstOpenPredefined);
    } else {
      this.startOnboarding(
        isUShape ? ConfigurationOnboardingType.FirstOpenUShape : ConfigurationOnboardingType.FirstOpen
      );
    }
  }

  installationModeWasActivated() {
    this.startOnboarding(
      this.mobileDetector.isMobile
        ? ConfigurationOnboardingType.FirstBenchInstallationModeMobile
        : ConfigurationOnboardingType.FirstBenchInstallationMode
    );
  }
  instrumentInstallationModeWasActivated() {
    this.startOnboarding(
      this.mobileDetector.isMobile
        ? ConfigurationOnboardingType.FirstInstrumentInstallationModeMobile
        : ConfigurationOnboardingType.FirstInstrumentInstallationMode
    );
  }

  resetOnboarding() {
    this.status = 'resetted';
    this.onboardingStates = getDefaultOnboardingStates();
    this.activeOnboardingType = null;
    this.saveToStorage();
  }

  skipOnboarding() {
    this.status = 'completed';
    this.activeOnboardingType = null;
    this.saveToStorage();
  }
}
