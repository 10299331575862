import { BoxGeometry, Mesh, MeshBasicMaterial, Object3D, PerspectiveCamera, Vector3 } from 'three';
import { Size } from '../../domain/viewer/types';
import { BoundingBoxService } from '../../domain/camera/BoundingBoxService';
import { Boundary } from '../../domain/camera/types';
import { ConfigurationScene } from '../../domain/viewer/ConfigurationScene';

/**
 * Project the bounding box of  Object3D's list into the canvas using PerspectiveCamera
 * and return the square containing the projection with coordinates relative to the window
 * @param nodes
 * @param camera
 * @param size - size of the canvas
 * @param scene
 */
export const getRectangleFromCanvas = (
  nodes: Array<Object3D>,
  camera: PerspectiveCamera,
  size: Size,
  scene?: ConfigurationScene
) => {
  const boundingBox = new BoundingBoxService();
  const rects: Array<Boundary> = [];

  for (let i = 0; i < nodes.length; i++) {
    let box = boundingBox.getBoundingBox(nodes[i]);
    // TODO: This is for debugging.  Remove this code after debugging or move to helper
    // const vector = new Vector3(0, 0, 0);
    // const vector2 = new Vector3(0, 0, 0);
    // box.getCenter(vector);
    // box.getSize(vector2);
    // const geometry = new BoxGeometry(vector2.x, vector2.y, vector2.z);
    // const material = new MeshBasicMaterial({ color: 0x00ff00 });
    // const cube = new Mesh(geometry, material);
    // cube.position.set(vector.x, vector.y, vector.z);
    //
    // scene?.getScene().add(cube);
    rects.push(boundingBox.getBoundingBoxProjection(box, camera, size));
  }

  const canvas = document.querySelector('canvas');
  if (!canvas) {
    throw new Error('Canvas not found');
  }
  const canvasOffset = canvas.getClientRects()[0];
  const minX = rects.reduce((min, rect) => Math.min(min, rect.minX), rects[0].minX);
  const minY = rects.reduce((min, rect) => Math.min(min, rect.minY), rects[0].minY);
  const maxX = rects.reduce((max, rect) => Math.max(max, rect.maxX), rects[0].maxX);
  const maxY = rects.reduce((max, rect) => Math.max(max, rect.maxY), rects[0].maxY);

  const width = maxX - minX;
  const height = maxY - minY;

  return {
    left: minX + canvasOffset.x - (height > width ? (height - width) / 2 : 0),
    top: minY + canvasOffset.y - (width > height ? (width - height) / 2 : 0),
    width: Math.max(width, height),
    height: Math.max(width, height)
  };
};
