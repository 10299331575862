import React, { useRef, useState } from 'react';
import { getOptionId } from '@canvas-logic/engine';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../../components';
import { CustomInstrumentsBanner } from '../CustomInstrumentsBanner';
import { localization } from '../../../../stores/Localization';
import { ICustomInstrument, IInstrument, InstrumentCategory } from '../../../../schema';
import { CustomInstrumentForm } from '../CustomInstrumentForm';
import { HorizontalElementCard } from '../../../../components/ElementCard';
import { InstrumentsByCategories } from '../../../../stores/types';
import { ActiveMode } from '../../../../domain/modes';
import { CustomInstrumentDimensions } from '../../../../domain/instruments';
import { getDimensionsString, scrollIntoNestedView } from '../../../../components/utils';
import { InstrumentsByCategoriesAccordionList } from './InstrumentsByCategoriesAccordionList';

export const cardsListStyle = 'grid grid-cols-1 gap-2  md:grid-cols-2 md2:grid-cols-3 ls:grid-cols-1 xl:grid-cols-2';
export const customInstrumentsCardsListStyle = 'grid grid-cols-1 gap-2 p-[2px] max-ls:grid-cols-2 max-md2:grid-cols-1';

interface ElementsListProps {
  standardInstruments: InstrumentsByCategories;
  customInstruments: ICustomInstrument[];
  mode: ActiveMode;

  onToggle: (instrument: IInstrument, selected: boolean) => void;
  isAvailable: (instrument: IInstrument) => boolean;
  createCustomInstrument: (dimensions: CustomInstrumentDimensions, name: string) => void;
}

export const InstrumentsInsertionPanel: React.FC<ElementsListProps> = observer(
  ({ standardInstruments, onToggle, mode, isAvailable, createCustomInstrument, customInstruments }) => {
    const [accordionValue, setAccordionValue] = useState<string>();
    const [justCreatedInstrument, setJustCreatedInstrument] = useState(false);

    const customInstrumentsFormRef = useRef<HTMLFormElement>(null);

    const onAccordionValueChange = (value: string): void => {
      setAccordionValue(value);
      setJustCreatedInstrument(false);
    };

    const scrollIntoView = () => {
      if (customInstrumentsFormRef.current) {
        scrollIntoNestedView(customInstrumentsFormRef.current);
      }
    };

    const scrollToCustomInstruments = () => {
      if (accordionValue === InstrumentCategory.custom) {
        scrollIntoView();
        return;
      }
    };

    const onCustomInstrumentsLinkClick = () => {
      if (accordionValue === InstrumentCategory.custom) {
        scrollIntoView();
        return;
      }
      setAccordionValue(InstrumentCategory.custom);
    };

    const isInstrumentSelectedForInsertion = (instrument: IInstrument): boolean => {
      return mode.type === 'insertingInstrument' && getOptionId(mode.instrumentToAdd) === getOptionId(instrument);
    };

    const formTitle: React.ReactNode = (
      <p className="typography-body1 mb-6">
        <FormattedMessage id="configurator.menu.customInstruments.form.formTitle" />
      </p>
    );

    const createCustomInstrumentHandler = (dimensions: CustomInstrumentDimensions, name: string): void => {
      createCustomInstrument(dimensions, name);
      setJustCreatedInstrument(true);
    };

    return (
      <Accordion
        type="single"
        collapsible
        className="w-full"
        value={accordionValue}
        onValueChange={onAccordionValueChange}
      >
        <CustomInstrumentsBanner onClick={onCustomInstrumentsLinkClick} />

        <InstrumentsByCategoriesAccordionList
          instruments={standardInstruments}
          onToggle={onToggle}
          isAvailable={isAvailable}
          isSelected={isInstrumentSelectedForInsertion}
          cardVariant="default"
        />

        <AccordionItem value={InstrumentCategory.custom} key={InstrumentCategory.custom}>
          <AccordionTrigger>
            <FormattedMessage id="instruments.category.custom" />
          </AccordionTrigger>
          <AccordionContent onAnimationEnd={scrollToCustomInstruments}>
            <CustomInstrumentForm
              onSubmit={createCustomInstrumentHandler}
              title={formTitle}
              className="mb-2"
              ref={customInstrumentsFormRef}
            />

            {!customInstruments.length && (
              <div className="typography-body2 mt-6 w-full text-center text-neutral-500">
                <FormattedMessage id="configurator.menu.customInstruments.noCustomInstrumentsYet" />
              </div>
            )}

            <div className={customInstrumentsCardsListStyle}>
              {[...customInstruments].reverse().map((instrument, index) => (
                <HorizontalElementCard
                  key={instrument.uuid}
                  title={instrument.name}
                  image={instrument.image}
                  onToggle={selected => onToggle(instrument, selected)}
                  selected={isInstrumentSelectedForInsertion(instrument)}
                  disabled={!isAvailable(instrument)}
                  disabledMessage={localization.formatMessage('configurator.menu.doesNotFit')}
                  description={getDimensionsString(instrument.length, instrument.width, instrument.height)}
                  recent={justCreatedInstrument && index === 0}
                />
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }
);
