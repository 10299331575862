/*** 
  Autogenerated by ts-generator 7.3.5.
  Schema: public/assets/dataset/schema.yml
  Date: 2024-02-05T07:49:53.758Z
***/
import { MLString, BigNumber, WithOptionId, DatasetService, ExternalFieldRegistry } from '@canvas-logic/engine';
export type GeometryElement = ISection | IConnection;
export type GeometryElementList = GeometryElement[];
export type InstrumentList = IInstrument[];
export interface ISection {
  uuid: string;
  hasTransport: boolean;
  hasManipulator: boolean;
  size: number;
  alignment: SectionAlignment;
  image: string;
  name: string;
}
export interface IPosition {
  x: number;
  y: number;
}
export type Angle = 0 | 180 | 90 | -90;
export enum SectionAlignment {
  L = 'L',
  R = 'R'
}
export enum ConnectionSide {
  L = 'L',
  R = 'R'
}
export enum ConnectionJoin {
  aside = 'aside',
  along = 'along'
}
export enum InstrumentCategory {
  reader = 'reader',
  centrifuge = 'centrifuge',
  liquid_handlers = 'liquid_handlers',
  peeler = 'peeler',
  sealing = 'sealing',
  liconic_licotel = 'liconic_licotel',
  incubator = 'incubator',
  thermocycler = 'thermocycler',
  washer = 'washer',
  dispenser = 'dispenser',
  imaging = 'imaging',
  custom = 'custom'
}
export interface IConnection {
  side: ConnectionSide;
  join: ConnectionJoin;
}
export interface IInstrument {
  uuid: string;
  name: string;
  category: InstrumentCategory;
  image: string;
  view: string;
  width: number;
  length: number;
}
export interface ICustomInstrument {
  uuid: string;
  name: string;
  category: InstrumentCategory;
  image: string;
  view: string;
  width: number;
  length: number;
  height: number;
}
export interface IPositionAngleCmp {
  angle: Angle;
  position: IPosition;
}
export interface ILine {
  angle: Angle;
  position: IPosition;
  uuid: string;
  geometry: GeometryElementList;
  instruments: InstrumentList;
}
export interface IInstrumentLine {
  uuid: string;
  instruments: InstrumentList;
}
export type LineList = ILine[];
export type ShapeList = Shape[];
export interface IAngleConstraintCmp {
  maxCorners: number;
  minCorners: number;
}
export interface ISingleLineShapeCmp {
  uuid: string;
  geometry: GeometryElementList;
  instruments: InstrumentList;
}
export interface ILinearShape {
  uuid: string;
  geometry: GeometryElementList;
  instruments: InstrumentList;
  angle: Angle;
  position: IPosition;
  maxCorners: number;
  minCorners: number;
}
export interface ILShape {
  uuid: string;
  geometry: GeometryElementList;
  instruments: InstrumentList;
  angle: Angle;
  position: IPosition;
  maxCorners: number;
  minCorners: number;
}
export interface IUShape {
  uuid: string;
  geometry: GeometryElementList;
  instruments: InstrumentList;
  angle: Angle;
  position: IPosition;
  maxCorners: number;
  minCorners: number;
}
export interface IBackToBackShape {
  angle: Angle;
  position: IPosition;
  maxCorners: number;
  minCorners: number;
  geometry: GeometryElementList;
  front: IInstrumentLine;
  back: IInstrumentLine;
}
export interface ICustomShape {
  maxCorners: number;
  minCorners: number;
  lines: LineList;
}
export type Shape = ILinearShape | IBackToBackShape | ILShape | IUShape | ICustomShape;
export interface IConfiguration {
  manipulatorBenchWidth: number;
  benchLength: number;
  railLength: number;
  benchWidth: number;
  benchView: string;
  transportBenchView: string;
  manipulatorBenchView: string;
  manipulatorView: string;
  manipulatorRailView: string;
  shapes: ShapeList;
}
