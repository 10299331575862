import FileSaver from 'file-saver';
import QRCodeGenerator from 'qrcode';
import { contactService, PdfCustomInstrumentEquipment, PdfDto, PdfDtoBase, PdfEquipment } from './ContactService';
import { SummaryEquipment, SummaryReport } from './SummaryService';
import { localization } from '../stores/Localization';
import { createUrl } from './utils';
import { ConfigurationMode } from '../domain/modes';
import { rootStore } from '../stores/RootStore';
import { routes } from '../routes';
import { InstrumentCategory } from '../schema';

export class PdfDownloaderService {
  private static readonly PDF_FILE_PREFIX = 'Automata_Lab_Configuration';
  private static readonly LINQ_BROCHURE_LINK =
    'https://automata.tech/wp-content/uploads/2023/11/Automata-LINQ-product-brochure.pdf';

  constructor(
    private readonly summaryData: SummaryReport,
    private readonly bluePrint: string,
    private readonly configurationLink: string,
    private readonly mode: ConfigurationMode
  ) {}

  public async downloadPdf() {
    const dto = await this.createPdfDto();
    const pdfBlob = await contactService.generatePdf(dto);
    await this.savePdfFile(pdfBlob);
  }

  public async createPdfDto(): Promise<PdfDto> {
    const linkToOwnConfigurator = rootStore.location.origin + rootStore.location.pathname + '#' + routes.own.path;
    const { topViewDimensions, ...necessaryData } = this.summaryData;
    const configurationQrImageBase64 = await QRCodeGenerator.toDataURL(this.configurationLink);
    const linqBrochureQrImageBase64 = await QRCodeGenerator.toDataURL(PdfDownloaderService.LINQ_BROCHURE_LINK);

    const benches = this.normalizeData(necessaryData.benches);
    const instruments = this.normalizeData(necessaryData.instruments);
    const customInstruments = this.normalizeCustomInstruments(necessaryData.customInstruments);

    const pdfDtoBase: PdfDtoBase = { benches, instruments, customInstruments, sceneImage: necessaryData.sceneImage };

    return {
      ...pdfDtoBase,
      mode: this.mode,
      topViewImage: this.bluePrint,
      configurationLinkQrImage: configurationQrImageBase64,
      configurationLink: this.configurationLink,
      linqBrochureLinkQrImage: linqBrochureQrImageBase64,
      linqBrochureLink: PdfDownloaderService.LINQ_BROCHURE_LINK,
      linkToOwnConfigurator: linkToOwnConfigurator
    };
  }

  private normalizeData(items: SummaryEquipment[]): PdfEquipment[] {
    return items.map(item => ({
      name: localization.formatMessage(item.name),
      image: createUrl(item.image),
      count: item.count,
      dimensions: item.dimensions
    }));
  }

  private normalizeCustomInstruments(items: SummaryEquipment[]): PdfCustomInstrumentEquipment[] {
    return items.map(item => ({
      name: item.name,
      image: createUrl(item.image),
      count: item.count,
      dimensions: item.dimensions,
      customInstrument: true
    }));
  }

  public static generateFileName(): string {
    const now = new Date();
    const dateString = new Intl.DateTimeFormat('en-GB').format(now).replaceAll(/[\/_]/g, '.');

    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const timeString = `${hours}_${minutes}_${seconds}`;
    return `${PdfDownloaderService.PDF_FILE_PREFIX}_${dateString}_${timeString}.pdf`;
  }

  private async savePdfFile(pdfBlob: Blob): Promise<void> {
    const fileName = PdfDownloaderService.generateFileName();
    FileSaver.saveAs(pdfBlob, fileName);
  }
}
