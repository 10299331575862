import { FormattedMessage } from 'react-intl';
import React from 'react';
import { toUSLocaleString } from '../../../../../services/utils';
import { Sizes } from '../../../../../domain/geometry/types';

interface ISummaryTopView {
  children: React.ReactNode;
  dimensions: Sizes;
}

export const SummaryTopView = ({ children, dimensions }: ISummaryTopView) => {
  return (
    <div className="h-auto">
      <h1 className="typography-h5">
        <FormattedMessage id="summary.topViewTitle" />
      </h1>
      <p className="typography-body2 mb-5 text-neutral-500">
        {`${toUSLocaleString(dimensions.length)} x ${toUSLocaleString(dimensions.width)} x ${toUSLocaleString(
          dimensions.height
        )} mm`}
      </p>
      {children}
    </div>
  );
};
