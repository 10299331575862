export type Task = () => Promise<void>;

interface TaskRecord {
  task: Task;
  resolve: () => void;
  reject: (e: any) => void;
}

export class AsyncQueue {
  private tasks: TaskRecord[] = [];
  private pendingTask = false;

  async add(task: Task): Promise<void> {
    return new Promise((resolve, reject) => {
      this.tasks.push({ task, resolve, reject });
      this.processQueue();
    });
  }

  clear() {
    this.tasks = [];
  }

  remove(task: Task): boolean {
    const taskIndex = this.tasks.findIndex(t => t.task === task);
    if (taskIndex !== -1) {
      this.tasks.splice(taskIndex, 1);
      return true;
    }

    return false;
  }

  private processQueue() {
    if (this.tasks.length > 0) {
      if (!this.pendingTask) {
        const { task, resolve, reject } = this.tasks.shift()!;

        (async () => {
          this.pendingTask = true;
          try {
            await task();
            resolve();
          } catch (e) {
            reject(e);
          } finally {
            this.pendingTask = false;
          }
          this.processQueue();
        })();
      }
    }
  }
}
