import { ModelMutator, MutatorContext, TypeReference, ValidationResult } from '@canvas-logic/engine';
import { IConfiguration } from '../../schema';
import { BenchMutationsValidator } from './validators';

export class BaseBenchMutator implements ModelMutator {
  mutate(context: MutatorContext, typeRef: TypeReference, model: IConfiguration): [IConfiguration, ValidationResult] {
    const [newModel, validationResult] = this.mutateBase(context, typeRef, model);

    if (!validationResult.isValid) {
      return [newModel, validationResult];
    }

    const baseStateValidationResult = this.validate(newModel);
    if (baseStateValidationResult.isValid) {
      return [newModel, ValidationResult.Valid()];
    } else {
      return [newModel, baseStateValidationResult];
    }
  }

  private validate(newModel: IConfiguration): ValidationResult {
    const validationResult = BenchMutationsValidator.validate(newModel);
    if (validationResult) {
      return ValidationResult.Valid();
    } else {
      return ValidationResult.Error('Validation failed');
    }
  }
  mutateBase(
    context: MutatorContext,
    typeRef: TypeReference,
    model: IConfiguration
  ): [IConfiguration, ValidationResult] {
    return [model, ValidationResult.Valid()];
  }
}
