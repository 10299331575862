import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { rootStore } from '../../stores/RootStore';
import { ConfigurationViewer, ViewModel } from '../../domain/viewer';
import { IConfiguration, IInstrument, ISection } from '../../schema';
import {
  BenchInsertPosition,
  InsertionLayoutPayload,
  InstrumentInsertionLayoutPayload,
  InstrumentInsertPosition,
  LayoutItem,
  SectionInsertionLayoutPayload
} from '../../domain/layout';
import {
  benchInsertionLayoutItemTypeGuard,
  benchLayoutItemTypeGuard,
  instrumentInsertionLayoutItemTypeGuard,
  instrumentLayoutItemTypeGuard
} from '../../domain/typeGuards';
import { useIsMobileScreenSize } from '../hooks/useIsMobileScreenSize';
import { useIsHoverable } from '../hooks/useMediaQuery';
import { SelectableElement } from '../../domain/modes';
import { InsertionApproachType } from 'domain/viewer/types';
import { CameraView } from '../../domain/camera';
import { BENCH_INSERTION, INSTRUMENT_INSERTION } from '../../domain/viewer/ConfigurationScene';
import { Orchestrator } from '../../services/Orchestrator';

interface IProps {
  viewModel: ViewModel;
  cameraView: CameraView;
  model: IConfiguration;
  activeUUID?: string; // for example instrument silhouette while replacing
  selectedUUID?: string; // for outline of selected instrument
  hoveredUUID?: string;
  hoveredPlaceholder?: LayoutItem<InsertionLayoutPayload>;
  availableInstrumentAreas: LayoutItem<InstrumentInsertionLayoutPayload>[];
  availableBenchAreas: LayoutItem<SectionInsertionLayoutPayload>[];

  onViewerCreated: (viewer: ConfigurationViewer) => void;
  onViewerRendered: (viewer: ConfigurationViewer) => void;

  onElementHovered: (element: SelectableElement | null) => void;
  onInsertionPlaceholderHovered: (payload?: LayoutItem<InsertionLayoutPayload>) => void;

  onInstrumentSelected: (instrument: IInstrument) => void;
  onBenchSelected: (bench: ISection) => void;
  onInstrumentInsertionSelected: (position: InstrumentInsertPosition) => void;
  onBenchInsertionSelected: (position: BenchInsertPosition) => void;

  onDeselected: () => void;

  showRuler: boolean;
}

export const Viewer: React.FC<IProps> = ({
  viewModel,
  model,
  activeUUID,
  selectedUUID,
  hoveredUUID,
  hoveredPlaceholder,
  onViewerCreated,
  onViewerRendered,
  onElementHovered,
  onInsertionPlaceholderHovered,
  onDeselected,
  availableInstrumentAreas,
  availableBenchAreas,
  onInstrumentSelected,
  onBenchSelected,
  onInstrumentInsertionSelected,
  onBenchInsertionSelected,
  showRuler,
  cameraView
}) => {
  const isScreenMobile = useIsMobileScreenSize();
  const hoverable = useIsHoverable();
  const isMobile = isScreenMobile ? isScreenMobile : !hoverable;
  const insertionApproach: InsertionApproachType = isMobile ? 'silhouettes' : 'rectanglePlaceholders';

  const canvasRef = createRef<HTMLCanvasElement>();
  const viewerRef = useRef<ConfigurationViewer | undefined>();
  const [orchestrator] = useState<Orchestrator>(() => new Orchestrator());
  const [initialized, setInitialized] = useState(false);

  const handleSelection = useCallback(
    (item: LayoutItem | undefined) => {
      if (item?.payload !== undefined) {
        switch (item.payload.type) {
          case 'section':
            onBenchSelected(item.payload.section);
            break;
          case 'instrument':
            onInstrumentSelected(item.payload.instrument);
            break;
          case 'instrumentInsertion':
            onInstrumentInsertionSelected(item.payload.position);
            break;
          case 'sectionInsertion':
            onBenchInsertionSelected(item.payload.position);
            break;
        }
      } else {
        onDeselected();
      }
    },
    [onBenchSelected, onInstrumentSelected, onDeselected]
  );

  const handleHoverSelection = useCallback(
    (item?: LayoutItem, name?: string) => {
      // TODO: determine if it is a good solution to pass name here
      if (name === BENCH_INSERTION || name === INSTRUMENT_INSERTION) {
        return;
      }

      if (item?.payload) {
        if (instrumentLayoutItemTypeGuard(item)) {
          onElementHovered(item.payload.instrument);
          onInsertionPlaceholderHovered(undefined);
        } else if (instrumentInsertionLayoutItemTypeGuard(item)) {
          onInsertionPlaceholderHovered(item);
        } else if (benchInsertionLayoutItemTypeGuard(item)) {
          onInsertionPlaceholderHovered(item);
        } else if (benchLayoutItemTypeGuard(item)) {
          onElementHovered(item.payload.section);
          onInsertionPlaceholderHovered(undefined);
        }
      } else {
        onElementHovered(null);
        onInsertionPlaceholderHovered(undefined);
      }
    },
    [onElementHovered]
  );

  useEffect(() => {
    function handleKeyPress(event: any) {
      // shift + r
      if (event.keyCode === 82 && event.shiftKey) {
        viewerRef.current?.resetCamera(cameraView);
      }
    }
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [cameraView]);

  useEffect(() => {
    let lastTap: number = 0;
    const doubleTapDelay = 250;
    function doubleTap(event: any) {
      const now = Date.now();
      if (event.targetTouches.length === 1 && now - lastTap < doubleTapDelay) {
        viewerRef.current?.resetCamera(cameraView);
        event.preventDefault();
      }
      lastTap = now;
    }
    window.addEventListener('touchstart', doubleTap);
    return () => {
      window.removeEventListener('touchstart', doubleTap);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (canvasRef.current) {
        const viewer = new ConfigurationViewer(canvasRef.current, rootStore.resourceLoader);
        viewerRef.current = viewer;
        orchestrator.injectViewer(viewer);

        viewer.setSelectionListener(handleSelection);
        viewer.setPointerListener(handleHoverSelection);

        await viewer.init(viewModel, cameraView, false);
        // viewer.setDimensions(store.viewModel.dimensions.width, store.viewModel.dimensions.height);

        await orchestrator.initialRender(viewModel);
        setInitialized(true);
        // viewer.updateCamera(cameraView);
        onViewerRendered(viewer);
        onViewerCreated(viewer);
      }
    })();

    return () => {
      viewerRef.current?.dispose();
    };
  }, []);

  useEffect(() => {
    orchestrator.changeViewModel(viewModel);
  }, [viewModel]);

  useEffect(() => {
    orchestrator.selectedChanged(selectedUUID);
  }, [selectedUUID]);

  useEffect(() => {
    orchestrator.changeRuler(showRuler);
  }, [showRuler]);

  useEffect(() => {
    orchestrator.changeActive(activeUUID);
  }, [activeUUID]);

  useEffect(() => {
    orchestrator.changeHovered(hoveredUUID);
  }, [hoveredUUID]);

  useEffect(() => {
    orchestrator.changeAvailableInstrumentAreas(availableInstrumentAreas, insertionApproach);
  }, [availableInstrumentAreas]);

  useEffect(() => {
    // wait for render to be finished
    orchestrator.changeAvailableBenchAreas(availableBenchAreas, cameraView, insertionApproach);
  }, [availableBenchAreas]);

  useEffect(() => {
    orchestrator.hoverPlaceholders(hoveredPlaceholder, model);
  }, [hoveredPlaceholder]);

  useEffect(() => {
    if (initialized) {
      orchestrator.transitionCamera(cameraView);
    }
  }, [initialized, cameraView]);

  return (
    <div id="viewer" className="relative h-full w-full overflow-hidden">
      <canvas
        className="absolute bottom-0 left-0 right-0 top-0 block h-full w-full transition-all"
        ref={canvasRef}
      ></canvas>
    </div>
  );
};
