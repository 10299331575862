import { IConfiguration, IInstrument } from '../../schema';
import { ModelMutator, MutatorContext, TypeReference, ValidationResult } from '@canvas-logic/engine';
import { ElementPositionById } from '../layout';
import { generateUUID } from 'three/src/math/MathUtils';
import { findInstrumentLocationByUUID } from './utils';
import { notFoundInMutator } from '../errors/notFound';

export class ReplaceInstrumentMutator implements ModelMutator {
  constructor(private readonly instrument: IInstrument, private readonly position: ElementPositionById) {
    this.instrument = { ...instrument, uuid: generateUUID() };
  }

  mutate(context: MutatorContext, typeRef: TypeReference, model: IConfiguration): [IConfiguration, ValidationResult] {
    const { instruments, index } = findInstrumentLocationByUUID(model, this.position.uuid);
    if (index >= 0) {
      instruments.splice(index, 1, this.instrument);
      return [model, ValidationResult.Valid()];
    }
    return [model, ValidationResult.Error(notFoundInMutator())];
  }
}
