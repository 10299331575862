import { getTypeRef } from '@canvas-logic/engine';
import { ShapeService, ShapeServiceModel } from './types';
import { BenchLayoutService, InstrumentLayoutService, LayoutItem, InstrumentLayoutPayload } from '../layout';
import { IBackToBackShape, InstrumentList } from '../../schema';
import { createBasis, SegmentLine } from '../geometry';
import { sectionTypeGuard } from '../typeGuards';
import { AvailableAreasService } from '../../services';
import { CameraPositionService } from '../camera/CameraPositionService';

export class BackToBackShapeService implements ShapeService {
  type: string;
  benchItems: LayoutItem[] = [];
  instrumentItems: LayoutItem<InstrumentLayoutPayload>[] = [];

  constructor(
    private model: ShapeServiceModel,
    private shape: IBackToBackShape,
    availableAreasService: AvailableAreasService,
    cameraPositionService: CameraPositionService
  ) {
    this.type = getTypeRef(shape);

    const basis = createBasis().angle(shape.angle).position(shape.position.x, shape.position.y);

    let benchesAmount = 0;
    let sectionsAmount = 0;
    const backGeometry = shape.geometry.concat().reverse();
    for (const sectionElement of shape.geometry) {
      if (sectionTypeGuard(sectionElement)) {
        benchesAmount += sectionElement.size;
        sectionsAmount++;
      }
    }
    const front = basis.local(basis => basis.position(0, 0.5 * this.model.benchWidth));
    const back = basis.local(basis =>
      basis.position(this.model.benchLength * benchesAmount, -0.5 * this.model.benchWidth).angle(180)
    );

    const frontBenchService = new BenchLayoutService(
      this.model,
      {
        geometry: shape.geometry,
        angle: front.angle,
        position: front.position,
        uuid: shape.front.uuid
      },
      true
    );
    const backBenchService = new BenchLayoutService(
      this.model,
      {
        geometry: backGeometry,
        angle: back.angle,
        position: back.position,
        uuid: shape.back.uuid
      },
      true
    );
    cameraPositionService.updateByBackToBackSegmentLines(frontBenchService.segmentLine, backBenchService.segmentLine);

    this.addBenchItems(backBenchService, frontBenchService);
    this.addInstruments(
      shape.front.instruments,
      frontBenchService.segmentLine,
      availableAreasService,
      shape.front.uuid
    );
    this.addInstruments(shape.back.instruments, backBenchService.segmentLine, availableAreasService, shape.back.uuid);

    availableAreasService.setBenchLayoutService({
      type: 'backToBack',
      services: [frontBenchService, backBenchService]
    });
  }

  private addInstruments(
    instruments: InstrumentList,
    segmentLine: SegmentLine,
    availableAreasContainer: AvailableAreasService,
    lineId: string
  ) {
    const service = new InstrumentLayoutService(this.model, instruments, segmentLine, lineId);
    this.instrumentItems.push(
      ...service.items.map(
        (item): LayoutItem<InstrumentLayoutPayload> => ({
          centerX: item.centerX,
          centerY: item.centerY,
          groupId: item.payload.instrument.uuid,
          angle: item.angle,
          view: item.payload.instrument.view,
          payload: { type: 'instrument', instrument: item.payload.instrument }
        })
      )
    );

    availableAreasContainer.setInstrumentLayoutService(service);

    this.instrumentItems.push(...service.items);
  }

  private addBenchItems(backService: BenchLayoutService, frontService: BenchLayoutService) {
    for (let i = 0; i < frontService.items.length; i++) {
      this.benchItems.push(frontService.items[i]);
      this.benchItems.push(backService.items[i]);
    }
  }
}
