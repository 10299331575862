import { GeometryElementList, IConfiguration, ISection } from '../../schema';
import { backToBackShapeTypeGuard, customShapeTypeGuard, sectionTypeGuard } from '../typeGuards';
import { LineTraverser } from './types';

export function traverseLines(configuration: IConfiguration, traverser: LineTraverser) {
  configuration.shapes.forEach(shape => {
    if (customShapeTypeGuard(shape)) {
      shape.lines.forEach(line => {
        const benches = getBenches(line.geometry);
        traverser({
          benches,
          instruments: line.instruments,
          line: line,
          allGeometry: line.geometry,
          sourceShape: shape
        });
      });
    } else if (backToBackShapeTypeGuard(shape)) {
      const benches = getBenches(shape.geometry);
      traverser({
        benches,
        instruments: shape.front.instruments,
        line: shape.front,
        allGeometry: shape.geometry,
        sourceShape: shape
      });
      traverser({
        benches,
        instruments: shape.back.instruments,
        line: shape.back,
        allGeometry: shape.geometry,
        sourceShape: shape
      });
    } else {
      const benches = getBenches(shape.geometry);
      traverser({
        benches,
        instruments: shape.instruments,
        line: shape,
        allGeometry: shape.geometry,
        sourceShape: shape
      });
    }
  });
}

function getBenches(geometry: GeometryElementList): ISection[] {
  const benches: ISection[] = [];
  for (const element of geometry) {
    if (sectionTypeGuard(element)) {
      benches.push(element);
    }
  }
  return benches;
}
