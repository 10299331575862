import * as React from 'react';
import { ReactComponent as icon } from './loader.svg';
import { cn } from 'components/utils';

interface IProps {
  className?: string;
  style?: React.CSSProperties | undefined;
}

export const Loader = ({ className, style }: IProps) => {
  const SVGIcon = icon;
  return (
    <div className={cn('m-auto', className)} style={style}>
      <SVGIcon />
    </div>
  );
};
