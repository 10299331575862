import { Object3D, Vector3 } from 'three';
import { Angle } from '../../schema';
import { Position } from './types';
import { round } from '../math';
import { angleNumberTypeGuard } from '../typeGuards';

export const toRadians = (degrees: number): number => (2 * Math.PI * degrees) / 360;

export function sumAngle(a: Angle, b: Angle): Angle {
  let current = (a + b) % 360; // values [0, 90, 180, 240];
  if (current === 270) {
    current = -90;
  }
  while (current !== -90 && current < 0) {
    current += 360;
  }
  if (angleNumberTypeGuard(current)) {
    return current;
  }
  throw new Error('Invalid angle');
}

export function shiftBasis(basis: Object3D, angle: number, shift: Vector3) {
  let newBasis = new Object3D();
  newBasis.rotation.y = angle;
  basis.add(newBasis);
  basis = newBasis;
  newBasis = new Object3D();
  newBasis.position.copy(shift);
  basis.add(newBasis);
  return newBasis;
}

export function getOrigin(basis: Object3D) {
  const position = new Vector3();
  return basis.localToWorld(position);
}

export function distance1d(coord1: number, coord2: number): number {
  return Math.sqrt((coord2 - coord1) ** 2);
}

export function distance(a: Position, b: Position): number {
  return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
}

export function roundPosition(position: Position): Position {
  return {
    x: round(position.x),
    y: round(position.y)
  };
}

export function positionEquals(a: Position, b: Position): boolean {
  return a.x === b.x && a.y === b.y;
}

export function getAngleBetween(first: Position, last: Position): Angle {
  if (first.y === last.y) {
    return last.x > first.x ? 0 : 180;
  }
  if (first.x === last.x) {
    return last.y > first.y ? -90 : 90;
  }
  throw new Error('Unsupported angle');
}

export function middlePosition(p1: Position, p2: Position): Position {
  return { x: 0.5 * (p1.x + p2.x), y: 0.5 * (p1.y + p2.y) };
}
