import { CameraState, ICameraOptimizer, IIntersectionService, IPositionService, ScreenBox2D, Size } from './types';
import { Camera, Object3D } from 'three';
import { IntersectionService } from './IntersectionService';
import { PositionService } from './PositionService';
import { BoundingBoxService } from './BoundingBoxService';

export class BinarySearchCameraOptimizer implements ICameraOptimizer {
  private min = 0;
  private max = 0;

  static create(
    size: Size,
    minDistance: number,
    maxDistance: number,
    precision = 0.001,
    maxIteration = 1000
  ): BinarySearchCameraOptimizer {
    const positionService = new PositionService();
    const intersectionService = new IntersectionService(size, positionService, new BoundingBoxService(), {});
    return new BinarySearchCameraOptimizer(
      minDistance,
      maxDistance,
      intersectionService,
      positionService,
      precision,
      maxIteration
    );
  }

  constructor(
    private readonly minDistance: number,
    private readonly maxDistance: number,
    private readonly intersectionService: IIntersectionService,
    private readonly positionService: IPositionService,
    private readonly precision = 1,
    private readonly maxIteration = 10000
  ) {}

  optimizeCameraPosition(camera: Camera, state: CameraState, root: Object3D, screenBoxes: ScreenBox2D[]): CameraState {
    this.intersectionService.init(screenBoxes, root);
    this.positionService.init(camera, state);

    this.min = this.minDistance;
    this.max = this.maxDistance;

    if (this.intersectionService.intersects(this.max)) {
      return this.positionService.stateFromDistance(this.max);
    }

    if (!this.intersectionService.intersects(this.min)) {
      return this.positionService.stateFromDistance(this.min);
    }

    let iterations = 0;
    let error = Infinity;
    while (iterations < this.maxIteration && error > this.precision) {
      let middle = 0.5 * (this.max + this.min);
      if (this.intersectionService.intersects(middle)) {
        this.min = middle;
      } else {
        this.max = middle;
      }
      error = Math.abs(this.max - this.min);
      iterations++;
    }

    return this.positionService.stateFromDistance(this.max);
  }
}
