import { Link } from 'react-router-dom';
import { cn } from '../../../components/utils';
import { ConfigurationMode } from '../../../domain/modes';
import { Icon, IconButton } from '../../../components';
import { routes } from '../../../routes';
import React from 'react';
import { HelpContent } from './Help';
import { Floater } from '../../../components/Floater';
import { FormattedMessage } from 'react-intl';

interface ConfigurationHeaderProps {
  isInstrumentActive: boolean;
  isBenchesActive: boolean;
  onInstrumentClick: () => void;
  onBenchesClick?: () => void;
  onSummaryClick: () => void;
  onBackClick: (event: React.MouseEvent<HTMLAnchorElement>, path: string) => void;
  isHiddenWhenEditMode: boolean;
  isSummaryShown: boolean;
  mode: ConfigurationMode;
}

export const ConfigurationHeader = ({
  onInstrumentClick,
  onBenchesClick,
  onSummaryClick,
  onBackClick,
  isBenchesActive,
  isInstrumentActive,
  isHiddenWhenEditMode,
  isSummaryShown,
  mode
}: ConfigurationHeaderProps) => {
  const isPredefined = mode === ConfigurationMode.Predefined;
  const backLink = isPredefined ? routes.predefined.link() : routes.own.link();

  return (
    <header
      className={cn(
        `fixed bottom-0 left-0 z-20 flex w-full items-center  border-t border-neutral-100 bg-neutral-0 px-4 py-4 max-ls:max-h-[102px] ls:top-0 ${
          isPredefined ? 'ls:w-20' : 'ls:w-24'
        } ls:flex-col ls:border-r ls:border-t-0 ls:px-4 ls:py-5`,
        isHiddenWhenEditMode && 'max-ls:hidden' // important: when changing ls:w-24 (width), change also <div className="ls:pl-24" /> to same value in ConfiguratorPage/index.tsx
      )}
    >
      <div
        className={cn(
          'pr-4 max-ls:flex max-ls:items-center max-ls:justify-center ls:pb-5 ls:pr-0',
          isPredefined && 'mx-auto my-auto pr-0'
        )}
      >
        <IconButton asChild size="lg">
          <Link to={backLink} onClick={event => onBackClick(event, backLink)}>
            <Icon icon="arrow_back" />
          </Link>
        </IconButton>
      </div>
      <div
        className={`w-[1px] border-r border-neutral-100 ls:h-[1px] ls:w-full ls:border-r-0 ${
          isPredefined ? 'border-r-0' : 'h-[70px] ls:border-b'
        }`}
      />
      <div className="flex w-full items-center justify-around gap-6 pl-4 ls:h-full ls:flex-col ls:justify-center ls:pl-0">
        {!isPredefined && onBenchesClick && (
          <IconButton
            variant="menu"
            color="secondary"
            onClick={onBenchesClick}
            active={isBenchesActive}
            data-onboarding="bench-button"
          >
            <Icon icon="menu_bench" />
            <p className="typography-menu-label mt-2">
              <FormattedMessage id="summary.benches" />
            </p>
          </IconButton>
        )}
        {!isPredefined && (
          <IconButton
            variant="menu"
            color="secondary"
            onClick={onInstrumentClick}
            active={isInstrumentActive}
            data-onboarding="instrument-button"
          >
            <Icon icon="menu_instrument" />
            <p className="typography-menu-label mt-2">
              <FormattedMessage id="summary.instruments" />
            </p>
          </IconButton>
        )}
        <IconButton
          variant="menu"
          color="secondary"
          onClick={onSummaryClick}
          active={isSummaryShown}
          data-onboarding="summary-button"
        >
          <Icon icon="menu_summary" />
          <p className="typography-menu-label mt-2">
            <FormattedMessage id="summary.header" />
          </p>
        </IconButton>
      </div>
      <Floater
        trigger={({ open }) => (
          <IconButton
            variant="menu"
            color="secondary"
            active={open}
            data-onboarding="help-button"
            data-testid="help-button"
            className="max-ls:hidden"
          >
            <Icon icon="help_outline" className="text-3xl" />
          </IconButton>
        )}
        options={{
          side: 'right',
          sideOffset: 30,
          alignOffset: 0,
          align: 'end'
        }}
        className="w-[380px]"
      >
        <HelpContent />
      </Floater>
    </header>
  );
};
