import React from 'react';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';

interface CustomInstrumentsBannerProps {
  className?: string;
  onClick: () => void;
}

export const CustomInstrumentsBanner: React.FC<CustomInstrumentsBannerProps> = ({ className, onClick }) => {
  const onSpanClick: React.KeyboardEventHandler<HTMLSpanElement> = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <div className={clsx('w-full rounded-sm bg-white p-4', className)}>
      <p className="typography-body2 !text-base text-neutral-900">
        <FormattedMessage id="configurator.menu.customInstruments.didntFindNeededTool" />{' '}
        <span
          className="border-none bg-none text-blue-500 outline-1 focus:outline focus:outline-blue-50"
          onClick={onClick}
          onKeyDown={onSpanClick}
          tabIndex={0}
          role="button"
        >
          <FormattedMessage id="configurator.menu.customInstruments.addYourCustomInstrument" />
        </span>
      </p>
    </div>
  );
};
