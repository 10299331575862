import { Button, ButtonProps } from 'components/Button';
import { Icon } from '../Icon';
import { Loader } from 'components/Loader/Loader';
import { cn } from 'components/utils';
import { useRef } from 'react';
import { useResizeObserver } from '../hooks/useResizeObserver';
import { FormattedMessage } from 'react-intl';

interface IProps extends ButtonProps {
  loading: boolean;
  onClick: () => void;
  active: boolean;
}

export const ARButton = ({ loading, onClick, active, variant = 'clear' }: IProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(buttonRef?.current);

  return (
    <Button
      variant={variant}
      size="lg"
      withIcon
      className={`max-md:px-3 ${active ? 'fill-primary-900 text-primary-900' : ''}`}
      onClick={onClick}
    >
      <Loader
        className={cn('flex items-center justify-center', loading ? 'absolute' : 'hidden')}
        style={{ width: `${width}px` }}
      />
      <div className={cn('flex items-center justify-center gap-2', loading ? 'invisible' : '')} ref={buttonRef}>
        <Button.ButtonIcon className="max-md:mr-0">
          <Icon icon="view_in_ar" />
        </Button.ButtonIcon>
        <Button.ButtonText className="m-auto h-full leading-normal max-md:hidden">
          <FormattedMessage id="scene.buttonAr" />
        </Button.ButtonText>
      </div>
    </Button>
  );
};
