import { ReactNode, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Menu, MenuItem, Header, Icon, IconButton, Button } from 'components';
import { arrowBackAnimation, duration, leftPartAnimation, rightPartAnimation } from './animationParameters';
import { routes } from '../../routes';
import { ConfigurationMode } from 'domain/modes/type';
import { localization } from 'stores/Localization';
import { rootStore } from 'stores/RootStore';
import { SelectLayoutEventGA } from 'services/GoogleAnalyticsService';
import { useHeightReporter } from '../../components/hooks/useHeightReporter';

export interface IMenuItem {
  value: string;
  text: string;
  description: string;
  link: string;
  preview?: string;
}

export interface ITemplatePageProps {
  title: ReactNode;
  menuItems: IMenuItem[];
  configurationMode: ConfigurationMode;
  description: ReactNode;
  footerContent?: ReactNode;
}

const AnotherOptionSection = ({ className, children }: { className?: string; children: ReactNode }) => {
  return (
    <p
      className={`typography-paragraph2 text-center text-neutral-500 max-ls:mb-10 max-md:mb-[24px] ls:text-left ${className} mb-4`}
    >
      {children}
    </p>
  );
};
export const PageTemplate = (props: ITemplatePageProps) => {
  const { title, menuItems, configurationMode, description, footerContent } = props;
  useHeightReporter();
  const navigate = useNavigate();
  if (!menuItems.length) {
    throw Error('There should be at least one item in template.');
  }

  const [activeItemValue, setActiveItemValue] = useState(menuItems[0].value);
  const activeItem = menuItems.find(item => item.value === activeItemValue);
  if (!activeItem) {
    throw new Error('Unexpected activeItem');
  }
  const goToActive = () => {
    navigate(activeItem.link);
  };

  return (
    <div className="flex flex-wrap items-center ls:min-h-0">
      <Header
        className="sticky top-0 w-full md:hidden"
        leftSlot={
          <IconButton asChild>
            <Link to={routes.home.link()}>
              <Icon icon="arrow_back" />
            </Link>
          </IconButton>
        }
      >
        {title}
      </Header>
      <motion.header
        className="fixed left-0 top-0 z-10 flex h-screen w-[72px] items-center justify-center border-r border-r-neutral-100 max-md:hidden"
        variants={arrowBackAnimation}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: duration }}
      >
        <IconButton asChild>
          <Link to={routes.home.link()}>
            <Icon icon="arrow_back" />
          </Link>
        </IconButton>
      </motion.header>
      <div className="relative mx-4 grid min-h-full grid-cols-2 gap-2 sm:mx-6 md:grid-cols-6 ls:grid-cols-12">
        <motion.div
          className="col-span-full row-start-1 flex flex-col justify-center gap-10 md:col-start-2 md:col-end-6 ls:gap-20 xl:col-end-5"
          variants={leftPartAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: duration }}
        >
          <div>
            <h1 className="mb-2 mt-10 text-[2.5rem]/[3rem] font-extrabold max-md:hidden md:text-[3.125rem]/[3.75rem] lg:text-[3.75rem]/[4.375rem]">
              {title}
            </h1>
            {description}
          </div>
          <div className="flex-col">
            <Menu className="max-ls:relative max-sm:left-1/2 max-sm:right-1/2 max-sm:ml-[-50vw] max-sm:mr-[-50vw] max-sm:w-screen max-sm:px-6">
              {menuItems.map(it => (
                <MenuItem<string>
                  onClick={setActiveItemValue}
                  value={it.value}
                  active={it.value === activeItemValue}
                  key={it.value}
                >
                  {localization.formatMessage(it.text)}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <AnotherOptionSection className="max-ls:hidden">{footerContent}</AnotherOptionSection>
        </motion.div>
        <motion.div
          className="col-span-full flex flex-col items-center justify-center md:col-start-2 ls:col-start-6 ls:col-end-13 ls:h-screen"
          variants={rightPartAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: duration }}
        >
          <div className="flex md:mb-20 md:mt-20 ls:justify-center">
            <div className="flex h-full min-h-72 w-full flex-grow justify-center md:min-h-96">
              {activeItem.preview ? (
                <motion.img
                  className="h-auto max-w-full animate-fadein object-contain"
                  src={activeItem.preview}
                  alt={localization.formatMessage(activeItem.text)}
                  key={activeItem.preview}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="mb-20 text-center ls:absolute ls:bottom-20 ls:mb-0 ls:items-center">
            <Button onClick={goToActive} asChild>
              <Link
                to={routes.configure.link(configurationMode, activeItemValue)}
                onClick={() =>
                  rootStore.gaEvent(
                    new SelectLayoutEventGA(localization.formatMessage(activeItem.text)),
                    configurationMode === ConfigurationMode.Own
                      ? 'Own layout selection'
                      : 'Pre-defined layout selection'
                  )
                }
              >
                Choose this configuration
              </Link>
            </Button>
          </div>
        </motion.div>

        <AnotherOptionSection className="col-span-full md:col-start-2 ls:hidden">{footerContent}</AnotherOptionSection>
      </div>
    </div>
  );
};
