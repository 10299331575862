import { cva, VariantProps } from 'class-variance-authority';
import { cn } from './utils';
import * as React from 'react';

const headerVariants = cva(
  'relative z-10 flex w-full  items-center border-b border-b-neutral-100 bg-neutral-0 px-4 py-6',
  {
    variants: {
      variant: {
        default: 'bg-neutral-0',
        dark: 'bg-neutral-50'
      },
      defaultVariants: {
        variant: 'default'
      }
    }
  }
);

interface IHeaderProps extends React.PropsWithChildren<VariantProps<typeof headerVariants>> {
  className?: string;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  withMobileGrabber?: boolean;
}

const Header = React.forwardRef<HTMLElement, IHeaderProps>(
  ({ children, className, variant, leftSlot, rightSlot, withMobileGrabber }, ref) => {
    return (
      <header ref={ref} className={cn(headerVariants({ variant, className }))}>
        {leftSlot && <div className="mr-2 flex items-center  ">{leftSlot}</div>}
        {withMobileGrabber && (
          <div className="absolute left-1/2 top-2.5 h-1 w-10 -translate-x-1/2 rounded bg-neutral-300 ls:hidden" />
        )}

        <h1 className="typography-h6 flex-1">{children}</h1>

        {rightSlot && <div className="flex items-center">{rightSlot}</div>}
      </header>
    );
  }
);

Header.displayName = 'Header';

export { Header };
