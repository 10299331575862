import { typeSymbol } from '@canvas-logic/engine';
import { generateUUID } from 'three/src/math/MathUtils';
import {
  Angle,
  ConnectionJoin,
  ConnectionSide,
  GeometryElementList,
  IConnection,
  IInstrument,
  InstrumentCategory,
  InstrumentList,
  ISection,
  SectionAlignment
} from '../../schema';
import { BenchLayoutService, InstrumentLayoutService } from '../layout';
import { Position, SegmentLine } from '../geometry';

type Section = ISection & { [typeSymbol]: 'Section' };
type Connection = IConnection & { [typeSymbol]: 'Connection' };
type Instrument = IInstrument & { [typeSymbol]: 'Instrument' };

function section(value: Omit<ISection, 'uuid' | 'hasTransport' | 'image' | 'name'>): Section {
  const result = value as Section;
  result[typeSymbol] = 'Section';
  result.uuid = generateUUID();
  return result;
}

function connection(value: IConnection): Connection {
  (value as any)[typeSymbol] = 'Connection';
  return value as Connection;
}

function instrument(value: Omit<IInstrument, 'uuid' | 'category' | 'name' | 'image'>): Instrument {
  const result: Instrument = value as Instrument;
  result[typeSymbol] = 'Instrument';
  result.uuid = generateUUID();
  result.category = InstrumentCategory.dispenser;
  result.image = '';
  result.name = '';
  return result;
}

export const b1R = section({
  alignment: SectionAlignment.R,
  size: 1,
  hasManipulator: false
});

export const b2R = section({
  alignment: SectionAlignment.R,
  size: 2,
  hasManipulator: false
});

export const b1L = section({
  alignment: SectionAlignment.L,
  size: 1,
  hasManipulator: false
});

export const b2L = section({
  alignment: SectionAlignment.L,
  size: 2,
  hasManipulator: false
});

export const m2R = section({
  alignment: SectionAlignment.R,
  size: 2,
  hasManipulator: true
});
export const m2L = section({
  alignment: SectionAlignment.L,
  size: 2,
  hasManipulator: true
});

export const alongR = connection({
  side: ConnectionSide.R,
  join: ConnectionJoin.along
});
export const alongL = connection({
  side: ConnectionSide.L,
  join: ConnectionJoin.along
});
export const asideR = connection({
  side: ConnectionSide.R,
  join: ConnectionJoin.aside
});
export const asideL = connection({
  side: ConnectionSide.L,
  join: ConnectionJoin.aside
});
export const x = instrument({
  view: 'x',
  width: 25,
  length: 50
});

export const s = instrument({
  view: 's',
  width: 100,
  length: 100
});

export const m = instrument({
  view: 'm',
  width: 100,
  length: 200
});

export const l = instrument({
  view: 'l',
  width: 100,
  length: 300
});

export const xl = instrument({
  view: 'xl',
  width: 200,
  length: 600
});

export const xxl = instrument({
  view: 'xxl',
  width: 300,
  length: 900
});

export const xxxl = instrument({
  view: 'xxxl',
  width: 300,
  length: 1200
});

export const BENCH_LENGTH = 980;
export const BENCH_WIDTH = 750;
export const BENCH_HEIGHT = 910;
export const RAIL_LENGTH = 1880;
export const MANIPULATOR_BENCH_WIDTH = 230;

export function createInstrumentLayout(
  instruments: InstrumentList,
  segmentLine: SegmentLine,
  benchWidth = BENCH_WIDTH
): InstrumentLayoutService {
  const layoutModel = {
    benchLength: BENCH_LENGTH,
    benchWidth: benchWidth,
    railLength: RAIL_LENGTH,
    manipulatorBenchWidth: MANIPULATOR_BENCH_WIDTH
  };

  return new InstrumentLayoutService(layoutModel, instruments, segmentLine, '');
}

export function createBenchLayout(
  geometry: GeometryElementList,
  angle: Angle = 0,
  position: Position = {
    x: 0,
    y: 0
  }
): BenchLayoutService {
  const model = {
    benchView: 'bench',
    transportBenchView: 'bench',
    manipulatorBenchView: 'manipulatorBench',
    manipulatorView: 'manipulator',
    manipulatorRailView: 'manipulatorRail',
    benchLength: BENCH_LENGTH,
    benchWidth: BENCH_WIDTH,
    railLength: RAIL_LENGTH,
    manipulatorBenchWidth: MANIPULATOR_BENCH_WIDTH
  };
  return new BenchLayoutService(model, {
    geometry,
    position,
    angle,
    uuid: ''
  });
}
