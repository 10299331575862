import React from 'react';
import { ElementCard } from '../../../../components';
import { localization } from '../../../../stores/Localization';
import { ISection } from '../../../../schema';
import { ActiveMode } from '../../../../domain/modes';

interface BenchesListProps {
  mode: ActiveMode;
  benches: ISection[];
  onToggle: (bench: ISection, selected: boolean) => void;
  isAvailable: (bench: ISection) => boolean;
}
export const BenchesList: React.FC<BenchesListProps> = ({ mode, benches, onToggle, isAvailable }) => {
  return (
    <div className="grid grid-cols-1 gap-2  md:grid-cols-2 md2:grid-cols-3 ls:grid-cols-1 xl:grid-cols-2">
      {benches.map((bench, index) => (
        <ElementCard
          key={bench.name}
          title={localization.formatMessage(bench.name)}
          image={bench.image}
          onToggle={selected => onToggle(bench, selected)}
          selected={mode.type === 'insertingBench' && mode.benchToAdd.name === bench.name}
          disabled={!isAvailable(bench)}
          disabledMessage={localization.formatMessage('installation.cantInstall')}
          autoFocus={index === 0}
        />
      ))}
    </div>
  );
};
