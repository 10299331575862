import { IInstrument, ISection } from '../../../schema';
import {
  InstrumentLayoutService,
  LayoutItem,
  InstrumentInsertionLayoutPayload,
  SectionInsertionLayoutPayload
} from '../index';
import { BenchServiceGroup, PlaceholdersPair } from './types';

export class AvailableAreasService {
  private readonly instrumentLayoutServices: InstrumentLayoutService[] = [];
  private readonly benchLayoutServices: BenchServiceGroup[] = [];

  public constructor() {}

  public setInstrumentLayoutService(service: InstrumentLayoutService): void {
    this.instrumentLayoutServices.push(service);
  }

  public setBenchLayoutService(serviceGroup: BenchServiceGroup): void {
    this.benchLayoutServices.push(serviceGroup);
  }

  public getAvailablePositionsForInstruments(instrument: IInstrument): LayoutItem<InstrumentInsertionLayoutPayload>[] {
    const response: LayoutItem<InstrumentInsertionLayoutPayload>[] = [];
    for (const service of this.instrumentLayoutServices) {
      response.push(...service.findFreePositionsToInsert(instrument));
    }
    return response;
  }

  public canInstrumentBeAppendedAnywhere(instrument: IInstrument): boolean {
    for (const service of this.instrumentLayoutServices) {
      if (service.isInstrumentInsertableAnywhere(instrument)) {
        return true;
      }
    }
    return false;
  }

  public getAvailablePositionsForBenches(bench: ISection): LayoutItem<SectionInsertionLayoutPayload>[] {
    const response: LayoutItem<SectionInsertionLayoutPayload>[] = [];

    for (const serviceGroup of this.benchLayoutServices) {
      if (serviceGroup.type === 'notBackToBack') {
        response.push(...serviceGroup.service.getPositionsToAppendBench(bench));
      } else if (serviceGroup.type === 'backToBack') {
        const rawPlaceholders = serviceGroup.services.map(service => service.getPositionsToAppendBench(bench));
        const combinedPlaceholders = AvailableAreasService.combinePlaceholdersForBackToBack(rawPlaceholders);
        response.push(...combinedPlaceholders);
      }
    }

    return response;
  }

  private static combinePlaceholdersForBackToBack(
    layoutItemPairs: LayoutItem<SectionInsertionLayoutPayload>[][]
  ): LayoutItem<SectionInsertionLayoutPayload>[] {
    if (layoutItemPairs.length !== 2) {
      throw new Error('For BackToBack 2 groups of placeholders must be returned');
    }

    const pairEnd: PlaceholdersPair = [layoutItemPairs[0][0], layoutItemPairs[1][1]];
    const pairStart: PlaceholdersPair = [layoutItemPairs[0][1], layoutItemPairs[1][0]];

    const startPlaceholder = AvailableAreasService.unite2PlaceholdersForBackToBack(pairStart);
    const endPlaceholder = AvailableAreasService.unite2PlaceholdersForBackToBack(pairEnd);

    return [startPlaceholder, endPlaceholder];
  }

  private static unite2PlaceholdersForBackToBack(pair: PlaceholdersPair): LayoutItem<SectionInsertionLayoutPayload> {
    const [first, second] = [pair[0], pair[1]];
    const angle = first.angle;

    if (angle === 0 || angle === 180) {
      return {
        ...first,
        centerY: (first.centerY + second.centerY) * 0.5
      };
    } else if (angle === -90 || angle === 90) {
      return {
        ...first,
        centerX: (first.centerX + second.centerX) * 0.5
      };
    }

    return pair[0];
  }
}
