import { useContext } from 'react';
import { cn } from 'components/utils';
import Notification from './Notification';
import { NotificationType } from './NotificationProvider';

const NotificationBar = () => {
  const notification = useContext(Notification);
  const color =
    notification.type === NotificationType.Success
      ? 'border-green-700'
      : notification.type === NotificationType.Error
      ? 'border-orange-700'
      : 'border-neutral-0';
  return (
    <div
      className={cn(
        'absolute inset-y-1/2 right-0 z-50 translate-x-full p-6 transition-transform duration-300 ease-in-out',
        'flex items-center justify-center',
        'bg-neutral-0',
        `orange-700 rounded-sm border-b-2 ${color}`,
        `transform ${!!notification.type ? 'translate-x-0' : 'translate-x-full'}`
      )}
    >
      <p> {notification.text} </p>
    </div>
  );
};
export default NotificationBar;
