import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Collapsible from '@radix-ui/react-collapsible';
import { CustomInstrumentForm } from '../CustomInstrumentForm';
import { CustomInstrumentDimensions } from '../../../../domain/instruments';
import { cn } from '../../../../components/utils';

interface CollapsibleCustomInstrumentFormProps {
  create: (dimensions: CustomInstrumentDimensions, name: string) => void;
}

export const CollapsibleCustomInstrumentForm: React.FC<CollapsibleCustomInstrumentFormProps> = ({ create }) => (
  <Collapsible.Root className="group w-full">
    <Collapsible.Trigger className="w-full">
      <button className="flex w-full justify-between bg-white px-6 py-8 outline-none">
        <span className="typography-body1">
          <FormattedMessage id="configurator.menu.customInstruments.form.formTitle" />
        </span>
        <span
          className={cn('material-icons transition-transform  duration-150 ease-out group-data-[state=open]:rotate-45')}
          aria-hidden
        >
          add
        </span>
      </button>
    </Collapsible.Trigger>
    <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
      <CustomInstrumentForm onSubmit={create} className="pt-0" />
    </Collapsible.Content>
  </Collapsible.Root>
);
