import { Box3, BufferGeometry, Camera, Mesh, Object3D, Texture, TextureLoader, Vector3 } from 'three';

export class ThreeUtils {
  getNodeBoundingBox(node: Object3D) {
    const bbox = new Box3();
    bbox.setFromObject(node);
    return bbox;
  }

  get2DPosition(vector: Vector3, camera: Camera, screenWidth: number, screenHeight: number) {
    const v = vector.project(camera);
    return {
      x: ((v.x + 1) * screenWidth) / 2,
      y: ((1 - v.y) * screenHeight) / 2
    };
  }

  // @todo: method fails during 2D -> 3D switch
  //        consider further investigation
  getMorphedBoundingBox(mesh?: Mesh) {
    if (!(mesh?.geometry instanceof BufferGeometry)) {
      throw new Error('BufferGeometry expected');
    }

    const morphCount = mesh.morphTargetInfluences ? mesh.morphTargetInfluences.length : 0;
    const positions = mesh.geometry.getAttribute('position');

    const min = new Vector3(Infinity, Infinity, Infinity),
      max = new Vector3(-Infinity, -Infinity, -Infinity);

    for (let i = 0; i < positions.count; i++) {
      let x = positions.getX(i);
      for (let j = 0; j < morphCount; j++) {
        x += mesh.morphTargetInfluences![j] * mesh.geometry.morphAttributes.position[j].getX(i);
      }
      if (x < min.x) {
        min.x = x;
      }
      if (x > max.x) {
        max.x = x;
      }
      let y = positions.getY(i);
      for (let j = 0; j < morphCount; j++) {
        y += mesh.morphTargetInfluences![j] * mesh.geometry.morphAttributes.position[j].getY(i);
      }
      if (y < min.y) {
        min.y = y;
      }
      if (y > max.y) {
        max.y = y;
      }
      let z = positions.getZ(i);
      for (let j = 0; j < morphCount; j++) {
        z += mesh.morphTargetInfluences![j] * mesh.geometry.morphAttributes.position[j].getZ(i);
      }
      if (z < min.z) {
        min.z = z;
      }
      if (z > max.z) {
        max.z = z;
      }
    }

    return new Box3(min, max);
  }

  async loadTexture(name: string) {
    return new Promise<Texture>((resolve: any, reject: any) => {
      new TextureLoader().load(
        name,
        texture => {
          resolve(texture);
        },
        //eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        () => {
          reject(new Error(`Cannot load texture ${name}`));
        }
      );
    });
  }

  vectorToArray(vector: Vector3): number[] {
    return [vector.x, vector.y, vector.z];
  }

  arrayToVector(xs: number[]) {
    return new Vector3(xs[0], xs[1], xs[2]);
  }
}

export const threeUtils = new ThreeUtils();
