import React, { ReactNode, createContext, useContext, useEffect } from 'react';
import {
  BeforeUnloadEventGA,
  ExitIntentPageName,
  HideTabEventGA,
  PageViewTitle
} from 'services/GoogleAnalyticsService';
import { RootStore } from 'stores/RootStore';
import { CloseTabHubspotEvent } from '../../services/HubspotService';

interface ExitIntentContextProps {
  page: ExitIntentPageName;
  rootStore: RootStore;
  children: ReactNode;
}

export const ExitIntentContext = createContext<ExitIntentContextProps | undefined>(undefined);

export const ExitIntentProvider = ({ children, page, rootStore }: ExitIntentContextProps) => {
  useEffect(() => {
    const pageTitle: PageViewTitle = page === ExitIntentPageName.HomePage ? "Configurator's home page" : page;
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        rootStore.gaEvent(new HideTabEventGA({ page: page }), pageTitle);
      }
    };

    const handleBeforeUnload = () => {
      rootStore.gaEvent(new BeforeUnloadEventGA({ page: page }), pageTitle);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return <ExitIntentContext.Provider value={{ page, rootStore, children }}>{children}</ExitIntentContext.Provider>;
};

export const useExitIntent = () => {
  return useContext(ExitIntentContext);
};
