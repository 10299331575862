import { cn } from './utils';

import { ReactComponent as BenchIcon } from '../components/icons/bench-icon.svg';
import { ReactComponent as InstrumentIcon } from '../components/icons/instrument-icon.svg';
import { ReactComponent as SummaryIcon } from '../components/icons/summary-icon.svg';
import { ReactComponent as CheckBoxChecked } from '../components/icons/check_box--checked.svg';

export type IconsVariants =
  | 'search'
  | 'arrow_back'
  | 'arrow_forward'
  | 'chevron_left'
  | 'chevron_right'
  | 'arrow_drop_up'
  | 'check'
  | 'close'
  | 'more_vert'
  | 'menu'
  | 'home'
  | 'share'
  | 'ios_share'
  | 'add'
  | 'remove'
  | 'delete'
  | 'view_in_ar'
  | 'picture_as_pdf'
  | 'email'
  | 'link'
  | 'straighten'
  | 'swap_horiz'
  | 'list_alt'
  | 'list'
  | 'loop'
  | 'delete_outline'
  | 'menu_bench'
  | 'menu_instrument'
  | 'menu_summary'
  | 'save_alt'
  | 'mail_outline'
  | 'check_box_checked'
  | 'help_outline';
export const Icon = ({ icon, className }: { icon: IconsVariants; className?: string }) => {
  let comp;
  switch (icon) {
    case 'menu_bench':
      comp = <BenchIcon />;
      break;
    case 'menu_instrument':
      comp = <InstrumentIcon />;
      break;
    case 'menu_summary':
      comp = <SummaryIcon />;
      break;
    case 'check_box_checked':
      comp = <CheckBoxChecked />;
      break;
    default:
      comp = icon;
  }
  return <span className={cn('material-icons [&>svg]:fill-current', className)}>{comp}</span>;
};
