import { observer } from 'mobx-react-lite';
import { ConfiguratorStore } from '../stores/ConfiguratorStore';
import { Viewer } from './Viewer/Viewer';
import { ConfigurationMode } from '../domain/modes';
import { ConfigurationViewer } from '../domain/viewer';
import { selectedModeTypeGuard } from '../domain/typeGuards';
import { useEffect } from 'react';
import { rootStore } from 'stores/RootStore';
import { ExitIntentPageName } from 'services/GoogleAnalyticsService';
import { ExitIntentProvider, useExitIntent } from './hooks/useExitIntent';

function noop() {}

interface IProps {
  store: ConfiguratorStore;
  mode: ConfigurationMode;
  onViewerCreated?: (viewer: ConfigurationViewer) => void;
}

export const Configuration = observer((props: IProps) => {
  const { store, onViewerCreated } = props;

  useEffect(() => {
    rootStore.gaPageView('Configurator');
  }, []);

  useExitIntent();

  return (
    <ExitIntentProvider page={ExitIntentPageName.Configurator} rootStore={rootStore}>
      <div className="m-auto h-screen w-full">
        {store.viewModel && (
          <Viewer
            cameraView={store.cameraStateProp}
            viewModel={store.viewModelProp}
            model={store.model}
            selectedUUID={store.selectedUUID}
            activeUUID={store.replacingInstrument}
            hoveredUUID={store.hoveredUUID}
            hoveredPlaceholder={store.hoveredPlaceholders}
            availableInstrumentAreas={store.highlightedInstrumentsPositions}
            availableBenchAreas={store.highlightedBenchPositions}
            onViewerCreated={onViewerCreated ? onViewerCreated : noop}
            onViewerRendered={noop}
            onElementHovered={store.enterHoveredElement.bind(store)}
            onInsertionPlaceholderHovered={store.highlightInsertionHover.bind(store)}
            onInstrumentSelected={store.enterSelectedInstrument.bind(store)}
            onBenchSelected={store.enterSelectedBench.bind(store)}
            onInstrumentInsertionSelected={store.addInstrument.bind(store)}
            onBenchInsertionSelected={store.addBench.bind(store)}
            onDeselected={() => selectedModeTypeGuard(store.activeMode) && store.enterOverviewMode()}
            showRuler={store.showRuler}
          />
        )}
      </div>
    </ExitIntentProvider>
  );
});
