export function normalizeUrl(url: string): string {
  if (url.slice(-1) === '/') {
    return url;
  }
  return url + '/';
}

function normalizePath(path: string): string {
  if (path.slice(0) === '/') {
    return path.slice(1);
  }
  return path;
}
export function createUrl(path: string): string {
  return window.location.origin + normalizeUrl(process.env.PUBLIC_URL) + normalizePath(path);
}

export function toUSLocaleString(value: number): string {
  return value.toLocaleString('en-US');
}
