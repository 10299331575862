import * as React from 'react';
import { Icon } from './Icon';
import { cn } from './utils';

type PropsToOmit = 'type';
export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, PropsToOmit> {
  label?: React.ReactNode;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({ className, label, checked, ...props }, ref) => {
  return (
    <label className={cn('group flex items-center gap-2', className)}>
      <div className="relative flex h-6 w-6 text-neutral-300">
        <input className="peer appearance-none" type="checkbox" checked={checked} {...props} />
        <div className="material-icons absolute h-full w-full opacity-100 transition-all group-hover:text-neutral-500 peer-checked:opacity-0 peer-focus-visible:ring-2">
          check_box_outline_blank
        </div>
        <Icon
          className="absolute h-full w-full text-pink-500 opacity-0 transition-all group-hover:text-pink-800 peer-checked:opacity-100 peer-focus-visible:ring-2"
          icon="check_box_checked"
        />
      </div>
      {label && <span className="typography-body2">{label}</span>}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';

export { Checkbox };
