import { PropsWithChildren } from 'react';

export const Menu = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <ul className={`gb:gap-6 flex w-full gap-4 overflow-x-scroll md:overflow-x-auto ls:flex-col ${className} `}>
      {children}
    </ul>
  );
};

export const MenuItem = <T extends any>({
  children,
  active,
  value,
  onClick
}: React.PropsWithChildren<{ active?: boolean; onClick: (value: T) => void; value: T }>) => {
  return (
    <li
      onClick={() => onClick(value)}
      className={`typography-h5  cursor-pointer whitespace-nowrap text-neutral-600  ${
        active ? 'text-primary-500' : 'hover:text-neutral-900'
      }`}
    >
      {children}
    </li>
  );
};
