import { INodeClickEvent } from './types';

class NodeClickEvent implements INodeClickEvent {
  stopped = false;

  stopPropagation() {
    this.stopped = true;
  }
}

export default NodeClickEvent;
