import { ConfiguratorStore } from '../../../../stores/ConfiguratorStore';
import { ConfigurationViewer } from '../../../../domain/viewer';
import { SummaryHeader } from './components/SummaryHeader';
import { SummaryBody } from './components/SummaryBody';
import { Header, useIsMobileScreenSize, useSwipable } from '../../../../components';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useScrollLock } from '../../../../components/hooks/useScrollLock';
import { EmailModal } from './components/EmailModal';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '../../../../components/utils';
import { PdfDownloaderService, SummaryService } from '../../../../services';
import { contactService } from '../../../../services/ContactService';
import { BluePrintService } from '../../../../services/BluePrintService';
import { ConfigurationMode } from '../../../../domain/modes';
import { rootStore } from 'stores/RootStore';
import { SendViaEmailEventGA } from 'services/GoogleAnalyticsService';
import { SaveAsPdfHubspotEvent, ShareViaEmailHubspotEvent } from '../../../../services/HubspotService';

interface ISummaryModal {
  onShare: () => void;
  shareLoading: boolean;
  viewer: ConfigurationViewer;
  store: ConfiguratorStore;
  mode: ConfigurationMode;
  onClose: () => void;
  createSharableLink: () => string;
}

const sanitizeSvg = (source: string = ''): string => {
  return source.replace('<svg width="100%"', '<svg');
};

export const SummaryModal = ({
  viewer,
  store,
  mode,
  onClose,
  onShare,
  shareLoading,
  createSharableLink
}: ISummaryModal) => {
  const [open, setOpen] = useState(false);
  const [emailFormVisible, setEmailFormVisible] = useState(false);
  const bluePrintRef = React.useRef<HTMLDivElement>(null);
  const isMobile = useIsMobileScreenSize();
  useScrollLock(isMobile);

  const { model, viewModel } = store;

  const { bluePrintService, reportData } = useMemo(() => {
    const bluePrintService = new BluePrintService(viewModel, model);
    const summaryService = new SummaryService(bluePrintService, viewer, model);
    const reportData = summaryService.prepareSummaryData();

    return {
      bluePrintService,
      reportData
    };
  }, [model, viewModel, viewer]);

  const handleEmailClose = useCallback(() => {
    setEmailFormVisible(false);
  }, []);

  const handleDownloadPdf = useCallback(async () => {
    const blueprintSvg = sanitizeSvg(bluePrintRef?.current?.innerHTML.toString());

    const configurationLink = createSharableLink();
    const pdfService = new PdfDownloaderService(reportData, blueprintSvg, configurationLink, mode);
    rootStore.hubspotTrackEvent(new SaveAsPdfHubspotEvent(configurationLink));
    await pdfService.downloadPdf();
  }, [createSharableLink, reportData]);

  const handleSubmitEmail = useCallback(
    async (recipients: string[]) => {
      const blueprintSvg = sanitizeSvg(bluePrintRef?.current?.innerHTML.toString());

      const configurationLink = createSharableLink();
      const gaEvent = new SendViaEmailEventGA();
      rootStore.gaEvent(gaEvent, 'Configurator');
      rootStore.hubspotTrackEvent(new ShareViaEmailHubspotEvent(configurationLink));
      const pdfService = new PdfDownloaderService(reportData, blueprintSvg, configurationLink, mode);
      const pdfDto = await pdfService.createPdfDto();

      await contactService.sendEmail(
        {
          recipients,
          link: configurationLink
        },
        pdfDto
      );
    },
    [createSharableLink, reportData]
  );

  /**
   * Delay state for 1 frame to ensure transition is visible
   */
  useEffect(() => {
    requestAnimationFrame(() => setOpen(true));
  }, []);

  const {
    sheetRef: emailSheetRef,
    handleRef: emailHandleRef,
    sheetStyle: emailSheetStyle,
    reset: resetEmailSwipe
  } = useSwipable({
    enabled: emailFormVisible,
    onCollapse: handleEmailClose,
    swipeDownBoundaryCoefficient: 0.7
  });

  useEffect(() => {
    if (!emailFormVisible) {
      resetEmailSwipe();
    }
  }, [emailFormVisible, resetEmailSwipe]);

  const {
    sheetRef: summarySheetRef,
    handleRef: summaryHandleRef,
    sheetStyle: summarySheetStyle,
    progress
  } = useSwipable({
    enabled: !emailFormVisible,
    onCollapse: () => onClose(),
    swipeDownBoundaryCoefficient: 0.7
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTransitionEnd = useCallback(
    (_: React.TransitionEvent) => {
      if (!open) {
        onClose();
      }
    },
    [onClose, open]
  );

  const initialStyle = { transform: 'translate(0, 100%)' } as CSSProperties;

  return (
    <div className="relative z-50 h-full w-full">
      <div
        className={cn('invisible absolute inset-0 bg-neutral-50 md:visible', {
          'transition-opacity': Number.isNaN(progress) || progress === 0 || !open
        })}
        style={{
          opacity: open && !Number.isNaN(progress) ? 0.8 - progress * 0.8 : 0
        }}
      />
      <div
        className={`
          isolate
          mx-auto
          my-0 mt-7 flex
          w-full
          flex-col
          items-center
          justify-center
          overflow-x-hidden
          shadow-modalMobile
          transition-transform
          ls:mt-0 ls:w-[740px] ls:shadow-modal xl:w-[900px]
        `}
        ref={summarySheetRef}
        style={{ ...initialStyle, ...(open ? summarySheetStyle : {}) }}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="flex max-h-[calc(100dvh-1.75rem)] w-full flex-col  bg-neutral-0 ls:max-h-none">
          <SummaryHeader onClose={handleClose} ref={isMobile ? summaryHandleRef : null} />
          <SummaryBody
            onDownloadPdf={handleDownloadPdf}
            onShare={onShare}
            shareLoading={shareLoading}
            onSendEmailClicked={() => {
              setEmailFormVisible(v => !v);
            }}
            mode={mode}
            bluePrintRef={bluePrintRef}
            reportData={reportData}
            bluePrintService={bluePrintService}
          />
        </div>
      </div>
      <AnimatePresence>
        {emailFormVisible && (
          <>
            <motion.div
              className="invisible absolute inset-0 bg-neutral-50 md:visible"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              exit={{ opacity: 0 }}
            />
            <motion.div
              className="absolute inset-0 flex flex-col"
              initial={{ opacity: 0.5, translateY: '100%' }}
              animate={{ opacity: 1, translateY: '0%' }}
              exit={{ opacity: 0.5, translateY: '100%' }}
              transition={{ type: 'tween', duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
            >
              <div
                ref={emailSheetRef}
                className={`
                  pointer-events-auto
                  relative
                  bottom-0
                  mt-auto
                  max-h-screen
                  w-full
                  shadow-modalMobile
                  md:m-auto md:w-[25rem] md:overflow-clip md:rounded-lg md:shadow-modal
                `}
                style={emailSheetStyle}
              >
                <Header className="md:hidden" withMobileGrabber ref={emailHandleRef} />
                <EmailModal onSubmit={handleSubmitEmail} onClose={handleEmailClose} />
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};
