import { Icon, Button, useResizeObserver } from '../../../components';
import { Loader } from '../../../components/Loader/Loader';
import { cn } from '../../../components/utils';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from '../../../components/Button';
import { rootStore } from '../../../stores/RootStore';

interface ShareButtonProps extends ButtonProps {
  onShare: () => void;
  loading: boolean;
  className?: string;
  hideTextOnScreen?: 'sm2' | 'md';
}

export const ShareButton = ({
  onShare,
  loading,
  variant = 'clear',
  className,
  hideTextOnScreen = 'md'
}: ShareButtonProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(buttonRef?.current);

  if (rootStore.isExhibition) {
    return null;
  }

  return (
    <Button size="lg" className={cn('relative max-md:px-3', className)} variant={variant} withIcon onClick={onShare}>
      <Loader
        className={cn('flex items-center justify-center', loading ? 'absolute' : 'hidden')}
        style={{ width: `${width}px` }}
      />
      <div className={cn('flex items-center justify-center gap-2', loading ? 'invisible' : '')} ref={buttonRef}>
        <Button.ButtonIcon className="max-md:mr-0">
          <Icon icon="ios_share" />
        </Button.ButtonIcon>
        <Button.ButtonText className={cn(`m-auto h-full leading-normal max-${hideTextOnScreen}:hidden`)}>
          <span className="align-middle">
            <FormattedMessage id="scene.buttonShare" />
          </span>
        </Button.ButtonText>
      </div>
    </Button>
  );
};
