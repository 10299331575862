import { ViewModel } from '../domain/viewer';
import { Angle, ConnectionJoin, ConnectionSide, IConfiguration, IConnection } from '../schema';
import { rotate, sortNumbers, unique } from '../components/BlueprintViewer/utils';
import { Vector2 } from 'three';
import { distance1d } from '../domain/geometry/utils';
import {
  backToBackShapeTypeGuard,
  connectionTypeGuard,
  linearShapeTypeGuard,
  lShapeTypeGuard
} from '../domain/typeGuards';

type DimensionsSet = Array<number>;
export type DimensionsSide = 'left' | 'right';

export type Dimensions = {
  horizontal: Array<DimensionsSet>;
  vertical: Array<DimensionsSet>;
};

export class BluePrintService {
  private shapeRotation: Angle = 0;
  public verticalDimensionsSide: DimensionsSide = 'left';

  constructor(private readonly _viewModel: ViewModel, private model: IConfiguration) {
    this._viewModel = _viewModel;
    this.model = model;

    this.shapeRotation = this.getShapeRotation();
    this.verticalDimensionsSide = this.getVerticalDimensionsSide();
  }

  get dimensions(): Dimensions {
    return this.buildDimensions();
  }

  get topLeftCorner(): Vector2 {
    return new Vector2(this.dimensions.horizontal[1][0], this.dimensions.vertical[1][0]);
  }

  get topRightCorner(): Vector2 {
    return new Vector2(this.dimensions.horizontal[1][1], this.dimensions.vertical[1][0]);
  }

  get bottomLeftCorner(): Vector2 {
    return new Vector2(this.dimensions.horizontal[1][0], this.dimensions.vertical[1][1]);
  }

  get bottomRightCorner(): Vector2 {
    return new Vector2(this.dimensions.horizontal[1][1], this.dimensions.vertical[1][1]);
  }

  get length(): number {
    return distance1d([...this.dimensions.horizontal].reverse()[0][1], [...this.dimensions.horizontal].reverse()[0][0]);
  }

  get width(): number {
    return distance1d([...this.dimensions.vertical].reverse()[0][1], [...this.dimensions.vertical].reverse()[0][0]);
  }

  get viewModel(): ViewModel {
    let sortedBenches = [...this._viewModel.benches].sort((a, b) => {
      if (a.view === b.view) {
        return 0;
      }

      if (a.view === this.model.manipulatorView) {
        return 1;
      }

      if (b.view === this.model.manipulatorView) {
        return -1;
      }

      return 0;
    });

    if (
      !(
        this.model.shapes.length === 1 &&
        (linearShapeTypeGuard(this.model.shapes[0]) || backToBackShapeTypeGuard(this.model.shapes[0]))
      )
    ) {
      sortedBenches = sortedBenches.map(bench => rotate(bench, this.shapeRotation));
    }

    return {
      ...this._viewModel,
      benches: sortedBenches
    };
  }

  get padding() {
    const defaultSidePadding = 120;

    return {
      top: 90,
      right: this.verticalDimensionsSide === 'right' ? defaultSidePadding : defaultSidePadding / 2,
      bottom: 50,
      left: this.verticalDimensionsSide === 'left' ? defaultSidePadding : defaultSidePadding / 2
    };
  }

  /**
   * Collect dimensions of elements that have perpendicular rotation angle as the target axis
   */
  private buildPerpendicularDimensions(angle: Angle): DimensionsSet {
    const marks = this.viewModel.benches.flatMap(bench => {
      if (angle === Math.abs(bench.angle) || angle + 180 === Math.abs(bench.angle)) {
        return [];
      }

      const benchRelevantCenter = Math.abs(angle) === 90 ? bench.centerY : bench.centerX;
      if (bench.view === this.model.transportBenchView || bench.view === this.model.benchView) {
        return [benchRelevantCenter - this.model.benchWidth / 2, benchRelevantCenter + this.model.benchWidth / 2];
      }

      if (bench.view === this.model.manipulatorView) {
        if (bench.angle === 0 || bench.angle === 90) {
          return [
            benchRelevantCenter - this.model.manipulatorBenchWidth / 2,
            benchRelevantCenter + this.model.manipulatorBenchWidth / 2 + 300
          ];
        } else {
          return [
            benchRelevantCenter - this.model.manipulatorBenchWidth / 2 - 300,
            benchRelevantCenter + this.model.manipulatorBenchWidth / 2
          ];
        }
      }

      if (bench.view === this.model.manipulatorBenchView) {
        return [
          benchRelevantCenter - this.model.manipulatorBenchWidth / 2,
          benchRelevantCenter + this.model.manipulatorBenchWidth / 2
        ];
      }

      return [];
    });

    return marks;
  }

  /**
   * Collect dimensions of elements that have the same rotation angle as the target axis
   */
  private buildParallelDimensions(angle: Angle): DimensionsSet {
    const marks = this.viewModel.benches.flatMap(bench => {
      if (angle !== Math.abs(bench.angle) && angle + 180 !== Math.abs(bench.angle)) {
        return [];
      }

      const benchRelevantCenter = Math.abs(angle) === 90 ? bench.centerY : bench.centerX;

      if (
        bench.view === this.model.manipulatorBenchView ||
        bench.view === this.model.transportBenchView ||
        bench.view === this.model.benchView
      ) {
        return [benchRelevantCenter - this.model.benchLength / 2, benchRelevantCenter + this.model.benchLength / 2];
      }

      return [];
    });

    return marks;
  }

  private buildDimensions(): Dimensions {
    const horizontal = unique([...this.buildParallelDimensions(0), ...this.buildPerpendicularDimensions(0)]);
    const vertical = unique([...this.buildParallelDimensions(90), ...this.buildPerpendicularDimensions(90)]);
    return {
      horizontal: [sortNumbers(horizontal), [Math.min(...horizontal), Math.max(...horizontal)]],
      vertical: [sortNumbers(vertical), [Math.min(...vertical), Math.max(...vertical)]]
    };
  }

  /**
   * Here we decide if shape should be rotated on a Blueprint canvas.
   * Ideally we want the longest section on top.
   *
   * @todo consider moving it to geometry service or smth
   */
  private getShapeRotation(): Angle {
    if (
      !(
        this.model.shapes.length === 1 &&
        (linearShapeTypeGuard(this.model.shapes[0]) || backToBackShapeTypeGuard(this.model.shapes[0]))
      )
    ) {
      if (lShapeTypeGuard(this.model.shapes[0])) {
        const shape = this.model.shapes[0];

        const { angle, sideOneLength, sideTwoLength } = shape.geometry.reduce<{
          angle?: IConnection;
          sideOneLength: number;
          sideTwoLength: number;
        }>(
          (result, geometry) => {
            if (connectionTypeGuard(geometry)) {
              result.angle = geometry;
              if (geometry.join === ConnectionJoin.along) {
                result.sideOneLength += this.model.benchWidth;
              }

              return result;
            }

            if (result.angle === undefined) {
              result.sideOneLength += geometry.size * this.model.benchLength;
            } else {
              result.sideTwoLength += geometry.size * this.model.benchLength;
            }

            return result;
          },
          { sideOneLength: 0, sideTwoLength: 0 }
        );

        if (angle) {
          if (angle.side === ConnectionSide.R) {
            return sideOneLength > sideTwoLength ? 0 : 90;
          } else {
            return sideOneLength > sideTwoLength ? 90 : 0;
          }
        }
      }

      return 90;
    }

    return 0;
  }

  /**
   * Determine a screen side to display vertical dimensions.
   *
   * We want to show it near the shape line (for L-configurations)
   */
  private getVerticalDimensionsSide(): DimensionsSide {
    if (this.model.shapes.length === 1 && lShapeTypeGuard(this.model.shapes[0])) {
      if (this.shapeRotation === 0) {
        return 'right';
      }
    }

    return 'left';
  }
}
