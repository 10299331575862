import { useEffect } from 'react';

export const useScrollLock = (active: boolean = true) => {
  useEffect(() => {
    if (active) {
      const originalOverflow = window.document.body.style.overflow;
      const originalOverscrollBehaviour = window.document.body.style.overscrollBehaviorY;

      window.document.body.style.overflow = 'hidden';
      window.document.body.style.overscrollBehaviorY = 'contain';

      return () => {
        window.document.body.style.overflow = originalOverflow;
        window.document.body.style.overscrollBehaviorY = originalOverscrollBehaviour;
      };
    }
  }, [active]);
};
