import { useEffect } from 'react';
import { rootStore } from '../../stores/RootStore';
import { IFrameMessageType } from '../../services/EmbeddingService';

export const useHeightReporter = (isConfiguratorPage = false) => {
  const isEmbedded = rootStore.isEmbedded();
  const handleHeightChanges = () => {
    if (isEmbedded) {
      const height = isConfiguratorPage ? '' : getFullHeight() + 'px';
      rootStore.embeddingService.sendMessageWithPayload(IFrameMessageType.ADJUST_HEIGHT, {
        height,
        scroll: isConfiguratorPage
      });
    }
  };

  useEffect(() => {
    if (isEmbedded) {
      setTimeout(handleHeightChanges, 300);
    }
  }, []);
};

function getFullHeight(): number {
  const limit = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  return limit;
}
